import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-qr-print',
  templateUrl: './qr-print.component.html',
  styleUrls: ['./qr-print.component.css']
})
export class QrPrintComponent implements OnInit, AfterViewInit {

  constructor(private route: ActivatedRoute) { }

  ngAfterViewInit(): void {
    window.print();
  }

  public printString = "";
  public label = "";

  ngOnInit(): void {
    this.route.queryParams.subscribe(x => {
      this.printString = x["value"];
      this.label = x["label"];
    })
  }

}
