import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Error404Component } from "./error404/error404.component";
import { EmployeetDashboardComponent } from "./employee/employeet-dashboard/employeet-dashboard.component";
import { RootDashboardComponent } from "./root/root-dashboard/root-dashboard.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { SpinnerModule } from "app/components/common/spinner/spinner.module";
import { LoginComponent } from "app/views/auth/login/login.component";
import { RecoveryComponent } from "app/views/auth/recovery/recovery.component";
import { RootCompaniesComponent } from "./root/root-companies/root-companies.component";
import { MatTableModule } from "@angular/material/table";
import { IboxModule } from "app/components/common/ibox/ibox.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RootCreateCompanyComponent } from "./root/root-companies/root-create-company/root-create-company.component";
import { CompanyFormModule } from "app/components/common/company-form/company-form.module";
import { RootEditCompanyComponent } from "./root/root-companies/root-edit-company/root-edit-company.component";
import { RootUsersComponent } from "./root/root-users/root-users.component";
import { RootCreateUserComponent } from "./root/root-users/root-create-user/root-create-user.component";
import { RootEditUserComponent } from "./root/root-users/root-edit-user/root-edit-user.component";
import { UserFormModule } from "app/components/common/user-form/user-form.module";
import { AdminUsersComponent } from "./admin/admin-users/admin-users.component";
import { AdminCreateUserComponent } from "./admin/admin-users/admin-create-user/admin-create-user.component";
import { AdminEditUserComponent } from "./admin/admin-users/admin-edit-user/admin-edit-user.component";
import { AdminBuildingsComponent } from "./admin/admin-buildings/admin-buildings.component";
import { AdminCreateBuildingsComponent } from "./admin/admin-buildings/admin-create-buildings/admin-create-buildings.component";
import { AdminEditBuildingsComponent } from "./admin/admin-buildings/admin-edit-buildings/admin-edit-buildings.component";
import { BuildingFormModule } from "app/components/common/building-form/building-form.module";
import { BuildingCompleteFormModule } from "app/components/common/building-complete-form/building-complete-form.module";
import { MatSliderModule } from "@angular/material/slider";
import { CommonModule } from "@angular/common";
import { ProfileComponent } from "./profile/profile.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { AppSelectModule } from "../components/common/appSelect/select.module";
import { RootBuildingsComponent } from "app/views/root/buildings/root-buildings.component";
import { AuthorisePointComponent } from "./root/buildings/authorisePoint/authorise-points.component";
import { AdminMetadataListComponent } from "./admin/PointMetaData/list/metadata.list.component";
import { AdminMetadataEditComponent } from "./admin/PointMetaData/edit/metadata.edit.component";
import { AdminDashboardComponent } from "./admin/admin-dashboard/admin-dashboard.component";
import { AdminPointDataComponent } from "./admin/admin-sensor-data/admin-sensor-data.component";

// import { VgCoreModule } from "videogular2/core";
// import { VgControlsModule } from "videogular2/controls";
// import { VgOverlayPlayModule } from "videogular2/overlay-play";
// import { VgBufferingModule } from "videogular2/buffering";

import { AdminMeterListComponent } from "./admin/PointMetaData/Meters/meter.list.component";
import { AppHighstockModule } from "../components/common/highcharts/highstock/highstock.module";
import { AdminMeterDataComponent } from "./admin/admin-meter-data/admin-meter-data.component";
import { TooltipModule } from "../components/common/tooltip/tooltip.module";
import { AdminAlarmListComponent } from "app/views/admin/alarm/alarm.list.component";
import { AdminAlarmDataComponent } from "./admin/admin-alarm-data/admin-alarm-data.component";
import { AdminApiKeyComponent } from "./admin/admin-apiKey/admin-apiKey.component";
import { AdminCreateApiKeyComponent } from "app/views/admin/admin-apiKey/admin-create-apiKey/admin-create-buildings.component";
import { AdminEditApiKeyComponent } from "./admin/admin-apiKey/admin-edit-apiKey/admin-edit-buildings.component";
import { ApiKeyFormModule } from "../components/common/apiKey-form/apiKey-form.module";
import { AdminMetadataVisibilityComponent } from "./admin/PointMetaData/visibility/metadata.visibility.component";
import { AdminMetadataCreateComponent } from "app/views/admin/PointMetaData/create/metadata.create.component";
import { MyQRCodeModule } from "app/components/common/QRCode/qr-code.module";
import { AdminMeterReadingComponent } from "./admin/meterReading/meter-reading.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DATEPICKER_FORMAT } from "app/components/adapters/DatepickerFormats";
import { AdminTagMapsComponent } from './admin/admin-tag-maps/admin-tag-maps.component';
import { AdminCreateTagMapsComponent } from './admin/admin-tag-maps/admin-create-tag-maps/admin-create-tag-maps.component';
import { AdminEditTagMapsComponent } from './admin/admin-tag-maps/admin-edit-tag-maps/admin-edit-tag-maps.component';
import { TagMapsModule } from "app/components/common/tag-maps-form/tag-maps.module";
import { TagMapsComponent } from 'app/components/common/tag-maps-form/tag-maps.component';
import { MapPopupComponent } from 'app/components/common/map-popup/map-popup.component';
import { MapPopupModule } from 'app/components/common/map-popup/map-popup.module';
import { AdminDriverComponent } from './admin/admin-driver/admin-driver.component';
import { MultiplePointsChartModule } from 'app/components/common/multiple-points-chart/multiple-points-chart.module';
import { PointsViewModule } from 'app/components/common/points-view/points-view.module';
import { AdminServerPointsComponent } from './admin/admin-server-points/admin-server-points.component';
import { AdminDevicesComponent } from './admin/admin-devices/admin-devices.component';
import { AdminDevicesFormComponent } from './admin/admin-devices-form/admin-devices-form.component';
import { PointFormModule } from 'app/components/common/point-form/point-form.module';
import { PointFormComponent } from 'app/components/common/point-form/point-form.component';
import { PointTagsComponent } from 'app/components/common/point-tags/point-tags.component';
import { PointTagsModule } from 'app/components/common/point-tags/point-tags.module';
import { PaginatorModule } from 'app/components/common/paginator/paginator.module';
import { AdminHistoryComponent } from './admin/admin-history/admin-history.component';
import { ProgressDisplayModule } from 'app/components/common/progress-display/progress-display.module';
import { PointsChartModule } from 'app/components/common/points-chart/points-chart.module';
import { AdminAdvancedViewComponent } from './admin/admin-advanced-view/admin-advanced-view.component';
import { AdvancedChartModule } from 'app/components/common/advanced-chart/advanced-chart.module';
import { AdminPredictionComponent } from './admin/admin-prediction/admin-prediction.component';
import { FilterFieldModule } from 'app/components/common/filter-field/filter-field.module';
import { AdminAlarmsNotificationComponent } from './admin/admin-alarms-notification/admin-alarms-notification.component';
import { AlarmsNotificationModule } from 'app/components/common/alarms-notification/alarms-notification.module';

import { AdminHealthComponent, FormatMonthYear, FormatYear, FormatMonth } from './admin/admin-health/admin-health.component';

import { GanttModule } from 'app/components/common/highcharts/gantt/gantt.module';
import { AdminTagFlagMapsComponent } from './admin/admin-tag-flag-maps/admin-tag-flag-maps.component';
import { GantAlarmsModule } from 'app/components/common/gant-alarms/gant-alarms.module';
import { AdminIntegrityIssuesComponent } from './admin/admin-integrity-issues/admin-integrity-issues.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AdminDataPredictionComponent } from './admin/admin-data-prediction/admin-data-prediction.component';
import { PredictionChartModule } from 'app/components/common/prediction-chart/prediction-chart.module';
import { GroupsListModule } from 'app/components/common/groups-list/groups-list.module';
import { AdminGroupsListComponent } from './admin/admin-groups-list/admin-groups-list.component';
import { AdminAssetsListComponent } from './admin/admin-assets-list/admin-assets-list.component';
import { AdminAssetViewComponent } from './admin/admin-asset-view/admin-asset-view.component';
import { MatIconModule } from '@angular/material/icon';
import { AssetItemComponent } from './admin/admin-asset-view/asset-item/asset-item.component';
import { AdminViolinComponent } from './admin/admin-violin/admin-violin.component';
import { HighchartsModule } from 'app/components/common/highcharts/highcharts/highcharts.module';
import { ComparsionChartComponent } from './admin/admin-asset-view/comparsion-chart/comparsion-chart.component';
import { AdminGroupsCreateComponent } from './admin/admin-groups-create/admin-groups-create.component';
import { AdminAssetsCreateComponent } from './admin/admin-assets-create/admin-assets-create.component';
import { GroupsAssetsFormModule } from 'app/components/common/groups-assets-form/groups-assets-form.module';
import { SearchPopupModule } from 'app/components/common/search-popup/search-popup.module';
import { AdminAssetsEditComponent } from './admin/admin-assets-edit/admin-assets-edit.component';
import { AdminGroupsEditComponent } from './admin/admin-groups-edit/admin-groups-edit.component';
import { AdminAutomaticGroupsComponent } from './admin/admin-automatic-groups/admin-automatic-groups.component';
import { AdvancedSearchModule } from 'app/components/common/advanced-search/advanced-search.module';
import { CarouselModule } from 'app/components/common/carousel/carousel.module';
import { PointsPopupComponent } from './admin/admin-automatic-groups/points-popup/points-popup.component';
import { AdminViolinDetailedComponent } from './admin/admin-violin-detailed/admin-violin-detailed.component';
import { AllChartComponent } from './admin/admin-asset-view/all-chart/all-chart.component';
import { GlobalErrorHandler } from "app/components/error_handler/global-error-handler";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptor } from "app/components/error_handler/http-error.interceptor";
import { AdminMetersReadingComponent } from './admin/admin-meters-reading/admin-meters-reading.component';
import { AdminMetersReadingPointPopupComponent } from './admin/admin-meters-reading/admin-meters-reading-point-popup/admin-meters-reading-point-popup.component';
import { DateSelectorModule } from "app/components/common/date-selector/date-selector.module";
import { TranslocoModule } from "@ngneat/transloco";
import { FaultsListComponent } from './admin/faults/faults-list/faults-list.component';
import { FaultsCreateComponent } from './admin/faults/faults-create/faults-create.component';
import { FaultsViewComponent } from "./admin/faults/faults-view/faults-view.component";
import { MatRadioButton, MatRadioModule } from "@angular/material/radio";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatSortModule } from "@angular/material/sort";
import { FaultsActionComponent } from './admin/faults/popups/faults-action/faults-action.component';
import { FaultsResolvedComponent } from './admin/faults/popups/faults-resolved/faults-resolved.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { FaultsReportComponent } from './admin/faults/faults-report/faults-report.component';
import { FaultsNotfaultComponent } from './admin/faults/popups/faults-notfault/faults-notfault.component';
import { FaultsDataComponent } from './admin/faults/popups/faults-data/faults-data.component';
import { IntegrityIssuesListComponent } from './admin/integrity-issues-list/integrity-issues-list.component';
import { UsersBuildingsModule } from "app/components/common/users-buildings/users-buildings.module";
import { PointValueSearchComponent } from './admin/point-value-search/point-value-search.component';
import { FaultAssignComponent } from './admin/faults/popups/fault-assign/fault-assign.component';
import { EndpointLog } from "app/services/endpointlog";
import { HelpPopupComponent } from './admin/help/help-popup/help-popup.component';
import { AlarmsComponent } from './admin/alarms/alarms.component';
import { NotePopupComponent } from './admin/alarms/note-popup/note-popup.component';
import { NoteTimelineComponent } from './admin/alarms/note-timeline/note-timeline.component';
import { DummyModalComponent } from "./admin/admin-dummy-modal/dummy-modal.component";
import { AdminHeatmapComponent } from './admin/admin-heatmap/admin-heatmap.component';
import { ExternalWeatherExportComponent } from './admin/external-weather-export/external-weather-export.component';

@NgModule({
  declarations: [
    //Date Formats
    FormatMonthYear,
    FormatYear,
    FormatMonth,

    // Errors components
    Error404Component,
    UnauthorizedComponent,

    // Admin components
    AdminDashboardComponent,
    AdminMetadataListComponent,
    AdminMetadataEditComponent,
    AdminMetadataCreateComponent,
    AdminPointDataComponent,
    AdminMeterListComponent,
    AdminMeterDataComponent,
    AdminAlarmListComponent,
    AdminAlarmDataComponent,
    AdminApiKeyComponent,
    AdminMetadataVisibilityComponent,
    AdminCreateApiKeyComponent,
    AdminEditApiKeyComponent,

    // Employee components
    EmployeetDashboardComponent,

    // Auth components
    LoginComponent,
    RecoveryComponent,

    //Dummy

    DummyModalComponent,


    // Root components
    RootBuildingsComponent,
    RootDashboardComponent,
    RootCompaniesComponent,
    AuthorisePointComponent,
    RootCreateCompanyComponent,
    RootEditCompanyComponent,
    RootUsersComponent,
    RootCreateUserComponent,
    RootEditUserComponent,
    AdminUsersComponent,
    AdminCreateUserComponent,
    AdminEditUserComponent,
    AdminBuildingsComponent,
    AdminCreateBuildingsComponent,
    AdminEditBuildingsComponent,
    ProfileComponent,
    ContactsComponent,
    ChangePasswordComponent,
    AdminMeterReadingComponent,
    AdminTagMapsComponent,
    AdminCreateTagMapsComponent,
    AdminEditTagMapsComponent,
    AdminDriverComponent,
    AdminServerPointsComponent,
    AdminDevicesComponent,
    AdminDevicesFormComponent,
    AdminHistoryComponent,
    AdminAdvancedViewComponent,
    AdminPredictionComponent,
    AdminAlarmsNotificationComponent,
    AdminHealthComponent,
    AdminHeatmapComponent,
    AdminTagFlagMapsComponent,
    AdminIntegrityIssuesComponent,
    AdminDataPredictionComponent,
    AdminGroupsListComponent,
    AdminAssetsListComponent,
    AdminAssetViewComponent,
    // AdminAssetItemViewComponent,
    AssetItemComponent,
    AdminViolinComponent,
    ComparsionChartComponent,
    AdminGroupsCreateComponent,
    AdminAssetsCreateComponent,
    AdminAssetsEditComponent,
    AdminGroupsEditComponent,
    AdminAutomaticGroupsComponent,
    PointsPopupComponent,
    AdminViolinDetailedComponent,
    AllChartComponent,
    AdminMetersReadingComponent,
    AdminMetersReadingPointPopupComponent,
    FaultsListComponent,
    FaultsCreateComponent,
    FaultsViewComponent,
    FaultsActionComponent,
    FaultsResolvedComponent,
    FaultsReportComponent,
    FaultsNotfaultComponent,
    FaultsDataComponent,
    IntegrityIssuesListComponent,
    PointValueSearchComponent,
    FaultAssignComponent,
    HelpPopupComponent,
    AlarmsComponent,
    NotePopupComponent,
    NoteTimelineComponent,
    ExternalWeatherExportComponent
  ],
  imports: [
    MatSlideToggleModule,
    TranslocoModule,
    PredictionChartModule,
    GantAlarmsModule,
    MatAutocompleteModule,
    GanttModule,
    AlarmsNotificationModule,
    AdvancedChartModule,
    PointsChartModule,
    ProgressDisplayModule,
    PaginatorModule,
    MatMenuModule,
    MyQRCodeModule,
    TooltipModule,
    AppHighstockModule,
    MatTooltipModule,
    BrowserModule,
    RouterModule,
    SpinnerModule,
    FilterFieldModule,
    IboxModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    CompanyFormModule,
    ApiKeyFormModule,
    UserFormModule,
    BuildingFormModule,
    TagMapsModule,
    MatTabsModule,
    BuildingCompleteFormModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,
    MatRadioModule,
    TextFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatDatepickerModule,
    MatListModule,
    AdvancedSearchModule,
    MatSortModule,

    MapPopupModule,
    AppSelectModule,
    LayoutModule,
    SearchPopupModule,

    // VgCoreModule,
    // VgControlsModule,
    // VgOverlayPlayModule,
    // VgBufferingModule,

    GroupsAssetsFormModule,
    MatIconModule,
    MatProgressBarModule,
    MultiplePointsChartModule,
    PointsViewModule,
    PointFormModule,
    PointTagsModule,
    GroupsListModule,
    HighchartsModule,
    CarouselModule,
    DateSelectorModule,
    NgxAudioPlayerModule,
    UsersBuildingsModule
  ],
  exports: [],
  entryComponents: [
    TagMapsComponent,
    AdminDevicesFormComponent,
    MapPopupComponent,
    PointFormComponent,
    PointTagsComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EndpointLog, multi: true }
  ]
})

export class ViewsModule {
}
