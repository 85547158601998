import { Component, OnInit } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { BuildingService } from "app/services/building.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerService } from "app/services/spinner.service";
import { IBuilding } from "app/interfaces/building";
import { LoginService } from "app/services/login.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { FileUploadService } from 'app/services/file-upload.service';
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-admin-edit-buildings",
  templateUrl: "./admin-edit-buildings.component.html",
  styleUrls: ["./admin-edit-buildings.component.css"]
})
export class AdminEditBuildingsComponent implements OnInit {

  constructor(private transloco: TranslocoService, private route: ActivatedRoute, private loginService: LoginService, private buildingService: BuildingService,
    public i18n: AppI18nService, private router: Router, private spinner: SpinnerService, private alert: SweetalertService, private uploadService: FileUploadService) {
  }

  public building: IBuilding;

  ngOnInit() {
    this.spinner.activate();
    this.route.params.subscribe(x => {
      let building = x["id"];

      this.buildingService.getOne(building).subscribe(result => {
        this.building = result.data;
        this.spinner.desactivate();
      });
    });
  }

  public save(event) {
    let values = event.value;
    let building = {
      address: values.address,
      butterWorthRecoveryMethod: values.butterWorthRecoveryMethod,
      company: this.loginService.userInfo.company,
      contacts: {
        emails: values.contact.emails,
        telephones: values.contact.telephones
      },
      description: values.description,
      floorBreakdown: values.floorBreakdown,
      ServiceLevelAgreements: values.ServiceLevelAgreements,
      workingHours: values.workingHours,
      name: values.name,
      timeZone: values.timeZone
    };

    this.spinner.activate();
    this.buildingService.update(this.building, building).subscribe(
      (x) => {
        if (values.photo) {
          this.buildingService.getUploadURL(x.data._id).subscribe(res => {
            let url = res.data.uploadURL;
            console.log(url);

            this.uploadService.upload(values.photo, url).subscribe(up => {
              this.spinner.desactivate();
              this.return();
            })
          })
        }
        else {
          this.spinner.desactivate();
          this.return();
        }
      },
      err => {
        console.log(err);
        this.spinner.desactivate();
      }
    );
  }



  private return() {
    this.alert.success(this.transloco.translate("messages.success"),this.transloco.translate("messages.changessaved"));
    this.router.navigate(["admin", "buildings"]);
  }
}
