import { Component, OnInit, ViewChild } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "app/services/app.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { LoginService } from "app/services/login.service";
import { ActivatedRoute } from "@angular/router";
import { AdminPointService } from "../../../services/data.service";
import * as moment from "moment-timezone";
import { UserType } from "app/interfaces/baseinterface";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DATEPICKER_FORMAT } from "app/components/adapters/DatepickerFormats";
import { BuildingService } from 'app/services/building.service';

import * as humanizeDuration from "humanize-duration"
import { MatDatepicker } from "@angular/material/datepicker";
import { Moment } from "moment-timezone";
import { SessionService } from "app/services/session.service";
import { FutureDecisionsDate } from "app/components/classes/futuredecisions.date";

@Component({
  selector: "app-admin-alarm-list",
  templateUrl: "./alarm.list.component.html",
  styleUrls: ["./alarm.list.component.css"],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }
  ]
})
export class AdminAlarmListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public max = moment().startOf("day");

  public dataSource = new MatTableDataSource();
  public enums = UserType;
  public displayedColumns: string[];
  public building_name: string;
  public onlyActiveAlarms = false;

  public hasAlarms = true;

  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.refresh();
  }

  private filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;

    this.refresh();
  }


  chosenMonthHandlerStart(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    this.form.get("start").setValue(normalizedMonth);
  }
  chosenMonthHandlerEnd(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    this.form.get("end").setValue(normalizedMonth);
  }

  public form = new FormGroup({
    building: new FormControl("", [Validators.required]),
    start: new FormControl(new FutureDecisionsDate().startOf("day"), [Validators.required]),
    onlyActiveAlarms: new FormControl(false),
    timeSelection: new FormControl("Day", [Validators.required]),
  })

  public viewStart = moment.utc();
  public viewEnd = moment.utc();

  constructor(private sessionService: SessionService, private buildingService: BuildingService, private loginService: LoginService, public dataService: AdminPointService, private appService: AppService,
    public i18n: AppI18nService, private spinnerService: SpinnerService, private alert: SweetalertService, private route: ActivatedRoute) {

  }

  public buildings = [];

  ngOnInit() {
    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;

      this.spinnerService.desactivate();
    })

    this.route.queryParams.subscribe(params => {
      let t = params["building"];
      this.form.get("building").setValue(t);
      this.form.get("onlyActiveAlarms").setValue(params["onlyActiveAlarms"]);

      if (t != null || t != undefined) {
        this.refresh()
        this.sessionService.building = t;
      }
      else if (this.sessionService.building) {
        this.form.get("building").setValue(this.sessionService.building);
        this.refresh();
      }
    });

    this.displayedColumns = [
      "index", "ord", "types", "timestamp", /*"lastReceivedAlarm", "numberOfAlarms",*/  "duration", "active"
    ];

    // this.refresh();
  }

  ngAfterViewInit() {
  }

  public possibleWindows = ["Day", "Month", "Week"];

  public refresh() {

    let value = this.form.value;
    this.sessionService.building = value.building;

    let start = value.start.dateObject.clone();
    let end = start.clone();

    switch (value.timeSelection) {
      case "Month":
        start = start.startOf("month");
        end = end.endOf("month");
        break;
      case "Day":
        start = start.startOf("day");
        end = end.endOf("day");
        break;
      case "Week":
        start = start.startOf("week");
        end = end.endOf("week");
        break;
    }

    this.spinnerService.activate();
    this.dataService.GetAllAlarms({
      building: value.building,
      start: start,
      end: end,
      onlyActives: value.onlyActiveAlarms
    }, this.filter, this.pagination.page, this.pagination.pageSize).subscribe(
      results => {
        // console.log(results);
        // let data = results.data.map((d, index) => {
        // //   return Object.assign(d, {
        // //     index: (this.pagination.page - 1) * this.pagination.pageSize + index + 1,
        // //     timestamp: this.appService.GetDateString(d.timestamp),
        // //     lastReceivedAlarm: this.appService.GetDateTimeString(d.lastReceivedAlarm),
        // //     active: d.inAlarmState ? (d.alarm[) : "Inactive"
        // //   });
        // });

        let data = [];

        for (let i = 0; i < results.data.length; i++) {
          // let keys = Object.keys(results.data[i].alarm.types);

          // for (let j = 0; j < keys.length; j++) {
          //   let x = results.data[i].alarm.types[j];

          // }

          let ts = [];

          let tempd = results.data[i].alarm.data
          for(let pos of Object.keys(tempd)){
            for(let key of tempd[pos]){
              ts[key] = [];
            }
          }

          data.push({
            duration: humanizeDuration(moment(results.data[i].timestamp).diff(moment(results.data[i].lastReceived)), { largest: 2, round: true }),
            index: (this.pagination.page - 1) * this.pagination.pageSize + i + 1,
            timestamp: moment(results.data[i].timestamp).format("DD/MM/YYY HH:mm Z"),
            types: Object.keys(ts).join(","),
            typesBreakdown: "",
            ord: results.data[i].ord,
            lastReceivedAlarm: moment(results.data[i].lastReceived).format("DD/MM/YYY HH:mm Z"),
            active: results.data[i].inAlarmState ? "Active" : "Inactive"
          });
        }

        this.hasAlarms = data.length > 0;

        this.dataSource.data = data;
        this.pagination.hasNext = data.length > this.pagination.pageSize;
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
        // this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
      }
    );
  }
}
