import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource } from '@angular/material/tree';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent implements OnInit {

  @Input() query: AdvancedSearchQuery = { condiotion: "And", subQuery: [], expanded: true, functions: [{
    function: "Contains",
    value: ""
  }] }
  @Output() queryChange: EventEmitter<AdvancedSearchQuery> = new EventEmitter<AdvancedSearchQuery>();

  constructor() { }

  ngOnInit(): void {
  }
}

export interface AdvancedSearchQuery {
  subQuery: AdvancedSearchQuery[],
  condiotion: string,
  expanded: boolean,
  functions: { function: string, value: any }[]
}

export const AdvancedSearchCondition: string[] = ["And", "Or"];

export const AdvancedSearchFunction: string[] = ["Contains", "Doesn't Contains"]
