import { Component, OnInit } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerService } from "app/services/spinner.service";
import { LoginService } from "app/services/login.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { IApiKey } from "../../../../interfaces/apiKey";
import { ApiKeyService } from "../../../../services/apiKey.service";

@Component( {
  selector: "app-admin-edit-buildings",
  templateUrl: "./admin-edit-buildings.component.html",
  styleUrls: [ "./admin-edit-buildings.component.css" ]
} )
export class AdminEditApiKeyComponent implements OnInit {

  constructor( private route: ActivatedRoute, private loginService: LoginService, private apiKeyService: ApiKeyService,
    public i18n: AppI18nService, private router: Router, private spinner: SpinnerService, private alert: SweetalertService ) {

  }

  private building;
  public apiKey: IApiKey;

  ngOnInit() {
  }

  public save( event: IApiKey ) {
    this.spinner.activate();
    this.apiKeyService.update( this.apiKey, event ).subscribe(
      ( x ) => {
        this.spinner.desactivate();

        // this.alert.success( this.i18n.translate( "general.messages.success" ),
        //   this.i18n.translate( "general.messages.updated", this.i18n.translate( "interfaces.apiKey.apiKeys" ) ) );
        this.alert.success("Success!", "Changes to API Key saved!");
        this.router.navigate( [ ".."], { relativeTo: this.route }  );
      },
      e => {
        console.log( e );
        this.spinner.desactivate();
      }
    );
  }

  public delete( event: any ) {
    this.apiKeyService.delete( event ).subscribe(
      ( x ) => {
        this.spinner.desactivate();

        this.alert.success( this.i18n.translate( "general.messages.success" ),
          this.i18n.translate( "general.messages.deleted", this.i18n.translate( "interfaces.apiKey.apiKeys" ) ) );
        this.router.navigate( [ "admin", "buildings", this.building, "apiKeys" ] );
      },
      e => {
        console.log( e );
        this.spinner.desactivate();
      }
    );
  }

}
