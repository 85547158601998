import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaultsService {

  constructor() { }

  public getAll(page: number, pageSize: number): Observable<any> {
    return new Observable(observer => {
      setTimeout(function () {
        let r = [];

        for (let i = 0; i < pageSize + 1; i++) {
          r.push({
            _id: i,
            date: new Date().toISOString(),
            status: "open",
            issue: "asset",
            subject: "Something went wrong",
            reportedBy: "James",
            assignedTo: "Big Mac",
            severity: "blue"
          })
        }

        observer.next({ data: r });
        observer.complete();
      }, 5000);
    })
  }

  public getContacts(): Observable<any> {

    let fake = [
      "scato@hotmail.com",
      "shawnce@mac.com",
      "parents@live.com",
      "jshirley@aol.com",
      "pavel@yahoo.ca",
      "harryh@yahoo.com",
      "gilmoure@icloud.com",
      "gospodin@verizon.net",
      "crowl@hotmail.com",
      "stefano@live.com",
      "okroeger@aol.com",
      "mailarc@yahoo.ca",
      "ghost@mac.com",
      "dogdude@sbcglobal.net",
      "mallanmba@yahoo.ca",
      "keiji@yahoo.ca",
      "lstein@verizon.net",
      "zavadsky@comcast.net",
      "philen@yahoo.ca",
      "drewf@icloud.com",
      "wilsonpm@optonline.net",
      "kidehen@yahoo.ca",
      "kingma@sbcglobal.net",
      "msroth@sbcglobal.net",
      "rasca@aol.com",
      "andale@att.net",
      "klaudon@comcast.net",
      "rgarcia@yahoo.com",
      "cyrus@live.com",
      "jsmith@msn.com",
      "sinkou@aol.com",
      "podmaster@live.com",
      "agapow@icloud.com",
      "william@msn.com",
      "jgoerzen@comcast.net",
      "presoff@gmail.com",
      "denism@verizon.net",
      "carmena@yahoo.com",
      "crandall@yahoo.ca",
      "malvar@optonline.net",
      "thowell@sbcglobal.net",
      "podmaster@hotmail.com",
      "microfab@msn.com",
      "amcuri@gmail.com",
      "ghost@hotmail.com",
      "microfab@gmail.com",
      "jfmulder@live.com",
      "jnolan@yahoo.com",
      "eurohack@yahoo.ca",
      "jemarch@att.net",
      "mthurn@mac.com",
      "weazelman@yahoo.com",
      "rgarton@gmail.com",
      "tskirvin@att.net",
      "samavati@yahoo.ca",
      "pkplex@me.com",
      "ubergeeb@sbcglobal.net",
      "mallanmba@comcast.net",
      "afifi@gmail.com",
      "msroth@gmail.com",
      "mosses@sbcglobal.net",
      "roamer@mac.com",
      "claesjac@msn.com",
      "epeeist@att.net",
      "demmel@verizon.net",
      "chaffar@aol.com",
      "muzzy@gmail.com",
      "cgcra@hotmail.com",
      "grinder@yahoo.ca",
      "trieuvan@verizon.net",
      "bolow@hotmail.com",
      "storerm@yahoo.ca",
      "ijackson@outlook.com",
      "houle@hotmail.com",
      "nasor@gmail.com",
      "jacks@icloud.com",
      "slanglois@optonline.net",
      "stakasa@aol.com",
      "eidac@outlook.com",
      "tedrlord@aol.com",
      "satch@comcast.net",
      "stakasa@aol.com",
      "konit@att.net",
      "carcus@optonline.net",
      "netsfr@yahoo.com",
      "kludge@icloud.com",
      "khris@verizon.net",
      "chance@yahoo.com",
      "kempsonc@sbcglobal.net",
      "howler@msn.com",
      "violinhi@mac.com",
      "chaki@aol.com",
      "scato@me.com",
      "elmer@icloud.com",
      "ghost@yahoo.ca",
      "intlprog@sbcglobal.net",
      "budinger@optonline.net",
      "csilvers@icloud.com",
      "cumarana@yahoo.com",
      "yzheng@live.com"
    ]

    return new Observable(observer => {
      setTimeout(function () {
        let r = [];

        fake.forEach((x,i) => {
          r.push({_id: i.toString(), name: x.split("@")[0], email: x});
        })

        observer.next({ data: r });
        observer.complete();
      }, 5000);
    })
  }
}
