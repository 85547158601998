import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BuildingService } from 'app/services/building.service';
import { DriverService } from 'app/services/driver.service';
import { NotificationAlarmTypes, ReportService } from 'app/services/report.service';
import { SpinnerService } from 'app/services/spinner.service';

import * as moment from "moment-timezone";
import * as humanizeDuration from "humanize-duration"
import { SessionService } from 'app/services/session.service';
import { QueryparamsService } from 'app/services/queryparams.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-integrity-issues-list',
  templateUrl: './integrity-issues-list.component.html',
  styleUrls: ['./integrity-issues-list.component.css']
})
export class IntegrityIssuesListComponent implements OnInit {

  constructor(private queryService: QueryparamsService, private sessionService: SessionService, private driverService: DriverService, private transloco: TranslocoService, private reportService: ReportService, private buildingService: BuildingService, private route: ActivatedRoute, private spinner: SpinnerService) { }
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });
  public filterBuilding(){
    
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;

    let searchBase = this.buildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.buildings;
}
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    this.selectedBuilding = this.FilteredselectedBuilding.id;
    this.sessionService.building =  this.FilteredselectedBuilding.id;
    console.log(this.selectedBuilding);
    this.onBuildingChange();
  }



  public displayedColumns: string[] = ["active", "device", "type", "start", "end", "duration", "samples"]

  public activeOnly: boolean = true;
  public selectedDevice: string = "";
  public ord: string = "";
  public selectedTypes: any[] = this.reportService.possibleAlarms;

  public devices: any[] = [];
  public buildings: any[] = [];

  public noIssues: boolean = true;

  private integrity: any[] = [];

  public types = this.reportService.possibleAlarms;

  public dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinner.desactivate();
      this.buildings = x.data.map(t => {
        return {
          id: t._id,
          name: t.name
        }
      })

      this.route.queryParams.subscribe(p => {
        if (p["building"]) {
          this.selectedBuilding = p["building"];

          this.onBuildingChange();
        }
        else if (this.sessionService.building) {
          this.selectedBuilding = this.sessionService.building;
          // this.onBuildingChange();
        }
      })
    });
  }

  public onBuildingChange() {
    this.sessionService.building = this.selectedBuilding;
    this.selectedDevice = "";

    this.refresh();
  }
  public reloadListDevices(){
    this.spinner.activate();

    this.buildingService.getIntegrityIssues(this.selectedBuilding, this.activeOnly, "", this.ord, this.selectedTypes).subscribe(d => {
        this.integrity = d.data;
        this.spinner.desactivate();

        //   console.log(d);
          this.devices = d.devices;
          this.devices.push({
              friendlyName: "All devices",
              _id: ""
          })
      
  
  })
}

  public refresh() {
    this.spinner.activate();
    this.buildingService.getIntegrityIssues(this.selectedBuilding, this.activeOnly, this.selectedDevice, this.ord, this.selectedTypes).subscribe(d => {
      this.integrity = d.data;

      this.spinner.desactivate();
        // console.log(d);
        this.devices = d.devices;
        this.devices.push({
            friendlyName: "All devices",
            _id: ""
        })

      

      let data = [];

      this.noIssues = data.length == 0;
      this.dataSource.data = data;

      for (let dd of this.integrity) {
        for (let key of Object.keys(dd.integrityIssues)) {
          if (this.selectedTypes.indexOf(parseInt(key)) >= 0) {
            let issue = dd.integrityIssues[key];

            let end = moment(issue.lastActive);
            data.push({
              device: dd.displayOrd,
              active: issue.active,
              end: end.format("DD/MM/YYYY HH:mm Z"),
              samples: issue.totalSamplesAffected,
              type: this.reportService.getErrorType(key),
              start: end.clone().subtract(issue.totalSamplesAffected, "minute").format("DD/MM/YYYY HH:mm Z"),
              // timestamp: start.valueOf(),
              duration: humanizeDuration(end.diff(end.clone().subtract(issue.totalSamplesAffected, "minute")), { round: true, units: ["d", "h"] })
            })

          }
        }
      }

      this.noIssues = data.length == 0;

      data = data.sort((firstEl, secondEl) => {
        if (firstEl.timestamp > secondEl.timestamp) {
          return -1;
        }
        if (firstEl.timestamp < secondEl.timestamp) {
          return 1;
        }
        return 0;
      })

      this.dataSource.data = data;
      this.reloadListDevices();

    });

   
    
  }
  

}
