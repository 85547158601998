import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersBuildingsComponent } from './users-buildings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PaginatorModule } from '../paginator/paginator.module';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [UsersBuildingsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    TranslocoModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSlideToggleModule,
    PaginatorModule,
    MatTooltipModule
  ]
})
export class UsersBuildingsModule { }
