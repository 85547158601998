import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'app/components/common/map-popup/map-popup.component';

@Component({
  selector: 'app-faults-notfault',
  templateUrl: './faults-notfault.component.html',
  styleUrls: ['./faults-notfault.component.css']
})
export class FaultsNotfaultComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<FaultsNotfaultComponent>) { }

  public form: FormGroup = new FormGroup({
    description: new FormControl("", [Validators.required])
  })

  ngOnInit(): void {
  }


  public save() {
    this.dialogRef.close(this.form.value);
  }
}
