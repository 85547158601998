
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { BaseService, IServiceError } from "app/services/base.service";
import { IBuilding } from "app/interfaces/building";
import { UserType } from "app/interfaces/baseinterface";
import { ITagMaps } from "app/interfaces/tagMap";

@Injectable()
export class BuildingService extends BaseService<IBuilding> {
  rootUrl = "buildings";

  public checkForCredentials() {
    // return super.checkForCredentials(UserType.Admin);
  }

  //[GET] buildings/{buildingId}/alarmSummary
  public alarmSummary(buildingId): Observable<any> {
    return this.doGet(`/buildings/${buildingId}/alarmSummary`);
  }

  //[GET] buildings/{buildingId}//detailedAlarms
  public detailedAlarms(buildingId, state: "Normal" | "NotNormal", acked: boolean, page: number = 1, pageSize: number = 100): Observable<any> {
    return this.doGet(`buildings/${buildingId}/detailedAlarms?state=${state}&acked=${acked}&page=${page}&pageSize=${pageSize}`);
  }

  //   actions
  // [post] /buildings/{buildingId}/faults/{faultId}
  // action types {
  //   1: { type: "resolve", msgRequired: true },
  //   2: { type: "notFault", msgRequired: true },
  //   3: { type: "addNote", msgRequired: true }
  // }
  public faultAction(buildingId: string, faultId: string, message: string, action: 1 | 2 | 3) {
    return this.doPost(`/buildings/${buildingId}/faults/${faultId}`, {
      message: message,
      action: action
    })
  }

  // [PUT/PATCH] /buildings/{buildingId}/faults/{faultId}
  public updateFault(buildingId, faultId, data) {
    return this.doPatch(`buildings/${buildingId}/faults/${faultId}`, data);
  }

  //[GET] /buildings/{buildingId}/maintenanceUsers
  public getMaintenanceUsers(buildingId): Observable<any> {
    return this.doGet(`buildings/${buildingId}/maintenanceUsers`);
  }

  // [GET] /buildings/{buildingId}/faultsReport
  public getFaultReport(buildingId: string, month: string, year: string) {
    return this.doGet(`buildings/${buildingId}/faultsReport?month=${month}&year=${year}`);
  }

  // [POST] buildings/{buildingId}/points/allVisibility
  public massVisibility(buildingId, fullText?: string, dashboardAccessible?: boolean, apiAccessible?: boolean): Observable<any> {
    return this.doPost(`buildings/${buildingId}/points/allVisibility`, { apiAccessible: apiAccessible, dashboardAccessible: dashboardAccessible, fullText: fullText });
  }

  public getFault(buildingId: string, faultId: string) {
    return this.doGet(`buildings/${buildingId}/faults?faultId=${faultId}&refQuery=${encodeURIComponent(JSON.stringify([{ "path": "assign" }, { "path": "createdBy" }, { "path": "pointGroup" }]))}`);
  }

  //[GET] building/{buildingId}/pointGroupFaults
  public getFaults(buildingId: string, page: number = 1, pageSize: number = 100, filter: string = "", queryParams: string) {

    return this.doGet(`buildings/${buildingId}/faults?page=${page}&pageSize=${pageSize}&fullText=${filter}&${queryParams}&refQuery=${encodeURIComponent(JSON.stringify([{ "path": "assign" }, { "path": "createdBy" }]))}`)
  }

  // [post] /buildings/{buildingId}/faults
  public createFault(buildingId: string, data: { description: any, subject: any, assign: any, severity: any, type: any, date: any, asset: any }) {
    return this.doPost(`buildings/${buildingId}/faults`, data);
  }

  // [POST] devices/{deviceId}/cmd/ackAllAlarm
  public ackAllAlarm(deviceId: string, body: { ackType: "NotNormal" | "Normal", note?: string }): Observable<any> {
    return this.doPost(`devices/${deviceId}/cmd/ackAllAlarm`, { entity: body });
  }

  // [POST] buildings/{buildingId}/ackAllAlarms
  public ackAllAlarmsBuilding(buildingId: string, body: { ackType: "NotNormal" | "Normal", note?: string }): Observable<any> {
    return this.doPost(`buildings/${buildingId}/ackAllAlarms`, { entity: body });
  }

  //[POST] /buildings/{buildingId}/tagMaps/ruleTest
  public testRule(buildingId: string, type: string, condition: string, lookup: string, page: number = 1, pageSize: number = 10, fulllText: string = "") {
    return super.doPost(`buildings/${buildingId}/tagMaps/ruleTest`, { entity: { type: type, condition: condition, lookup: lookup, page: page, pageSize: pageSize, fulllText: fulllText } });
  }

  public getTagMaps(buildingId: string, filter: string = "", page: number = 1, pageSize: number = 100) {
    let url = `buildings/${buildingId}/tagMaps?page=${page}&pageSize=${pageSize}&fullText=${filter}`;
    return super.doGet(url).map(response => response);
  }

  public uploadImage(buildingId: string): Observable<any> {
    return this.doGet("");
  }

  //[GET] buildings/{buildingId}/meterReports/{pointId}?month=1&year=2021
  public weatherForecast(buildingId, start, end): Observable<any> {
      console.log(this.doPost(`buildings/${buildingId}/forecast`, { start:start, end:end }))
    //   return<any>;
    return this.doPost(`buildings/${buildingId}/forecast`, { start:start, end:end })
  }

  public holidays(country = 'UK', year): Observable<any> {
    // console.log(this.doOpenGet(`https://www.googleapis.com/calendar/v3/calendars/en.uk%23holiday%40group.v.calendar.google.com/events?key=AIzaSyCJZgrDdRtejDwdVLDsfxVwKIAjBnVeQ0I`))
    // return this.doOpenGet(`https://www.googleapis.com/calendar/v3/calendars/en.${country}%23holiday%40group.v.calendar.google.com/events?key=AIzaSyCJZgrDdRtejDwdVLDsfxVwKIAjBnVeQ0I`);
    return this.doOpenGet(`https://date.nager.at/api/v3/publicholidays/${year}/${country}`);
  }

    

  public meterpointReport(buildingId, pointId, month, year): Observable<any> {
    return this.doGet(`buildings/${buildingId}/meterReports/${pointId}?month=${month}&year=${year}`)
  }

  //[GET] buildings/{buildingId}/meterReports
  public meterReports(buildingId, month, year, page, pageSize): Observable<any> {
    return this.doGet(`buildings/${buildingId}/meterReports?month=${month}&year=${year}&page=${page}&pageSize=${pageSize}`);
  }
  //[GET] buildings/{buildingId}/meterReports/{reportId}
  public meterReport(buildingId, reportId): Observable<any> {
    return this.doGet(`buildings/${buildingId}/meterReports/${reportId}`);
  }

  // [POST] devices/{deviceId}/cmd/updateAlarm
  public updateAlarm(deviceId: string, body: { ack: boolean, note?: string, alarmId: string }): Observable<any> {
    return this.doPost(`devices/${deviceId}/cmd/updateAlarm`, { entity: body });
  }

  //[GET] /buildings/{buildingId}/pointsIntegrityIssue
  public getIntegrityIssues(buildingId, active: boolean = false, device?: string, ord?: string, types?: number[]) {

    return this.doGet(`/buildings/${buildingId}/pointsIntegrityIssue?types=${types.join("&types=")}&device=${device}&ord=${ord}&active=${active}`)
  }

  public createTagMap(buildingId: string, tagMap: any) {
    return super.doPost("buildings/" + buildingId + "/tagMaps", tagMap).map(response => response);
  }

  // public createTagMap(buildingId: string, tagMap: ITagMaps) {
  //   return super.doPost("buildings/" + buildingId + "/tagMaps", JSON.stringify(tagMap)).map(response => response);
  // }

  public deleteAllTagMaps(buildingId: string, deleteExisting: boolean) {
    return super.doPost("buildings/" + buildingId + "/tagMaps/delete", { applyToExistingPoints: deleteExisting }).map(response => response);
  }

  public deleteTagMap(buildingId: string, tagMapId: string, deleteExisting: boolean) {
    return super.doPost("buildings/" + buildingId + "/tagMaps/" + tagMapId + "/delete", { applyToExistingPoints: deleteExisting }).map(response => response);
  }

  public updateTagMap(buildingId: string, tagMap: any, tagId: string) {
    return super.doPatch("buildings/" + buildingId + "/tagMaps/" + tagId, JSON.stringify(tagMap)).map(response => response);
  }

  private checkAll(building: IBuilding) {
    let errors: IServiceError[] = [];

    if (building.company === "") {
      errors.push({ field: "company", message: "This building needs to belong to a valid company" });
    }

    if (building.address === undefined) {
      errors.push({ field: "address", message: "This building needs a valid address" });
    }

    if (building.name === "") {
      errors.push({ field: "name", message: "This building needs a valid name" });
    }

    if (building.description === "") {
      errors.push({ field: "description", message: "This building needs a valid description" });
    }

    if ((building.contacts.emails === undefined || building.contacts.emails.length === 0) &&
      (building.contacts.telephones === undefined || building.contacts.telephones.length === 0)) {
      errors.push({ field: "contacts", message: "This building needs at least one valid contact information" });
    }

    if (errors.length > 0) {
      return observableThrowError({ status: 400, errors: errors });
    }

    return undefined;
  }

  public getUploadURL(buildingId): Observable<any> {
    return this.doGet(`/buildings/${buildingId}/upload`);
  }

  //[GET] /buildings/{id}/metadatas
  public metadata(id, fullText: string = "", page: number = 1, pageSize: number = 100, dataView: number = 0): Observable<any> {
    return this.doGet(`buildings/${id}/metadatas?meter=0&page=${page}&pageSize=${pageSize}&fullText=${fullText}&dataView=${dataView}`);
  }

  public create(building: IBuilding) {
    return super.create(building);
  }

  public update(oldBuilding: IBuilding, building: IBuilding) {
    return super.update(oldBuilding, building);
  }

  public delete(uuid: string) {
    return super.delete(uuid);
  }

  public getNewDevices(building: string, unAuthOnly: boolean) {
    return super.doGet("buildings/" + building + "/newdevices" + (unAuthOnly ? "?unauth=true" : "")).
      map(response => response);
  }

  public setNewDeviceAuth(building: string, device: string, rejected: boolean) {
    return super.doPost("buildings/" + building + "/device/" + device + "/validation", { reject: rejected }).
      map(response => response);
  }

  public GetMeterReadings(building, fromDate, toDate, filter: string = "", page: number = 1, pageSize: number = 10, onlyProcessed: boolean = false, onlyVirtual: boolean = false, onlyLiveData: boolean = false) {


    let url = `mobileMetering/meterReadingsForBuilding/${building}?onlyProcessed=${onlyProcessed ? 1 : 0}&onlyVirtual=${onlyVirtual ? 1 : 0}&onlyLiveData=${onlyLiveData ? 1 : 0}&from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&fullText=${encodeURI(filter)}`;
    return super.doGet(url).map(response => response);
  }

  public GetMeterReadingImage(id) {
    return super.doGet("mobileMetering/download/" + id).map(response => response);
  }

  public SaveMeterReadings(id, reading, unitreading) {
    return super.doPost("mobileMetering/" + id + "/reading", { meter_reading: reading, meter_unit: unitreading }).map(response => response);
  }

  public validateMeterReadings(id) {
    return super.doPost("mobileMetering/" + id + "/validate", {}).map(response => response);
  }
}
