import * as moment from "moment-timezone";
export enum enumUserType {
  Root = 100,
  Empty90 = 90,
  CompanyAdmin = 80,
  Empty70 = 70,
  Empty60 = 60,
  BuildingAdmin = 50,
  Empty40 = 40,
  Maintenance = 30,
  Empty20 = 20,
  Empty10 = 10,
  Viewer = 0,
  Dashboard = -10,
  MobileMetering = -20,
  Report = -30,
  AccessBocked = -100
}

export function noValidatation() {
  return true;
}

let arrayIntersect = ( a: any[], b: any[] ) => {
  let setB = new Set( b );
  return [ new Set( a ) ].filter( x => setB.has( x ) );
};

export { arrayIntersect };

export function isObjectEmpty( obj: Object ) {
  if( isNil( obj ) ) {
    return true;
  }
  for( let key in obj ) {
    if( obj.hasOwnProperty( key ) ) {
      return false;
    }
  }
  return true;
}




export function isNil( value: any ) {
  return value === null || value === undefined;
}

export function isArrayEmpty( array: any ) {
  return isNil( array ) || !isArray( array ) || array.length === 0;
}

export function isEmailInvalid( str: string ) {
  // RF2822 https://tools.ietf.org/html/rfc2822#section-3.4.1
  return isStringEmpty( str ) || isNil( str.match(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/ ) );
}

export function isStringEmpty( str: string | null | undefined ) {
  return isNil( str ) || str === "";
}

export function isStringEmptyOrNullStr( str: string | null | undefined ) {
  return ( isStringEmpty( str ) || str === "null" || str === "undefined" );
}

export function AddMinutes( dt: Date, minutes: number ) {
  return new Date( dt.getTime() + minutes * 60000 );
}

export function isFunction( obj: any ) {
  return {}.toString.apply( obj ) === "[object Function]";
}

export function isArray( obj: any ) {
  return {}.toString.apply( obj ) === "[object Array]";
}

export function isBoolean( obj: any ) {
  return {}.toString.apply( obj ) === "[object Boolean]";
}

export function isString( obj: any) {
  return {}.toString.apply( obj ) === "[object String]"
}

export function isNumber( obj: any ) {
  return {}.toString.apply( obj ) === "[object Number]";
}

export function isDate( obj: any ) {
  return {}.toString.apply( obj ) === "[object Date]";
}

export function isObject( obj: any ) {
  return {}.toString.apply( obj ) === "[object Object]";
}

export function isValue( obj: any ) {
  return !isObject( obj ) && !isArray( obj );
}

export function isInvalidValue( value: any, validValues: any[] ) {
  return isNil( value ) || validValues.indexOf( value ) === -1;
}

export function isInvalidDate( date: any ) {
  return isNil( date ) || !moment( date ).isValid();

}

export function betweenNumber( value: number, min: number, max: number ) {
  return value > min && value < max;
}

export function betweenIncludingMinNumber( value: number, min: number, max: number ) {
  return value >= min && value < max;
}

export function betweenIncludingMaxNumber( value: number, min: number, max: number ) {
  return value > min && value <= max;
}

export function betweenIncludingMinMaxNumber( value: number, min: number, max: number ) {
  return value >= min && value <= max;
}

/**
 * Check if logged-in user has access to all building belonging to the company
 * @param authorizer - object received when using API Gateway authorization
 * @returns { boolean } - true if has access and false otherwise
 */
export function hasAccessToAllBuildings( authorizer: any ) {
  return checkUserType( authorizer[ "custom:type" ], enumUserType.CompanyAdmin ) || authorizer[ "custom:allBuildings" ];
}

/**
 * Check if logged-in user has access to the building
 * @param authorizer - object received when using API Gateway authorization
 * @param building - string representing the string ID
 * @param from { undefined | enumUserType } - [OPTIONAL] Minimum permission required
 * @param to { undefined | enumUserType } - [OPTIONAL] End of permission required ( not included as permitted )
 * @param special { number[] } - [optional] array of permission on the negative side ( special user types )
 * @returns { boolean } - true if has access and false otherwise
 */
export function hasAccessToBuilding( authorizer: any, building: string, from: undefined | number,
  to: undefined | number = undefined, special: number[] = [] ): boolean {
  return checkUserType( buildingAccessLevel( authorizer, building ), from, to, special );
}

/**
 * get user building access level
 * @param authorizer - object received when using API Gateway authorization
 * @param building - string representing the string ID
 * @returns { null | number } - access level of user in the building
 */
export function buildingAccessLevel( authorizer: any, building: string ) {
  if( checkUserType( authorizer[ "custom:type" ], enumUserType.AccessBocked, enumUserType.CompanyAdmin ) ) {
    if( authorizer[ "custom:allBuildings" ] ) {
      return authorizer[ "custom:type" ];
    } else {
      return authorizer[ `custom:building_${building}` ] || enumUserType.AccessBocked;
    }
  } else {
    return authorizer[ "custom:type" ];
  }
}

/**
 * Check if logged-in user has access to any building
 * @param authorizer - object received when using API Gateway authorization
 * @returns { boolean } - true if don't have access and false otherwise
 */
export function blockUserWithoutAnyBuildingAccess( authorizer: any ) {
  if( checkUserType( authorizer[ "custom:type" ], enumUserType.Viewer, enumUserType.CompanyAdmin, [ enumUserType.Report ] ) ) {
    return !authorizer[ "custom:allBuildings" ] &&
      isNil( Object.keys( authorizer ).find( ( k: string ) => k.indexOf( "custom:building_" ) === 0 ) );
  } else if( checkUserType( authorizer[ "custom:type" ], enumUserType.CompanyAdmin ) ) {
    return false;
  }
  return true;
}

/**
 * check if user type is out of the range ( excluding to )
 * @param userType {enumUserType} - numeric from enumUserType
 * @param from { undefined | enumUserType } - [OPTIONAL] Minimum permission required
 * @param to { undefined | enumUserType } - [OPTIONAL] End of permission required ( not included as permitted )
 * @param special { number[] } - [optional] array of permission on the negative side ( special user types )
 * @returns { boolean } - true if user permission is out of required ranged, false otherwise
 */
export function blockUserWithoutPermission( userType: number, from: undefined | number, to: undefined | number = undefined,
  special: number[] = [] ) {
  if( isNil( to ) && from !== undefined ) {
    return userType < from && !special.includes( userType );
  }
  if( isNil( from ) && to !== undefined ) {
    return userType >= to && !special.includes( userType );
  }
  if( from !== undefined && to !== undefined ) {
    return !betweenIncludingMinNumber( userType, from, to ) && !special.includes( userType );
  } else {
    return true;
  }
}

/**
 * check if user type is in the range ( excluding to )
 * @param userType {enumUserType} - numeric from enumUserType
 * @param from { undefined | enumUserType } - [OPTIONAL] Minimum permission required
 * @param to { undefined | enumUserType } - [OPTIONAL] End of permission required ( not included as permitted )
 * @param special { number[] } - [optional] array of permission on the negative side ( special user types )
 * @returns { boolean } - true if user permission is within the required ranged, false otherwise
 */
export function checkUserType( userType: number, from: undefined | number, to: undefined | number = undefined, special: number[] = [] ) {
  return !blockUserWithoutPermission( userType, from, to, special );
}

/**
 * Get list of building that user has specific permission to
 * @param authorizer - object received when using API Gateway authorization
 * @param from { undefined | enumUserType } - [OPTIONAL] Minimum permission required
 * @param to { undefined | enumUserType } - [OPTIONAL] End of permission required ( not included as permitted )
 * @param special { number[] } - [optional] array of permission on the negative side ( special user types )
 * @returns { string[] } - array with building Ids user has specific permission to access
 */
export function getBuildingsFromUser( authorizer: any, from: undefined | number, to: undefined | number = undefined,
  special: number[] = [] ): string[] {
  let buildings: string[] = [];
  for( let kb of Object.keys( authorizer ).filter( ( k: any ) => k.indexOf( "custom:building_" ) === 0 ) ) {
    if( checkUserType( authorizer[ kb ], from, to, special ) ) {
      buildings.push( kb.split( "_" )[ 1 ] );
    }
  }
  return buildings;
}

export function ArrayFlatten( arr: any[], depth: number = 1 ): any[] {
  return arr.reduce( function( flat: any[], toFlatten: any[] ) {
    return flat.concat( ( Array.isArray( toFlatten ) && ( depth > 1 ) ) ? ArrayFlatten( toFlatten, depth - 1 ) : toFlatten );
  }, [] );
}

export function ArrayDistinct( arr: any[] ) {
  function unique( value: any, index: any, self: any ) {
    return self.indexOf( value ) === index;
  }

  return arr.filter( unique );
}

export function ArrayDistinctByField( arr: any[], field: string ) {
  function unique( value: any, index: any, self: any ) {
    let unq = true;
    for( let i = 0; i < self.length; i++ ) {
      if( self[ i ][ field ] === self[ index ][ field ] && i !== index ) {
        unq = false;
        break;
      }
    }
    return unq;
  }

  return arr.filter( unique );
}

export function zip( rows: any[][] ) {
  return rows[ 0 ].map( ( _: any, c: any ) => rows.map( ( row: any ) => row[ c ] ) );
}


export function parseDataToFrequency( data: any[], frequency: any ) {
  function avg( dd: number[] ) {
    if( dd.length === 0 ) {
      return null;
    }
    if( dd.length === 1 ) {
      return dd[ 0 ][ 1 ];
    }
    let sum = dd.reduce( function( acc: number, value: any ) {
      return acc + value[ 1 ];
    }, 0 );

    return sum / dd.length;
  }

  frequency = frequency || 1;
  if( frequency === 1 ) {
    return data.filter( ( x: any ) => x[ 1 ] !== null );
  }
  let size = Math.floor( data.length / frequency );
  let newData = new Array( size );

  for( let i = 0; i < size; i++ ) {
    newData[ i ] = [
      data[ i * frequency ][ 0 ],
      avg( data.slice( i * frequency, ( i + 1 ) * frequency ).filter( ( x ) => x[ 1 ] !== null ) )
    ];
  }
  // return newData;
  return newData.filter( ( x ) => x[ 1 ] !== null );

}
