import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PointTagsComponent } from 'app/components/common/point-tags/point-tags.component';
import { SearchPopupComponent } from 'app/components/common/search-popup/search-popup.component';
import { BuildingService } from 'app/services/building.service';
import { AdminPointService } from 'app/services/data.service';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SessionService } from 'app/services/session.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PointsPopupComponent } from './points-popup/points-popup.component';

@Component({
  selector: 'app-admin-automatic-groups',
  templateUrl: './admin-automatic-groups.component.html',
  styleUrls: ['./admin-automatic-groups.component.css']
})
export class AdminAutomaticGroupsComponent implements OnInit {

  constructor(private sessionService: SessionService, private alert: SweetalertService, private router: Router, public dialog: MatDialog, private pointService: AdminPointService, private spinner: SpinnerService, private buildingService: BuildingService, private poingroupService: PointgroupsService) { }

  public selectedIndex: number = 0;

  public selectedBuilding;
  public buildings: any[] = [];
  public previewData;

  ngOnInit(): void {
    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinner.desactivate();

      this.buildings = x.data;

      if (this.sessionService.building) {
        this.selectedBuilding = this.sessionService.building;
      }
    })
  }

  public uniquePoints = [];

  public _filter(value: string, option) {
    const filterValue = value.toLowerCase();

    let t = this.uniquePoints.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    option.filtered = t;
  }

  public changePageOptions(event) {
    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.generatePreview(false);
  }


  public applyFilter(event) {
    this.filter = event;
    this.generatePreview();
  }

  public filter: string = "";
  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 50 }


  public displayedColumns = ["index", "friendlyName", "dataTypeString"]
  public datasource = new MatTableDataSource<any>();

  public selectedTypeOptions = [];
  public options: { icon: string, key: number, type: string, values: string[], filtered: [] }[] = [];

  public criteria: string = "";
  public criteriaAt: number = -1;

  public assets = [];

  public buildingChanged() {
    this.sessionService.building = this.selectedBuilding;
  }

  private isValidQuery(query): boolean {
    let ret = false;
    console.log(query);

    let comparsions = query.value.filter(t => t.value != "" && (t.type == "not_contain" || t.type == "contain"));
    let conditions = query.value.filter(t => (t.type == "and" || t.type == "or"));

    if (comparsions.length > 0) {
      ret = true;
    }
    else if (comparsions.length == 0 && conditions.length > 0) {
      for (let i of conditions) {
        if (ret == false) {
          let x = this.isValidQuery(i);
          if (x) {
            ret = x;
          }
        }
      }
    }

    return ret;
  }

  public invalidQuery: boolean = false;

  private currentQuery;
  public generatePreview(generateQuerry = true) {
    if (generateQuerry) {
      this.currentQuery = this.getSubQuery(this.query)
      this.pagination.page = 1;
    }

    // if (this.isValidQuery(this.currentQuery)) {
    this.spinner.activate();
    this.poingroupService.getFromRules(this.selectedBuilding, this.currentQuery, this.pagination.page, this.pagination.pageSize, this.filter).subscribe(x => {
      this.spinner.desactivate();

      for (let i = 0; i < x.data.length; i++) {
        let d = x.data[i];

        d.index = this.pagination.pageSize * (this.pagination.page - 1) + i + 1;
        d.dataTypeString = this.resolveDataType(d.dataType);
        d.dataTypeDescLong = [];
        d.friendlyNamePart = d.ord.split("/");
        if (d.dataTypeDesc)
          for (let key of Object.keys(d.dataTypeDesc)) {
            d.dataTypeDescLong.push(key + ": " + d.dataTypeDesc[key]);
          }
      }

      this.datasource.data = x.data;
      this.previewData = x;

      if (this.criteria != "") {
        this.applyCriteria();
      }

      if (x.data.length > this.pagination.pageSize) this.pagination.hasNext = true;

    },
      err => {
        this.alert.info("Information", "Invalid query.");
        this.spinner.desactivate();
      })

    // }
  }

  public cleanCriteria() {
    this.criteria = '';
    this.criteriaAt = -1;
    for (let d of this.datasource.data) d.css = undefined;
  }

  public applyCriteria() {
    for (let d of this.datasource.data) {
      if (d.ord.split("/").length >= this.criteriaAt) {
        d.css = "include";
      }
      else d.css = "exclude";
    }
  }

  public changeType(options) {
    this.selectedTypeOptions = options.value.values.map(x => { return { value: "", key: x } })
  }

  public finalPreview = [];

  public openTags(id: string) {
    let p = this.allPoints.find(x => x._id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.tags,
        title: "Tags for " + p.ord
      }
    });
  }

  public selectedFinal = null;
  public changeFinal(asset) {
    this.selectedFinal = asset;
    this.selectedFinal.datasource = new MatTableDataSource();
    this.selectedFinal.datasource.data = asset.points;
  }

  public displayedColumnsPoints = ["index", "friendlyName", "ord", "option"]

  public openFacets(id: string) {
    let p = this.allPoints.find(x => x._id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.meta,
        title: "Meta for " + p.ord
      }
    });
  }

  public filteredOptions: Observable<string[]>;
  public allPoints = [];

  public saveAssets() {
    this.spinner.activate();
    let t = this.getSubQuery(this.query);
    this.poingroupService.createFromRules(this.selectedBuilding, t, this.selectedTypeOptions, this.selectedType.key, this.criteriaAt).subscribe(x => {
      this.spinner.desactivate();

      this.alert.success("Success!", "Assets created.")
      this.router.navigate(["/admin/grouping/assets"])
    },
      err => {
        let message = "";

        if (err.error.errors) {
          let errors = "";
          for (let error of err.error.errors) {
            errors += <string>error.message + "\n ";
          }

          message = "Server returned the following: \n" + errors;
        }

        this.alert.info("Information", message);
        this.spinner.desactivate();
      })
  }

  public showMissingOnly = false;
  public hasMissingOptions = false;
  public generateAssets() {
    let t = this.getSubQuery(this.query);

    this.finalPreview = [];
    this.hasMissingOptions = false;
    this.showMissingOnly = false;

    this.spinner.activate();
    this.poingroupService.getFromRules(this.selectedBuilding, t, undefined, undefined, undefined, undefined, this.selectedTypeOptions, this.selectedType.key, this.criteriaAt).subscribe(x => {
      this.spinner.desactivate();

      this.finalPreview = x.data;

      this.goToStep(2);



      for (let d of this.finalPreview) {
        d.missingOptions = [];

        let validOptions = this.selectedTypeOptions.filter(t => t.value != "");

        for (let i = 0; i < d.points.length; i++) {

          d.points[i].statusText = "";

          for (let option of validOptions) {
            if (d[option.key] == undefined || d[option.key] == "") {
              this.hasMissingOptions = true;

              if (d.missingOptions.find(x => x == (option.key + ": '" + option.value + "'")) == undefined)
                d.missingOptions.push(option.key + ": '" + option.value + "'");
            }

            if (d[option.key] == d.points[i]._id) {
              d.points[i].statusText = option.key;
            }
          }


          d.missingOptions = d.missingOptions.filter(x => x.indexOf("undefined") == -1)

          d.points[i].index = i + 1

        }
      }



    },
      err => {
        this.alert.info("Information", "Invalid request. Contact support.");
        this.spinner.desactivate();
      });
  }

  public visibleAssets = [];
  public searchPointsPopup(filter: string, pageOptions: { page: number, pageSize: number, hasNext: boolean }): Observable<any> {
    return new Observable(observer => {

      console.log(pageOptions);
      console.log(pageOptions.page * pageOptions.pageSize);
      console.log((pageOptions.page + 1) * pageOptions.pageSize + 1);


      let res = this.uniquePoints.filter(x => x.point.indexOf(filter) >= 0).slice((pageOptions.page - 1) * pageOptions.pageSize, pageOptions.page * pageOptions.pageSize + 1);

      res.forEach(x => {
        x.name = x.point + "(" + x.count + " assets)";
      })

      observer.next(res);
      observer.complete();
    })
  }
  public openPointsPopupTop(option) {

    const dialogRef = this.dialog.open(SearchPopupComponent, {
      width: '600px',
      data: {
        single: true,
        assetName: "Unique points",
        displayField: "name",
        valueField: "point",
        searchCallback: this.searchPointsPopup.bind(this),
      }
    });


    dialogRef.afterClosed().subscribe(ret => {
      this.selectedTypeOptions.find(x => x.key == option).value = ret.point;
    })
  }


  public openPointsPopup(asset) {

    const dialogRef = this.dialog.open(PointsPopupComponent, {
      width: '600px',
      data: {
        assetName: asset.assetName,
        points: this.assetPoints.find(x => x.asset == asset.assetName).points
      }
    });


    dialogRef.afterClosed().subscribe(ret => {
      this.selectedTypeOptions.forEach(x => x.value = ret);
    })
  }

  public paginationAssets = { page: 1, pageSize: 50, hasNext: false };
  public changePaginationAssets(pagination) {
    this.paginationAssets = pagination;
    this.visibleAssets = this.assets.slice((this.paginationAssets.page - 1) * this.paginationAssets.pageSize, this.paginationAssets.page * this.paginationAssets.pageSize)

    if (this.paginationAssets.page * this.paginationAssets.pageSize < this.assets.length)
      this.paginationAssets.hasNext = true;
  }

  public selectedType = null;
  public assetPoints = [];

  public goToStep2() {
    this.options = [];
    this.assets = [];
    this.selectedTypeOptions = [];

    let t = this.getSubQuery(this.query);

    this.spinner.activate();
    this.poingroupService.getFromRules(this.selectedBuilding, t, this.pagination.page, this.pagination.pageSize, this.filter, this.criteriaAt).subscribe(x => {
      this.spinner.desactivate();

      this.assets = x.assetPoints;

      this.changePaginationAssets({ page: 1, pageSize: 50, hasNext: true });

      this.uniquePoints = x.uniquePoints;

      for (let key of Object.keys(x.types)) {
        this.options.push({
          icon: x.icons[key],
          key: parseInt(key),
          type: x.types[key],
          values: x.options[key],
          filtered: []
        })
      }

      this.selectedType = this.options.find(x => x.key == 0);

      this.assetPoints = x.assetPoints
    },
      err => {
        this.alert.info("Information", "Invalid request. Contact support.");
        this.spinner.desactivate();
      });

    this.selectedIndex = 1;
  }

  public viewPointsInsideAsset
  public showPoints(asset) {
    this.viewPointsInsideAsset = asset;
    const dialogRef = this.dialog.open(SearchPopupComponent, {
      width: '600px',
      data: {
        single: true,
        assetName: "Points",
        displayField: "name",
        valueField: "point",
        disableSelect: true,
        searchCallback: this.searchShowPointPopup.bind(this),
      }
    });
  }

  public searchShowPointPopup(filter: string, pageOptions: { page: number, pageSize: number, hasNext: boolean }): Observable<any> {
    return new Observable(observer => {
      let res = this.viewPointsInsideAsset.points.filter(x => x.indexOf(filter) >= 0).slice((pageOptions.page - 1) * pageOptions.pageSize, pageOptions.page * pageOptions.pageSize + 1);

      observer.next(res.map(x => { return { name: x, point: x } }));
      observer.complete();
    })
  }

  public goToStep(index) {
    this.selectedIndex = index;
  }

  public groupings: string[] = [];

  public removeCommon(pointName, index) {

    let segments = pointName.split("/").slice(0, index).join("/");

    this.criteria = segments;
    this.criteriaAt = index;

    this.applyCriteria();

    // this.generatePreview();
  }

  public resolveDataType(dataType) {
    switch (dataType) {
      case 1: return "Boolean";
      case 2: return "Boolean Writable";
      case 3: return "Numeric";
      case 4: return "Numeric Writable";
      case 5: return "Enum";
      case 6: return "Enum Writable";
      case 7: return "String";
      case 8: return "String Writable";
    }
  }


  private getSubQuery(query) {
    let condition = this.mapCondition(query.condiotion);

    let ret: { type: string, value: any } = { type: condition, value: [] };

    for (let f of query.functions) {
      ret.value.push({ type: this.mapCondition(f.function), value: f.value })
    }

    for (let s of query.subQuery) {
      ret.value.push(this.getSubQuery(s))
    }

    if (ret.value == []) return;

    return ret;
  }

  public _query = {
    condiotion: "And", subQuery: [], expanded: true, functions: [{
      function: "Contains",
      value: ""
    }]
  };
  get query() {
    return this._query;
  }
  set query(value) {
    console.log("HauSuAhSD");
    this._query = value;
  }

  public mapCondition(condition) {
    switch (condition) {
      case "And":
        return "and";
      case "Or":
        return "or";
      case "Contains":
        return "contain";
      case "Doesn't Contains":
        return "not_contain";

    }
  }
}
