import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PointsViewComponent } from './points-view.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IboxModule } from '../ibox/ibox.module';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from '../paginator/paginator.module';
import { ProgressDisplayModule } from '../progress-display/progress-display.module';
import { FilterFieldModule } from '../filter-field/filter-field.module';
import { TranslocoModule } from '@ngneat/transloco';
import { FormGroup, FormControl } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'; 
import { MatAutocompleteModule } from "@angular/material/autocomplete";

@NgModule({
  declarations: [PointsViewComponent],
  imports: [
    FilterFieldModule,
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    IboxModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatTooltipModule,
    PaginatorModule,
    MatExpansionModule,
    ProgressDisplayModule,
    TranslocoModule
  ],
  exports: [PointsViewComponent]
})
export class PointsViewModule { }
