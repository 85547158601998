import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapPopupComponent } from './map-popup.component';
import { AgmCoreModule } from '@agm/core';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [MapPopupComponent],
  imports: [
    CommonModule,
    AgmCoreModule,
    MatDialogModule
  ],
  exports: [MapPopupComponent]
})
export class MapPopupModule { }
