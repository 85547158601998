import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "app/services/user.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { User } from "app/interfaces/user";
import { HelpPageService } from "app/services/help-page.service";
import { AppService } from "app/services/app.service";

@Component( {
  selector: "app-admin-edit-user",
  templateUrl: "./admin-edit-user.component.html",
  styleUrls: [ "./admin-edit-user.component.css" ]
} )
export class AdminEditUserComponent implements OnInit {

  constructor(private helppageService: HelpPageService, private appService: AppService,private router: Router, private userService: UserService, public i18n: AppI18nService,
    private spinnerService: SpinnerService, private alertService: SweetalertService, private route: ActivatedRoute ) {
  }

  private userId: string = "";
  public user: User = undefined;
  private originalUser: User;

  ngOnInit() {


    this.helppageService.changeUrl("./assets/help-pages/users.html?language=" + this.appService.getLanguage());

    this.spinnerService.activate();
    this.route.params.subscribe( params => {
      this.userId = params[ "id" ];

      this.userService.getOne( this.userId ).subscribe( user => {
        // console.log(company);
        this.spinnerService.desactivate();
        this.originalUser = user.data;
        this.user = user.data;
      } );
    } );
  }

  public save( event ) {

    event.value = event.getRawValue()

    let user: User = {
      _id: this.originalUser._id,
      company: event.value.company,
      email: event.value.primaryEmail,
      person: {
        country: event.value.country,
        city: event.value.city,
        landline: event.value.landline,
        contacts: {
          emails: event.value.emails,
          telephones: event.value.telephones
        },
        primaryEmail: event.value.primaryEmail,
        name: event.value.name,
        mobilePhone: event.value.mobilephone
      },
      type: event.value.type,
      username: event.value.primaryEmail
    };

    // console.log(newCompany);

    this.spinnerService.activate();
    this.userService.update( this.originalUser, user ).subscribe(
      res => {
        this.spinnerService.desactivate();
        this.alertService.success( this.i18n.translate( "general.messages.success" ), this.i18n.translate( "general.messages.updated", this.i18n.translate( "interfaces.user.user" )) );
        this.router.navigate( [ "/admin/users" ] );
      },
      err => {
        console.log( err );
        this.spinnerService.desactivate();
        if(err.errors) {
          this.alertService.info( this.i18n.translate( "general.messages.error" ),
            this.i18n.translate( "general.errors.serverSide", err.errors.join( ", " ) ) );
        } else {
          this.alertService.info( this.i18n.translate( "general.messages.error" ),
            this.i18n.translate( "general.errors.unknown" ) );
        }
      } );
  }

  public delete(user){
    this.spinnerService.activate();
    this.userService.delete( user._id ).subscribe(
      res => {
        this.spinnerService.desactivate();
        this.alertService.success( this.i18n.translate( "general.messages.success" ), this.i18n.translate( "general.messages.deleted", user.email ) );
        this.router.navigate( [ "/admin/users" ] );
      },
      err => {
        console.log( err );
        this.spinnerService.desactivate();
        if(err.errors) {
          this.alertService.info( this.i18n.translate( "general.messages.error" ),
            this.i18n.translate( "general.errors.serverSide", err.errors.join( ", " ) ) );
        } else {
          this.alertService.info( this.i18n.translate( "general.messages.error" ),
            this.i18n.translate( "general.errors.unknown" ) );
        }
      } );
  }
}
