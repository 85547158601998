import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { DriverService } from 'app/services/driver.service';
import { BuildingService } from 'app/services/building.service';

import * as moment from "moment-timezone";
import { MatTableDataSource } from '@angular/material/table';
import { SweetalertService } from 'app/services/sweetalert.service';
import { Observable, Subscription } from 'rxjs';
import { ProgressDisplayComponent } from 'app/components/common/progress-display/progress-display.component';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-history',
  templateUrl: './admin-history.component.html',
  styleUrls: ['./admin-history.component.css']
})
export class AdminHistoryComponent implements OnInit {

  constructor(private alert: SweetalertService, private spinner: SpinnerService, private driverService: DriverService, private buildingService: BuildingService) { }
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup;
  public filterBuilding(){
    
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;
      

    let searchBase = this.buildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.buildings;
}
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    this.selectedBuilding = this.FilteredselectedBuilding.id;
    console.log(building_formed);
    // console.log(this.building);
    // this.updateData();
    this.buildingChange();


  }
  @ViewChild("progressDisplay", { static: true }) progressDisplay: ProgressDisplayComponent;

  public buildings: { id: string, name: string }[] = [];
  public selectedDevice: string = "";
  public devices: { id: string, name: string }[] = [];
  public date = moment().utc().startOf("day");

  public filter = "";

  public hasNext: boolean = false;


  public commandSent() {
    this.progressDisplay.resetState();
  }

  public changePage(event) {


    if (this.page.pageSize != event.pageSize) {
      event.page = 1;
    }

    this.page = event;
    let t = this.page.page * this.page.pageSize;
    if (t > this.originalData.length) {
      this.hasNext = false;
      t = this.originalData.length;
    }

    this.datasource.data = this.originalData.slice((this.page.page - 1) * this.page.pageSize, t);
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.datasource.filter = filterValue;
  }

  public statusMessage: string = "No command sent";
  public progress: number = 0;
  public lastUpdateAt: string = "";
  public mode: string = "";

  public displayedColumns = ["index", "timestamp", "name", "message", "exception"];

  public originalData = [];

  public datasource = new MatTableDataSource<any>();

  public page = { page: 1, pageSize: 100 };

  get canDoSomething() {
    return this.selectedDevice != "" && this.selectedBuilding != "";
  }

  ngOnInit() {
    this.buildingForm = new FormGroup({
      buildings_input: new FormControl('')
      
    });
    this.spinner.activate();
    this.buildingService.getAll().subscribe(d => {
      this.buildings = d.data.map(x => { return { id: x._id, name: x.name } });

      this.spinner.desactivate();
    });
  }


  public buildingChange() {
    this.spinner.activate();
    this.driverService.getDevices(this.selectedBuilding).subscribe(d => {
      this.devices = d.data.map(x => { return { id: x._id, name: x.friendlyName + " " + x.hostId } });
      this.selectedDevice = "";
      this.spinner.desactivate();
    });
  }
  public commands = [];

  public getCommandName(cid: number) {
    switch (cid) {
      case 1:
        return "Discover";
      case 2:
        return "Add";
      case 3:
        return "Remove";
      case 4:
        return "List Histories";
      case 5:
        return "Get History";
      case 6:
        return "Get All Metadata"
      case 7:
        return "Get Metadata for single point";
      case 8:
        return "Get Log History";
      case 9:
        return "Discover FD Points";
      case 10:
        return "Create FD Point";
      case 11:
        return "Delete FD Point";
      case 12:
        return "Resync";
      default:
        return "Unkown command";
    }
  }



  public disableActions: boolean = true;

  public blockActions(event) {
    this.disableActions = event.other;
  }

  private needRefresh: boolean = false;

  public release(event) {
    if (this.needRefresh && event == 8) {
      this.needRefresh = false;
      this.refresh();
    }
  }


  public updateLogs() {
    this.alert.custom({
      title: "Attention", text: "This operation will execute a command that can take between one and five minutes to complete. Please confirm.", buttons: {
        yes: {
          text: "Yes",
          value: "yes"
        },
        no: {
          text: "No",
          value: "no"
        }
      },
      icon: "info",

    }).then(x => {
      if (x == "yes") {

        this.needRefresh = true;

        this.spinner.activate();
        this.driverService.sendLogCommand(this.selectedDevice, this.date, this.date.clone().endOf("day")).subscribe(x => {

          this.alert.success("Success!", "Command has been sent.");

          this.spinner.desactivate();

          this.progressDisplay.updateCommands();
        }, err => {
          this.spinner.desactivate();
          if (err.error.errors) {
            this.alert.info("Attention", err.error.errors[0].message);
          }
          else {
            this.alert.error("Error", "Unkown error. Contatct the admin.");
          }
        })
      }
    });

  }


  public refresh() {
    this.spinner.activate();

    this.driverService.getLogs(this.selectedDevice).subscribe(x => {
      this.originalData = x.data.entries.map((t, index) => {
        return {
          index: index + 1,
          name: t.name,
          message: t.message,
          timestamp: moment(t.timestamp).format("DD/MM/YYYY HH:mm Z"),
          exception: t.exception
        }
      });

      this.filter = "";

      if (this.originalData.length > this.page.pageSize) {
        this.hasNext = true;
      }

      this.datasource.data = this.originalData.slice(0, this.page.pageSize);


      this.spinner.desactivate();
    }, err => {
      this.spinner.desactivate();

      let message = "";
      if (err.status == 404) {
        message = "No logs available. Please retrieve logs from device.";
      }
      else {
        message = "Unkown Error.";
      }
      this.alert.info("Notice", message);
    })
  }

}
