import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppHighstockComponent } from 'app/components/common/highcharts/highstock/highstock.component';

@Component({
  selector: 'app-faults-data',
  templateUrl: './faults-data.component.html',
  styleUrls: ['./faults-data.component.css']
})
export class FaultsDataComponent implements OnInit, AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FaultsDataComponent>) { }

  @ViewChild("chart") chart: AppHighstockComponent;

  ngOnInit(): void {
    console.log(this.data);
  }

  ngAfterViewInit() {
    this.data.points.forEach(x => {
      this.chart.updateSeries(x, x.id, false);
    })

    this.chart.redraw();

  }

  public options = {
    chart: {
      height: 500
    }, tooltip: {
      valueDecimals: 2,
    },
    navigator: {
      enabled: true,
    },
    rangeSelector: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    credits: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
  }
}
