import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PointTagsComponent } from 'app/components/common/point-tags/point-tags.component';

@Component({
  selector: 'app-points-popup',
  templateUrl: './points-popup.component.html',
  styleUrls: ['./points-popup.component.css']
})
export class PointsPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PointsPopupComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  public hasReturn = true;

  ngOnInit(): void {
    this.assetName = this.data.assetName;
    this.points = this.data.points;
    this.disabled = this.data.disabled ? this.data.disabled : [];
    this.hasReturn = this.data.return == undefined ? true : this.data.return;
  }

  public disabled: string[] = [];
  public assetName: string = "";
  public points: string[] = [];

  public returnPoint(point) {
    if (this.hasReturn)
      this.dialogRef.close(point);
  }
}
