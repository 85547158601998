import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-edit-tag-maps',
  templateUrl: './admin-edit-tag-maps.component.html',
  styleUrls: ['./admin-edit-tag-maps.component.css']
})
export class AdminEditTagMapsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
