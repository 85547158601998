export interface BaseInterface {
  _id?: string;
  // isActive: boolean;
}

export enum UserType {
  Report = -30,
  Maintenance = 30,
  Viewer = 0,
  Root = 100,
  BuildingAdmin = 50,
  CompanyAdmin = 80
}
