import { Injectable } from "@angular/core";
import { BaseService } from "app/services/base.service";
import { UserType } from "../interfaces/baseinterface";
import { Observable, Observer } from 'rxjs';

@Injectable()
export class AdminPointService extends BaseService<IMetadata> {

  rootUrl = "buildings";

  public checkForCredentials() {

  }

  //[GET] buildings/{buildingId}/metadatasFromValue
  // QSP: { type: <TYPE>, value?: string, low?: string, high?: string }
  public metadatasFromValue(buildingId, type: number, valueString, valueBoolean, low, high, datasize, fullText, page, pageSize): Observable<any> {
    let url = `buildings/${buildingId}/metadatasFromValue?type=${type}&dataSize=${datasize}&fullText=${fullText}&page=${page}&pageSize=${pageSize}`;

    switch (type) {
      case 1:
        url += `&value=${valueBoolean}`;
        break;
      case 3:
        url += `&value=${valueString}`;
        break;
      case 2:
        url += `&low=${low}&high=${high}`
        break;
    }

    return this.doGet(url)
  }

  private checkMetadata(metadata: IMetadata) {
    /*let errors: IServiceError[] = [];

     if( !metadata.accuracy ) {
     errors.push( { message: "Accuracy is required.", field: "accuracy" } );
     }
     if( !metadata.dateInstalled ) {
     errors.push( { message: "Date installed is required.", field: "dateInstalled" } );
     } else if( !Date.parse( metadata.dateInstalled ) ) {
     errors.push( { message: "Date installed is invalid.", field: "dateInstalled" } );
     }
     if( !metadata.friendlyName ) {
     errors.push( { message: "Friendly name is required.", field: "friendlyName" } );
     }
     if( !metadata.manufacturer ) {
     errors.push( { message: "Manufacturer is required.", field: "manufacturer" } );
     }
     if( !metadata.maxValue ) {
     errors.push( { message: "Maximum value is required.", field: "maxValue" } );
     }
     if( !metadata.minValue ) {
     errors.push( { message: "Minimum value is required.", field: "minValue" } );
     }
     if( !metadata.model ) {
     errors.push( { message: "Model is required.", field: "model" } );
     }
     if( !metadata.resolution ) {
     errors.push( { message: "Resolution is required.", field: "resolution" } );
     }
     /!*if( !metadata.tags || metadata.tags.length < 1 ) {
     errors.push( { message: "At least one tag is required.", field: "tags" } );
     }*!/
     // if( !metadata.pointDescription ) {
     //   errors.push( { message: "Description is required.", field: "description" } );
     // }
     if( !metadata.unit ) {
     errors.push( { message: "Unit is required.", field: "unit" } );
     }
     if( !metadata.unitType ) {
     errors.push( { message: "Unit type is required.", field: "unitType" } );
     }

     if( errors.length > 0 ) {
     return Observable.throw( { status: 400, errors: errors } );
     }*/

    return undefined;
  }

  // buildings/{id}/points/{point}/metadata
  public SetMetadata(oldMetadata: IMetadata, metadata: IMetadata) {
    if (!oldMetadata) {
      return this.doPost(this.rootUrl + "/" + metadata.building + "/points/createMetadata",
        { entity: metadata }).map(response => response);
    } else {
      return this.doPost(this.rootUrl + "/" + oldMetadata.building + "/points/" + oldMetadata._id + "/metadata",
        { entity: metadata }).map(response => response);
    }
  }

  public GetUnits() {
    return this.doGet("units").map(response => (response));
  }

  public GetAllPointsWithMetadata(building: string, onlyMeters: boolean = true, filter: string = "", page: number = 1, pageSize: number = 100, linked: boolean = false): Observable<any> {

    return new Observable((observer: Observer<any>) => {
      let m = onlyMeters ? 1 : 0;
      let url = `${this.rootUrl}/${building}/metadatas?linked=${linked ? 1 : 0}&meter=${m}&page=${page}&pageSize=${pageSize}&fullText=${encodeURI(filter)}`;
      this.doGet(url).map(response => (response)).subscribe(x => {
        for (let d of x.data) {
          if (d.friendlyName)
            d.friendlyName = this.appService.convertFromASCII(d.friendlyName);
          if (d.point)
            d.point = this.appService.convertFromASCII(d.point);
          if (d.ord)
            d.ord = this.appService.convertFromASCII(d.ord);

          if (d.name)
            d.name = this.appService.convertFromASCII(d.name);
        }

        observer.next(x);
      });
    })

    // if (onlyMeters) {
    // return new Observable((observer: Observer<any>) => {
    //   let url = filter == "" ? `${this.rootUrl}/${building}/metadatas?meter=1&page=${page}&pageSize=${pageSize}` : `${this.rootUrl}/${building}/metadatas?meter=1&page=${page}&pageSize=${pageSize}&fullText=${filter}`;
    //   this.doGet(url).map(response => (response)).subscribe(x => {
    //     for (let d of x.data) {
    //       if (d.friendlyName)
    //         d.friendlyName = this.appService.convertFromASCII(d.friendlyName);
    //       if (d.point)
    //         d.point = this.appService.convertFromASCII(d.point);
    //       if (d.ord)
    //         d.ord = this.appService.convertFromASCII(d.ord);

    //       if (d.name)
    //         d.name = this.appService.convertFromASCII(d.name);
    //     }

    //     observer.next(x);
    //   });
    // })
    // }
    // return new Observable((observer: Observer<any>) => {
    //   let url = filter == "" ? `${this.rootUrl}/${building}/metadatas?page=${page}&pageSize=${pageSize}` : `${this.rootUrl}/${building}/metadatas?page=${page}&pageSize=${pageSize}&fullText=${filter}`;
    //   this.doGet(url).map(response => (response)).subscribe(x => {
    //     for (let d of x.data) {
    //       if (d.friendlyName)
    //         d.friendlyName = this.appService.convertFromASCII(d.friendlyName);
    //       if (d.point)
    //         d.point = this.appService.convertFromASCII(d.point);
    //       if (d.ord)
    //         d.ord = this.appService.convertFromASCII(d.ord);

    //       if (d.name)
    //         d.name = this.appService.convertFromASCII(d.name);
    //     }

    //     observer.next(x);
    //   });
    // })
  }


  public GetAllPointsWithVisibility(building: string, filter: string = "", page: number = 1, pageSize: number = 100) {
    return new Observable((observer: Observer<any>) => {
      this.doGet(`${this.rootUrl}/${building}/metadatas?page=${page}&pageSize=${pageSize}&visibility=1&fullText=${encodeURI(filter)}`).
        map(response => (response)).subscribe(x => {
          for (let d of x.data) {
            if (d.friendlyName)
              d.friendlyName = this.appService.convertFromASCII(d.friendlyName);
            if (d.point)
              d.point = this.appService.convertFromASCII(d.point);
            if (d.ord)
              d.ord = this.appService.convertFromASCII(d.ord);

            if (d.name)
              d.name = this.appService.convertFromASCII(d.name);
          }
          observer.next(x);
        });
    })
  }

  public UpdatePointsVisibility(building: string, update: any) {
    return this.doPost(this.rootUrl + "/" + building + "/points/visibility", update).
      map(response => (response));
  }

  public GetPointMetadata(building, point) {
    return this.doGet(this.rootUrl + "/" + building + "/points/" + point + "/metadata").
      map(response => (response));
  }

  // buildings/{id}/points/{point}/data
  public GetDataForPoint(data) {
    return this.doGet(this.rootUrl + "/" + data.building + "/points/" + data.point + "/data?start=" + data.from + "&end=" + data.to +
      (data.addHistoricData ? "&addHistoricData=1" : "")).map(response => (response));
  }

  //testPrediction?isoWeek=5
  public getPrediction(week: Number = 5, dow: number = 1): Observable<any> {
    return this.doGet("testPrediction?isoWeek=" + week + "&dow=" + dow);
  }

  public GetListOfPoints(id, meter = false, filter = "", page = 1, pageSize = 100, dataView: number = 0): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.doGet(`${this.rootUrl}/${id}/points?meter=${(meter ? "1" : "0")}&page=${page}&pageSize=${pageSize}&fullText=${filter}&dataView=${dataView}`).
        map(response => (response)).subscribe(x => {
          observer.next(x);
        });
    })
  }

  public GetDashboardInfo(company, page: number = 1, pageSize: number = 100, search = "") {
    let url = "admin/dashboard?";
    if (company) {
      url += "company=" + company + "&";
    }

    url += `page=${page}&pageSize=${pageSize}&fullText=${search}`;

    return this.doGet(url).map(response => (response));
  }

  public GetAllAlarms(data, filter: string = "", page: number = 1, pageSize: number = 100) {
    // let url = `${this.rootUrl}/${data.building}/alarms?onlyActives=${data.onlyActives}&start=${data.start}&end=${data.end}&page=${page}&pageSize=${pageSize}&fullText=${encodeURI(filter)}`;
    let url = `${this.rootUrl}/${data.building}/alarms`

    data["fullText"] = filter;
    data["pageSize"] = pageSize;
    data["page"] = page;

    return this.doPost(url, data).map(response => response as any);
  }

  public GetAlarmForPoint(data): any {
    return this.doPost(this.rootUrl + "/" + data.building + "/alarms", data).map(response => (response));
  }

  public GetPointsWithAlarm(building) {
    return this.doGet(this.rootUrl + "/" + building + "/pointswithalarm").
      map(response => (response));
  }
}

export interface IMetadata {
  _id?: string;
  ord?: string;
  accuracy?: number;
  accuracyDescription?: string;
  dateInstalled?: string;
  friendlyName?: string;
  manufacturer?: string;
  maxValue?: number;
  minValue?: number;
  model?: string;
  pointName?: string;
  pointDescription?: string;
  resolution?: number;
  resolutionDescription?: string;
  tags?: string[];
  unit?: string;
  unitsDescription?: string;
  unitType?: string;
  building?: string;
  created_at?: string;
  updated_at?: string;
  firstReceivedData?: string;
  lastReceivedData?: string;
  numberOfDocs?: number;
  numberOfDataPoints?: number;
  numberOfGaps?: number;
  gaps?: string[];
  meterDataPoint?: boolean;
  meterType?: string;
  meterUseDelta?: boolean;
  meterValue?: number;
  sensorToken?: string;
  approximation?: string;
  tempDataPoint?: boolean;
  serialNumber?: string;
}
