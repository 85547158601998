import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiplePointsChartComponent } from './multiple-points-chart.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppHighstockModule } from '../highcharts/highstock/highstock.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [MultiplePointsChartComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    AppHighstockModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    TranslocoModule
  ],
  exports: [MultiplePointsChartComponent]
})
export class MultiplePointsChartModule { }
