import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IBuilding } from "app/interfaces/building";
import { SpinnerService } from "app/services/spinner.service";
import { BuildingService } from "app/services/building.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { LoginService } from "app/services/login.service";
import { MatTabGroup } from "@angular/material/tabs";

@Component( {
  selector: "app-building-complete-form",
  templateUrl: "./building-complete-form.component.html",
  styleUrls: [ "./building-complete-form.component.css" ]
} )
export class BuildingCompleteFormComponent implements OnInit {

  @Input() building = "";

  @ViewChild("mattab") matTab: MatTabGroup;

  public buldingActive = true;
  public selectedTabIndex = 0;
  public buildingEntity: IBuilding;

  constructor( public spinner: SpinnerService, private buildingService: BuildingService, public i18n: AppI18nService,
    private loginService: LoginService ) {
  }

  ngOnInit() {
    // this.matTab.selectedIndex = 2;
  }


  public saveBuilding( event ) {
    // let values = event.value;

    // let building: IBuilding = {
    //   address: values.address,
    //   company: this.loginService.userInfo.company,
    //   contacts: {
    //     emails: values.contact.emails.split(','),
    //     telephones: values.contact.telephones.split(',')
    //   },
    //   description: values.description,
    //   geographicalPosition: {
    //     x: values.geographicalPosition.x,
    //     y: values.geographicalPosition.y
    //   },
    //   name: values.name,
    // }

    // this.spinner.activate();
    // this.buildingService.create(building).subscribe(
    //   (x: IBuilding) => {
    //     this.spinner.desactivate();
    //     this.floorActive = true;
    //     this.matTab.selectedIndex = 1;
    //     // this.floorList.show = true;
    //     // this.floorForm.show = false;

    //     this.building = x;

    //     this.refreshFloorList();
    //   }
    // )
  }

}

