import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlarmsNotificationComponent } from './alarms-notification.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AlarmsNotificationComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule
  ],
  exports:[AlarmsNotificationComponent]
})
export class AlarmsNotificationModule { }
