import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit {

  @Input() type: SpinnerType = SpinnerType.Plane;

  public showSpinner = false;

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.spinnerService.isLoading.subscribe(isLoading => {
      this.showSpinner = isLoading;
    })
  }

  public enums = SpinnerType;

}

export enum SpinnerType {
  Plane,
  DoubleBounce,
  Wave,
  Cubes,
  Pulse,
  Dots,
  ThreeBounce,
  Circle,
  Grid,
  FadingCircle
}
