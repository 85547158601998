// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

/*
export const environment = {
  production: false,
  apiUrl: "https://l02azhy8a7.execute-api.eu-west-1.amazonaws.com/dev/",
  region: "eu-west-1",
};
*/

export const environment = {
  production: true,
  apiUrl: "https://e5vzabuyac.execute-api.eu-west-2.amazonaws.com/prod/",
  region: "eu-west-2"
};
