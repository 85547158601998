import { Injectable } from '@angular/core';
import { BasicRequestService } from './basic-request.service';
import { LoginService } from './login.service';
import { SpinnerService } from './spinner.service';
import { SweetalertService } from './sweetalert.service';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyViewService } from './company.view.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BasicRequestService {

  constructor(public transloco: TranslocoService, public companyView: CompanyViewService, public snackbar: MatSnackBar, public spinner: SpinnerService, private alert: SweetalertService, public http: HttpClient, public appService: AppService, public loginService: LoginService) {
    super(transloco, snackbar, companyView, http, appService, loginService, spinner);
  }

  public getBMSHealthReport(buildingId: string, device?: string, point?: string, nodeId?: string, year?: number, month?: number, page?: number, pageSize?: number): Observable<any> {
    let arr = [];

    if (device) {
      arr.push(`device=${device}`);
    }

    if (point) {
      arr.push(`point=${point}`);
    }

    if (nodeId) {
      arr.push(`nodeId=${nodeId}`);
    }

    if (year) {
      arr.push(`year=${year}`);
    }

    if (month) {
      arr.push(`month=${month}`);
    }

    if (page) {
      return this.doGet(`buildings/${buildingId}/bmsHealthReport?page=${page}&pageSize=${pageSize}&${arr.join("&")}`);
    }
    else {
      return this.doGet(`buildings/${buildingId}/bmsHealthReport?${arr.join("&")}`);
    }


  }

  public getErrorType(err: any) {
    switch (err) {
      case "0":
      case 0:
        return this.transloco.translate("issuetype.0");
      case "2":
      case 2:
        return this.transloco.translate("issuetype.2");
      case "3":
      case 3:
        return this.transloco.translate("issuetype.3");
      case "4":
      case 4:
        return this.transloco.translate("issuetype.4");
      case "5":
      case 5:
        return this.transloco.translate("issuetype.5");
      case "6":
      case 6:
        return this.transloco.translate("issuetype.6");
      case "7":
      case 7:
        return this.transloco.translate("issuetype.7");
      case 8:
      case "8":
        return this.transloco.translate("issuetype.8");
    }
  }

  get possibleAlarms() {
    return [0, 2, 4, 5, 6, 7, 8, 9, 10, 11];
  }
}

export enum NotificationAlarmTypes {
  heartBeat = 0,// ( 5min all point without data )
  missingData = 2,// ( 10 min point without data, and heart beat not sent )
  niagaraAlarms = 3,// ( Generated by attached niagara alarm )
  pointStatesChanged = 4,// ( Point or Subscription Status Change )
  nodeAlarm = 5,// ( data stale without in_maintenance )
  nonZeroData = 6,
  invalidValue = 7,
  maintenance = 8,
  subscriptionChange = 9,
  missingUnit = 10,
  timeSync = 11
}

export const MONTH_YEAR = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


export const YEAR = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MONTH = {
  parse: {
    dateInput: 'MMM',
  },
  display: {
    dateInput: 'MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
