import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { SpinnerType } from 'app/components/common/spinner/spinner.component';

@Component({
    selector: 'app-fullscreen-spinner',
    templateUrl: 'fullscreen-spinner.component.html',
    styleUrls: ['fullscreen-spinner.component.css']
})

export class FullscreenSpinnerComponent implements OnInit {
    constructor(public spinner: SpinnerService) { }

    @Input() type: SpinnerType = SpinnerType.Plane;

    ngOnInit() { }
}