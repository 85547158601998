import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ITagMaps, TagMapType, TagMapCondition } from 'app/interfaces/tagMap';
import { AppI18nService } from 'app/services/app.i18n.service';
import { ValidatorsService } from 'app/services/validators.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tag-maps-form',
  templateUrl: './tag-maps.component.html',
  styleUrls: ['./tag-maps.component.css']
})
export class TagMapsComponent implements OnInit {

  @Output() onsubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() tagMap: ITagMaps;

  public form;

  public types = [];
  public conditions = [];

  constructor(public i18n: AppI18nService, public validatorsService: ValidatorsService, public dialogRef: MatDialogRef<TagMapsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    for (let key of Object.keys(TagMapType)) {
      this.types.push({ key: key, text: TagMapType[key] });
    }

    for (let key of Object.keys(TagMapCondition)) {
      this.conditions.push({ key: key, text: TagMapCondition[key] });
    }

    this.reset();
  }

  public reset() {
    // console.log(this.data);
    if (this.tagMap || this.data.tagMap != undefined) {
      if (this.data.tagMap != undefined) {
        this.tagMap = {entity : null, applyToExistingPoints: true};
        this.tagMap.entity = this.data.tagMap;
      }

      this.form = new FormGroup({
        entity: new FormGroup({
          name: new FormControl(this.tagMap.entity.name, [Validators.required]),
          lookup: new FormControl(this.tagMap.entity.lookup, [Validators.required]),
          caseSensitive: new FormControl(this.tagMap.entity.caseSensitive, [Validators.required]),
          type: new FormControl(this.tagMap.entity.type, [Validators.required]),
          tags: new FormArray([], [Validators.required, this.validatorsService.minLengthArray(1)]),
          condition: new FormControl(this.tagMap.entity.condition, [Validators.required])
        }),
        applyToExistingPoints: new FormControl(this.tagMap.applyToExistingPoints, [Validators.required]),
      })

      for (let tag of this.tagMap.entity.tags) {
        (this.form.get('entity').get('tags') as FormArray).push(new FormControl(tag, [Validators.required]));
      }
    }
    else {
      this.form = new FormGroup({
        entity: new FormGroup({
          name: new FormControl("", [Validators.required]),
          lookup: new FormControl("", [Validators.required]),
          type: new FormControl("", [Validators.required]),
          caseSensitive: new FormControl(false, [Validators.required]),
          tags: new FormArray([new FormControl("", [Validators.required])], [Validators.required, this.validatorsService.minLengthArray(1)]),
          condition: new FormControl("", [Validators.required])
        }),
        applyToExistingPoints: new FormControl(true, [Validators.required])
      })
    }
  }

  public onSubmit() {
    if (this.form.valid) {
      // this.onsubmit.emit(this.form)
      this.dialogRef.close(this.form);
    }
  }

  public addTag() {
    (this.form.get('entity').get('tags') as FormArray).push(new FormControl("", [Validators.required]));
  }

  public removeTag(i) {
    (this.form.get('entity').get('tags') as FormArray).removeAt(i);
  }
}
