import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from 'app/services/spinner.service';

declare var jQuery: any;

@Component({
  selector: 'app-blank',
  templateUrl: 'blankLayout.template.html'
})
export class BlankLayoutComponent implements AfterViewInit, OnDestroy {

  constructor(private router: Router, public spinnerService: SpinnerService) {

  }

  ngOnInit() {
    this.spinnerService.fullStop();

  }

  ngAfterViewInit() {
    jQuery('body').addClass('gray-bg');
  }

  ngOnDestroy() {
    jQuery('body').removeClass('gray-bg');
  }
}
