import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressDisplayComponent } from './progress-display.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [ProgressDisplayComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatProgressBarModule
  ],
  exports: [
    ProgressDisplayComponent
  ]
})
export class ProgressDisplayModule { }
