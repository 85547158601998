import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "app/services/app.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { BuildingService } from "app/services/building.service";
import { LoginService } from "../../../services/login.service";
import { MapPopupComponent } from 'app/components/common/map-popup/map-popup.component';
import { QueryparamsService } from "app/services/queryparams.service";
import { HelpPageService } from "app/services/help-page.service";

@Component({
  selector: "app-admin-buildings",
  templateUrl: "./admin-buildings.component.html",
  styleUrls: ["./admin-buildings.component.css"]
})
export class AdminBuildingsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.updateData();
  }

  public filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;

    this.updateData();
  }


  public buildingsList: any[] = [];

  public displayedColumns: string[];

  constructor(private helppageService: HelpPageService,private queryService: QueryparamsService, public dialog: MatDialog, public appService: AppService, public i18n: AppI18nService, private spinnerService: SpinnerService,
    private alert: SweetalertService, private buildingService: BuildingService, public loginService: LoginService) {
  }

  public updateData() {

    this.queryService.ReplaceParams({
      filter: this.filter,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize
    }).subscribe(x => {

    });

    this.spinnerService.activate();
    this.buildingService.getAll(undefined, this.pagination, this.filter, undefined).subscribe(
      results => {
        this.spinnerService.desactivate();

        this.buildingsList = [];

        for (let building of results.data) {
          building.descriptionTooltip = building.description + "\nAddress: " + building.address.street + "," + building.address.city + "," + building.address.country;

          if (!building.downloadURL)
            building.downloadURL = "assets/images/building.png";

          this.buildingsList.push(building);

        }

        this.pagination.hasNext = (this.buildingsList.length > this.pagination.pageSize);

      },
      err => {
        this.spinnerService.desactivate();
        // this.alert.error(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.unkwonError", err));
      }
    );
  }

  ngOnInit() {

    this.helppageService.changeUrl("./assets/help-pages/buildings.html?language=" + this.appService.getLanguage());
    this.appService.sectionName = this.i18n.translate("general.sections.building.buildings");

    this.displayedColumns = ["name", "description", "address", "operations"];

    this.queryService.params().subscribe(params => {
      this.pagination.pageSize = params["pageSize"] ? parseInt(params["pageSize"]) : 100;
      this.pagination.page = params["page"] ? parseInt(params["page"]) : 1;
      this.filter = params["filter"];


      this.updateData();
    })
  }

  public openMap(id) {
    let building = this.buildingsList.find(x => x._id == id);
    building.address.lat
    let data = {
      lat: building.address.lat,
      lng: building.address.lng,
      textDescription: building.address.street + " " + building.address.number + ", " + building.address.city + " - " + building.address.country
    }

    const dialogRef = this.dialog.open(MapPopupComponent, {
      width: '600px',
      data: data
    });
  }

  ngAfterViewInit() {
  }

}
