import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import * as humanizeDuration from "humanize-duration"
import { SpinnerService } from 'app/services/spinner.service';
import { BuildingService } from 'app/services/building.service';
import { MatDialog } from '@angular/material/dialog';
import { NotePopupComponent } from './note-popup/note-popup.component';
import { NoteTimelineComponent } from './note-timeline/note-timeline.component';
import { SweetalertService } from 'app/services/sweetalert.service';
import { TranslocoService } from '@ngneat/transloco';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.css']
})
export class AlarmsComponent implements OnInit {

  constructor(private translocoService: TranslocoService, private alertService: SweetalertService, public spinner: SpinnerService, public buildingService: BuildingService, public dialog: MatDialog) { }
  public FilteredselectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildings: any[] = [];
  public selectedBuilding = "";

  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });

  public filterBuilding(){
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;

    let searchBase = this.buildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.buildings;
}

  }
  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  public changeBuilding(val) {
    this.selectedBuilding = this.FilteredselectedBuilding._id;
    this.onBuildingChange();
  }

  private shortEnglishHumanizer = humanizeDuration.humanizer({
    language: "shortEn",
    languages: {
      shortEn: {
        y: () => "y",
        mo: () => "mo",
        w: () => "w",
        d: () => "d",
        h: () => "h",
        m: () => "m",
        s: () => "s",
        ms: () => "ms",
      },
    },
  });


  public summary = { alarmsAck: 0, alarmsUnack: 0, normalUnack: 0, criticalAck: 0 }

  public activeUnack = new MatTableDataSource<any>();
  public activeAck = new MatTableDataSource<any>();
  public normalUnack = new MatTableDataSource<any>();
  public normalAck = new MatTableDataSource<any>();

  public paginationActiveUnack = { page: 1, pageSize: 100, hasNext: false };
  public paginationActiveAck = { page: 1, pageSize: 100, hasNext: false };
  public paginationNormalUnack = { page: 1, pageSize: 100, hasNext: false };
  public paginationNormalAck = { page: 1, pageSize: 100, hasNext: false };

  public displayedActiveUnack = ["raised", "type", "name", "duration", "priority", "pointname", "state", "ackstate", "operation"];
  public displayedActiveAck = ["raised", "type", "name", "duration", /*"durationack",*/ "priority", "pointname", "state", "ackstate", "lastupdate", "operation"];
  public displayedNomalUnack = ["raised", "type", "name", "duration", /*"durationack",*/ "priority", "pointname", "state", "ackstate", "lastupdate", "operation"];
  public displayedHistory = ["raised", "type", "duration", "priority", "pointname", "state", "operation"];

  ngOnInit(): void {
    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;
      this.spinner.desactivate();
    })
  }

  public onBuildingChange() {
    this.spinner.activate();
    this.buildingService.alarmSummary(this.selectedBuilding).subscribe(x => {
      if (x.data.length == 0) {
        this.summary = { alarmsAck: 0, alarmsUnack: 0, normalUnack: 0, criticalAck: 0 }
      }
      else {
        this.summary = { alarmsAck: x.data[0].alarmAck, alarmsUnack: x.data[0].alarmUnAck, normalUnack: x.data[0].normalUnAck, criticalAck: x.data[0].normalAck }
      }
      this.spinner.desactivate();
    })

    this.paginationActiveUnack = { page: 1, pageSize: 100, hasNext: false };
    this.paginationActiveAck = { page: 1, pageSize: 100, hasNext: false };
    this.paginationNormalUnack = { page: 1, pageSize: 100, hasNext: false };
    this.paginationNormalAck = { page: 1, pageSize: 100, hasNext: false };

    this.changePageActiveUnack(this.paginationActiveUnack);
    this.changePageActiveAck(this.paginationActiveAck);
    this.changePageNormalAck(this.paginationNormalAck);
    this.changePageNormalUnack(this.paginationNormalUnack);
  }


  public changePageActiveUnack(pagination) {
    this.paginationActiveUnack.page = pagination.page;
    this.paginationActiveUnack.pageSize = pagination.pageSize;

    this.spinner.activate();
    this.buildingService.detailedAlarms(this.selectedBuilding, "NotNormal", false, this.paginationActiveUnack.page, this.paginationActiveUnack.pageSize).subscribe(x => {

      this.activeUnack.data = x.data.map(t => {
        let notes = [];

        for (let i = 0; i < t.history.length; i++) {
          let message = t.history[i].alarm.noteSent ? t.history[i].alarm.noteSent : "No note attached";

          if (t.history[i].alarm.ackState != undefined) {
            message += "\nAck state: " + (t.history[i].alarm.ackState != 0 ? "Unack" : "Ack");
          }

          if(t.history[i].alarm.state != undefined){
            message += "\nState: " +this.alarmEnum(t.state)
          }

          notes.push({
            header: moment(t.history[i].receivedAt).format("DD/MM/YYYY HH:mm"),
            message: message,
            sync: t.history[i].alarm.noteSent ? t.history[i].alarm.sync : true,
            timestamp: moment(t.history[i].receivedAt).valueOf()
          })
        }

        notes.sort((a, b) => {
          return a.timestamp > b.timestamp ? -1 : 1;
        });

        return {
          id: t._id,
          raised: moment(t.dateRaised).format("DD/MM/YYYY HH:mm"),
          type: t.alarmType,
          name: t.alarmName,
          duration: this.shortEnglishHumanizer(moment(t.dateRaised).diff(moment()), { largest: 2, round: true }),
          priority: t.priority,
          pointName: t.sourceName ? t.sourceName : t.ord,
          ord:  t.ord,
          notes: notes,
          state: this.alarmEnum(t.state),
          ackState: t.ackState != 0 ? "Unack" : "Ack",
          lastUpdate: moment(t.lastUpdate).format("DD/MM/YYYY HH:mm"),
          device: t.device
        }
      })

      this.paginationActiveUnack.hasNext = x.data.length > this.paginationActiveUnack.pageSize;
      this.spinner.desactivate();
    })
  }

  public changePageActiveAck(pagination) {
    this.paginationActiveAck.page = pagination.page;
    this.paginationActiveAck.pageSize = pagination.pageSize;

    this.spinner.activate();
    this.buildingService.detailedAlarms(this.selectedBuilding, "NotNormal", true, this.paginationActiveAck.page, this.paginationActiveAck.pageSize).subscribe(x => {
      this.activeAck.data = x.data.map(t => {
        let notes = [];


        for (let i = 0; i < t.history.length; i++) {
          let message = t.history[i].alarm.noteSent ? t.history[i].alarm.noteSent : "No note attached";

          if (t.history[i].alarm.ackState != undefined) {
            message += "\nAck state: " + (t.history[i].alarm.ackState != 0 ? "Unack" : "Ack");
          }

          if(t.history[i].alarm.state != undefined){
            message += "\nState: " +this.alarmEnum(t.state)
          }

          notes.push({
            header: moment(t.history[i].receivedAt).format("DD/MM/YYYY HH:mm"),
            message: message,
            sync: t.history[i].alarm.noteSent ? t.history[i].alarm.sync : true,
            timestamp: moment(t.history[i].receivedAt).valueOf()
          })
        }

        notes.sort((a, b) => {
          return a.timestamp > b.timestamp ? -1 : 1;
        });

        return {
          id: t._id,
          raised: moment(t.dateRaised).format("DD/MM/YYYY HH:mm"),
          type: t.alarmType,
          name: t.alarmName,
          duration: this.shortEnglishHumanizer(moment(t.dateRaised).diff(moment()), { largest: 2, round: true }),
          priority: t.priority,
          pointName: t.sourceName ? t.sourceName : t.ord,
          ord:  t.ord,
          notes: notes,
          state: this.alarmEnum(t.state),
          ackState: t.ackState != 0 ? "Unack" : "Ack",
          lastUpdate: moment(t.lastUpdate).format("DD/MM/YYYY HH:mm"),
          device: t.device
        }
      })

      this.paginationActiveAck.hasNext = x.data.length > this.paginationActiveAck.pageSize;
      this.spinner.desactivate();
    })
  }

  public changePageNormalUnack(pagination) {
    this.paginationNormalUnack.page = pagination.page;
    this.paginationNormalUnack.pageSize = pagination.pageSize;


    this.spinner.activate();
    this.buildingService.detailedAlarms(this.selectedBuilding, "Normal", false, this.paginationNormalUnack.page, this.paginationNormalUnack.pageSize).subscribe(x => {
      this.normalUnack.data = x.data.map(t => {
        let notes = [];


        for (let i = 0; i < t.history.length; i++) {
          let message = t.history[i].alarm.noteSent ? t.history[i].alarm.noteSent : "No note attached";

          if (t.history[i].alarm.ackState != undefined) {
            message += "\nAck state: " + (t.history[i].alarm.ackState != 0 ? "Unack" : "Ack");
          }

          if(t.history[i].alarm.state != undefined){
            message += "\nState: " +this.alarmEnum(t.state)
          }

          notes.push({
            header: moment(t.history[i].receivedAt).format("DD/MM/YYYY HH:mm"),
            message: message,
            sync: t.history[i].alarm.noteSent ? t.history[i].alarm.sync : true,
            timestamp: moment(t.history[i].receivedAt).valueOf()
          })
        }

        notes.sort((a, b) => {
          return a.timestamp > b.timestamp ? -1 : 1;
        });

        return {
          id: t._id,
          raised: moment(t.dateRaised).format("DD/MM/YYYY HH:mm"),
          type: t.alarmType,
          name: t.alarmName,
          duration: this.shortEnglishHumanizer(moment(t.dateRaised).diff(moment(t.alarmClosedAt)), { largest: 2, round: true }),
          priority: t.priority,
          pointName: t.sourceName ? t.sourceName : t.ord,
          ord:  t.ord,
          notes: notes,
          state: this.alarmEnum(t.state),
          ackState: t.ackState != 0 ? "Unack" : "Ack",
          lastUpdate: moment(t.lastUpdate).format("DD/MM/YYYY HH:mm"),
          device: t.device
        }
      })

      this.paginationNormalUnack.hasNext = x.data.length > this.paginationNormalUnack.pageSize;
      this.spinner.desactivate();
    })
  }

  public changePageNormalAck(pagination) {
    this.paginationNormalAck.page = pagination.page;
    this.paginationNormalAck.pageSize = pagination.pageSize;

    this.spinner.activate();
    this.buildingService.detailedAlarms(this.selectedBuilding, "Normal", true, this.paginationNormalAck.page, this.paginationActiveAck.pageSize).subscribe(x => {
      this.normalAck.data = x.data.map(t => {
        let notes = [];


        for (let i = 0; i < t.history.length; i++) {
          let message = t.history[i].alarm.noteSent ? t.history[i].alarm.noteSent : "No note attached";

          if (t.history[i].alarm.ackState != undefined) {
            message += "\nAck state: " + (t.history[i].alarm.ackState != 0 ? "Unack" : "Ack");
          }

          if(t.history[i].alarm.state != undefined){
            message += "\nState: " +this.alarmEnum(t.state)
          }

          notes.push({
            header: moment(t.history[i].receivedAt).format("DD/MM/YYYY HH:mm"),
            message: message,
            sync: t.history[i].alarm.noteSent ? t.history[i].alarm.sync : true,
            timestamp: moment(t.history[i].receivedAt).valueOf()
          })
        }

        notes.sort((a, b) => {
          return a.timestamp > b.timestamp ? -1 : 1;
        });

        return {
          id: t._id,
          raised: moment(t.dateRaised).format("DD/MM/YYYY HH:mm"),
          type: t.alarmType,
          name: t.alarmName,
          duration: this.shortEnglishHumanizer(moment(t.dateRaised).diff(moment(t.alarmClosedAt)), { largest: 2, round: true }),
          priority: t.priority,
          pointName: t.sourceName ? t.sourceName : t.ord,
          ord:  t.ord,
          notes: notes,
          state: this.alarmEnum(t.state),
          ackState: t.ackState != 0 ? "Unack" : "Ack",
          lastUpdate: moment(t.lastUpdate).format("DD/MM/YYYY HH:mm"),
          device: t.device
        }
      })

      this.paginationNormalAck.hasNext = x.data.length > this.paginationNormalAck.pageSize;
      this.spinner.desactivate();
    })
  }

  public alarmEnum(value) {
    switch (value) {
      case 0:
        return "normal";
        break;
      case 1:
        return "offnormal";
        break;
      case 2:
        return "fault";
        break;
      case 3:
        return "alert";
        break;
      default:
        return value;
    }
  }

  public addNote(alarm) {
    const dialogRef = this.dialog.open(NotePopupComponent, {
      width: '440px',
      data: {
        required: true
      }
    });

    dialogRef.afterClosed().subscribe(ret => {
      if (ret) {
        this.spinner.activate();
        this.buildingService.updateAlarm(alarm.device, { ack: false, note: ret.note, alarmId: alarm.id }).subscribe(x => {
          this.spinner.desactivate();

          this.onBuildingChange();

          this.alertService.success(this.translocoService.translate('admin.alarms.summary.messages.success'), this.translocoService.translate('admin.alarms.summary.messages.info'));
        })
      }
    })

  }

  public ackOne(alarm) {
    const dialogRef = this.dialog.open(NotePopupComponent, {
      width: '440px',
      data: {
        required: false
      }
    });

    dialogRef.afterClosed().subscribe(ret => {
      if (ret) {
        this.spinner.activate();
        this.buildingService.updateAlarm(alarm.device, { ack: true, note: ret.note, alarmId: alarm.id }).subscribe(x => {
          this.spinner.desactivate();

          this.alertService.success(this.translocoService.translate('admin.alarms.summary.messages.success'), this.translocoService.translate('admin.alarms.summary.messages.info'));

          this.onBuildingChange();
        })
      }
    })
  }

  public ackAllNormal() {
    const dialogRef = this.dialog.open(NotePopupComponent, {
      width: '440px',
      data: {
        required: false
      }
    });

    dialogRef.afterClosed().subscribe(ret => {
      if (ret) {
        this.spinner.activate();
        this.buildingService.ackAllAlarmsBuilding(this.selectedBuilding, { note: ret.note, ackType: "Normal" }).subscribe(x => {
          this.spinner.desactivate();

          this.alertService.success(this.translocoService.translate('admin.alarms.summary.messages.success'), this.translocoService.translate('admin.alarms.summary.messages.info'));

          this.onBuildingChange();
        })
      }
    })
  }

  public ackAllAlarm() {
    const dialogRef = this.dialog.open(NotePopupComponent, {
      width: '440px',
      data: {
        required: false
      }
    });

    dialogRef.afterClosed().subscribe(ret => {
      if (ret) {
        this.spinner.activate();
        this.buildingService.ackAllAlarmsBuilding(this.selectedBuilding, { note: ret.note, ackType: "NotNormal" }).subscribe(x => {
          this.spinner.desactivate();

          this.alertService.success(this.translocoService.translate('admin.alarms.summary.messages.success'), this.translocoService.translate('admin.alarms.summary.messages.info'));

          this.onBuildingChange();
        })
      }
    })
  }

  public viewNotes(notes) {
    const dialogRef = this.dialog.open(NoteTimelineComponent, {
      width: '600px',
      data: {
        notes: notes
      }
    });
  }
}
