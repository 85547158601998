import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserType } from 'app/interfaces/baseinterface';
import { LoginService } from 'app/services/login.service';

@Component({
  selector: 'app-users-buildings',
  templateUrl: './users-buildings.component.html',
  styleUrls: ['./users-buildings.component.css']
})
export class UsersBuildingsComponent implements OnInit {

  public filter = "";
  public pagination = { page: 1, pageSize: 10, hasNext: false };

  public displayedColumns = ["building", "description", "permission"];

  public allbuildings = [];

  public needToSave = false;

  public datasource = new MatTableDataSource<any>();

  constructor(public dialogRef: MatDialogRef<UsersBuildingsComponent>, @Inject(MAT_DIALOG_DATA) public data, private loginService: LoginService) { }


  public applyFilter() {
    let filteredList = this.allbuildings.filter(x => x.name.indexOf(this.filter) >= 0);
    this.datasource.data = filteredList.slice((this.pagination.page - 1) * this.pagination.pageSize, this.pagination.page * this.pagination.pageSize)
    this.pagination.hasNext = !(this.datasource.data.length < this.pagination.pageSize);
  }

  public types = Object.keys(UserType).map(key => UserType[key]).filter(x => typeof (x) == "number").filter(x => x <= this.data.user).concat([-1000]);

  ngOnInit(): void {

    this.allbuildings = this.data.buildings.map(b => {
      let userbuilding = this.data.userBuildings.find(x => x.id == b._id);

      return {
        _id: b._id,
        name: b.name,
        description: b.description,
        permission: userbuilding ? userbuilding.permission : -1000
      }
    })

    this.applyFilter();
  }

  public changePage(event) {
    this.pagination.pageSize = event.pageSize;
    this.pagination.page = event.page;

    this.applyFilter();
  }

  public cancel() {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close(this.allbuildings.filter(x => x.permission > -1000).map(x => { return {id: x._id, permission:x.permission}}));
  }

  public changeView(id) {
    this.needToSave = true;
  }
}
