import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "app/services/app.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { BuildingService } from "app/services/building.service";
import { ActivatedRoute } from "@angular/router";

@Component( {
  selector: "app-root-buildings",
  templateUrl: "./root-buildings.component.html",
  styleUrls: [ "./root-buildings.component.css" ]
} )
export class RootBuildingsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource();

  public displayedColumns: string[];

  constructor( private route: ActivatedRoute, public appService: AppService, public i18n: AppI18nService,
    private spinnerService: SpinnerService, private alert: SweetalertService, private buildingService: BuildingService ) {
  }

  ngOnInit() {
    this.appService.sectionName = this.i18n.translate( "general.sections.building.buildings" );

    this.displayedColumns = [ "name", "description", "address", "locationToken", "operations" ];

    this.spinnerService.activate();

    this.route.params.subscribe( params => {
      this.buildingService.getAll( "company=" + params["id"] ).subscribe(
        results => {
          this.dataSource.data = results.data;
          this.spinnerService.desactivate();
        },
        err => {
          this.spinnerService.desactivate();
          // this.alert.error(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.unkwonError", err));
        }
      );
    } );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter( filterValue: string ) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
