import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class EndpointLog implements HttpInterceptor {
  public list: { url: string, endpoints: string[] }[] = [];

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.list = JSON.parse(localStorage.getItem("endpointlog"));
    if (!this.list) this.list = [];

    let endpoint = httpRequest.urlWithParams.split("?")[0];
    let url = window.location.href.split("?")[0];

    if (endpoint.startsWith(environment.apiUrl)) {
      endpoint = `${httpRequest.method} ${endpoint}`;

      let page = this.list.find(x => x.url == url);
      if (page) {
        if(!page.endpoints.find(x => x == endpoint)){
          page.endpoints.push(endpoint);
        }
      }
      else {
        this.list.push({
          url: url,
          endpoints: [endpoint]
        })
      }
      // console.log(this.list);
      localStorage.setItem("endpointlog", JSON.stringify(this.list));
    }

    return next.handle(httpRequest);
  }
}
