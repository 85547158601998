import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { BuildingService } from 'app/services/building.service';
import { SpinnerService } from 'app/services/spinner.service';
import { Moment } from 'moment-timezone';

import * as moment from 'moment-timezone';

import { jsPDF } from 'jspdf';
import * as canvg from "canvg";


import { HighchartsComponent } from 'app/components/common/highcharts/highcharts/highcharts.component';
import { AppHighstockComponent } from 'app/components/common/highcharts/highstock/highstock.component';
import { MatDialog } from '@angular/material/dialog';
import { AdminMetersReadingPointPopupComponent } from './admin-meters-reading-point-popup/admin-meters-reading-point-popup.component';

@Component({
  selector: 'app-admin-meters-reading',
  templateUrl: './admin-meters-reading.component.html',
  styleUrls: ['./admin-meters-reading.component.css']
})
export class AdminMetersReadingComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    building: new FormControl(null, [Validators.required]),
    date: new FormControl(moment().startOf("month"), [Validators.required]),
    point: new FormControl('', [Validators.required]),
    reportId: new FormControl()
  })

  @ViewChildren("tableDaysSparkline") tableDaysSparkline: QueryList<HighchartsComponent>;
  @ViewChildren("tableDaysDelta") tableDaysDelta: QueryList<HighchartsComponent>;

  public tableDays: { raw: number, label: string, sparkline: any, avg: string, min: string, max: string, delta: any }[] = [];

  public canvas = document.createElement('canvas');

  public months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  public radialMonthComparsion = {
    chart: {
      type: 'column',
      inverted: true,
      polar: true
    },
    title: {
      text: ''
    },
    tooltip: {
      outside: true
    },
    pane: {
      size: '85%',
      innerSize: '20%',
      endAngle: 270
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        align: 'right',
        useHTML: true,
        allowOverlap: true,
        step: 1,
        y: 3,
        style: {
          fontSize: '13px'
        }
      },
      lineWidth: 0,
      categories: []
    },
    legend: {
      enabled: false
    },
    yAxis: {
      crosshair: {
        enabled: true,
        color: '#333'
      },
      lineWidth: 0,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15
      }
    }
  }

  displayByName(obj) {
    return obj ? obj.point.friendlyName : obj;
  }


  get validForm() {

    return this.form.valid && (typeof this.form.get('point').value != typeof '')
  }

  public sparklineOptions = {
    chart: {
      backgroundColor: null,
      borderWidth: 0,
      type: 'area',
      margin: [2, 0, 2, 0],
      width: 160,
      height: 30,
      style: {
        overflow: 'visible'
      },
      skipClone: true
    },
    exporting: {
      enabled: false
    },
    series: [{
      name: "Consumption",
      data: [],
      pointStart: 0
    }],
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      tickPositions: [0]
    },
    legend: {
      enabled: false
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true,
      valueDecimals: 2,
      valueSuffix: " ",
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          }
        },
        fillOpacity: 0.5
      },
      column: {
        borderColor: 'silver'
      }
    }
  }

  public filteredPoints = [];
  public hasPoint: boolean = false;
  filterPoint(event) {
    let filter = event instanceof Object ? event.name : event;
    if (!filter) return;
    this.filteredPoints = this.points.filter(p => {

      let add = p.point.friendlyName ? p.point.friendlyName.toLowerCase().indexOf(filter.toLowerCase()) >= 0 : false;
      return add;
    });
  }

  public halfhourConsuptiomOptions = {
    chart: {
      alignTicks: false,
      zoomType: "x"
    },

    boos: {
      enabled: false
    },

    plotOptions: {
      series: {
        turboThreshold: 1488
      }
    },

    rangeSelector: {
      enabled: false
    },
    yAxis: [{
      min: 0,
      title: {
        text: 'kWh'
      }
    }],
    xAxis: {
      type: "datetime"
    },

    title: {
      text: ''
    },

    credits: {
      enabled: false
    },
    // tooltip: {
    //   formatter: function () {
    //     console.log(this);
    //     var s = this.points[0].point.category == null ? '<b>' + moment(this.x).format("DD/MM") + '</b>':  '<b>' + moment(this.x).format("DD/MM HH:mm") + '</b>';

    //     this.points.forEach((point, i) => {
    //       s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + point.y.toFixed(2) + " " + point.point.options.unit;
    //       if (point.point.options.missingImpact + point.point.options.interpolationImpact > 0) {
    //         s += '<br/>Missing Impact: ' + point.point.options.missingImpact;
    //         s += '<br/>Interpolation Impact: ' + point.point.options.interpolationImpact
    //       }
    //     });

    //     return s;
    //   },
    //   shared: true
    // },

    series: [{
      events: {
        click: function (event) {
          let d = moment(new Date(event.point.x));
          let max = moment.utc(d).endOf("day").valueOf();
          let min = moment.utc(d).startOf("day").valueOf();

          event.point.series.chart.xAxis[0].setExtremes(min, max);
        }
      },
      dataGrouping: {
        forced: true,
        approximation: "sum",
        units: [[
          'minute',
          [30]
        ], [
          'hour',
          [24]
        ]]
      },
      type: 'column',
      id: "halfhour",
      name: 'Half Hour Consuptiom'
    }]
  }

  public lastMonths: any[] = this.populateMonths();

  constructor(private spinner: SpinnerService, private buildingService: BuildingService, public dialog: MatDialog) { }

  @ViewChild("radialChart") radialChart: HighchartsComponent;
  @ViewChild("deltaChart") deltaChart: HighchartsComponent;
  @ViewChild("weekdayMonth") weekdayMonth: HighchartsComponent;
  @ViewChild("weekdayWorkinghoursMonth") weekdayWorkinghoursMonth: HighchartsComponent;
  @ViewChild("piechart") piechart: HighchartsComponent;
  @ViewChild("yearconsuptiom") yearconsuptiom: HighchartsComponent;
  @ViewChild("halfhoursConsuptiom") halfhoursConsuptiom: AppHighstockComponent;
  @ViewChild("weekdayWorkinghoursMonthHourAvg") weekdayWorkinghoursMonthHourAvg: HighchartsComponent;
  @ViewChild("piechartHourAvg") piechartHourAvg: HighchartsComponent;

  @ViewChild("wholePage") wholePage: ElementRef;

  public buildings: any[] = [];

  ngOnInit(): void {

  }

  public reportName = "";

  public openSeachReport() {
    const dialogRef = this.dialog.open(AdminMetersReadingPointPopupComponent, {
      width: '1080px',
      data: {
      }
    });


    dialogRef.afterClosed().subscribe(ret => {
      if (ret != undefined && ret != undefined) {
        this.reportName = ret.reportName;

        this.form.get("date").setValue(ret.date);
        this.form.get("building").setValue(ret.building);
        this.form.get("point").setValue(ret.report);
        this.form.get("reportId").setValue(ret.report);

        this.refreshData();
      }
    })
  }

  private populateMonths(): string[] {
    let date = moment(this.form.get("date").value);
    let lastYear = this.months[date.clone().subtract(1, "year").month()] + " " + date.clone().subtract(1, "year").year();
    let ret = [];

    for (let i = 0; i < 3; i++) {
      ret.push(this.months[date.month()] + " " + date.year());
      date = date.subtract(1, "month");
    }

    ret.push(lastYear);
    return ret;
  }

  public workingdaysOfWeek = {
    chart: {
      type: 'column'
    },
    title: {
      text: ""
    },
    yAxis: {
      min: 0,
      title: {
        text: 'kWh'
      }
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        var s = '<b>' + this.x + '</b>';

        this.points.forEach((point, i) => {
          s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + point.y.toFixed(2) + " " + point.point.options.unit;
          if (point.point.options.missingImpact + point.point.options.interpolationImpact > 0) {
            s += '<br/>Missing Impact: ' + point.point.options.missingImpact;
            s += '<br/>Interpolation Impact: ' + point.point.options.interpolationImpact
          }
        });

        return s;
      },
      shared: true
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        grouping: false,
        shadow: false,
        borderWidth: 0
      }
    },
    series: [{
      name: 'Working Hours',
      id: "working",
      data: [],
      // pointPadding: 0.3
    }, {
      name: 'Non Working Hours',
      id: "nonworking",
      data: [],
      // pointPadding: 0.4
    }]
  }

  public workingdaysOfWeekAvg = {
    chart: {
      type: 'column'
    },
    title: {
      text: ""
    },
    yAxis: {
      min: 0,
      title: {
        text: 'kWh'
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0
      }
    },
    tooltip: {
      formatter: function () {
        var s = '<b>' + this.x + '</b>';

        this.points.forEach((point, i) => {
          s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + point.y.toFixed(2) + " " + point.point.options.unit;
          if (point.point.options.missingImpact + point.point.options.interpolationImpact > 0) {
            s += '<br/>Missing Impact: ' + point.point.options.missingImpact;
            s += '<br/>Interpolation Impact: ' + point.point.options.interpolationImpact
          }
        });

        return s;
      },
      shared: true
    },
    series: [{
      name: 'Working Hours',
      id: "working",
      data: [],
      // pointPadding: 0.3
    }, {
      name: 'Non Working Hours',
      id: "nonworking",
      data: [],
      // pointPadding: 0.4
    }]
  }


  public daysOfWeekOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    yAxis: {
      min: 0,
      title: {
        text: 'kWh'
      }
    },
    legend: {
      shadow: false
    },
    tooltip: {
      shared: true
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0
      }
    },
    series: [{
      name: 'Maximum',
      id: "maximum",
      data: [],
      pointPadding: 0.3
    }, {
      name: 'Minimum',
      id: "minimum",
      data: [],
      pointPadding: 0.4
    }, {
      name: 'Average',
      id: "average",
      data: [],
      type: "line"
    }]
  }

  public deltaChartOptions = {
    chart: {
      type: 'column',
    },
    credits: {
      enabled: false
    },
    tooltip: {
      valueDecimals: 2,
    },
    title: {
      text: ''
    },
    xAxis: { categories: ['Months'] },
    yAxis: { title: { text: "kWh" } }
  };

  public yearConsumption = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'category',
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'kWh'
      }
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    tooltip: {

    },
    series: [{
      id: "consuptiom",
      name: 'Consumption',
      data: [
      ],
      dataLabels: {
        enabled: true,
        rotation: -90,
        color: '#FFFFFF',
        align: 'right',
        format: '{point.y:.1f} kWh', // one decimal
        y: 10, // 10 pixels down from the top
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    }]
  }

  public piechartOption = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> ({point.y:.2f} {point.unit})'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [{
      name: "Working hours comparsion",
      id: "working",
      data: []
    }]
  }

  public points = [];

  public peaks: string[] = [];
  public consumptions: string[] = [];

  public refreshData() {
    this.reportReady = false;
    this.lastMonths = this.populateMonths();
    let d: Moment = this.form.get("date").value.clone().startOf("month");


    this.spinner.activate();
    this.buildingService.meterpointReport(this.form.get("building").value, this.form.get("point").value.point._id, d.month() + 1, d.year()).subscribe(x => {
      this.clearCharts();

      if (x.data.length == 0) {
        return;
      }

      let unit = x.point.unit;
      let totalDays = d.daysInMonth();

      this.peaks = [];
      this.consumptions = [];


      let monthdaySeries = [{
        name: 'Maximum',
        id: "maximum",
        data: [],
        pointPadding: 0.3
      }, {
        name: 'Minimum',
        id: "minimum",
        data: [],
        pointPadding: 0.4
      }, {
        name: 'Average',
        id: "average",
        data: [],
        type: "line"
      }];



      //Radial start
      let t: any = {};
      Object.assign(t, this.radialMonthComparsion);

      t.xAxis.categories = [
        this.lastMonths[0],
        this.lastMonths[1],
        this.lastMonths[3],
      ];

      t.tooltip = {
        valueDecimals: 2,
        valueSuffix: " " + unit,
        shared: true
      };

      this.radialChart.updateChart(t);

      let cm = 0;
      let mb = 0;
      let lym = 0;

      let radialData = [];
      let found = x.data.find(y => y.month == d.month() + 1 && y.year == d.year());
      this.peaks.push(found ? (found.worstHalfHour.consumption.toFixed(2) + " " + unit + " @ " + moment(found.worstHalfHour.timestamp).format("dd DD HH:mm Z")) : "Na data available");
      radialData.push(found ? found.monthConsumption : null);
      cm = found ? found.monthConsumption : null;
      this.consumptions.push(found ? found.monthConsumption.toFixed(2) + " " + unit : "Na data available");

      let minusmonth = d.clone().subtract(1, "month");
      found = x.data.find(y => y.month == minusmonth.month() + 1 && y.year == minusmonth.year());
      this.peaks.push(found ? (found.worstHalfHour.consumption.toFixed(2) + " " + unit + " @ " + moment(found.worstHalfHour.timestamp).format("dd DD HH:mm Z")) : "Na data available");
      radialData.push(found ? found.monthConsumption : null);
      mb = found ? found.monthConsumption : null;
      this.consumptions.push(found ? found.monthConsumption.toFixed(2) + " " + unit : "Na data available");

      let minusyear = d.clone().subtract(1, "year");
      found = x.data.find(y => y.month == minusyear.month() + 1 && y.year == minusyear.year());
      this.peaks.push(found ? (found.worstHalfHour.consumption.toFixed(2) + " " + unit + " @ " + moment(found.worstHalfHour.timestamp).format("dd DD HH:mm Z")) : "Na data available");
      radialData.push(found ? found.monthConsumption : null);
      lym = found ? found.monthConsumption : null;
      this.consumptions.push(found ? found.monthConsumption.toFixed(2) + " " + unit : "Na data available");


      this.radialChart.updateSeries({
        id: "month0",
        name: unit,
        data: radialData
      }, "month0")

      //radial end


      //Workin/non working start
      found = x.data.find(y => y.month == d.month() + 1 && y.year == d.year());
      let workingTotal = 0;
      let nonworkingTotal = 0;

      let workinghours = [{
        name: 'Working Hours',
        id: "working",
        data: [],
        // pointPadding: 0.3
      }, {
        name: 'Non Working Hours',
        id: "nonworking",
        data: [],
        // pointPadding: 0.4
      }]


      let workinghoursAvg = [{
        name: 'Average Working Hours',
        id: "working",
        data: [],
        pointPadding: 0
      }, {
        name: 'Average Non Working Hours',
        id: "nonworking",
        data: [],
        pointPadding: 0.2
      }]


      let categories = [];
      for (let i = 1; i <= totalDays; i++) {
        let wcon = null;
        let nwcon = null;

        let awcon = null;
        let anwcon = null;

        let awmissing = null;
        let awinterpolation = null;

        let anmissing = null;
        let aninterpolation = null;

        let max = null;
        let min = null;
        let avg = null;

        if (found && found.daySummary && found.daySummary[i]) {
          wcon = found.daySummary[i].workHour.consumption;
          nwcon = found.daySummary[i].nonWorkHour.consumption;

          awcon = found.daySummary[i].workHour.hourAvg ? found.daySummary[i].workHour.hourAvg : 0;
          anwcon = found.daySummary[i].nonWorkHour.hourAvg;

          awmissing = found.daySummary[i].workHour.missingImpact;
          awinterpolation = found.daySummary[i].workHour.interpolationImpact;

          anmissing = found.daySummary[i].nonWorkHour.missingImpact;
          aninterpolation = found.daySummary[i].nonWorkHour.interpolationImpact;

          min = found.daySummary[i].min ? found.daySummary[i].min : 0;
          max = found.daySummary[i].max ? found.daySummary[i].max : 0;
          avg = found.daySummary[i].avg ? found.daySummary[i].avg : 0;
        }

        workingTotal += wcon;
        nonworkingTotal += nwcon;

        workinghours[0].data.push({
          y: wcon,
          unit: unit,
          missingImpact: awmissing,
          interpolationImpact: awinterpolation,
          color: awmissing + awinterpolation > 0 ? { patternIndex: 0 } : undefined
        });
        workinghours[1].data.push({
          y: nwcon,
          unit: unit,
          missingImpact: anmissing,
          interpolationImpact: aninterpolation,
          color: anmissing + aninterpolation > 0 ? { patternIndex: 1 } : undefined
        });

        workinghoursAvg[0].data.push({
          y: awcon,
          unit: unit,
          missingImpact: awmissing,
          interpolationImpact: awinterpolation,
          color: awmissing + awinterpolation > 0 ? { patternIndex: 0 } : undefined
        });
        workinghoursAvg[1].data.push(
          {
            y: anwcon,
            unit: unit,
            missingImpact: anmissing,
            interpolationImpact: aninterpolation,
            color: anmissing + aninterpolation > 0 ? { patternIndex: 1 } : undefined
          });

        categories.push(d.format("dd DD"))

        monthdaySeries[0].data.push(max);
        monthdaySeries[1].data.push(min);
        monthdaySeries[2].data.push(avg);

        d.add(1, "day");
      }

      let piedata = {
        name: "Working hours comparsion",
        id: "working",
        data: [
          {
            name: "Working Hours",
            y: workingTotal,
            unit: unit
          },
          {
            name: "Non Working Hours",
            y: nonworkingTotal,
            unit: unit
          }
        ]
      };


      let piedataAvg = {
        name: "Working hours comparsion",
        id: "working",
        data: [
          {
            name: "Average Working Hours",
            y: found.workHour.hourAvg,
            unit: unit
          },
          {
            name: "Average Non Working Hours",
            y: found.nonWorkHour.hourAvg,
            unit: unit
          }
        ]
      };

      this.weekdayWorkinghoursMonth.updateChart({
        xAxis: { categories: categories },
        tooltip: {
          valueDecimals: 2,
          valueSuffix: " " + unit,
          shared: true
        }
      });
      this.weekdayWorkinghoursMonth.updateSeries(workinghours[0], workinghours[0].id);
      this.weekdayWorkinghoursMonth.updateSeries(workinghours[1], workinghours[1].id);
      this.piechart.updateSeries(piedata, "working");


      this.weekdayWorkinghoursMonthHourAvg.updateChart({
        xAxis: { categories: categories },
        tooltip: {
          valueDecimals: 2,
          valueSuffix: " " + unit,
          shared: true
        }
      });
      this.weekdayWorkinghoursMonthHourAvg.updateSeries(workinghoursAvg[0], workinghoursAvg[0].id);
      this.weekdayWorkinghoursMonthHourAvg.updateSeries(workinghoursAvg[1], workinghoursAvg[1].id);
      this.piechartHourAvg.updateSeries(piedataAvg, "working");
      console.log(workinghoursAvg);
      // working/non working end

      this.weekdayMonth.updateChart({
        xAxis: { categories: categories }, tooltip: {
          valueDecimals: 2,
          valueSuffix: " " + unit,
          shared: true
        }
      });
      this.weekdayMonth.updateSeries(monthdaySeries[0], monthdaySeries[0].id);
      this.weekdayMonth.updateSeries(monthdaySeries[1], monthdaySeries[1].id);
      this.weekdayMonth.updateSeries(monthdaySeries[2], monthdaySeries[2].id);


      // this.deltaChart.updateSeries({
      //   id: "month0",
      //   name: this.lastMonths[0],
      //   data: [500 + Math.random() * 100]
      // }, "month0")
      this.deltaChart.updateChart({
        tooltip: {
          valueDecimals: 2,
          valueSuffix: " " + unit,
          shared: true
        }
      });
      this.deltaChart.updateSeries({
        id: "month1",
        name: "Consumption difference - " + this.lastMonths[1] + (mb ? "" : " (No Data)"),
        data: [mb ? cm - mb : 0]
      }, "month1")
      this.deltaChart.updateSeries({
        id: "month2",
        name: "Consumption difference - " + this.lastMonths[3] + (lym ? "" : " (No Data)"),
        data: [lym ? cm - lym : 0]
      }, "month2")


      d = this.form.get("date").value.clone();
      d = d.subtract(1, "year");

      let yearSeries = [];
      for (let i = 0; i < 13; i++) {
        let v = null;
        let found = x.data.find(y => y.month == d.month() + 1 && y.year == d.year());
        if (found) {
          v = found.monthConsumption;
        }

        yearSeries.push([this.months[d.month()] + " " + d.year(), v]);
        d.add(1, "month");
      }

      let halfhourData = [];
      d = moment.utc(this.form.get("date").value);

      found = x.data.find(y => y.month == d.month() + 1 && y.year == d.year());

      this.tableDays = [];

      let cats = [];
      let aux = moment().startOf("day");
      for (let i = 0; i <= 48; i++) {
        cats.push(aux.format("HH:mm"));
        aux.add(30, "minute");
      }


      if (found) {
        for (let hd of Object.keys(found.daySummary)) {
          d.date(parseInt(hd)).startOf("day");

          let table = { label: "", sparkline: null, avg: "", min: "", max: "", raw: 0, delta: null };
          table.label = d.format("dd DD");
          table.avg = (found.daySummary[hd].avg ? found.daySummary[hd].avg.toFixed(2) : 0) + " " + unit;
          table.min = (found.daySummary[hd].min ? found.daySummary[hd].min.toFixed(2) : 0) + " " + unit;
          table.max = (found.daySummary[hd].max ? found.daySummary[hd].max.toFixed(2) : 0) + " " + unit;
          table.raw = found.daySummary[hd].avg;

          table.sparkline = JSON.parse(JSON.stringify(this.sparklineOptions));
          table.sparkline.chart.width = 480;
          table.sparkline.tooltip.valueSuffix = " " + unit

          table.delta = JSON.parse(JSON.stringify(this.sparklineOptions));
          table.delta.chart.type = "column";
          table.delta.tooltip.valueSuffix = " " + unit

          // color: awmissing + awinterpolation > 0 ? { patternIndex: 0 } : undefined



          for (let i = 0; i < 7; i++)
            table.delta.series[0].data.push(0);

          table.sparkline.xAxis.categories = cats;


          let count = 0;
          if (this.tableDays.length < 7) {
            for (let f of this.tableDays) {
              let v = found.daySummary[hd].avg - f.raw;
              table.delta.series[0].data[count] = { y: v, color: v > 0 ? '#910000' : "" };
              count++;
            }
          }
          else {
            for (let f of this.tableDays.slice(this.tableDays.length - 7, this.tableDays.length)) {
              let v = found.daySummary[hd].avg - f.raw;
              table.delta.series[0].data[count] = { y: v, color: v > 0 ? '#910000' : "" };
              count++;
            }
          }

          for (let hfd of found.daySummary[hd].halfHourData) {
            halfhourData.push(
              {
                missingImpact: hfd.missingImpact,
                interpolationImpact: hfd.interpolationImpact,
                x: d.valueOf(),
                y: hfd.consumption,
                unit: unit,
                color: hfd.missingImpact + hfd.interpolationImpact > 0 ? { patternIndex: 0 } : undefined
              });
            table.sparkline.series[0].data.push({
              y: hfd.consumption,
              color: hfd.missingImpact + hfd.interpolationImpact > 0 ? "rgb(67, 67, 72)" : undefined,
              missingImpact: hfd.missingImpact,
              interpolationImpact: hfd.interpolationImpact
            });

            d.add(30, "minute");
          }

          table.sparkline.series[0]

          // console.log(found.daySummary[hd].halfHourData);

          this.tableDays.push(table);
        }
      }

      this.halfhoursConsuptiom.updateChart({
        tooltip: {
          valueDecimals: 2,
          valueSuffix: " " + unit,
          shared: true
        }
      })
      this.halfhoursConsuptiom.updateSeries({ data: halfhourData }, "halfhour");
      console.log(halfhourData);

      this.yearconsuptiom.updateChart({
        tooltip: {
          valueDecimals: 2,
          valueSuffix: " " + unit,
          shared: true
        }
      });
      this.yearconsuptiom.updateSeries({ data: yearSeries }, "consuptiom");

      this.spinner.desactivate();
    })

    this.reportReady = true;
  }

  private clearCharts() {
    this.radialChart.updateSeries({ data: [] }, "month0");

    this.weekdayWorkinghoursMonth.updateSeries({ data: [] }, "working");
    this.weekdayWorkinghoursMonth.updateSeries({ data: [] }, "nonworking");
    this.piechart.updateSeries({ data: [] }, "working");

    this.weekdayMonth.updateSeries({ data: [] }, "maximum");
    this.weekdayMonth.updateSeries({ data: [] }, "minimum");
    this.weekdayMonth.updateSeries({ data: [] }, "average");

    this.deltaChart.updateSeries({
      id: "month1",
      name: "",
      data: []
    }, "month1")
    this.deltaChart.updateSeries({
      id: "month2",
      name: "",
      data: []
    }, "month2")
    this.halfhoursConsuptiom.updateSeries({ data: [] }, "halfhour");
    this.yearconsuptiom.updateSeries({ data: [] }, "consuptiom");
  }



  public reportReady = false;

  public generatePDFReport() {

    var doc = new jsPDF({ unit: "px", format: "a4" });

    let height: number = 30;
    doc.setFontSize(10);
    doc.text("Metering Report", 30, height);

    height += 10;
    doc.setFontSize(7);
    doc.text(this.reportName, 50, height);

    height += 25;
    doc.setFontSize(10);
    doc.text("Usage Summary", 30, height);

    height += 10;
    doc.setFontSize(7);
    doc.text("Peak usage this month (" + this.lastMonths[0] + "): " + this.peaks[0], 50, height);
    doc.text("Total Consumption This Month (" + this.lastMonths[0] + "): " + this.consumptions[0], 240, height);

    doc.line(230, height - 3, 230, height + 20);

    height += 10;
    doc.text("Peak usage this month last year (" + this.lastMonths[3] + "): " + this.peaks[2], 50, height);
    doc.text("Consumption this Month last year (" + this.lastMonths[3] + "): " + this.consumptions[2], 240, height);

    height += 10;
    doc.text("Peak usage previous month (" + this.lastMonths[1] + "): " + this.peaks[1], 50, height);
    doc.text("Consumption month before (" + this.lastMonths[1] + "): " + this.consumptions[1], 240, height);

    height += 25;
    doc.setFontSize(10);
    doc.text("Month Comparison", 30, height);

    height += 20;
    let img = this.radialChart.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 0, height, 210, 140);

    img = this.deltaChart.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 180, height, 250, 140);

    height += 180;
    doc.text("Days of the week (half hour usage) - " + this.lastMonths[0], 30, height);

    img = this.weekdayMonth.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 50, height + 10, 350, 220);

    doc.addImage("assets/images/Future_Decisions.png", "PNG", 380, 14, 30, 10);
    doc.setFontSize(7);
    doc.text("https://futuredecisions.net || info@futuredecisions.net || +44 (0)2081444917", 30, 620);

    doc.addPage();
    height = 30;
    doc.text("Working / Non working hours - " + this.lastMonths[0], 30, height);

    height += 20;
    img = this.piechart.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 260, height, 210, 140);

    img = this.weekdayWorkinghoursMonth.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 20, height, 280, 140);

    height += 180;
    img = this.piechartHourAvg.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', -20, height, 210, 140);

    img = this.weekdayWorkinghoursMonthHourAvg.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 150, height, 280, 140);

    height += 180;
    doc.text("Consumption totals per month for the previous 12 months", 30, height);

    height += 20;
    img = this.yearconsuptiom.Chart.getSVG();
    canvg(this.canvas, img);
    doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 30, height + 10, 380, 160);

    doc.addImage("assets/images/Future_Decisions.png", "PNG", 380, 14, 30, 10);
    doc.setFontSize(7);
    doc.text("https://futuredecisions.net || info@futuredecisions.net || +44 (0)2081444917", 30, 620);

    doc.addPage();
    height = 30;
    doc.text("Half Hourly Consumption", 30, height);

    height += 15;
    doc.setFontSize(8);
    doc.text("Day", 50, height);
    doc.text("Half Hour Consumption", 80, height);
    doc.text("Minimum", 240, height);
    doc.text("Maximum", 280, height);
    doc.text("Average", 320, height);
    doc.text("Last 4 days delta", 360, height);
    height += 12;

    doc.setFontSize(7);
    let pos = 0;
    for (let table of this.tableDays) {

      img = this.tableDaysSparkline.toArray()[pos].Chart.getSVG();
      canvg(this.canvas, img);
      doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 80, height - 8, 150, 10);

      img = this.tableDaysDelta.toArray()[pos].Chart.getSVG();
      canvg(this.canvas, img);
      doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 360, height - 5, 30, 10);

      doc.text(table.label, 50, height);
      doc.text(table.min, 240, height);
      doc.text(table.max, 280, height);
      doc.text(table.avg, 320, height);
      // doc.text("----", 360, height);
      height += 15;
      pos++;
    }

    // doc.text("Half Hourly Consumption Chart", 30, height);

    // height += 20;
    // img = this.halfhoursConsuptiom.Chart.getSVG();
    // canvg(this.canvas, img);
    // doc.addImage(this.canvas.toDataURL("image/png"), 'PNG', 50, height + 10, 380, 160);
    doc.addImage("assets/images/Future_Decisions.png", "PNG", 380, 14, 30, 10);
    doc.text("https://futuredecisions.net || info@futuredecisions.net || +44 (0)2081444917", 30, 620);
    doc.save(this.reportName);
  }

}
