import { Component, OnInit, Directive, Input, ViewChild, OnChanges, SimpleChanges, Output } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MONTH_YEAR, YEAR, MONTH, ReportService } from 'app/services/report.service';

import * as moment from "moment-timezone";
import * as humanizeDuration from "humanize-duration"

import { GanttComponent } from 'app/components/common/highcharts/gantt/gantt.component';
import { AppService } from 'app/services/app.service';
import { SpinnerService } from 'app/services/spinner.service';
import { Moment } from 'moment-timezone';

@Component({
  selector: 'app-gant-alarms',
  templateUrl: './gant-alarms.component.html',
  styleUrls: ['./gant-alarms.component.css']
})
export class GantAlarmsComponent implements OnInit, OnChanges {

  constructor(private appService: AppService, private spinnerService: SpinnerService, private reportService: ReportService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue != null && changes.data.currentValue != undefined) this.generateReport();
  }

  @ViewChild("gantChart", { static: true }) gantChart: GanttComponent;

  @Input() disablePagination: boolean = false;

  private date = moment();
  @Input() data;

  private groupsIndexes: number[] = [];
  private gantSeries;

  ngOnInit() {
  }

  public pageInfo: { pageSize: number, page: number, hasNext: boolean } = { pageSize: 10, page: 1, hasNext: false };

  public pageChange(event: { page: number, pageSize: number }) {

    if (this.pageInfo.pageSize != event.pageSize) {
      this.pageInfo.pageSize = event.pageSize;
      event.page = 1;
      this.pageInfo.page = 1;
    }

    let min = (event.page - 1) * event.pageSize;
    let max = min + event.pageSize - 1;

    if (max >= this.groupsIndexes.length) {
      max = this.groupsIndexes.length - 1;
      this.pageInfo.hasNext = false;
    }
    else {
      this.pageInfo.hasNext = true;
    }

    let startIndex = min == 0 ? 0 : this.groupsIndexes[min];
    let endIndex = this.groupsIndexes[max];

    // console.log(JSON.stringify(this.gantSeries.data.slice(startIndex, endIndex)));

    // console.log(this.gantSeries.data);
    // console.log(this.gantSeries.data.slice(startIndex, endIndex));
    let series = { name: "Occurrences", minPointLength: 10, id: "ocurrences", data: this.gantSeries.data.slice(startIndex, endIndex) }

    series.data.forEach(element => {
      // if (element.start)
      //   console.log(moment(element.start).format("DD/MM/YYYY HH:mm") + " " + moment(element.end).format("DD/MM/YYYY HH:mm"))
    });

    this.gantChart.updateSeries(series);
  }

  public removeData(id){
    this.gantChart.removeById(id);
  }

  public gantChartOptions = {
    chart: {
      zoomType: "x"
    },
    yAxis: { uniqueNames: true, /*categories: []*/ },
    series: [],
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormatter: function () {
        if (this.options.hide) {
          return;
        }
        return `<div style="font-size:12px;">
        <span style="font-weight:bold;">${this.name}</span><br>
        <span>Start: ${moment(this.x).format("DD/MM/YYYY HH:mm Z")}</span><br>
        <span>End: ${moment(this.x2).format("DD/MM/YYYY HH:mm Z")}</span> <br>
        <span>Duration: ${humanizeDuration(moment.utc(this.x2).diff(moment.utc(this.x)), { round: true })} </span>
        </div>`
      }
    },
    xAxis: {
      max: (this.date.month() == moment.utc().month()) ? this.date.clone().valueOf() : this.date.clone().endOf("month").valueOf(),
      min: this.date.clone().startOf("month").valueOf()
    },
    time: { useUTC: false },
    plotOptions: {
      gantt:{
      },
      series: {
        events: {
          legendItemClick: (event) => {
            console.log(event);
          }
        },
        turboThreshold: 99999,
      }
    }
  }


  private checkOverlaps(seriesData, data: { start, end, open }[], name, id, parent = null, tooltip = undefined) {
    if (data.length > 0) {
      seriesData.push({
        name: name,
        tooltipLabel: tooltip,
        id: id,
        collapsed: true,
        color: "transparent",
        hide: true,
        parent: parent ? parent : undefined
      })

      let overlaps: { start, end, open }[] = [];

      data.sort(function (a, b) { return a.start - b.start });

      let s = {
        name: name,
        start: data[0].start,
        end: data[0].end,
        color: data[0].open ? "#ed5565" : "#23c6c8",
        parent: parent ? parent : undefined
      }

      for (let i = 0; i < data.length; i++) {

        if (i == data.length - 1) {
          seriesData.push(s);
        }
        else {
          if (this.overlapTimes(s.start, s.end, data[i + 1].start, data[i + 1].end)) {

            if (s.start > data[i + 1].start)
              s.start = data[i + 1].start;

            if (s.end < data[i + 1].end)
              s.end = data[i + 1].end;

            if (data[i + 1].open)
              s.color = "#ed5565";
          }
          else {
            seriesData.push(s);

            s = {
              name: name,
              start: data[i + 1].start,
              end: data[i + 1].end,
              color: data[i + 1].open ? "#ed5565" : "#23c6c8",
              parent: parent ? parent : undefined
            }
          }
        }
      }
    }
  }

  public generateReport() {
    if (this.data.proccessed) {

      this.date = this.data.date ? this.data.date.clone() : moment();

      this.gantChartOptions.xAxis = {
        max: this.date.clone().endOf("month").valueOf(),
        min: this.date.clone().startOf("month").valueOf()
      }

      this.gantChart.updateXaxis(this.gantChartOptions.xAxis);
      this.gantSeries = this.data.series;

      if (this.disablePagination)
        this.pageChange({ page: 1, pageSize: 1000000000 });
      this.pageChange({ page: 1, pageSize: 10 });


      this.spinnerService.desactivate();
    }

    else if (this.data.date) {

      if (this.data.type == "alarms") {

      }
      else {

        this.date = moment().month(this.data.date.month()).year(this.data.date.year()).startOf("month")

        this.groupsIndexes = [];
        let endMonth = (this.date.month() == moment.utc().month()) ? moment.utc().valueOf() : this.date.clone().endOf("month").valueOf();

        let series = { name: "Occurrences", minPointLength: 10, id: "ocurrences", data: [] };

        if (this.data.issueTypes) {
          this.data.integrityIssues.forEach(t => {

            let name = t.point.ord.split("/");
            if (name.length >= 3) {
              name = name[name.length - 3] + "/" + name[name.length - 2] + "/" + name[name.length - 1];
            }
            else if (name.length == 2) {
              name = name[name.length - 2] + "/" + name[name.length - 1];
            }
            else
              name = name[name.length - 1];

            if (this.data.issueTypes) {
              for (let key of Object.keys(this.data.issueTypes)) {
                let auxKey = [];

                if (t.integrityIssues[key] == undefined) {
                  continue;
                }

                if (t.integrityIssues[key].detailed) {
                  let auxDetailed = [];

                  for (let i = 0; i < t.integrityIssues[key].detailed.length; i++) {
                    let z = t.integrityIssues[key].detailed[i];
                    let start = moment.utc(z.start).valueOf();
                    let end = moment.utc(z.end).valueOf();

                    if (z.end == null)
                      end = endMonth;

                    series.data.push({
                      start: start,
                      id: key + "_" + t.point.ord + "_" + i,
                      name: this.reportService.getErrorType(key) + " (" + t.integrityIssues[key].detailed.length + " alarms)",
                      end: end,
                      color: (!z.active) ? "#23c6c8" : "#ed5565",
                      samples: z.samplesAffected,
                      parent: t.point.ord
                    });

                    auxDetailed.push({ start: start, end: end, open: z.active });
                    auxKey.push({ start: start, end: end, open: z.active });
                  }
                }

                if (t.integrityIssues[key].types) {

                  let auxIssues = [];

                  for (let type of Object.keys(t.integrityIssues[key].types)) {
                    let auxTypes = [];

                    let typeT = t.integrityIssues[key].types[type];

                    for (let i = 0; i < typeT.length; i++) {

                      let z = typeT[i];
                      let start = moment.utc(z.start).valueOf();
                      let end = moment.utc(z.end).valueOf();

                      if (z.end == null)
                        end = endMonth;

                      series.data.push({
                        start: start,
                        id: key + "_" + t.point.ord + "_" + type + " " + i,
                        name: type + " (" + typeT.length + " alarms)",
                        end: end,
                        color: (!z.active) ? "#23c6c8" : "#ed5565",
                        samples: z.samplesAffected,
                        parent: key + "_" + t.point.ord
                      });

                      auxKey.push({ start: start, end: end, open: z.active });
                      auxTypes.push({ start: start, end: end, open: z.active });
                      auxIssues.push({ start: start, end: end, open: z.active });
                    }

                    // series.data.push({
                    //   name: type + " (" + typeT.length + " alarms)",
                    //   id: key + "_" + t.point.ord + "_" + type,
                    //   collapsed: true,
                    //   color: "transparent",
                    //   hide: true,
                    //   parent: key + "_" + t.point.ord
                    // })

                    //overlaps of types
                    this.checkOverlaps(series.data, auxTypes, type + " (" + typeT.length + " alarms)", type + "_" + key + "_" + t.point.ord, key + "_" + t.point.ord);
                  }
                  //overlaps of issues
                  this.checkOverlaps(series.data, auxIssues, this.reportService.getErrorType(key), key + "_" + t.point.ord, t.point.ord);

                }
                //overlaps of point ord
                this.checkOverlaps(series.data, auxKey, name, t.point.ord, null, t.point.ord);
              }
            }


            this.groupsIndexes.push(series.data.length);
          })
        }
        else {
          this.data.forEach(v => {
            let auxData = [];

            if (v.alarm.types) {

              let auxDataTypes = [];

              for (let type of Object.keys(v.alarm.types)) {

                let auxDataSubtype = [];

                let typeT = v.alarm.types[type];

                for (let i = 0; i < typeT.length; i++) {

                  let z = typeT[i];
                  let start = moment.utc(z.start).valueOf();
                  let end = moment.utc(z.end).valueOf();

                  if (z.end == null)
                    end = endMonth;

                  series.data.push({
                    start: start,
                    id: this.getOrdName(v.ord) + "_" + type + " " + i,
                    name: type + " (" + typeT.length + " alarms)",
                    end: end,
                    color: (!z.active) ? "#23c6c8" : "#ed5565",
                    samples: z.samplesAffected,
                    parent: this.getOrdName(v.ord)
                  })

                  auxData.push({ start: start, end: end, open: z.active })
                  auxDataSubtype.push({ start: start, end: end, open: z.active })
                  auxDataTypes.push({ start: start, end: end, open: z.active })
                }

              }


              series.data.push({
                name: this.getOrdName(v.ord),
                id: this.getOrdName(v.ord),
                collapsed: true,
                color: "transparent",
                hide: true
              })
              // console.log(auxDataTypes);
              // console.log(key + "  " + this.reportService.getErrorType(key));
              // console.log(series.data.length);
              // this.checkOverlaps(series.data, auxDataTypes, this.reportService.getErrorType(key), key + "_" + t.point.ord, t.point.ord)
              // console.log(series.data.length);
              this.checkOverlaps(series.data, auxData, this.getOrdName(v.ord), this.getOrdName(v.ord));
            }


            this.groupsIndexes.push(series.data.length);
          })
        }



        this.gantChartOptions.xAxis = {
          max: this.date.clone().endOf("month").valueOf(),
          min: this.date.clone().startOf("month").valueOf()
        }

        this.gantChart.updateXaxis(this.gantChartOptions.xAxis);
        this.gantSeries = series;

        if (this.disablePagination)
          this.pageChange({ page: 1, pageSize: 1000000000 });
        this.pageChange({ page: 1, pageSize: 10 });


        this.spinnerService.desactivate();
      }
    }
  }

  public getOrdName(ord) {
    let t = this.appService.convertFromASCII(ord).split("/").slice(-3).join("/");
    return t;
  }

  public overlapTimes(start1: number, end1: number, start2: number, end2: number) {
    return (start1 >= start2 && start1 <= end2) || (end1 >= start2 && end1 <= end2) || (start2 >= start1 && start2 <= end1) || (end2 >= start1 && end2 <= end1)
  }

}

@Directive({
  selector: '[dateFormatMonthYear]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR },
  ],
})
export class FormatMonthYear {
}


@Directive({
  selector: '[dateFormatYear]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: YEAR },
  ],
})
export class FormatYear {
}

@Directive({
  selector: '[dateFormatMonth]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH },
  ],
})
export class FormatMonth {
}
