import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { LoginService } from 'app/services/login.service';
import { TranslocoService } from '@ngneat/transloco';
import { LanguagesDataService } from 'app/services/languages.data.service';
import { AppService } from 'app/services/app.service';
import { SpinnerService } from 'app/services/spinner.service';
import { UserType } from 'app/interfaces/baseinterface';
import { BuildingService } from 'app/services/building.service';
import { timeStamp } from 'console';
import { CompanyService } from 'app/services/company.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpPageService } from 'app/services/help-page.service';
import { HelpPopupComponent } from 'app/views/admin/help/help-popup/help-popup.component';

declare var jQuery: any;

@Component({
  selector: 'app-topnavbar',
  templateUrl: 'topnavbar.template.html'
})
export class TopNavbarComponent {

  public currentLocale: string;
  public isRoot: boolean = false;

  public selectedCompany: string = "";
  public selectedBuilding: string = "";

  public buildings: { id: string, name: string, buildings: { id: string, name: string }[] }[] = [];

  constructor(public dialog: MatDialog, public helpPageService: HelpPageService, private companyService: CompanyService, private buildingService: BuildingService, private spinnerService: SpinnerService, public appService: AppService, public translocoService: TranslocoService, public dataLanguage: LanguagesDataService, public loginService: LoginService) {
    this.currentLocale = this.translocoService.getActiveLang();
  }

  public changeLocale(locale) {
    this.currentLocale = locale;

    this.appService.setLanguage(this.currentLocale);
    this.translocoService.setActiveLang(this.currentLocale);

    this.spinnerService.activate();
    location.reload();
  }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar').toggleClass('not-mini-navbar');
    smoothlyMenu();
  }

  public loadHelp() {
    const dialogRef = this.dialog.open(HelpPopupComponent, {
      width: window.innerWidth >= 1920 ? '1280px' : '960px',
      data: {
        url: this.helpPageService.pageUrl
      }
    })
  }

}
