import { AfterViewInit, Component, ViewChild, ViewEncapsulation, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerService } from "../../../services/spinner.service";
import { AdminPointService } from "../../../services/data.service";
import { AppHighstockComponent } from "../../../components/common/highcharts/highstock/highstock.component";
import * as moment from "moment-timezone";
import { IBuilding } from "../../../interfaces/building";
import { LoginService } from "../../../services/login.service";
import { CompanyService } from "../../../services/company.service";
import { AppI18nService } from "../../../services/app.i18n.service";
import { BuildingService } from "../../../services/building.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { DATEPICKER_FORMAT } from "app/components/adapters/DatepickerFormats";
import { MultiplePointsChartComponent } from 'app/components/common/multiple-points-chart/multiple-points-chart.component';


import * as humanizeDuration from "humanize-duration"
import { AppService } from 'app/services/app.service';
import { SessionService } from "app/services/session.service";
import { GantAlarmsComponent } from "app/components/common/gant-alarms/gant-alarms.component";

@Component({
  selector: "app-admin-alarm-data",
  templateUrl: "./admin-alarm-data.component.html",
  styleUrls: ["./admin-alarm-data.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }
  ]
})

export class AdminAlarmDataComponent implements AfterViewInit, OnInit {
  ngOnInit(): void {
  }
  @ViewChild("multipleChart") multipleChart: MultiplePointsChartComponent;
  @ViewChild("ganttalarms") ganttalarms: GantAlarmsComponent;

  public series: { id: string, name: string, data: any[] }[] = []


  @ViewChild("chart") chart: MultiplePointsChartComponent;
  public FilteredselectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });
  public filterBuilding(){
      console.log(this?.FilteredselectedBuilding);
      let newf;
      if(this?.FilteredselectedBuilding){
    if(this?.FilteredselectedBuilding?.name){
        newf = this?.FilteredselectedBuilding.name.toLowerCase();
    }
    else {
        newf = this?.FilteredselectedBuilding.toLowerCase();
    }
    const filterValue = newf;
        
        console.log(filterValue);
        if(this.buildings){
            let searchBase = this.buildings;
            this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
              console.log(this.BuildingSearchResult);
            // return this.buildings.filter(building => 
            //   this.buildings.includes(filterValue));
        
        }
    }else {
        this.BuildingSearchResult = this.buildings;
    }
    
   
  }
  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }
  public changeBuilding(val) {
    // console.log("changeBuilding EVENT");
    // console.log(val);
    // console.log(this.FilteredselectedBuilding);
    this.selectedBuilding = this.FilteredselectedBuilding.id;
    // this.buildingChanged(this.FilteredselectedBuilding.id);
    
    // this.onBuildingChange();
  }
  public alarmsList: { building: string, id: string, alarmName: string, data: { dates: any[], open: boolean, duration: number, from: string, to: string, type: string }[] }[] = []

  public chartOptions = {
    chart: {
      type: 'xrange',
      zoomType: "x"
    },
    xAxis: {
      type: 'datetime',
      // tickInterval: 1000 * 60
    },
    title: {
      text: ""
    },
    tooltip: {
      shared: false,
      split: false,
      pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.origin}</b> {point.alarm}<br/>Duration: {point.duration}'
    },
    plotOptions: {
      // xrange: {
      //   minPointLength: "5"
      // }
    },
    yAxis: {
      title: {
        text: ''
      },
      categories: []
    },
    rangeSelector: {
      enabled: false
    }
  }

  public refresh(event) {
    let t = this.pointsStatus.map(x => x.id);

    this.pointsStatus = [];
    this.series = [];

    for (let s of t) {
      if (this.chart.chart)
        this.chart.chart.removeById(s);

      this.addPoint({ building: event.building, start: event.start, end: event.end, point: s });
    }
  }

  public gantData: { series: any, proccessed: boolean, date: any } = { date: null, series: { name: "Occurrences", minPointLength: 10, id: "ocurrences", data: [] }, proccessed: true };

  private mapData(data, id, pos) {

    this.gantData.series.data = [];
    this.gantData.date = this.date;

    if (this.alarmsList.find(x => x.id == pos) == null) {
      this.alarmsList.push({
        building: this.buildings.find(x => x.id == this.selectedBuilding).name,
        id: pos,
        alarmName: this.getOrdName(id),
        data: []
      });
    }

    let list = this.alarmsList.find(x => x.id == pos);
    list.data = [];

    let types: { type: string, times: { open: boolean, start: number, end: number }[] }[] = [];

    //TODO: Make a smart way, not too much time for that now
    //getting all types of errors
    for (let d of data) {
      for (let key of Object.keys(d.alarm.data)) {
        if (d.alarm.data[key]) {
          for (let type of d.alarm.data[key]) {
            let t = types.find(x => x.type == type);
            if (!t) {
              types.push({ type: type, times: [] })
            }
          }
        }
      }
      // end = moment(d.alarm.lastReceived);
      // start = end.clone().subtract(d.alarm.sampleAffected, "minute");

      // list.data.push({
      //   duration: humanizeDuration(end.diff(start).valueOf(), { round: true }),
      //   from: start.format("DD/MM/YYYY HH:mm"),
      //   to: end.format("DD/MM/YYYY HH:mm"),
      //   type: ""
      // })
    }
    //getting times
    for (let type of types) {
      let last = { start: null, open: null, end: null };
      for (let d of data) {
        let keys = Object.keys(d.alarm.data)
        for (let key of keys) {
          if (d.alarm.data[key] && d.alarm.data[key].indexOf(type.type) >= 0) {
            if (last.start == null) {
              last.open = d.inAlarmState;
              last.start = moment(d.timestamp).add(parseInt(key), "minutes").valueOf();
            }
          }
          else {
            last.end = moment(d.timestamp).add(parseInt(key), "minutes").valueOf();
            type.times.push(last);

            last = { start: null, open: null, end: null };
          }
        }
      }
      if (last.start && last.end == null) {
        let keys = Object.keys(data[data.length - 1].alarm.data);
        last.end = moment(data[data.length - 1].timestamp).add(parseInt(keys[keys.length - 1]), "minutes").valueOf();
        type.times.push(last);
      }
    }

    for (let type of types) {
      // this.gantData.series.data.push(
      //   {
      //     name: type.type,
      //     id: list.building + "_" + type.type,
      //     collapsed: true,
      //     color: "transparent",
      //     hide: true
      //   })

      for (let time of type.times) {
        let s = moment(time.start);
        let e = moment(time.end);
        list.data.push({
          dates: [s, e],
          duration: humanizeDuration(e.diff(s).valueOf(), { round: true }),
          from: s.format("DD/MM/YYYY HH:mm"),
          to: e.format("DD/MM/YYYY HH:mm"),
          open: time.open,
          type: type.type
        })
      }
    }

    for (let a of this.alarmsList) {
      let open = false;
      for (let time of a.data) {
        let s = moment(time.dates[0]);
        let e = moment(time.dates[1]);

        // console.log(a.data);
        let t = {
          name: `${time.type} (${a.data.filter(x => x.type == time.type).length} alarms)`,
          start: s.valueOf(),
          end: e.valueOf(),
          id: `${time.type}_${a.alarmName}_${a.building}`,
          color: time.open ? "#ed5565" : "#23c6c8",
          parent: a.alarmName
        };

        time.open ? open = true : open = open;

        this.gantData.series.data.push(t)
      }

      let b = this.gantData.series.data.find(x => x.id == a.building);
      if (!b) {
        let t = {
          name: `${a.building}`,
          id: a.building,
          start: null,
          end: null,
          color: open ? "#ed5565" : "#23c6c8"
        };
        this.gantData.series.data.push(t)
      }
      else {
        b.color = open ? "#ed5565" : b.color;
      }
      let c = this.gantData.series.data.find(x => x.id == a.alarmName);
      if (!c) {
        let t = {
          name: `${a.alarmName}`,
          id: a.alarmName,
          start: null,
          end: null,
          color: open ? "#ed5565" : "#23c6c8",
          parent: a.building
        };
        this.gantData.series.data.push(t)
      }
      else {
        c.color = open ? "#ed5565" : c.color
      }
    }


    // console.log(this.gantData);
    // console.log(this.alarmsList);
    console.log(types);
    this.ganttalarms.generateReport();

    this.updateStatus(pos, id);
  }

  private updateStatus(id, ord) {
    console.log(this.alarmsList);

    let d = this.alarmsList.find(x => x.id == id);
    let t = this.pointsStatus.find(x => x.id == ord)
    t.col2 = d.data.map(x => x.type).filter((value, index, self) => { if (self.indexOf(value) === index) return value }).join(", ");
  }

  public pointsStatus: { id: string, name: string, col1: string, col2: string, col3: string, csv: { filename: string, data: any } }[] = [];
  public date;
  public addPoint(event) {
    if (this.pointsStatus.find(x => x.id == event.point))
      return;
    this.spinner.activate();

    console.log(event);

    this.date = event.start;

    let data = { building: event.building, start: event.start.format("YYYY-MM-DD"), end: event.end.format("YYYY-MM-DD"), ord: event.point, date: event.start };

    this.dataService.GetAlarmForPoint(data).subscribe(d => {
      this.spinner.desactivate();
      let arr = [];
      this.data.forEach(element => {
        arr.push(element);
      })
      d.data.forEach(element => {
        if (arr.find(w => w._id == element._id)) {
          arr.push(element);
        }
      });

      this.data = arr;
      this.data["data"] = arr;
      this.data["date"] = this.date;

      let pos = (this.pointsStatus.length);
      this.pointsStatus.push({
        csv: null,
        name: (pos + 1) + "# " + this.getOrdName(event.point),
        id: event.point,
        col1: this.buildings.find(x => x.id == event.building).name,
        col2: status,
        col3: null
      })

      this.mapData(d.data, event.point, pos);
    });
  }


  public data = [];

  public buildingChanged(event) {
    console.log("buildingChangedEVENT");
    console.log(event);
    this.spinner.activate();
    this.sessionService.building = event;
    this.selectedBuilding = event;
    this.dataService.GetPointsWithAlarm(event).subscribe(alarms => {

      this.spinner.desactivate();

      // let t = this.pointsStatus.map(x => x.id);
      // for (let s of t) {
      //   this.chart.chart.removeById(s);
      // }

      this.pointsInBuilding = alarms.data.map(x => { return { id: x, name: x } });

      // this.pointsStatus = [];
    })
  }

  public removePoint(point) {
    this.pointsStatus.splice(this.pointsStatus.indexOf(point), 1);
    this.series.splice(this.series.indexOf(point), 1);

    // this.chart.chart.removeById(point.id);
    let arr = [];

    this.data.forEach(x => {
      if (x.ord != point.id) {
        arr.push(x);
      }
    })

    this.data = arr;
    this.data["date"] = this.date;

    this.alarmsList.splice(this.alarmsList.indexOf(this.alarmsList.find(x => x.id == point.id)), 1);

    // let alarmtypes = this.gantData.series.data.filter(x => x.parent == point.id)
    // let alarmpoint = this.gantData.series.data.find(x => x.id == point.id);

    // this.gantData.series.data.splice(this.gantData.series.data.indexOf(alarmpoint), 1);
    // for (let a of alarmtypes) {
    //   this.gantData.series.data.splice(this.gantData.series.data.indexOf(a), 1);
    // }

    // let building = this.gantData.series.data.find(x => x.id == point.col1);
    // let buildingalarms = this.gantData.series.data.filter(x => x.parent == point.col1);

    // if(buildingalarms){
    //   building.start = null;
    //   building.end = null;

    //   if(buildingalarms.find(x => x.color == "#ed5565")){
    //     building.color = "#ed5565";
    //   }
    //   else{
    //     building.color = "#23c6c8";
    //   }
    // }
    // else{
    //   this.gantData.series.data.splice(this.gantData.series.data.indexOf(building), 1);
    // }

    // this.ganttalarms.generateReport();
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(query => {
      this.spinner.activate();
      this.buildingService.getAll().subscribe(b => {
        this.spinner.desactivate();
        this.buildings = b.data.map(x => {
          return { id: x._id, name: x.name };
        })
        if (query["building"]) {
          let b = this.buildings.find(x => x.id == query["building"]);
          this.sessionService.building = b.id;

          if (b) {
            this.selectedBuilding = b.id;
          }
        }
        else if (this.sessionService.building) {
          this.selectedBuilding = this.sessionService.building;
        }
      })
    });
  }

  public buildings: { id: string, name: string }[] = [];
  public selectedBuilding: string = "";
  public pointsInBuilding: { id: string, name: string }[] = [];

  constructor(private sessionService: SessionService, private appService: AppService, private dataService: AdminPointService, private route: ActivatedRoute, private spinner: SpinnerService, private buildingService: BuildingService) {
  }


  // @ViewChild("chartStock") chart: AppHighstockComponent;
  // private series: any = {};

  // private today = moment.utc();

  // public form = new FormGroup({
  //   start: new FormControl(this.today.clone().add(-1, "d"), [Validators.required]),
  //   end: new FormControl(this.today.clone(), [Validators.required]),
  //   building: new FormControl(null, [Validators.required]),
  //   point: new FormControl(null, [Validators.required])
  // });

  // private startDate = this.today.clone().add(-1, "d");
  // private endDate = this.today.clone();

  // public viewStartDate = this.startDate.clone();
  // public viewEndDate = this.endDate.clone();

  // public listOfPoints: any[] = [];
  // public filteredPoints: any[] = [];
  // public point: any = undefined;
  // private initialPoint: any = undefined;
  // public pointFilter: any = "";

  // public filteredBuildings: IBuilding[];
  // private listOfBuildings: IBuilding[];
  // private building: IBuilding;
  // public buildingFilter: any = "";

  // chartOptions: any = {
  //   chart: {
  //     zoomType: "x",
  //     height: 600
  //   },
  //   navigator: {
  //     series: {
  //       includeInCSVExport: false
  //     }
  //   },
  //   legend: {
  //     floating: false,
  //     enabled: true,
  //     align: "center",
  //     shadow: true,
  //     y: 50,
  //     verticalAlign: "top"
  //   },
  //   tooltip: {
  //     enabled: true,
  //     shared: true,
  //     crosshairs: true,
  //     split: false
  //   },
  //   rangeSelector: {
  //     inputEnabled: false,
  //     buttons: [
  //       /*{
  //        type: "hour",
  //        count: 1,
  //        text: "1h"
  //        }, {
  //        type: "day",
  //        count: 1,
  //        text: "1d"
  //        }, {
  //        type: "week",
  //        count: 1,
  //        text: "1w"
  //        }, {
  //        type: "month",
  //        count: 1,
  //        text: "1m"
  //        }, {
  //        type: "year",
  //        count: 1,
  //        text: "1y"
  //        },*/ {
  //         type: "all",
  //         text: "All"
  //       }
  //     ],
  //     selected: 1, // all
  //     buttonTheme: {
  //       width: null
  //     }
  //   }
  // };

  // ngAfterViewInit() {
  //   this.route.queryParams.subscribe(async (query) => {
  //     this.spinner.activate();
  //     try {
  //       let buildings = await this.buildingService.getAll().toPromise();
  //       this.listOfBuildings = buildings.data;
  //       this.filteredBuildings = this.listOfBuildings.filter(building => {
  //         let add = building.name.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0 ||
  //           building.description.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0;
  //         if (building.address) {
  //           add = add || building.address.street.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0;
  //         }
  //         return add;
  //       });
  //       for (let b of this.filteredBuildings) {
  //         if (b._id === query["building"]) {
  //           this.buildingFilter = b;
  //           this.onBuildingChange({ option: { value: b } });
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }

  //     if (this.building) {
  //       let data = [];
  //       try {
  //         let resp = await this.dataService.GetPointsWithAlarm(this.building._id).toPromise();
  //         for (let point of resp["data"]) {
  //           if (point === query["point"]) {
  //             this.initialPoint = point;
  //           }
  //           data.push(point);
  //         }
  //         this.listOfPoints = data;
  //         if (this.initialPoint) {
  //           this.GetDataForPoint(this.initialPoint);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //     this.spinner.desactivate();
  //   });
  // }

  // constructor(public router: Router, private dataService: AdminPointService, private spinner: SpinnerService,
  //   private route: ActivatedRoute, public i18n: AppI18nService, private buildingService: BuildingService,
  //   private companyService: CompanyService, private loginService: LoginService) {
  // }

  // public dateChanged(dateStr, dateObj) {
  //   if (dateObj === 1) {
  //     this.startDate = moment.utc(dateStr);
  //   } else {
  //     this.endDate = moment.utc(dateStr);
  //   }
  // }

  // public changeDate() {
  //   if (this.point !== "" && !!this.point) {
  //     this.GetDataForPoint(this.point);
  //   }
  // }

  // private GetDataForPoint(point) {

  //   let value = this.form.value;

  //   this.spinner.activate();
  //   this.point = point;
  //   let start = value.start.clone();
  //   let end = value.end.clone();

  //   this.dataService.GetAlarmForPoint(
  //     { building: this.building._id, start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD"), ord: point }
  //   ).subscribe(
  //     resp => {
  //       let pointData = [];

  //       for (let d of resp["data"]) {
  //         let date = moment.utc(d["timestamp"]).startOf("day");
  //         let dt = date.valueOf();
  //         for (let minData of d["alarmData"]) {
  //           if (minData != null) {
  //             pointData.push([dt, !!minData ? 1 : 0]);
  //           }
  //           else{
  //             pointData.push([dt, 0]);
  //           }
  //           dt += 60000;
  //         }
  //       }

  //       pointData.sort((a, b) => {
  //         return a[0] < b[0] ? -1 : 1;
  //       });

  //       console.log(pointData);

  //       /*if( point.id != this.series[ 0 ] ) {
  //        this.chart.removeById( this.series.pop() );
  //        this.series.push( point.id );
  //        }

  //        this.chart.updateSeries( {
  //        id: point.id,
  //        name: point.name,
  //        data: pointData,
  //        type: this.ChangeToType[ this.chartType ][ 1 ],
  //        dataGrouping: {
  //        enabled: false
  //        }
  //        }, point.id );*/

  //       this.chart.removeById("TempId");
  //       this.series["TempId"] = {
  //         id: "TempId"/*point.id*/,
  //         name: point,
  //         data: pointData,
  //         type: "column"
  //       };

  //       // let min = getMinArrayObj( pointData, 1 );
  //       // let max = getMaxArrayObj( pointData, 1 );

  //       if (pointData.length > 0) {
  //         this.chart.updateSeries(this.series["TempId"], "TempId");
  //       }
  //       // this.chart.updateYaxis( { min: min - ( max - min ) * 0.1, max: max + ( max - min ) * 0.1 } );

  //       this.spinner.desactivate();
  //     },
  //     error => {
  //       this.spinner.desactivate();
  //     }
  //   );
  // }

  // onPointChange(event): void {
  //   if (this.point !== event.option.value) {
  //     this.point = event.option.value;
  //   }
  // }

  // filterPoint(filter) {
  //   this.filteredPoints = this.listOfPoints.filter(point => {
  //     return point.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
  //   });
  // }

  // filterBuildings(event) {
  //   let filter = event instanceof Object ? event.name : event;
  //   this.filteredBuildings = this.listOfBuildings.filter(building => {
  //     let add = building.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
  //       building.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
  //     if (building.address) {
  //       add = add || building.address.street.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
  //     }
  //     return add;
  //   });
  // }

  // onBuildingChange(event) {
  //   if (this.building !== event.option.value) {
  //     this.building = event.option.value;
  //     this.spinner.activate();

  //     let data = [];
  //     this.point = "";
  //     this.dataService.GetPointsWithAlarm(this.building._id).subscribe(
  //       resp => {
  //         data = data.concat(resp["data"]);
  //         this.listOfPoints = data;//.map((ord: string) => ord.split("/").splice(-5 ).join('/'));

  //         this.filterPoint(this.point);
  //         this.spinner.desactivate();
  //       },
  //       error => {
  //         console.log(error);
  //         this.spinner.desactivate();
  //       });
  //   }
  // }

  // displayByName(obj) {
  //   return (obj && obj.name) ? obj.name : obj;
  // }


  public getOrdName(ord) {
    let t = this.appService.convertFromASCII(ord).split("/").slice(-3).join("/");
    return t;
  }
}
