import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SpinnerService } from "../../../services/spinner.service";

@Component( {
  selector: "app-qr-code",
  templateUrl: "./qr-code.component.html",
  styleUrls: [ "./qr-code.component.css" ]
} )
export class MyQRCodeComponent {
  @Input() value: string = "";
  @Input() size: number = 250;
  @Input() level: string = "L"; //('L', 'M', 'Q', 'H')
  @Input() background: string = "white";
  @Input() backgroundAlpha: number = 1.0;
  @Input() foreground: string = "black";
  @Input() foregroundAlpha: number = 1.0;
  @Input() mime: string = "image/png";
  @Input() padding: number | null = null;
  @Input() canvas: boolean = false;

  constructor( private route: ActivatedRoute, public spinner: SpinnerService ) {

  }
}
