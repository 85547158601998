import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuildingService } from 'app/services/building.service';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-fault-assign',
  templateUrl: './fault-assign.component.html',
  styleUrls: ['./fault-assign.component.css']
})
export class FaultAssignComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<FaultAssignComponent>,private spinner: SpinnerService, private buildingService: BuildingService, @Inject(MAT_DIALOG_DATA) public data: any ) { }

  public form: FormGroup = new FormGroup({
    contact: new FormControl("", [Validators.required, Validators.email])
  })

  public options = [];
  public contact: string = "";

  public save(){
    let email = this.form.get("contact").value;
    let ret = email;

    this.dialogRef.close(ret);
  }

  ngOnInit(): void {
    this.spinner.activate();
    this.buildingService.getMaintenanceUsers(this.data.building).subscribe(x => {
      this.spinner.desactivate();
      this.options = x.fromUser;
    })
  }

}
