import {Component, OnInit, Inject} from '@angular/core';
import { detectBody } from '../../../app.helpers';
import { DOCUMENT } from '@angular/common';
import { SpinnerService } from 'app/services/spinner.service';
import { Router } from '@angular/router';

declare var jQuery:any;

@Component({
  selector: 'app-topnavigationlayout',
  templateUrl: 'topNavigationLayout.template.html',
  host: {
    '(window:resize)': 'onResize()'
  }
})

export class TopNavigationLayoutComponent implements OnInit{

  constructor(@Inject(DOCUMENT) private document: Document, private spinner: SpinnerService, private router: Router){
    router.events.subscribe((val) => {
      if(this.spinner.isBusy){
        this.spinner.fullStop();
      }
    });
  }

  public ngOnInit(): any {
    this.document.body.classList.add('top-navigation');
    detectBody();
  }

  public onResize() {
    detectBody();
  }

}
