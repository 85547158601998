import { Component, OnInit } from "@angular/core";
import { UserService } from "app/services/user.service";
import { SpinnerService } from "app/services/spinner.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SweetalertService } from "app/services/sweetalert.service";
import { AppI18nService } from "app/services/app.i18n.service";
import { User } from "app/interfaces/user";

@Component( {
  selector: "app-root-edit-user",
  templateUrl: "./root-edit-user.component.html",
  styleUrls: [ "./root-edit-user.component.css" ]
} )
export class RootEditUserComponent implements OnInit {

  constructor( private userService: UserService, private spinnerService: SpinnerService, private router: Router,
    private alertService: SweetalertService, private i18n: AppI18nService, private route: ActivatedRoute ) {
  }

  public id: string;
  public user: User;

  ngOnInit() {
    this.spinnerService.activate();
    this.route.params.subscribe( params => {
      this.id = params[ "id" ];

      this.userService.getOne( this.id ).subscribe( ret => {
        this.user = ret.data;

        this.spinnerService.desactivate();
      } );
    } );

  }

  public save( event ) {
    console.log( event );
    let user: User = {
      _id: this.user._id,
      company: event.value.company,
      email: event.value.primaryEmail,
      person: {
        country: event.value.country,
        city: event.value.city,
        landline: event.value.landline,
        contacts: {
          emails: event.value.emails,
          telephones: event.value.telephones
        },
        primaryEmail: event.value.primaryEmail,
        name: event.value.name,
        mobilePhone: event.value.mobilephone
      },
      type: event.value.type,
      username: event.value.primaryEmail
    };

    // console.log(newCompany);

    this.spinnerService.activate();
    this.userService.update( this.user, user ).subscribe(
      res => {
        this.spinnerService.desactivate();
        this.alertService.success( this.i18n.translate( "general.messages.success" ), this.i18n.translate( "general.messages.updated",  this.i18n.translate( "interfaces.user.user" ) ) );
        this.router.navigate( [ "/root/users" ] );
      },
      err => {
        console.log( err );
        this.spinnerService.desactivate();
        if( err.errors ) {
          this.alertService.info( this.i18n.translate( "general.messages.error" ),
            this.i18n.translate( "general.errors.serverSide", err.errors.join( ", " ) ) );
        } else {
          this.alertService.info( this.i18n.translate( "general.messages.error" ),
            this.i18n.translate( "general.errors.unknown" ) );
        }
      } );
  }

  public delete( user ) {
    this.spinnerService.activate();
    this.userService.delete( user._id ).subscribe(
      res => {
        this.spinnerService.desactivate();
        this.alertService.success( this.i18n.translate( "general.messages.success" ),
          this.i18n.translate( "general.messages.deleted", user.email ) );
        this.router.navigate( [ "/admin/users" ] );
      },
      err => {
        console.log( err );
        this.spinnerService.desactivate();
        if( err.errors ) {
          this.alertService.info( this.i18n.translate( "general.messages.error" ),
            this.i18n.translate( "general.errors.serverSide", err.errors.join( ", " ) ) );
        } else {
          this.alertService.info( this.i18n.translate( "general.messages.error" ),
            this.i18n.translate( "general.errors.unknown" ) );
        }
      } );
  }
}
