import { Component, OnInit } from '@angular/core';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-admin-data-prediction',
  templateUrl: './admin-data-prediction.component.html',
  styleUrls: ['./admin-data-prediction.component.css']
})
export class AdminDataPredictionComponent implements OnInit {


  public dataToSelect: { name: string, id: any }[] = [{
    name: "option 1",
    id: 0
  },
  {
    name: "option 2",
    id: 1
  }];

  public yesterdayData = { rawData: [], filtered: [], prediction: [] };
  public liveData = { rawData: [], filtered: [], prediction: [], sd: [] };

  public onDataSelected(value) {
    let max = moment.utc().diff(moment.utc().startOf("day"), "minute");
    max = Math.round(max / 10);

    let start = moment.utc().startOf("day");
    let lastValue = 15;
    this.liveData = { rawData: [], filtered: [], prediction: [], sd: [] };

    for (let i = 0; i < max; i++) {
      lastValue = lastValue + ((Math.random() - 0.5) * 2);

      this.liveData.rawData.push([start.valueOf(), lastValue]);
      this.liveData.filtered.push([start.valueOf(), Math.round(lastValue)]);

      start = start.add(10, "minutes");
    }

    let tsd = 0.1;
    for (let i = 0; i < 144 - max; i++) {
      lastValue = lastValue + ((Math.random() - 0.5) * 2);

      this.liveData.prediction.push([start.valueOf(), lastValue]);
      this.liveData.sd.push([start.valueOf(), lastValue - tsd, lastValue + tsd]);

      if (tsd < 3)
        tsd += 0.1;

      start = start.add(10, "minutes");
    }

    lastValue = 15;
    start = moment.utc().startOf("day");
    this.yesterdayData = { rawData: [], filtered: [], prediction: [] };
    for (let i = 0; i < 144; i++) {
      lastValue = lastValue + ((Math.random() - 0.5) * 2);
      let v = (Math.random() - 0.5);

      this.yesterdayData.prediction.push([start.valueOf(), lastValue + v])
      this.yesterdayData.rawData.push([start.valueOf(), lastValue]);
      this.yesterdayData.filtered.push([start.valueOf(), Math.round(lastValue)]);

      start = start.add(10, "minutes");
    }

    console.log(this.yesterdayData);
  }

  constructor() { }

  ngOnInit() {
  }

}
