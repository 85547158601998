import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { CompanyService } from "app/services/company.service";
import { AppService } from "app/services/app.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-root-companies",
  templateUrl: "./root-companies.component.html",
  styleUrls: ["./root-companies.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class RootCompaniesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource<any>();

  public displayedColumns: string[];

  constructor(public companyService: CompanyService, public appService: AppService, private transloco: TranslocoService,
    private spinnerService: SpinnerService, private alert: SweetalertService) {
  }

  ngOnInit() {

    this.displayedColumns = ["name", "contactEmail", "contactPhone", "country", "apiToken", "operations"];

    // merge(this.sort.sortChange, this.paginator.page).pipe()

    this.request(undefined);
  }

  public copy(text) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private request(filter: string) {
    this.spinnerService.activate();
    this.companyService.getAll("refQuery=" + encodeURIComponent(JSON.stringify([{ "path": "responsibles" }])), undefined, filter).
      subscribe(
        results => {
          // console.log(results);
          this.dataSource.data = results.data.map(x => {
            return {
              id: x._id,
              name: x.name,
              responsibles: x.responsibles ? x.responsibles.map(y => {
                return { name: y.person.name, id: y._id };
              }) : undefined,
              primaryEmail: x.primaryEmail,
              primaryTelephone: x.primaryTelephone,
              contactPhone: x.contacts.telephones || [],
              contactEmail: x.contacts.emails || [],
              country: this.appService.countries.filter(y => x.address.country == y.code)[0].name,
              apiToken: x.apiToken
            };
          });
          // console.log(this.dataSource.data);
          this.spinnerService.desactivate();

          // this.paginator.pageSize = results.pageSize;
          // this.paginator.pageIndex = results.page;
          // this.paginator.length = results.totalPages;

        },
        err => {
          this.spinnerService.desactivate();
          console.log(err);
        }
      );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;

    // this.request(filterValue);
  }

  public newKey(id) {
    this.spinnerService.activate();
    this.companyService.manangeToken(id, "action=generateToken").subscribe(
      result => {
        this.alert.success(this.transloco.translate("messages.success"),this.transloco.translate("messages.created", {entity: this.transloco.translate("entities.token")}));
        for (let c of this.dataSource.data) {
          if (c.id == id) {
            c.apiToken = result.data.apiToken;
          }
        }
        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
      }
    );
  }

  public removeKey(id) {
    this.spinnerService.activate();
    this.companyService.manangeToken(id, "action=removeToken").subscribe(
      result => {
        this.alert.success(this.transloco.translate("messages.success"),this.transloco.translate("messages.deleted", {entity: this.transloco.translate("entities.token")}));
        for (let c of this.dataSource.data) {
          if (c.id == id) {
            delete c.apiToken;
          }
        }
        this.spinnerService.desactivate();
      },
      err => {

        this.spinnerService.desactivate();
        console.log(err);
      }
    );
  }
}
