import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PointgroupsService } from 'app/services/pointgroups.service';
import { SpinnerService } from 'app/services/spinner.service';
import { SweetalertService } from 'app/services/sweetalert.service';

@Component({
  selector: 'app-admin-groups-create',
  templateUrl: './admin-groups-create.component.html',
  styleUrls: ['./admin-groups-create.component.css']
})
export class AdminGroupsCreateComponent implements OnInit {

  constructor(private spinner: SpinnerService, private pointGroupService: PointgroupsService, private alertService: SweetalertService, private router: Router) { }

  public save(form){
    this.spinner.activate();

    form.subGroups = form.subGroups.concat(form.assets);
    form.assets = undefined;

    this.pointGroupService.create(form).subscribe(res => {
      this.spinner.desactivate();

      this.alertService.success("Success!", "New group created.")
      this.router.navigate(["/admin/grouping/groups"])
    })

  }

  ngOnInit(): void {
  }

}
