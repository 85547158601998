import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { IboxModule } from 'app/components/common/ibox/ibox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApiKeyFormComponent } from "./apiKey-form.component";
import { TranslocoTestingModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    IboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    RouterModule,
    TranslocoTestingModule
  ],
  declarations: [ApiKeyFormComponent],
  exports: [ApiKeyFormComponent]
})
export class ApiKeyFormModule { }
