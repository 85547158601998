import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from 'app/services/spinner.service';
import { Observable } from 'rxjs';
import { PointTagsComponent } from '../point-tags/point-tags.component';

@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.css']
})
export class SearchPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PointTagsComponent>, @Inject(MAT_DIALOG_DATA) public data: { buttons?: { ok: string, cancel: string }, disableSelect: false, single: boolean, searchCallback: (filter: string, pageOptions: { page: number, pageSize: number, hasNext: boolean }) => Observable<any>, displayField: string, returnObjec?: boolean, valueField: string, preselected?: { value: string, text: string }[] }, private spinner: SpinnerService) { }

  public displayData = [];

  public cancel() {
    this.dialogRef.close();
  }


  ngOnInit(): void {
    this.refresh();
    if (!this.data.disableSelect)
      this.dialogRef.disableClose = true;
    if (this.data.preselected) {
      for (let p of this.data.preselected) {
        this.addItem(p.value, p.text);
      }
    }
  }

  public addAll() {
    for (let d of this.displayData) {
      if (this.isInAdd(d[this.data.valueField]) == undefined)
        this.addItem(d[this.data.valueField], d[this.data.displayField]);
    }
  }

  public close() {
    let ret = this.toAdd.map(x => x.data);
    this.dialogRef.close(ret);
  }

  public removeOption(value: string) {
    this.toAdd.splice(this.toAdd.findIndex(x => x.value == value), 1);
  }

  public filter: string = "";
  public pageOptions: { page: number, pageSize: number, hasNext: boolean } = { hasNext: false, page: 1, pageSize: 100 }
  public toAdd: { value: string, text: string, data: any }[] = [];

  public refresh() {
    this.data.searchCallback(this.filter, this.pageOptions).subscribe(res => {

      this.displayData = res;

      this.pageOptions.hasNext = res.length > this.pageOptions.pageSize;
    });
  }

  public isInAdd(value) {
    return this.toAdd.find(x => x.value == value);
  }

  public pageChanged(pageOptions) {
    this.pageOptions.pageSize = pageOptions.pageSize;
    this.pageOptions.page = pageOptions.page;

    this.refresh();
  }

  public addItem(value: string, text: string) {
    this.toAdd.push({ value: value, text: text, data: this.displayData.find(x => x[this.data.valueField] == value) });

    if (this.data.single) {
      this.dialogRef.close(this.toAdd[0].data);
    }
  }
}
