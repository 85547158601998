import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { AppI18nService } from 'app/services/app.i18n.service';
import { BuildingService } from 'app/services/building.service';
import { ITagMaps } from 'app/interfaces/tagMap';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetalertService } from 'app/services/sweetalert.service';

@Component({
  selector: 'app-admin-create-tag-maps',
  templateUrl: './admin-create-tag-maps.component.html',
  styleUrls: ['./admin-create-tag-maps.component.css']
})
export class AdminCreateTagMapsComponent implements OnInit {

  constructor(private router: Router, private spinnerService: SpinnerService, public i18n: AppI18nService, private buildingService: BuildingService, private route: ActivatedRoute, private alert: SweetalertService) { }

  ngOnInit() {
  }

  public save(form) {
    let tagMap: ITagMaps = form.value;

    this.buildingService.spinner.activate();
    this.route.params.subscribe(params => {
      this.buildingService.createTagMap(params["buildingId"], tagMap).subscribe(results => {
        this.spinnerService.desactivate();
        this.alert.success(this.i18n.translate("general.messages.success"), this.i18n.translate("general.messages.created", this.i18n.translate("interfaces.tagMaps.tagMap")));
        this.router.navigate(["admin", "buildings", params["buildingId"], "tagMaps"]);
      },
        err => {
          console.log(err);
        });
    });
  }
}
