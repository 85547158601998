import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { SpinnerService } from "app/services/spinner.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private spinner: SpinnerService, private _snackBar: MatSnackBar) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // console.error("Error from error interceptor\n", error);

        // this._snackBar.open('Anomaly detected and has been automatically reported.', 'Dismiss', {
        //   duration: 5000,
        //   horizontalPosition: "right",
        //   verticalPosition: "bottom",
        // });

        this.spinner.fullStop();
        return throwError(error);
      }),
      finalize(() => {
      })
    ) as Observable<HttpEvent<any>>;
  }
}
