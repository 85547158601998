import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IboxComponent } from './ibox.component';
import { SpinnerModule } from 'app/components/common/spinner/spinner.module';

@NgModule({
  imports: [
    CommonModule,
    SpinnerModule
  ],
  declarations: [IboxComponent],
  exports: [IboxComponent]
})
export class IboxModule { }
