import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.css']
})
export class HelpPopupComponent implements OnInit {

  public html;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // console.log(this.data.url);
    this.html = this.sanitizer.bypassSecurityTrustHtml(
      `<iframe style="border:none; overflow: visible; height: 50vh; width: 100%;" src="${this.data.url}"></iframe>`,
    );
  }

}
