import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DriverService } from 'app/services/driver.service';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-point-form',
  templateUrl: './point-form.component.html',
  styleUrls: ['./point-form.component.css']
})
export class PointFormComponent implements OnInit {

  public building: string = "";
  public buildingName: string = "";

  public device: string = "";
  public deviceName: string = "";

  public enableEdit: boolean = false;

  public points: { name: string, type: number, value: string }[] = [];

  constructor(private spinner: SpinnerService, public driverService: DriverService, public dialogRef: MatDialogRef<PointFormComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  
  public point: { name: string, type: number, value: string } = { name: "", type: 2, value: null };

  ngOnInit() {
    this.buildings = this.data.buildings;
    this.devices = this.data.devices;

    this.building = this.data.selectedBuilding;
    this.buildingName = this.buildings.find(x => x.id == this.building).name;

    this.device = this.data.selectedDevice;
    this.deviceName = this.devices.find(x => x.id == this.device).name;

    if (this.data.point) {
      this.enableEdit = true;

      this.point.name = this.data.point.name.split("/").slice(-1)[0];
      this.point.type = this.data.point.type;
      this.point.value = this.data.point.value;

      this.title = "Edit point";
      this.subtitle = "";

      console.log(this.point)
    }
  }

  public title: string = "Add Points";
  public subtitle: string = "New Point"

  public delete(point) {
    this.points.splice(this.points.indexOf(point), 1);
  }


  public close() {
    this.dialogRef.close();
  }

  public save() {
    if (this.point.name != "" && this.point.type != 0 && (this.point.value != "" || Number(this.point.value) == 0)) {
      this.points.push({
        name: this.point.name,
        type: this.point.type,
        value: this.point.value
      })
    }

    this.dialogRef.close({
      building: this.building,
      device: this.device,
      points: this.points
    })
  }

  public canSave() {
    if (this.enableEdit && this.point.value) {
      return true;
    }

    if (this.device != "")
      return true;
    return false;
  }

  public saveAndNew() {
    this.points.push({
      name: this.point.name,
      type: this.point.type,
      value: this.point.value
    })

    this.point = { name: "", type: this.point.type, value: null };
  }

  public valid() {
    if (this.point.name != "" && this.point.type != 0 && this.point.value != null) return true;
    return false;
  }

  public buildings: { id: string, name: string }[] = [];
  public devices: { id: string, name: string }[] = [];

  public onSubmit() {

  }

}
