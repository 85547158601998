import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter, OnChanges, Directive } from '@angular/core';
import { AppHighstockComponent } from '../highcharts/highstock/highstock.component';

import * as moment from "moment-timezone";

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Moment } from 'moment-timezone';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MONTH_YEAR } from 'app/services/report.service';


@Component({
  selector: 'app-multiple-points-chart',
  templateUrl: './multiple-points-chart.component.html',
  styleUrls: ['./multiple-points-chart.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR }
  ]
})
export class MultiplePointsChartComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("pointSelector", { static: true }) pointInput;

  @ViewChild("chartStock") _chart: AppHighstockComponent;

  @Input() hideChart: boolean = false;
  @Input() useMonth: boolean = false;


  public max = moment()

  get chart(): AppHighstockComponent {
    return this._chart;
  }

  public listAllPoints() {
    return this.pointsInBuilding.map(x => {
      {
        return { point: x.id, start: this.form.get("start").value, end: this.form.get("end").value, building: this.form.get("building").value }
      }
    });
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.buildings) {
      this.filteredBuildings = this.buildings;
    }

    if (changes.selectedBuilding && changes.selectedBuilding.currentValue != "") {
      this.form.get("building").setValue(this.selectedBuilding);
    }

    if (changes.pointsInBuilding) {
      if (changes.pointsInBuilding.currentValue != []) {
        this.filteredPoints = this.pointsInBuilding;
      }
      if (changes.pointsInBuilding.currentValue == []) {

      }
    }
  }

  @Input() buildings: { id: any, name: any }[] = [];
  @Input() selectedBuilding: string = "";
  @Input() pointsInBuilding: { id: string, name: string }[] = [];
  @Input() tags: string[] = [];

  @Output() onAddPoint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemovePoint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBuildingChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

  @Input() chartOption = { zoomType: "x", height: 600 };
  @Input() horizontal = false;
  @Input() title = "Set the chart Title with the Input field title";
  @Input() subTitle = "";
  @Input() xAxis = { type: "datetime" };
  @Input() yAxis = [{ opposite: false }];
  @Input() tooltip = { shared: true, split: false };
  @Input() scrollbar;
  @Input() legend;
  @Input() series = [];
  @Input() navigator = {
    series: {
      includeInCSVExport: false
    }
  };
  @Input() rangeSelector;
  @Input() plotOptions;
  @Input() chartExportOptions = {
    buttons: {
      contextButton: {
        menuItems: [
          {
            textKey: "downloadCSV",
            onclick: function () {
              this.downloadCSV();
            }
          }, {
            textKey: "downloadXLS",
            onclick: function () {
              this.downloadXLS();
            }
          }, {
            separator: true
          }, {
            textKey: "printChart",
            onclick: function () {
              this.print();
            }
          }, {
            textKey: "downloadPNG",
            onclick: function () {
              this.exportChart();
            }
          }, {
            textKey: "downloadJPEG",
            onclick: function () {
              this.exportChart({
                type: "image/jpeg"
              });
            }
          }, {
            textKey: "downloadPDF",
            onclick: function () {
              this.exportChart({
                type: "application/pdf"
              });
            }
          }, {
            textKey: "downloadSVG",
            onclick: function () {
              this.exportChart({
                type: "image/svg+xml"
              });
            }
          }
        ]
      }
    }
  };

  ngAfterViewInit(): void {
    this.form.get('building').valueChanges.subscribe(val => {
      this.onBuildingChanged.emit(val);
    });

    this.form.get("tags").valueChanges.subscribe(val => {
      // this.filteredPoints = this.pointsInBuilding.filter(point => {
      //   return point.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      // });
    })

    this.form.get("point").valueChanges.subscribe(val => {
      this.filterPoints(val);
    })
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {

    datepicker.close();
    this.form.get("start").setValue(normalizedMonth);
  }

  chosenYearHandler(normalizedYear: Moment) {

  }


  public hasData: boolean = false;

  public filteredTags: string[] = [];

  public addPoint(event) {
    this.form.get("point").setValue("");
    this.pointInput.nativeElement.blur();
    if (this.useMonth) {
      let m = moment(this.form.get("start").value).startOf("month");
      this.form.get("start").setValue(m);
      this.form.get("end").setValue(m.clone().endOf("month"));

      console.log(m.toISOString())

      console.log(this.form);
    }
    this.onAddPoint.emit({ point: event.option.value.id, start: this.form.get("start").value, end: this.form.get("end").value, building: this.form.get("building").value });
  }

  filterPoints(event) {
    let filter = event instanceof Object ? event.name : event;
    this.filteredPoints = this.pointsInBuilding.filter(point => {
      return (point.name.toLowerCase().indexOf(filter.toLowerCase())) >= 0;
    });
  }

  public filteredPoints: { id: any, name: any }[] = [];
  public filteredBuildings: { id: any, name: any }[] = []

  private today = moment.utc();

  @Input() pointsStatus: { id: string, name: string, col1: string, col2: string, col3: string, csv: { filename: string, data: any } }[] = [];

  public form = new FormGroup({
    start: new FormControl(this.today.clone().add(-1, "d"), [Validators.required]),
    end: new FormControl(this.today.clone(), [Validators.required]),
    tags: new FormControl([]),
    building: new FormControl(null, [Validators.required]),
    point: new FormControl(""),
  });


  public refresh() {
    let m = moment(this.form.get("start").value).startOf("month");
    this.form.get("start").setValue(m);
    this.form.get("end").setValue(m.clone().endOf("month"));

    this.onRefresh.emit({
      start: this.form.get("start").value, end: this.form.get("end").value, building: this.form.get("building").value
    });
  }

  constructor() { }

  ngOnInit() {
  }

  displayBuildingByName(obj) {
    return (obj && obj.name) ? obj.name : obj;
  }

  displayPointByName(obj) {
    return (obj && obj.name) ? obj.name : obj;
  }


  public generateCSV(csv) {
    var str = 'timestamp, value, unit\r\n';

    for (var i = 0; i < csv.data.length; i++) {
      var line = '';


      for (var index in csv.data[i]) {
        if (line != '') line += ','

        line += csv.data[i][index];
      }

      str += line + '\r\n';
    }

    var file = new Blob([str], {
      type: "text/csv;charset=UTF-8"
    });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, csv.filename + ".csv");
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = csv.filename + ".csv";
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  public removePoint(point) {
    this.onRemovePoint.emit(point);
  }

  get point(): any {
    return this.form.get("point").value;
  }
}

