import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.css']
})
export class FilterFieldComponent implements OnInit {

  @Input() time: number = 1;

  @Input() filter = "";
  @Output() filterChange = new EventEmitter<string>();

  @Output() return: EventEmitter<string> = new EventEmitter<string>();


  private timerObservable: Subscription = new Subscription();

  constructor() { }

  ngOnInit() {
  }

  public returnFilter() {
    // this.timerObservable.unsubscribe();

    let ret = this.filter.trim().toLowerCase();
    this.return.emit(ret);
  }

  public applyFilter() {
    this.timerObservable.unsubscribe();
    this.timerObservable = Observable.timer(this.time * 1000).subscribe(x => {
      this.returnFilter();
    });
  }
}
