import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @Input() pageSizeOptions: number[] = [10, 25, 50, 100, 200];
  @Input() hasNext: boolean = false;
  @Input() page: any = 1;
  @Input() pageSize: number = 100;

  @Output() pageChanged: EventEmitter<{ page: number, pageSize: number }> = new EventEmitter<{ page: number, pageSize: number }>();

  constructor() { }

  ngOnInit() {
    if (!this.pageSizeOptions.find(x => this.pageSize)) {
      this.pageSize = this.pageSizeOptions[this.pageSizeOptions.length - 1];
    }
  }


  public goToFirstPage() {
    this.page = 1;
    this.pageChanged.emit({ page: parseInt(this.page), pageSize: this.pageSize });
  }


  public changePage(event) {
    this.page = event;
    this.pageChanged.emit({ page: parseInt(this.page), pageSize: this.pageSize });
  }

  public changeSize() {
    this.page = 1;
    this.pageChanged.emit({ page: parseInt(this.page), pageSize: this.pageSize });
  }
}
