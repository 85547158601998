import { Component, OnInit, ViewChild } from '@angular/core';
import { DriverService } from 'app/services/driver.service';
import { SpinnerService } from 'app/services/spinner.service';
import { BuildingService } from 'app/services/building.service';
import { HealthStatus, PointsViewComponent, getSubStatus, getPointStatus, badSub, badPoint } from 'app/components/common/points-view/points-view.component';
import { SweetalertService } from 'app/services/sweetalert.service';
import { MatDialog } from '@angular/material/dialog';

import * as moment from "moment-timezone";
import { timer, Observable, Subscription, forkJoin } from 'rxjs';
import { ProgressDisplayComponent } from 'app/components/common/progress-display/progress-display.component';
import { delay } from 'rxjs/operators';
import { SessionService } from 'app/services/session.service';
import { QueryparamsService } from 'app/services/queryparams.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-driver',
  templateUrl: './admin-driver.component.html',
  styleUrls: ['./admin-driver.component.css']
})
export class AdminDriverComponent implements OnInit {
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });
  public filterBuilding(){
    
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;
      
    let searchBase = this.buildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.buildings;
}
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    this.selectedBuilding = this.FilteredselectedBuilding._id;
    // console.log(this.building);
    // this.updateData();


  }
  public buildings: { id: string, name: string }[] = [];
  public selectedDevice: string = "";
  public devices: { id: string, name: string }[] = [];

  @ViewChild("pointsView", { static: true }) pointsView: PointsViewComponent;

  public selected: any[] = [];

  public progress: number = 0;
  public status: string = "No command sent";

  public points: { uid: number, uidAck: boolean, selected: boolean, etc: string, index: number, healthTooltip: string, value: string, id: string, ord: string, health: HealthStatus, meta: string[], tags: { key: string, value: string }[], type: any }[] = []

  public statusMessage: string = "";

  private originalStatus: any[] = [];

  public numberOfPoints = "No device selected";

  public filterAndAddAll() {
    // this.pointsView.commandSent();
    this.spinnerService.activate();
    this.driverService.addAllPoints(this.selectedDevice, this.filter).subscribe(x => {
      this.spinnerService.desactivate();

      this.alert.success("Success!", "Filter and add all points command sent.");

      // this.deviceChanged(this.selectedDevice);
      this.points = [];
      this.resetPoints();
      // this.pointsView.updateProgress();

      this.pointsView.commandSent();
      this.needRefresh = true;

      // setTimeout(() => {

      //   this.spinnerService.desactivate();

      //   this.deviceChanged(this.selectedDevice);
      // }, 3000)
    })
  }

  public removeAll() {
    // this.pointsView.commandSent();
    this.spinnerService.activate();
    this.driverService.removeAllPoints(this.selectedDevice).subscribe(x => {
      this.spinnerService.desactivate();

      this.alert.success("Success!", "Remove all points command sent.");

      // this.deviceChanged(this.selectedDevice);
      this.points = [];
      this.resetPoints();
      // this.pointsView.updateProgress();

      this.pointsView.commandSent();
      this.needRefresh = true;

      // setTimeout(() => {

      //   this.spinnerService.desactivate();

      //   this.deviceChanged(this.selectedDevice);
      // }, 3000)
    })
  }

  public addAll() {
    // this.pointsView.commandSent();
    this.spinnerService.activate();
    this.driverService.addAllPoints(this.selectedDevice).subscribe(x => {
      this.spinnerService.desactivate();

      this.alert.success("Success!", "Add all points command sent.");

      // this.deviceChanged(this.selectedDevice);
      this.points = [];
      this.resetPoints();
      // this.pointsView.updateProgress();


      this.pointsView.commandSent();
      this.needRefresh = true;

      // setTimeout(() => {

      //   this.spinnerService.desactivate();

      //   this.deviceChanged(this.selectedDevice);
      // }, 3000)
    })
  }

  public toRemove: string[] = [];
  public toAdd: string[] = [];

  public hasNext: boolean = false;
  public page: number = 1;
  public pageSize: number = 100;

  constructor(private queryService: QueryparamsService, private sessionService: SessionService, private alert: SweetalertService, public dialog: MatDialog, private driverService: DriverService, private spinnerService: SpinnerService, private buildingService: BuildingService) { }

  public getDevices(event) {
    this.points = [];
    this.spinnerService.activate()
    this.sessionService.building = event;
    this.driverService.getDevices(event).subscribe(d => {
      this.selectedBuilding = event;
      this.devices = d.data.map(x => { return { id: x._id, name: x.friendlyName + " " + x.hostId } });

      this.spinnerService.desactivate();
    });
  }

  public disableUpdate: boolean = true;

  public reloadAllMeta() {
    this.pointsView.commandSent();

    this.spinnerService.activate();
    this.driverService.listMetadata(this.selectedDevice).subscribe(x => {
      this.spinnerService.desactivate();

      this.alert.success("Success", "List command was sent")

      // this.checkCommands();
      this.pointsView.updateProgress();

    })
  }

  public updateMetadata(event) {
    console.log(event);
    this.spinnerService.activate();
    this.driverService.getMetadata(this.selectedDevice, event.id).subscribe(x => {
      this.spinnerService.desactivate();
      this.alert.success("Success!", "Get metadata command has been sent.");

      this.pointsView.updateProgress();
    })
  }

  public resetPoints() {
    for (let p of this.points) {
      p.selected = this.originalStatus[p.id];
    }

    this.statusMessage = "";

    this.toAdd = [];
    this.toRemove = [];
  }


  public changeSelection(event) {
    this.disableUpdate = true;
    this.statusMessage = "";

    let keys = Object.keys(event);


    this.selected = event;

    this.toAdd = [];
    this.toRemove = [];

    for (let key of keys) {
      if (event[key] != this.originalStatus[key]) {
        if (event[key] == true) {
          this.toAdd.push(key);
        }
        else {
          this.toRemove.push(key);
        }
      }
    }

    let t = [];
    if (this.toAdd.length > 0)
      t.push(this.toAdd.length + " points to add");
    if (this.toRemove.length > 0)
      t.push(this.toRemove.length + " points to remove");

    this.statusMessage = t.join(", ");
  }

  public needRefresh: boolean = false;
  public filter: string = "";

  public pageChanged(event) {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.filter = event.filter;

    console.log(this.page);

    this.deviceChanged(this.selectedDevice);
  }

  public release(event) {
    if (event == 1 || event == 12 || this.needRefresh) {
      this.needRefresh = false;
      this.deviceChanged(this.selectedDevice);
    }
    else if (event == 2 || event == 3) {
      this.deviceChanged(this.selectedDevice);
    }
  }

  public lastUpdateAt: string = "";
  public timer: Subscription = new Subscription();

  public updatePoints() {
    this.spinnerService.activate();

    let arr = [];
    if (this.toAdd.length > 0)
      arr.push(this.driverService.addPoints(this.selectedDevice, this.toAdd));

    if (this.toRemove.length > 0)
      arr.push(this.driverService.removePoints(this.selectedDevice, this.toRemove).pipe(delay(100)));

    this.pointsView.commandSent();

    forkJoin(arr).subscribe(res => {
      this.needRefresh = true;
      this.spinnerService.desactivate();

      this.alert.success("Success!", "Update commands has been sent.");

      this.deviceChanged(this.selectedDevice);
    });
  }

  public blockDiscover: boolean = true;
  public blockMetadata: boolean = true;
  public blockMass: boolean = true;

  public blockAction(event) {
    this.blockDiscover = event.discover;
    this.blockMetadata = event.getAllMetadata;
    this.blockMass = event.discover;
  }

  public commands: any[] = [];

  public lockAll: boolean = false;

  public deviceChanged(event) {
    this.selectedDevice = event;

    this.originalStatus = [];
    this.statusMessage = "";

    // this.checkCommands();

    this.spinnerService.activate();
    this.driverService.getMetadataNonFD(this.selectedBuilding, event, this.page, this.pageSize, this.filter).subscribe(
      suc => {
        this.spinnerService.desactivate();

        this.numberOfPoints = suc.device.pointSummary.total;

        this.hasNext = suc.data.length > this.pageSize ? true : false;

        this.points = suc.data.map((t, index) => {
          this.originalStatus[t._id] = (t.uid && t.uidAck) ? true : false;

          let status = HealthStatus.Unkown;
          let statusTooltip = "Unkown";


          if (t.lastReceivedSubStatus != undefined && typeof (t.lastReceivedSubStatus) === 'number') {

            if (badPoint.indexOf(t.lastReceivedPointStatus) >= 0 || badSub.indexOf(t.lastReceivedSubStatus) >= 0) {
              status = HealthStatus.Bad;
            }
            else {
              status = HealthStatus.Good;
            }


            statusTooltip = "Subscription status is '" + getSubStatus(t.lastReceivedSubStatus) + "'. Point status is '" + getPointStatus(t.lastReceivedPointStatus) + "'.";
          }


          return {
            etc: t.friendlyName + t.toRemove ? "to remove" : "",
            index: (this.page - 1) * this.pageSize + index + 1,
            tags: t.pointTags ? Object.keys(t.pointTags).map(a => { return { key: a, value: t.pointTags[a] } }) : [],
            health: status,
            meta: t.facets ? t.facets.split(",") : [],
            healthTooltip: statusTooltip,
            type: this.getType(t.dataType),
            id: t._id,
            ord: t.displayOrd,
            selected: (t.uid && t.uidAck) ? true : false,
            uid: t.uid,
            uidAck: t.uidAck,
            toRemove: t.toRemove,
          }
        })

        if (suc.data.find(x => x.toRemove)) {
          this.lockAll = true;
        }
        else {
          this.lockAll = false;
        }

        if (this.points.length > this.pageSize)
          this.points = this.points.splice(0, this.points.length - 1);
      }
    )
  }

  public getType(number: number) {
    switch (number) {
      case 1:
      case 2:
        return "Boolean";
      case 3:
      case 4:
        return "Double";
      case 5:
      case 6:
        return "Enum";
      case 7:
      case 8:
        return "String"
    }
  }

  public discover() {
    this.alert.custom({
      title: "Attention", text: "This operation will execute a command that can take between one and five minutes to complete. Please confirm.", buttons: {
        yes: {
          text: "Yes",
          value: "yes"
        },
        no: {
          text: "No",
          value: "no"
        }
      },
      icon: "info",

    }).then(x => {
      if (x == "yes") {
        this.pointsView.commandSent();

        this.needRefresh = true;
        this.spinnerService.activate();
        this.driverService.discoverDevices(this.selectedBuilding, this.selectedDevice).subscribe(x => {
          // this.checkCommands();
          this.spinnerService.desactivate();
          this.pointsView.updateProgress();
        }, err => {
          this.spinnerService.desactivate();
          if (err.error.errors) {
            this.alert.info("Attention", err.error.errors[0].message);
          }
          else {
            this.alert.error("Error", "Unkown error. Contatct the admin.");
          }


          this.pointsView.updateProgress();
        })
      }
    });
  }

  ngOnInit() {
    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(d => {
      this.buildings = d.data.map(x => { return { id: x._id, name: x.name } });

      this.spinnerService.desactivate();
    });
  }

  public string_of_enum(e, v) {
    for (var k in e) if (e[k] == v) return k;
    return null;
  }

}
