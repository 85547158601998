import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildingService } from 'app/services/building.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { TagMapType, TagMapCondition } from 'app/interfaces/tagMap';
import { SweetalertService } from 'app/services/sweetalert.service';

@Component({
  selector: 'app-admin-tag-flag-maps',
  templateUrl: './admin-tag-flag-maps.component.html',
  styleUrls: ['./admin-tag-flag-maps.component.css']
})
export class AdminTagFlagMapsComponent implements OnInit {

  constructor(private alert: SweetalertService, private router: Router, private spinner: SpinnerService, private route: ActivatedRoute, private buildingService: BuildingService) { }

  public hasPoints: boolean = false;

  public invalidQuery: boolean = false;


  public form: FormGroup = new FormGroup({
    building: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    type: new FormControl("", [Validators.required]),
    condition: new FormControl("", [Validators.required]),
    lookup: new FormControl("", [Validators.required]),
    caseSensitive: new FormControl(true),
    applyToExistingPoints: new FormControl(true),
    tags: new FormArray([]),
    flag: new FormControl([])
  });

  get validPreview() {
    return this.form.get("building").value && this.form.get("condition").valid && this.form.get("lookup").valid && this.form.get("type").valid
  }

  get validForm() {
    return this.form.valid && ((this.tags.controls.length > 0 && this.tags.valid) || this.form.get("flag").value.length > 0)
  }

  public filter: string = "";
  public filteredOptions: Observable<{ id: string, name: string }[]>;
  public buildings: { id: string, name: string }[] = [];

  public flagValues: string[] = [
    "subscribedPoint",
    "nonZeroDataNotification",
    "dataStaleAlarm",
    "tempDataPoint",
    "powerMeter",
    "gasMeter",
    "waterMeter",
    "otherMeter"
  ];

  public datasource = new MatTableDataSource<any>();
  public displayedColumns: string[] = ["index", "point"];
  public pagination: { hasNextPage: boolean, page: number, pageSize: number } = { hasNextPage: false, page: 1, pageSize: 10 }

  public clearFlags() {
    this.form.get("flag").setValue("");
  }

  public saveMap() {
    let values = this.form.getRawValue();
    let t = {
      entity: {
        name: values.name,
        lookup: values.lookup,
        type: values.type,
        tags: values.tags,
        condition: values.condition,
        caseSensitive: values.caseSensitive,
        flags: values.flag
      },
      applyToExistingPoints: values.applyToExistingPoints,
    }

    this.spinner.activate();
    this.buildingService.createTagMap(values.building, t).subscribe(x => {
      this.spinner.desactivate();

      this.alert.success("Success!", "New tag map created.");
      this.router.navigate(["admin", "tagMaps"], { queryParams: { building: values.building } })
    })
  }

  public changePageOptions(pagination) {
    this.pagination.page = pagination.page;
    this.pagination.pageSize = pagination.pageSize;

    this.testRules();
  }

  public applyFilter(filter) {
    this.filter = filter;
    this.generatePreview();
  }

  get tags(): FormArray {
    return <FormArray>this.form.get('tags');
  }

  public pointsCount: number = 0;

  public addTag() {
    this.tags.push(new FormControl("", [Validators.required]));
  }

  public removeTag(i) {
    this.tags.removeAt(i);
  }

  public generatePreview() {
    this.pagination.page = 1;
    this.pagination.pageSize = 10;
    this.pagination.hasNextPage = false;

    this.testRules();
  }

  public testRules() {
    this.spinner.activate();
    this.buildingService.testRule(this.form.get("building").value, this.form.get("type").value, this.form.get("condition").value, this.form.get("lookup").value, this.pagination.page, this.pagination.pageSize, this.filter).subscribe(x => {
      this.spinner.desactivate();

      let c = ((this.pagination.page - 1) * this.pagination.pageSize) + 1;

      let data = x.data.map((t, i) => {
        return {
          id: t.id,
          ord: t.ord,
          index: c + i
        }
      })

      this.pointsCount = x.count;

      this.datasource.data = data;

      this.pagination.hasNextPage = x.data.length > this.pagination.pageSize;
    })
  }

  displayFn(option): string {
    if (this.buildings.length == 0)
      return "";
    if (option) {
      let t = this.buildings.find(x => x.id == option);
      return t.name ? t.name : '';
    }
    return "";
  }

  private _filter(value: string): { id: string, name: string }[] {
    if (this.buildings.length == 0)
      return;
    if (value) {
      const filterValue = value.toLowerCase();

      return this.buildings.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
    }
    return this.buildings;
  }

  public types: { key: string, text: string }[] = [];
  public conditions: { key: string, text: string }[] = [];

  ngOnInit() {

    // this.spinner.activate();
    // throw Error("The app component has thrown an error!");

    for (let key of Object.keys(TagMapType)) {
      this.types.push({ key: key, text: TagMapType[key] });
    }

    for (let key of Object.keys(TagMapCondition)) {
      this.conditions.push({ key: key, text: TagMapCondition[key] });
    }

    this.filteredOptions = this.form.get("building").valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.spinner.activate();
    this.buildingService.getAll().subscribe(buildings => {
      this.buildings = buildings.data.map(b => {
        return {
          id: b._id,
          name: b.name
        }
      })

      this.spinner.desactivate();

      this.route.queryParams.subscribe(x => {
        let building = x["building"];
        if (building) {
          this.form.get("building").disable();
          this.form.get("building").setValue(building);

          this.onBuildingChange();
        }
      })
    })
  }

  public onBuildingChange() {
    this.spinner.activate();
    this.buildingService.getTagMaps(this.form.get("building").value).subscribe(tags => {
      this.spinner.desactivate();

      console.log(tags);
    })
  }

}
