import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PointsChartComponent } from './points-chart.component';
import { AppHighstockModule } from '../highcharts/highstock/highstock.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PointsChartComponent],
  imports: [
    CommonModule,
    AppHighstockModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    PointsChartComponent
  ]
})
export class PointsChartModule { }
