import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from "@angular/core";
import { Location } from "@angular/common";
import { IBuilding } from "app/interfaces/building";
import { Router } from "@angular/router";
import { CompanyService } from "app/services/company.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { AppService } from "app/services/app.service";
import { PhoneType } from "app/interfaces/contact";
import { Subscription, Observable } from "rxjs";
import { BuildingService } from "app/services/building.service";

import * as moment from "moment-timezone";
import { map, startWith } from "rxjs/operators";

@Component( {
  selector: "app-building-form",
  templateUrl: "./building-form.component.html",
  styleUrls: [ "./building-form.component.css" ]
} )
export class BuildingFormComponent implements OnInit {

  public subCountrys = [
    { "name": "Scotland", "code": "GB-SCT" },
    { "name": "Wales", "code": "GB-WLS" },
    { "name": "Northern Ireland", "code": "GB-NIR" },
    { "name": "England", "code": "GB-ENG" }
  ];

  public numberPermitKeys = [
    8,
    9,
    37,
    38,
    39,
    40,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105,
    110,
    190

  ];

  public notNull( element ) {
    // console.log(element);
  }

  @Output() onsubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() building: any;

  @Input() enabledEdit = true;
  public workingHours = [
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "07:00",
    "07:15",
    "07:30",
    "07:45",
    "08:00",
    "08:15",
    "08:30",
    "08:45",
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00"
  ];

  public workingDays = [
    [ { "number": 1, "text": "Monday" } ],
    [ { "number": 2, "text": "Tuesday" } ],
    [ { "number": 3, "text": "Wednesday" } ],
    [ { "number": 4, "text": "Thursday" } ],
    [ { "number": 5, "text": "Friday" } ],
    [ { "number": 6, "text": "Saturday" } ],
    [ { "number": 0, "text": "Sunday" } ]
  ];
  public numberFloorsNg: number = 1;
  public totalFloorSpaceNg: number = 0;
  public floorsDetailed: any[] = [];
//   public serviceLevelAgreements:any[] = [];

  public country: any;
  public serviceLevelAgreementsSelected: any;

  public countriesFiltered: Observable<string[]>;
  public serviceLevelAgreementsMetrics = [
    { "name": "Temp", "unity": "°C" },
    { "name": "Humidity", "unity": "%" },
    { "name": "CO2", "unity": "ppm" },
    { "name": "Light Intensity (Lux)", "unity": "lux" }
  ];
  public serviceLevelAgreements = [

    {
      "name": "group",
      "contents": [
        {
          "name": null,
          "type": null,
          "working_hours_min": null,
          "working_hours_max": null,
          "non_working_hours_min": null,
          "non_working_hours_max": null
        }

      ]
    }

  ];
  // name: string;
  // address: string;
  // company: string;
  // description: string;
  // contact: Contact;
  // geographicalPosition: Vector2;

  constructor( public appService: AppService, private router: Router, private oldbuildingService: BuildingService,
    private buildingService: CompanyService, private _location: Location, private spinnerService: SpinnerService,
    private alertService: SweetalertService ) {
  }

  public holidays: any[] = [];
  public holidaysHtml: any;

  public check() {
    console.log( this.serviceLevelAgreementsSelected );
    console.log( this.serviceLevelAgreements );
    console.log( this.serviceLevelAgreements[ "teste1" ] );
    console.log( this.serviceLevelAgreements[ this.serviceLevelAgreementsSelected ] );

  }

  private keyPressedTimer: Subscription = new Subscription();

  public setCountry( country ) {
    this.buildingForm.get( "address" ).get( "country" ).setValue( country );
  }

  public getHolidays( country: any, subcountry = false ) {
    //   console.log(country);
    let country_use = "";
    let sub_country = "";
    if ( subcountry === false ) {
      country_use = country;
      if ( country_use == "GB" ) {
        sub_country = this.buildingForm?.get( "address" )?.get( "subcountry" )?.value ?
                      this.buildingForm?.get( "address" )?.get( "subcountry" )?.value :
                      "";
      }
    } else {
      country_use = this.buildingForm.get( "address" ).get( "country" ).value;
      if ( country_use == "GB" ) {
        sub_country = country;
      }

    }
    //   if(event == 1){
    //       country_use = country.target.value;
    //   }
    //   else {
    //       country_use = country;
    //   }
    //   country = this.country;
    let year = new Date().getFullYear();
    let holidays = [];
    let holidaysHtml = "";
    if ( country_use == "GB" ) {

      if ( sub_country != "" ) {
        // console.log("SUBCOUNTRY");
        // console.log(sub_country);
        let new_subcountry = [];
        this.subCountrys.map( function( mapped ) {
          if ( mapped.code != sub_country ) {
            new_subcountry.push( mapped.code );
          }
        } );
        this.oldbuildingService.holidays( country_use, year ).subscribe( x => {
          if ( x ) {
            x.map( function( val ) {
              let input = true;
              val?.counties?.map( function( vel ) {
                // console.log(x);
                // console.log("VEL:")
                // console.log(vel);
                // console.log(new_subcountry);
                if ( new_subcountry.includes( vel ) ) {
                  input = false;
                }

              } );

              // holidaysHtml += val.start.date + ' - ' + val.summary + "\n";
              if ( input === true ) {
                let saida = [
                  val.date,
                  val.localName,
                  val.name
                ];

                holidays.push( saida );
              }

            } );

            holidays.map( function( v ) {
              holidaysHtml += v[ 0 ] + " - " + v[ 1 ] + "\n";
            } );
            this.holidaysHtml = holidaysHtml;
            // console.log(holidays);
            this.holidays = holidays;
          } else {
            this.holidaysHtml = "";
          }

          // console.log(items);
          // holidays.sort((a,b) => a[3].localeCompare(b[3]));

          // holidays.sort(function (a, b) {
          //     return a[3] - b[3];
          //   });

        } );
      }
    } else {
      this.oldbuildingService.holidays( country_use, year ).subscribe( x => {
        if ( x != null ) {
          x.map( function( val ) {

            let saida = [
              val.date,
              val.localName,
              val.name
            ];

            // holidaysHtml += val.start.date + ' - ' + val.summary + "\n";
            holidays.push( saida );

          } );
          // console.log(items);
          // holidays.sort((a,b) => a[3].localeCompare(b[3]));

          holidays.map( function( v ) {
            holidaysHtml += v[ 0 ] + " - " + v[ 1 ] + "\n";
          } );

          this.holidaysHtml = holidaysHtml;

          // holidays.sort(function (a, b) {
          //     return a[3] - b[3];
          //   });

          // console.log(holidays);
          this.holidays = holidays;
        } else {
          this.holidaysHtml = "";
        }
      } );
    }
  }

  public search() {

    if ( this.buildingForm.get( "address" ).valid ) {
      this.keyPressedTimer.unsubscribe();
      this.keyPressedTimer = Observable.timer( 2000 ).subscribe( t => {
        this.spinnerService.activate();

        let temp = this.buildingForm.get( "address" ).value;
        let address = temp.country + " " + temp.state + " " + temp.city + " " + temp.street + " " + temp.number + " " + temp.zipCode;

        this.appService.getGeolocation( address ).subscribe( result => {
          this.spinnerService.desactivate();
          if ( result.results[ 0 ] ) {
            console.log( "MAPS" );
            console.log( result.results[ 0 ] );
            this.location_name = result.results[ 0 ]?.formatted_address;
            this.location_lat = result.results[ 0 ]?.geometry.location.lat;
            this.location_long = result.results[ 0 ]?.geometry.location.lng;

            this.buildingForm.get( "address" ).get( "lat" ).setValue( this.location_lat );
            this.buildingForm.get( "address" ).get( "lng" ).setValue( this.location_long );
          }

        } );
      } );
    }
    // this.getHolidays(this.buildingForm.get("address").get("country").value);
  }

  public onChange( value: any ) {
    // this.ngModelChange.emit(value);
  }

  public doubleClick( event ) {
    this.spinnerService.activate();
    this.appService.getGeolocation( event.coords.lat + " " + event.coords.lng ).subscribe( result => {
      this.spinnerService.desactivate();
      console.log( "MAPS" );
      // Parar quando encontrar um objeto que contenha street_number
      let street_number: any;
      let postal: any;
      let use = 0;
      let cont = 0;
      result.results.map( x => {
        let checkout = x.address_components;
        checkout.find( t => {
          if ( t.types.find( t => t == "street_number" ) && !street_number ) street_number = t;
          if ( t.types.find( t => t == "postal_code" ) && !postal ) postal = t;
        } );

        cont++;
      } );

      if ( !street_number ) {
        street_number = "";
      }
      if ( !postal ) {
        postal = "";
      }
      console.log( "Street Numebr!" );
      console.log( street_number.short_name );
      console.log( result.results[ 0 ] );

      this.location_name = result.results[ 0 ].formatted_address;
      this.location_lat = result.results[ 0 ].geometry.location.lat;
      this.location_long = result.results[ 0 ].geometry.location.lng;

      this.buildingForm.get( "address" ).get( "lat" ).setValue( this.location_lat );
      this.buildingForm.get( "address" ).get( "lng" ).setValue( this.location_long );

      // console.log(result.results.find(x => {
      //   if (x.types.find(t => t == "country")) return true;
      // }));
      // this.buildingForm.get("address").get("country").setValue(result.results.find(x => {
      //   if (x.types.find(t => t == "country")) return true;
      // }).address_components[0].short_name);

      let r = result.results[ 0 ].address_components;

      //   let postal = r.find(t => {
      //     if (t.types.find(x => x == "postal_code")) return true;
      //   })
      //   let street_number = r.find(t => {
      //     if (t.types.find(x => x == "street_number")) return true;
      //   });
      this.buildingForm.get( "address" ).get( "zipCode" ).setValue( postal ? postal.long_name : "" );
      let country = r.find( t => {
        if ( t.types.find( x => x == "country" ) ) {
          if ( t.short_name != "GB" ) {
            this.getHolidays( t.short_name );
          }
          return true;
        }
      } );
      let subcountry = r.find( t => {
        // console.log(t.types);
        if ( country.short_name == "GB" ) {
          if ( ( t.types.includes( "administrative_area_level_1" ) && t.types.includes( "political" ) ) ) {
            let i = 0;
            for ( i = 0; i < this.subCountrys.length; i++ ) {
              if ( t.long_name == this.subCountrys[ i ].name ) {

                this.buildingForm.get( "address" ).get( "subcountry" ).setValue( this.subCountrys[ i ].code );
                this.getHolidays( "GB" );
                console.log( this.subCountrys[ i ].code );
                return this.subCountrys[ i ].code;
              }
              // console.log(this.subCountrys[i].name);
            }
            // console.log(t.long_name);
          }
        } else {
          return "";
        }
        console.log( subcountry );
        this.buildingForm.get( "address" ).get( "subcountry" ).setValue( subcountry );

        //   if((t.types.includes("administrative_area_level_4") && t.types.includes("political"))  ||
        // (t.types.includes("administrative_area_level_2") && t.types.includes("political") ||
        // (t.types.includes("administrative_area_level_2") && t.types.includes("postal_town")))) return true;
        // if((t.types.includes("postal_town"))) return true;

        // if (t.types.find(x => x == "administrative_area_level_2")) return true;
        // if (t.types.find(x => x == "political" || x == "postal_town" || x == "administrative_area_level_2")) return true;

      } );
      //    this.buildingForm.get("address").get("subcountry").setValue(subcountry ? city.long_name : "")
      // let neighborhood = r.find(t => {
      //     if(t.types.includes("neighborhood") && t.types.includes("political") ) return true;
      // });
      // sublocallity_level_1 goes | address street
      let city: any;
      let finish = false;
      let address_complement: any;

      r.find( t => {
        if ( !finish ) {
          console.log( t.types );
          if ( t.types.includes( "postal_town" ) ) {
            finish = true;
            city = t;
            address_complement = true;
          } else {
            if ( ( t.types.includes( "administrative_area_level_2" ) && t.types.includes( "political" ) ) ) {
              console.log( "get administrative_area_level_2" );
              city = t;
            }
            if ( t.types.includes( "locality" ) && t.types.includes( "political" ) ) {
              // sublocality_level_1 or any goes , address street
              address_complement = true;
              console.log( "get locality" );
              city = t;
            }
            if ( ( !t.types.includes( "locality" ) ) &&
              ( t.types.includes( "neighborhood" ) && t.types.includes( "political" ) || t.types.includes( "sublocality" ) ||
                t.types.includes( "sublocality_level_1" ) ) ) {
              console.log( "get neighborhood" );
              city = t;
            }

          }
          //   if((t.types.includes("administrative_area_level_4") && t.types.includes("political"))  ||
          // (t.types.includes("administrative_area_level_2") && t.types.includes("political") ||
          // (t.types.includes("administrative_area_level_2") && t.types.includes("postal_town")))) return true;
          // if((t.types.includes("postal_town"))) return true;

          // if (t.types.find(x => x == "administrative_area_level_2")) return true;
          // if (t.types.find(x => x == "political" || x == "postal_town" || x == "administrative_area_level_2")) return true;
        }
      } );

      if ( address_complement === true ) {
        console.log( "comeplement!" );
        r.find( t => {
          if ( ( t.types.includes( "neighborhood" ) || t.types.includes( "sublocality" ) || t.types.includes( "sublocality_level_1" ) ) ) {
            address_complement = ", " + t.long_name;
          }
        } );
      }
      if ( address_complement === true ) {
        address_complement = "";
      }
      if ( !address_complement ) {
        address_complement = "";
      }
      console.log( city );
      this.buildingForm.get( "address" ).get( "city" ).setValue( city ? city.long_name : "" );

      this.country = country ? country.short_name : "";
      this.buildingForm.get( "address" ).get( "country" ).setValue( country ? country.short_name : "" );

      let route = r.find( t => {
        if ( t.types.find( x => x == "route" ) ) return true;
      } );
      this.buildingForm.get( "address" ).get( "street" ).setValue( route ? route.short_name + address_complement : "" );

      this.buildingForm.get( "address" ).get( "number" ).setValue( street_number ? street_number.short_name : "" );

      let state = r.find( t => {
        if ( this.country ) {
          if ( country.short_name == "GB" ) {
            if ( ( t.types.includes( "administrative_area_level_2" ) && t.types.includes( "political" ) ) ) return true;
          } else {
            if ( ( t.types.includes( "administrative_area_level_1" ) && t.types.includes( "political" ) ) ) return true;

          }
        }
        // if (t.types.find(x => x == "administrative_area_level_2")) return true;
      } );
      this.buildingForm.get( "address" ).get( "state" ).setValue( state ? state.long_name : "" );
    } );
  }

  public location_name: string = "";
  public location_lat: number = 51.5074086;
  public location_long: number = -0.128555;

  public buildingForm: FormGroup = new FormGroup( {
    name: new FormControl( "", [ Validators.required ] ),
    timeZone: new FormControl( "", [ Validators.required ] ),
    butterWorthRecoveryMethod: new FormControl( 1, Validators.required ),
    address: new FormGroup( {
      complement: new FormControl( "" ),
      lat: new FormControl( null ),
      lng: new FormControl( null ),
      zipCode: new FormControl( null, [ Validators.required ] ),
      street: new FormControl( null, [ Validators.required ] ),
      number: new FormControl( null, [ Validators.required ] ),
      state: new FormControl( null, [ Validators.required ] ),
      city: new FormControl( null, [ Validators.required ] ),
      country: new FormControl( null, [ Validators.required ] ),
      subcountry: new FormControl( "GB-SCT" )

    } ),
    workingHours: new FormGroup( {
      workingday1opened: new FormControl( true ),
      workingday1opentime: new FormControl( "09:00" ),
      workingday1closetime: new FormControl( "17:00" ),

      workingday2opened: new FormControl( true ),
      workingday2opentime: new FormControl( "09:00" ),
      workingday2closetime: new FormControl( "17:00" ),

      workingday3opened: new FormControl( true ),
      workingday3opentime: new FormControl( "09:00" ),
      workingday3closetime: new FormControl( "17:00" ),

      workingday4opened: new FormControl( true ),
      workingday4opentime: new FormControl( "09:00" ),
      workingday4closetime: new FormControl( "17:00" ),

      workingday5opened: new FormControl( true ),
      workingday5opentime: new FormControl( "09:00" ),
      workingday5closetime: new FormControl( "17:00" ),

      workingday6opened: new FormControl(),
      workingday6opentime: new FormControl( "09:00" ),
      workingday6closetime: new FormControl( "17:00" ),

      workingday7opened: new FormControl(),
      workingday7opentime: new FormControl( "09:00" ),
      workingday7closetime: new FormControl( "17:00" )

    } ),
    // numberFloors: new FormControl(null),
    // totalFloorSpace: new FormControl(null),
    floorBreakdown: new FormGroup( {
      numberFloors: new FormControl( 1 ),
      totalFloorSpace: new FormControl( 1 ),
      floorArea: new FormArray( [] )
    } ),
    ServiceLevelAgreements: new FormArray( [] ),
    photo: new FormControl(),
    description: new FormControl( "" ),
    contact: new FormGroup( {
      emails: new FormArray( [] ),
      telephones: new FormArray( [] )
    } )

  } );

  public selected: "09:00";

  ngOnChanges( changes: SimpleChanges ) {
    // console.log("mudou");
    // console.log(changes);
    if ( this?.building ) {
      if ( this?.building?.workingHours && this?.building?.floorBreakdown ) {
        // console.log(this.building?.workingHours[4]?.openTime === null);
        // console.log(typeof(this.building?.workingHours[1]?.openTime));
        if ( typeof ( this.building?.workingHours[ 1 ]?.openTime ) != "string" ) {
          let i = 0;
          for ( i = 1; i <= 7; i++ ) {
            if ( !this?.building?.workingHours ) {
              this.building.workingHours = [];
            }
            if ( typeof ( this.building?.workingHours[ i ]?.openTime ) != "string" ) {
              if ( !this?.building?.workingHours[ i ] ) {
                this.building.workingHours[ i ] = [];
              }
              this.building.workingHours[ i ].openTime = "09:00";
            }
            if ( typeof ( this.building?.workingHours[ i ]?.closeTime ) != "string" ) {
              this.building.workingHours[ i ].closeTime = "17:00";
            }
          }
          for ( i = 1; i <= 5; i++ ) {
            if ( i > 5 ) {
              this.building.workingHours[ i ].opened = false;
            } else {
              this.building.workingHours[ i ].opened = true;
            }

          }
        }

        this.buildingForm = new FormGroup( {
          name: new FormControl( { value: this.building.name, disabled: !this.enabledEdit }, [ Validators.required ] ),
          timeZone: new FormControl( { value: this.building.timeZone, disabled: !this.enabledEdit }, [ Validators.required ] ),
          butterWorthRecoveryMethod: new FormControl( { value: this.building.butterWorthRecoveryMethod || 1, disabled: !this.enabledEdit },
            Validators.required ),
          address: new FormGroup( {
            complement: new FormControl( { value: this.building.address.complement, disabled: !this.enabledEdit } ),
            lat: new FormControl( { value: this.building.address.lat, disabled: !this.enabledEdit } ),
            lng: new FormControl( { value: this.building.address.lng, disabled: !this.enabledEdit } ),
            zipCode: new FormControl( { value: this.building.address.zipCode, disabled: !this.enabledEdit }, [ Validators.required ] ),
            street: new FormControl( { value: this.building.address.street, disabled: !this.enabledEdit }, [ Validators.required ] ),
            number: new FormControl( { value: this.building.address.number, disabled: !this.enabledEdit }, [ Validators.required ] ),
            state: new FormControl( { value: this.building.address.state, disabled: !this.enabledEdit }, [ Validators.required ] ),
            city: new FormControl( { value: this.building.address.city, disabled: !this.enabledEdit }, [ Validators.required ] ),
            country: new FormControl( { value: this.building.address.country, disabled: !this.enabledEdit }, [ Validators.required ] ),
            subcountry: new FormControl(
              { value: this.building?.address?.subcountry ? this.building?.address?.subcountry : "GB-SCT", disabled: !this.enabledEdit } )

          } ),
          workingHours: new FormGroup( {

            workingday1opened: new FormControl(
              { value: this.building?.workingHours[ 1 ]?.opened == true ? true : false, disabled: !this.enabledEdit } ),
            workingday1opentime: new FormControl( { value: this.building?.workingHours[ 1 ]?.openTime, disabled: !this.enabledEdit } ),
            workingday1closetime: new FormControl( { value: this.building?.workingHours[ 1 ]?.closeTime, disabled: !this.enabledEdit } ),

            workingday2opened: new FormControl(
              { value: this.building?.workingHours[ 2 ]?.opened == true ? true : false, disabled: !this.enabledEdit } ),
            workingday2opentime: new FormControl( { value: this.building.workingHours[ 2 ].openTime, disabled: !this.enabledEdit } ),
            workingday2closetime: new FormControl( { value: this.building.workingHours[ 2 ].closeTime, disabled: !this.enabledEdit } ),

            workingday3opened: new FormControl(
              { value: this.building?.workingHours[ 3 ]?.opened == true ? true : false, disabled: !this.enabledEdit } ),
            workingday3opentime: new FormControl( { value: this.building.workingHours[ 3 ].openTime, disabled: !this.enabledEdit } ),
            workingday3closetime: new FormControl( { value: this.building.workingHours[ 3 ].closeTime, disabled: !this.enabledEdit } ),

            workingday4opened: new FormControl(
              { value: this.building?.workingHours[ 4 ]?.opened == true ? true : false, disabled: !this.enabledEdit } ),
            workingday4opentime: new FormControl( { value: this.building.workingHours[ 4 ].openTime, disabled: !this.enabledEdit } ),
            workingday4closetime: new FormControl( { value: this.building.workingHours[ 4 ].closeTime, disabled: !this.enabledEdit } ),

            workingday5opened: new FormControl(
              { value: this.building?.workingHours[ 5 ]?.opened == true ? true : false, disabled: !this.enabledEdit } ),
            workingday5opentime: new FormControl( { value: this.building.workingHours[ 5 ].openTime, disabled: !this.enabledEdit } ),
            workingday5closetime: new FormControl( { value: this.building.workingHours[ 5 ].closeTime, disabled: !this.enabledEdit } ),

            workingday6opened: new FormControl(
              { value: this.building?.workingHours[ 6 ]?.opened == true ? true : false, disabled: !this.enabledEdit } ),
            workingday6opentime: new FormControl( { value: this.building.workingHours[ 6 ].openTime, disabled: !this.enabledEdit } ),
            workingday6closetime: new FormControl( { value: this.building.workingHours[ 6 ].closeTime, disabled: !this.enabledEdit } ),

            workingday7opened: new FormControl(
              { value: this.building?.workingHours[ 7 ]?.opened == true ? true : false, disabled: !this.enabledEdit } ),
            workingday7opentime: new FormControl( { value: this.building.workingHours[ 7 ].openTime, disabled: !this.enabledEdit } ),
            workingday7closetime: new FormControl( { value: this.building.workingHours[ 7 ].closeTime, disabled: !this.enabledEdit } )

          } ),
          floorBreakdown: new FormGroup( {
            numberFloors: new FormControl( { value: this.building?.floorBreakdown?.numberFloors, disabled: !this.enabledEdit } ),
            totalFloorSpace: new FormControl( { value: this.building?.floorBreakdown?.totalFloorSpace, disabled: !this.enabledEdit } ),
            floorArea: new FormControl( {} )
          } ),
          ServiceLevelAgreements: new FormArray( [] ),
          photo: new FormControl(),
          description: new FormControl( { value: this.building.description, disabled: !this.enabledEdit } ),
          contact: new FormGroup( {
            emails: this.building.contacts?.emails?.map ? new FormArray( this.building.contacts?.emails?.map( x => {
              return new FormControl( x, [ Validators.required, Validators.email ] );
            } ) ) : new FormArray( [] ),
            telephones: this.building.contacts?.telephones?.map ? new FormArray( this.building.contacts.telephones.map( x => {
              return new FormGroup( {
                number: new FormControl( x.number, [ Validators.required ] ),
                internalExtension: new FormControl( x.internalExtension ),
                type: new FormControl( x.type, Validators.required )
              } );
            } ) ) : new FormArray( [] )
          } )
        } );
        if ( this.building?.floorBreakdown?.numberFloors > 0 ) {
          this.numberFloorsNg = this.building?.floorBreakdown?.numberFloors;
          this.totalFloorSpaceNg = this.building?.floorBreakdown?.totalFloorSpace;
          if ( this.building?.floorBreakdown?.floorSpaces?.Are ) {
            this.populateFloors( this.building?.floorBreakdown?.floorSpaces?.Are );
          }
        } else {
          this.numberFloorsNg = 1;
          this.totalFloorSpaceNg = 1;
          this.floorsDetailed.push( {
            "floor": 0,
            "area": 0
          } );
          this.buildingForm.get( "floorBreakdown" ).patchValue( { "numberFloors": this.numberFloorsNg } );
          this.buildingForm.get( "floorBreakdown" ).patchValue( { "totalFloorSpace": this.floorsDetailed } );
          this.buildingForm.get( "floorBreakdown" ).patchValue( { "floorArea": this.totalFloorSpaceNg } );

        }
        if ( this.building?.ServiceLevelAgreements ) {
          this.serviceLevelAgreements = this.building?.ServiceLevelAgreements;
        }
        if ( this.building?.address?.country ) {
          this.getHolidays( this.building?.address?.country );
        }
        // Populate Floors

      } else {
        let i = 0;
        this.building.workingHours = [];
        for ( i = 1; i <= 7; i++ ) {
          this.building.workingHours[ i ] = {};
          this.building.workingHours[ i ].openTime = "09:00";
          this.building.workingHours[ i ].closeTime = "17:00";
        }
        for ( i = 1; i <= 5; i++ ) {
          this.building.workingHours[ i ].opened = true;
        }

        this.buildingForm = new FormGroup( {
          name: new FormControl( { value: this.building.name, disabled: !this.enabledEdit }, [ Validators.required ] ),
          timeZone: new FormControl( { value: this.building.timeZone, disabled: !this.enabledEdit }, [ Validators.required ] ),
          butterWorthRecoveryMethod: new FormControl( { value: this.building.butterWorthRecoveryMethod || 1, disabled: !this.enabledEdit },
            Validators.required ),
          address: new FormGroup( {
            complement: new FormControl( { value: this.building.address.complement, disabled: !this.enabledEdit } ),
            lat: new FormControl( { value: this.building.address.lat, disabled: !this.enabledEdit } ),
            lng: new FormControl( { value: this.building.address.lng, disabled: !this.enabledEdit } ),
            zipCode: new FormControl( { value: this.building.address.zipCode, disabled: !this.enabledEdit }, [ Validators.required ] ),
            street: new FormControl( { value: this.building.address.street, disabled: !this.enabledEdit }, [ Validators.required ] ),
            number: new FormControl( { value: this.building.address.number, disabled: !this.enabledEdit }, [ Validators.required ] ),
            state: new FormControl( { value: this.building.address.state, disabled: !this.enabledEdit }, [ Validators.required ] ),
            city: new FormControl( { value: this.building.address.city, disabled: !this.enabledEdit }, [ Validators.required ] ),
            country: new FormControl( { value: this.building.address.country, disabled: !this.enabledEdit }, [ Validators.required ] ),
            subcountry: new FormControl( { value: this.building?.address?.subcountry ? this.building?.address?.subcountry : "GB-SCT" } )

          } ),
          workingHours: new FormGroup( {
            workingday1opened: new FormControl( { value: this.building?.workingHours[ 1 ]?.opened, disabled: !this.enabledEdit } ),
            workingday1opentime: new FormControl( { value: this.building?.workingHours[ 1 ]?.openTime, disabled: !this.enabledEdit } ),
            workingday1closetime: new FormControl( { value: this.building?.workingHours[ 1 ]?.closeTime, disabled: !this.enabledEdit } ),

            workingday2opened: new FormControl( { value: this.building.workingHours[ 2 ].opened, disabled: !this.enabledEdit } ),
            workingday2opentime: new FormControl( { value: this.building.workingHours[ 2 ].openTime, disabled: !this.enabledEdit } ),
            workingday2closetime: new FormControl( { value: this.building.workingHours[ 2 ].closeTime, disabled: !this.enabledEdit } ),

            workingday3opened: new FormControl( { value: this.building.workingHours[ 3 ].opened, disabled: !this.enabledEdit } ),
            workingday3opentime: new FormControl( { value: this.building.workingHours[ 3 ].openTime, disabled: !this.enabledEdit } ),
            workingday3closetime: new FormControl( { value: this.building.workingHours[ 3 ].closeTime, disabled: !this.enabledEdit } ),

            workingday4opened: new FormControl( { value: this.building.workingHours[ 4 ].opened, disabled: !this.enabledEdit } ),
            workingday4opentime: new FormControl( { value: this.building.workingHours[ 4 ].openTime, disabled: !this.enabledEdit } ),
            workingday4closetime: new FormControl( { value: this.building.workingHours[ 4 ].closeTime, disabled: !this.enabledEdit } ),

            workingday5opened: new FormControl( { value: this.building.workingHours[ 5 ].opened, disabled: !this.enabledEdit } ),
            workingday5opentime: new FormControl( { value: this.building.workingHours[ 5 ].openTime, disabled: !this.enabledEdit } ),
            workingday5closetime: new FormControl( { value: this.building.workingHours[ 5 ].closeTime, disabled: !this.enabledEdit } ),

            workingday6opened: new FormControl( { value: this.building.workingHours[ 6 ].opened, disabled: !this.enabledEdit } ),
            workingday6opentime: new FormControl( { value: this.building.workingHours[ 6 ].openTime, disabled: !this.enabledEdit } ),
            workingday6closetime: new FormControl( { value: this.building.workingHours[ 6 ].closeTime, disabled: !this.enabledEdit } ),

            workingday7opened: new FormControl( { value: this.building.workingHours[ 7 ].opened, disabled: !this.enabledEdit } ),
            workingday7opentime: new FormControl( { value: this.building.workingHours[ 7 ].openTime, disabled: !this.enabledEdit } ),
            workingday7closetime: new FormControl( { value: this.building.workingHours[ 7 ].closeTime, disabled: !this.enabledEdit } )
          } ),
          // numberFloors: new FormControl(null),
          // totalFloorSpace: new FormControl(null),
          floorBreakdown: new FormGroup( {
            numberFloors: new FormControl( "", { updateOn: "blur" } ),
            totalFloorSpace: new FormControl( null ),
            floorArea: new FormControl( {} )

          } ),
          ServiceLevelAgreements: new FormArray( this.building?.ServiceLevelAgreements ),
          photo: new FormControl(),
          description: new FormControl( { value: this.building.description, disabled: !this.enabledEdit } ),
          contact: new FormGroup( {
            emails: this.building.contacts?.emails?.map ? new FormArray( this.building.contacts?.emails?.map( x => {
              return new FormControl( x, [ Validators.required, Validators.email ] );
            } ) ) : new FormArray( [] ),
            telephones: this.building.contacts?.telephones?.map ? new FormArray( this.building.contacts.telephones.map( x => {
              return new FormGroup( {
                number: new FormControl( x.number, [ Validators.required ] ),
                internalExtension: new FormControl( x.internalExtension ),
                type: new FormControl( x.type, Validators.required )
              } );
            } ) ) : new FormArray( [] )
          } )
        } );
        this.numberFloorsNg = 1;
        this.floorsDetailed = [];
        this.floorsDetailed.push( {
          "floor": 0,
          "area": 0
        } );

      }
      if ( this.building?.address?.country ) {
        this.country = this.building.address.country;

      }

      if ( this.serviceLevelAgreementsSelected == null ) {
        this.serviceLevelAgreementsSelected = 0;
      }
    }
  }

  private _filterCountries( value: string ): string[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter( x => x.toLowerCase().indexOf( filterValue ) >= 0 );
  }

  public countries: any[] = [];

  public types: { key: string, value: string }[] = [];

  ngOnInit() {
    this.numberFloorsNg = 1;
    this.totalFloorSpaceNg = 1;
    this.floorsDetailed.push( {
      "floor": 0,
      "area": 0
    } );

    // this.getHolidays();
    this.building?.floorBreakdown?.numberFloors.registerOnChange( () => {
      console.log( "Change" );
    } );
    // console.log(this.workingDays);

    this.countries = moment.tz.names();

    this.countriesFiltered = this.buildingForm.get( "timeZone" ).valueChanges.pipe(
      startWith( "" ),
      map( value => this._filterCountries( value ) )
    );

    for ( let value in PhoneType ) {
      this.types.push( { key: PhoneType[ value ], value: PhoneType[ value ] } );
    }
  }

  public imagePath: any;
  public imgURL: any = null;

  onDrop( event ) {
    this.onChangeFile( event.dataTransfer.files );
    event.preventDefault();
  }

  onDragOver( event ) {
    event.stopPropagation();
    event.preventDefault();
  }

  public removePhoto() {
    this.buildingForm.get( "photo" ).setValue( "" );
    this.imagePath = null;
    this.imgURL = null;
  }

  public onChangeFile( files ) {
    // console.log(files);
    if ( files.length === 0 ) {
      return;
    }

    var mimeType = files[ 0 ].type;
    if ( mimeType.match( /image\/*/ ) == null ) {
      // this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL( files[ 0 ] );
    reader.onload = ( _event ) => {
      this.imgURL = reader.result;
    };

    this.buildingForm.get( "photo" ).setValue( files[ 0 ] );

    // console.log(this.buildingForm.value);
  }

  public addContactEmail() {
    ( <FormArray>( <FormGroup>this.buildingForm.controls.contact ).controls.emails ).push(
      new FormControl( "", [ Validators.required, Validators.email ] )
    );
  }

  public addContactPhone() {
    ( <FormArray>( <FormGroup>this.buildingForm.controls.contact ).controls.telephones ).push(
      new FormGroup( {
        number: new FormControl( "", [ Validators.required ] ),
        internalExtension: new FormControl( "" ),
        type: new FormControl( "", Validators.required )
      } )
    );
  }

  public removeContactEmail( pos ) {
    ( <FormArray>( <FormGroup>this.buildingForm.controls.contact ).controls.emails ).removeAt( pos );
  }

  public removeContactPhone( pos ) {
    ( <FormArray>( <FormGroup>this.buildingForm.controls.contact ).controls.telephones ).removeAt( pos );
  }

  public goBack() {
    this._location.back();
  }

  public onSubmit() {
    if ( this.buildingForm.valid ) {
      // this.buildingForm.get( "ServiceLevelAgreements" ).setValue( this.serviceLevelAgreements );
      const serviceLevelArray = this.buildingForm.get("ServiceLevelAgreements") as FormArray;
      serviceLevelArray.patchValue(this.serviceLevelAgreements);
    }

    this.onsubmit.emit( this.buildingForm );
  }

  public reset() {
    this.buildingForm.reset( {} );
  }

  get contacts(): FormGroup {
    return <FormGroup>this.buildingForm.get( "contact" );
  }

  public updateFloors( event ) {
    if ( event.target.value <= 0 ) {
      this.numberFloorsNg = 1;
    } else {
      this.numberFloorsNg = event.target.value;
    }

    //   console.log(this.numberFloorsNg);
  }

  public populateFloors( floors ) {
    this.floorsDetailed = floors;
    console.log( "Populate floors comming" );
    console.log( floors );
    var i = 0;
    let totalArea = 0;

    for ( let floor of this.floorsDetailed ) {
      totalArea += floor.area;
    }
    this.totalFloorSpaceNg = totalArea;

    this.appendForm();

  }

  public isNumberKey( evt, permit = false, floors = false ) {

    console.log( evt.target.value );
    //   if(evt.target.value == 0){
    //     this.numberFloorsNg = 1;
    //   }
    var e = evt || window.event; //window.event is safer, thanks @ThiefMaster
    var charCode = e.which || e.keyCode;
    console.log( charCode );
    if ( charCode == 13 ) {
      return false;
    }
    if ( floors === true && ( charCode == 46 || charCode == 190 || charCode == 110 ) ) {
      return false;
    }
    // if (charCode > 31 && (charCode < 47 || charCode > 57) && (charCode <37 && charCode >40 && charCode != 46)){
    // if (charCode > 31 && (charCode < 47 || charCode > 57) && (charCode < 96 || charCode > 105)){
    if ( !this.numberPermitKeys.includes( charCode ) ) {

      console.log( "nao exibir" );
      evt.preventDefault;

      return false;
    }

    if ( e.shiftKey ) return false;
    // if(evt.target.value <= 0 && evt.target.value != ""){
    //     evt.target.value = 1;
    //     return false;
    // }
    if ( permit === false ) {
      if ( evt.target.value > 200 && evt.target.value != "" ) {
        evt.target.value = 200;
        return false;
        evt.preventDefault;
      }
    } else {
      if ( evt.target.value < 0 ) {
        console.log( "Entrou na condicao" );
        console.log( evt.target.value );
        evt.target.value = 0;
        // let totalArea = 0;
        // console.log(this.floorsDetailed);
        // for(let item of this.floorsDetailed){
        //     console.log("Loop: " + item.area);
        //     totalArea += item.area;
        // }
        evt.preventDefault;
        return false;

      }
    }
    // this.appendForm2(evt);

    console.log( "this.numberFloorsNg: " + this.numberFloorsNg );

    return true;
  }

  public PreonSubmit( evt ) {
    //  console.log(evt);
    var e = evt || window.event; //window.event is safer, thanks @ThiefMaster
    var charCode = e.which || e.keyCode;
    // console.log(charCode);    
    if ( charCode == 13 ) {
      return false;
    }
    this.onSubmit();
  }

  public blockEnter( evt ) {
    var e = evt || window.event; //window.event is safer, thanks @ThiefMaster
    var charCode = e.which || e.keyCode;
    // console.log(charCode);    
    if ( charCode == 13 ) {
      return false;
    }
  }

  public appendForm2( event ) {
    let valorSeg = 1;

    if ( event.target.value <= 0 && event.target.value != "" ) {
      console.log( "mudei pra 1 o valor " + event.target.value );
      event.target.value = "";
      event.target.value = 1;
      this.numberFloorsNg = 1;
      this.numberFloorsNg = 1;
      this.numberFloorsNg = 1;
      this.numberFloorsNg = 1;
      // this.buildingForm.markAsDirty();
      // this.buildingForm.markAsTouched();

      // this.ngModelChange.emit(1);
      // event.target.focus();
      // event.target.blur();
      // event.target.focus();
      event.target.dispatchEvent( new Event( "change" ) );
      console.log( this.numberFloorsNg );
      // event.target.blur();
      // event.target.value = 1;

    } else {
      valorSeg = event.target.value;
    }

    if ( event.target.value != "" ) {
      this.numberFloorsNg = event.target.value;
    }

    this.buildingForm.get( "floorBreakdown" ).patchValue( { "numberFloors": valorSeg } );
    // console.log(this.buildingForm);
    console.log( "Chamada de: " + valorSeg );
    this.appendForm( valorSeg );
    console.log( "this.numberFloorsNg: " + this.numberFloorsNg );

  }

  public appendForm( loop = 0 ) {
    var i = 0;
    console.log( "appendForm" );
    var elements = document.querySelectorAll( ".campo" );
    if ( loop == 0 ) {
      loop = this.numberFloorsNg;
    }

    if ( loop <= 0 ) {
      loop = 1;
    }
    if ( loop < this.floorsDetailed.length ) {
      this.floorsDetailed.splice( loop );
    } else if ( loop > this.floorsDetailed.length ) {
      for ( i = this.floorsDetailed.length; i < loop; i++ ) {
        console.log( "Running floorsDetailed" );
        this.floorsDetailed.push( {
          "floor": i,
          "area": 0
        } );
      }
    }
    let totalArea = 0;
    console.log( this.floorsDetailed );
    for ( let item of this.floorsDetailed ) {
      console.log( "Loop: " + item.area );
      totalArea += parseFloat( item.area );
    }
    console.log( "totalArea: " );
    console.log( totalArea );
    console.log( this.floorsDetailed );
    this.totalFloorSpaceNg = totalArea;
    this.buildingForm.get( "floorBreakdown" ).patchValue( { "totalFloorSpace": totalArea } );

    //   console.log(elements);
    //   var i = 0;
    //   let totalArea = 0;
    //   for(i=0; i<loop; i++){
    //       let contagem = 0;
    //       if(i == 0){
    //         contagem = i;
    //       }
    //       else {
    //           contagem = i*2;
    //       }
    //       if(elements[contagem]?.id){
    //         this.floorsDetailed.push({
    //             'floor': (<HTMLInputElement>document.getElementById(elements[contagem].id)).value ?
    // (<HTMLInputElement>document.getElementById(elements[contagem].id)).value : 1, 'area':
    // (<HTMLInputElement>document.getElementById(elements[contagem+1].id)).value ?
    // (<HTMLInputElement>document.getElementById(elements[contagem+1].id)).value : 1 }); //   console.log(totalArea);
    // if(parseFloat((<HTMLInputElement>document.getElementById(elements[contagem+1].id)).value) > 0){ totalArea =
    // totalArea+parseFloat((<HTMLInputElement>document.getElementById(elements[contagem+1].id)).value); } } } console.log(totalArea);
    // this.totalFloorSpaceNg = totalArea; console.log(totalArea); console.log(this.floorsDetailed);
    // this.buildingForm.get("floorBreakdown").patchValue({ 'floorArea': this.floorsDetailed

    //   });

    //   ((this.buildingForm.get("floorBreakdown") as FormGroup).get("floorArea") as FormArray).patchValue(
    //       this.floorsDetailed

    //   );

    this.buildingForm.get( "floorBreakdown" ).patchValue( { "floorArea": this.floorsDetailed } );
    // ((this.buildingForm.get("floorBreakdown") as FormGroup).get("floorArea") as FormGroup).setValue(
    //     this.floorsDetailed

    //     );

    //   console.log(this.buildingForm);

    //   var id = elements[0].id;
    //   console.log((<HTMLInputElement>document.getElementById(id)).value);
    //   console.log(getProperty(elements, 0));
    //   console.log(elements[0].getProperty("value"));

    //   console.log(this.floorsDetailed[fieldNumber]);
    // //   if(this.floorsDetailed[fieldNumber]){

    // //   }
    // //   else {
    // //   }
    //   console.log(this.floorsDetailed[fieldNumber]);
    //   this.floorsDetailed.push({'teste':fieldNumber});  
    //     // this.floors[fieldNumber][ 'value'] = "50";
    //   console.log(this.floorsDetailed);
    //   console.log(event);
    //   let value = event.target.value;
    //   console.log(value);
    //   console.log(event.target.parentNode);
    //   let element_id = event.target.id;
    // ((this.buildingForm.get("floorBreakdown") as FormGroup).get("floorArea") as FormGroup).removeControl(fieldNumber);
    // ((this.buildingForm.get("floorBreakdown") as FormGroup).get("floorArea") as FormGroup).addControl(fieldNumber, new
    // FormControl(value)); // let form = this.buildingForm.get("floorBreakdown") as FormGroup; // form.addControl(); // console.log(form);
    // console.log(this.buildingForm.get("floorBreakdown"));
    console.log( "this.numberFloorsNg: " + this.numberFloorsNg );
    console.log( this.floorsDetailed );
    console.log( this.buildingForm.get( "floorBreakdown" ) );

  }

  public populateAll( event, type ) {
    let value = event.value;
    var confirmation = confirm( "Do you want to apply the same time for every day of the week?" );
    if ( confirmation ) {
      // this.buildingForm.patchValue({
      //   workingHours['workingday2opentime']: '20:00'
      // });
      if ( type == "open" ) {
        this.buildingForm.get( "workingHours" ).patchValue( {
          workingday2opentime: value,
          workingday3opentime: value,
          workingday4opentime: value,
          workingday5opentime: value,
          workingday6opentime: value,
          workingday7opentime: value
        } );
      } else {
        this.buildingForm.get( "workingHours" ).patchValue( {
          workingday2closetime: value,
          workingday3closetime: value,
          workingday4closetime: value,
          workingday5closetime: value,
          workingday6closetime: value,
          workingday7closetime: value
        } );
      }
    } else {
    }
  }

  public newServiceLevelAgreement() {
    this.serviceLevelAgreements.push(
      {
        "name": "New Group",
        "contents": [
          { "name": "", "type": "", "working_hours_min": 0, "working_hours_max": 0, "non_working_hours_min": 0, "non_working_hours_max": 0 }
        ]
      } );

    let cont = this.serviceLevelAgreements.length;
    this.serviceLevelAgreementsSelected = cont - 1;
  }

  public newServiceLevelAgreementContent( index ) {
    console.log( index );
    this.serviceLevelAgreements[ index ].contents.push(
      { "name": "", "type": "", "working_hours_min": 0, "working_hours_max": 0, "non_working_hours_min": 0, "non_working_hours_max": 0 }
    );
  }

  public serviceLevelAgreementRemoveProperty( property, group ) {
    if ( this.serviceLevelAgreements[ group ].contents.length > 1 ) {

      this.serviceLevelAgreements[ group ].contents[ property ].type = null;
      // delete this.serviceLevelAgreements[group].contents[property];
      this.serviceLevelAgreements[ group ].contents.splice( property, 1 );
    } else {
      this.serviceLevelAgreements[ group ].contents[ property ].name = null;
      this.serviceLevelAgreements[ group ].contents[ property ].type = null;
      this.serviceLevelAgreements[ group ].contents[ property ].working_hours_min = null;
      this.serviceLevelAgreements[ group ].contents[ property ].working_hours_max = null;
      this.serviceLevelAgreements[ group ].contents[ property ].non_working_hours_min = null;
      this.serviceLevelAgreements[ group ].contents[ property ].non_working_hours_max = null;
    }

  }

  public serviceLevelAgreementRemove( group ) {
    // this.serviceLevelAgreements[group].contents[property].type = null;
    // delete this.serviceLevelAgreements[group].contents[property];
    this.serviceLevelAgreements.splice( group, 1 );
    let cont = this.serviceLevelAgreements.length;
    this.serviceLevelAgreementsSelected = cont - 1;

  }

}
