import { Component, OnInit } from '@angular/core';
import { BrowserModule, } from '@angular/platform-browser';
import { CommonModule, } from '@angular/common';

import { HttpClient } from '@angular/common/http';
import { BaseService, IServiceError } from "app/services/base.service";
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { BuildingService } from 'app/services/building.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { FutureDecisionsDate } from "app/components/classes/futuredecisions.date";
import { AppI18nService } from "app/services/app.i18n.service";
import { AppService } from "app/services/app.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
// import { AdminPointService } from "../../../../services/data.service";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from "moment-timezone";
import { MatDialog } from "@angular/material/dialog";
import { QrPopupComponent } from "app/components/common/qr-popup/qr-popup.component";
import { SessionService } from "app/services/session.service";
import { QueryparamsService } from "app/services/queryparams.service";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { DATEPICKER_FORMAT } from "app/components/adapters/DatepickerFormats";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-external-weather-export',
  templateUrl: './external-weather-export.component.html',
  styleUrls: ['./external-weather-export.component.css']
})
@Injectable()

export class ExternalWeatherExportComponent implements OnInit {

    downloadFile(data: any) {
        console.log(typeof(data));
        if(typeof(data) != "undefined" && data[1]){
            let filtered = this.FilteredselectedBuilding.name;
            let start = this.start;
            let end = this.end;
            console.log(filtered);
            const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
            const header = Object.keys(data[0]);
            const csv = data.map((row) =>
                header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
            );
            csv.unshift(header.join(','));
            const csvArray = csv.join('\r\n');
            
            const a = document.createElement('a');
            const blob = new Blob([csvArray], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            
            a.href = url;
            a.download = filtered+start+end + '.csv';
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }
        else {
            alert('no data to export');
        }
    }
  private today = new FutureDecisionsDate();

  public maxDate = moment.utc().endOf("day");
  public max = new FutureDecisionsDate().endOf("day");

  public possibleWindows = ["Day", "Month", "Week"];
public start:any;
public end:any;
public forecast:any;
  public building: string = null;
  public building_name: string;
  public meters: any[];
  public allMeters: any[];
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });
  public filterBuilding(){
    
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;

    let searchBase = this.buildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.buildings;
}
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    this.building = this.FilteredselectedBuilding._id;
    console.log(this.building);
    // this.updateData();


  }
  public buildings = [];
  constructor(private buildingService: BuildingService){

  }
  public form = new FormGroup({
    start: new FormControl(this.today.clone().add(-1, "d"), [Validators.required]),
    end: new FormControl(this.today.clone(), [Validators.required]),
    tags: new FormControl([]),
    building: new FormControl(null, [Validators.required]),
    point: new FormControl(""),
    points: new FormControl([]),
    timeSelection: new FormControl("Day")
  });

  public getForecast(){
      console.log(this.forecast);
      let building = this.building;
      let start = this.start;
      let end = this.end;
      console.log(building);
    
        this.buildingService.weatherForecast(building, start, end).subscribe(x => {
          this.forecast = x.data;
          this.downloadFile(x.data);
          console.log(x);
        });
      
      
  }
  ngOnInit(): void {
   this.buildingService.getAll().subscribe(x => {
      // this.spinnerService.desactivate();
      this.buildings = x.data;
      console.log(this.buildings);
      this.buildingService.weatherForecast(this.buildings[0]._id, 1641056771, 1643389571).subscribe(x => {
        console.log(x);
      });
    
    })

    // let year = 2022;
    // let holidays = [];
    // this.buildingService.holidays().subscribe(x => {
    //     x.items.map(function(val){
    //         let date = val.start.date;
    //         let date_year = date.split("-");
    //         date_year = date_year[0];
    //         // holidays.push(val);
    //         console.log(date_year + ' -  ' + year)
    //         if(date_year == year){
    //             let saida = [
    //                 val.start.date,
    //                 val.summary,
    //                 val.description
    //             ];
    //             holidays.push(saida);
    //         }
    //         console.log(date)
    //     });
    //     // console.log(items);

    //     console.log(holidays);
    //   });
    

  }
  

  

  

}
