import { Component, OnInit } from '@angular/core';
import { NavigationEnd, ActivatedRoute, Router, PRIMARY_OUTLET, Params } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { LoginService } from 'app/services/login.service';
import { UserType } from 'app/interfaces/baseinterface';
import { BuildingService } from 'app/services/building.service';
import { CompanyService } from 'app/services/company.service';
import { SpinnerService } from 'app/services/spinner.service';
import { CompanyViewService } from 'app/services/company.view.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: 'breadCrumbs.component.html',
  styleUrls: ["breadCrumbs.component.css"]
})

export class BreadcrumbsComponent implements OnInit {
  public breadcrumbs: IBreadcrumb[];
  public routePrefix: string = "/admin";

  constructor(private companyService: CompanyService, private companyView: CompanyViewService, private buildingService: BuildingService, private spinnerService: SpinnerService, private ActivatedRoute: ActivatedRoute, private Router: Router, public appService: AppService, private loginService: LoginService) {
    this.breadcrumbs = [];
  }

  public isRoot: boolean = false;
  public companies: { id: string, name: string }[] = []
  public selectedCompany = "";
  public currentview = "Root";


  public changeCompanyView(logout: boolean = false) {
    if (logout) {
      this.selectedCompany = "";
      this.companyView.onLogout();
      location.reload();
    }
    else if(this.selectedCompany) {

      this.spinnerService.activate();
      this.companyView.mimicUserType(UserType.CompanyAdmin, this.selectedCompany).subscribe(x => {

        x._id = this.selectedCompany;
        x.company = this.companies.find(d => d.id == this.selectedCompany).name;

        this.spinnerService.desactivate();
        this.companyView.setCurrentCompany(x);
        location.reload();
      })
    }
  }

  ngOnInit() {

    this.isRoot = this.loginService.userInfo.type == UserType.Root;
    if (this.isRoot) {

      let c = this.companyView.getCurrentCompany();
      if(c){
        this.selectedCompany = c._id;
        this.currentview = c.company;
      }

      this.spinnerService.activate();
      this.companyService.getAll().subscribe(c => {
        this.companies = c.data.map(x => {
          return {
            id: x._id,
            name: x.name
          }
        })



        this.spinnerService.desactivate();


        this.changeCompanyView();
      })
    }

    const ROUTE_DATA = 'breadcrumb';

    let root: ActivatedRoute = this.ActivatedRoute.root;
    this.breadcrumbs = this.getBreadcrumbs(root);

    this.Router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        let root: ActivatedRoute = this.ActivatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
      });

  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {

    const ROUTE_DATA = 'breadcrumb';

    let children: ActivatedRoute[] = route.children;


    if (children.length === 0) {
      return breadcrumbs;
    }

    for (let child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      let routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');


      url += `/${routeURL}`;

      let breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA],
        params: child.snapshot.params,
        url: url
      };
      breadcrumbs.push(breadcrumb);

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

}

export interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}
