import { Injectable } from "@angular/core";
import { UserType } from "../interfaces/baseinterface";
import { ActivatedRoute, Router } from "@angular/router";
import { Person } from "app/interfaces/person";

import { AppI18nService } from "app/services/app.i18n.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { SpinnerService } from "app/services/spinner.service";
import { environment } from "../../environments/environment";
import { AppService } from "./app.service";
import * as moment from "moment-timezone";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of, Subscriber, Observer } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CompanyViewService } from "./company.view.service";
import { SessionService } from "./session.service";

@Injectable()
export class LoginService {

  public params: any = undefined;

  public urlReturn: string = "";

  public loggedIn() {
    return !!this.userInfo;
  }

  authenticate(username: string, password: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.doPost("auth/signIn", { username: username.toLowerCase().trim(), password: password.trim() }).subscribe(
        (res: any) => {
          console.log(res);
          // res.forcePasswordChange = true;
          if (!res.forcePasswordChange) {
            console.log(res);
            localStorage.setItem(this.appService.productKey + ".loggedUser", JSON.stringify(res));
          }
          else {

          }
          observer.next(res);
          return;
        },
        (err: any) => {
          observer.error(err);
        }
      );
    });
  }

  changePassword(userInfo: NewPasswordUser): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.isAuthenticated().subscribe(
        result => {
          let session: any = JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedUser"));
          this.doPost("auth/changeUserPassword", { current_password: userInfo.existingPassword.trim(), password: userInfo.password.trim() },
            { headers: { Authorization: session.token } }).subscribe(
              data => {
                observer.next(data);
                this.router.navigate(["/admin/"]);
                return;
              },
              err => observer.error(err)
            );
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  forgotPassword(username: string): Observable<any> {
    return this.doPost("auth/forgotPassword", { username: username.toLowerCase() });
  }

  confirmNewPassword(email: string, verificationCode: string, password: string): Observable<any> {
    return this.doPost("auth/changePassword", { username: email.toLowerCase().trim(), code: verificationCode.trim(), password: password.trim() });
  }

  logout(showMessage: boolean = true, redirect: boolean = true, returnUrl: boolean = false) {
    let session: any = JSON.parse(localStorage.getItem(this.appService.productKey + ".loggedUser"));
    if(!session){
      this.router.navigate(["/auth/login"]);
    }
    this.spinner.activate();
    this.doPost("auth/signOut", {}, { headers: { Authorization: session.token } }).subscribe(
      (data) => {
        this.spinner.desactivate();
        this.userInfo = undefined;
        this.sessionService.onLogout();
        localStorage.removeItem(this.appService.productKey + ".loggedUser");

        this.companyView.onLogout();

        if (showMessage) {
          this.alert.success(this.i18n.translate("general.logout.title"), this.i18n.translate("general.logout.text"));
        }
        if (redirect) {
          if (returnUrl) {
            let url = this.router.url;
            this.router.navigate(["/auth/login"], { queryParams: { returnUrl: encodeURI(url) } });
          } else {
            this.router.navigate(["/auth/login"]);
          }
        }
      },
      (err) => {
        console.log(err);
        this.spinner.desactivate();
        this.userInfo = undefined;
        localStorage.removeItem(this.appService.productKey + ".loggedUser");

        this.companyView.onLogout();

        if (showMessage) {
          this.alert.success(this.i18n.translate("general.logout.title"), this.i18n.translate("general.logout.text"));
        }
        if (redirect) {
          if (returnUrl) {
            let url = this.router.url;
            this.router.navigate(["/auth/login"], { queryParams: { returnUrl: encodeURI(url) } });
          } else {
            this.router.navigate(["/auth/login"]);
          }
        }
      });
  }

  public setUserInfo(result) {
    if (result == null) this.userInfo = null;
    else {
      this.userInfo = {
        _id: result.user._id,
        landline: undefined,
        city: "",
        company: result.user.company,
        primaryEmail: result.user.email,
        type: <UserType>result.user.type,
        username: result.user.email,
        country: "",
        name: result.user.name,
        mobilePhone: "",
        contacts: {
          emails: [],
          telephones: []
        },
        loggedAt: new Date()
      };
    }
    return this.userInfo;
  }

  private subject: Subject<any> = new Subject();
  private proccessingRequest: boolean = false;

  private t: Subscriber<any>[] = [];

  isAuthenticated(): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      let storage = localStorage.getItem(this.appService.productKey + ".loggedUser");
      if (!storage) {
        observer.error({ error: "User Session not found." });
      }
      let session: any = JSON.parse(storage);
      if (!this.userInfo) {

        this.setUserInfo(session);
      }
      if(!session){
        observer.next(false);
      }
      else if (moment.utc(session.tokenExpiration) > moment.utc()) {
        observer.next(true);
      } else {
        if (this.proccessingRequest == false) {
          this.spinner.activate();
          this.proccessingRequest = true;
          this.doPost("auth/refreshToken", { username: session.user.email, token: session.token }).pipe(delay(500)).subscribe(
            (res) => {
              this.spinner.desactivate();
              session = Object.assign(session, res);
              localStorage.setItem(this.appService.productKey + ".loggedUser", JSON.stringify(session));

              this.subject.next(true);

              this.proccessingRequest = false;
            },
            (error) => {
              this.spinner.desactivate();
              console.log(error);
              // Uncomment this line to disable login
              // observer.next(true);
              // And comment this line

              observer.error({ error: error });
            },
            () => {
            }
          );

        }

        this.subject.subscribe(x => {
          observer.next(true);
        })
      }
    });
  }

  constructor(
    private sessionService: SessionService,
    private companyView: CompanyViewService,
    private activatedRouter: ActivatedRoute,
    private alert: SweetalertService, private router: Router,
    private activatedRoute: ActivatedRoute, private i18n: AppI18nService, private spinner: SpinnerService,
    private appService: AppService, private http: HttpClient) {

  }

  public userInfo: any = undefined;

  // Use this method to do something to ALL post request, such as passing tokens/timestamps/headers
  protected doPost(url: string, body: any, options: any = {}): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(environment.apiUrl + url, body, options).subscribe(
        (t: any) => {
          observer.next(t);
          return;
        },
        error => {
          observer.error({ status: error.status, errors: error });
        }
      );
    });
  }

  protected doGet(url: string, options: any = {}): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.get(environment.apiUrl + url, options).subscribe(
        (t: any) => {
          observer.next(JSON.parse(t._body));
        },
        error => {
          observer.error({ status: error.status, errors: JSON.parse(error._body).errors });
        }
      );
    });
  }
}

export interface UserInfo extends Person {
  loggedAt: Date;
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  type: UserType;
  company: string;
  username: string;
}

export class NewPasswordUser {
  username: string;
  existingPassword: string;
  password: string;
}

export class RegistrationUser {
  name: string;
  email: string;
  phone_number: string;
  password: string;
}
