import { Component, OnInit } from "@angular/core";
import { AppService } from "app/services/app.service";
import { Subscription, Observable } from "rxjs";
import 'rxjs/add/observable/timer';
import { VersionCheckService } from 'app/services/version-checker.service';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: "app-footer",
  templateUrl: "footer.template.html",
  styleUrls: ["footer.component.css"]
})
export class FooterComponent implements OnInit {
  ngOnInit(): void {
    let remainingTime = new Date().getSeconds() -
      new Date(new Date(new Date().setMinutes(new Date().getMinutes() + 1)).setSeconds(0)).getSeconds();
    remainingTime *= 1000;

    this.timerObservable = Observable.timer(remainingTime, 60000).subscribe(t => {
      this.currentDate = new Date();
    });
  }


  private _version: string = "";
  private _hash: string = "";

  public timerObservable: Subscription;
  public currentDate: Date = new Date();
  public currentYear: number = new Date().getFullYear();

  constructor(private platformLocation: PlatformLocation, private versionCheckerService: VersionCheckService, public appService: AppService) {
    if ((this.platformLocation as any).location.origin.indexOf("localhost") >= 0) {
      this._version = "localhost";
      this._hash = "####"
    }
  }



  get version() {
    if (this._version != "") {
      return this._version;
    }

    return this.versionCheckerService.version;
  }

  get hash() {
    if (this._hash != "") {
      return this._hash;
    }

    return this.versionCheckerService.hash;
  }

  get lastCheck() {
    return this.versionCheckerService.lastUpdate;
  }

  get needsRefresh() {
    return this.versionCheckerService.needsRefresh;
  }

  public refresh() {
    location.reload();
  }

}
