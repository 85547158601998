import { NgModule } from "@angular/core";
import { QRCodeModule } from "angular2-qrcode";
import { MyQRCodeComponent } from "./qr-code.component";
import { CommonModule } from '@angular/common';

@NgModule( {
  imports: [
    QRCodeModule,
    CommonModule
  ],
  declarations: [ MyQRCodeComponent ],
  exports: [ MyQRCodeComponent ]
} )
export class MyQRCodeModule {
}
