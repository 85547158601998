import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { FullscreenSpinnerComponent } from 'app/components/common/spinner/fullscreen-spinner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SpinnerComponent, FullscreenSpinnerComponent],
  exports: [SpinnerComponent, FullscreenSpinnerComponent]
})
export class SpinnerModule { }
