import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppI18nService } from 'app/services/app.i18n.service';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class Error404Component implements OnInit {

  constructor(public i18n: AppI18nService) { }

  ngOnInit() {
  }

}
