import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private _currentbuilding = "";

  set building(building) {
    this._currentbuilding = building;

    localStorage.setItem(`${this.appService.productKey}.session_settings.building`, this._currentbuilding);
  }

  get building(): string {
    this._currentbuilding = localStorage.getItem(`${this.appService.productKey}.session_settings.building`);
    return this._currentbuilding;
  }

  constructor(private appService: AppService) {
    // this.building = localStorage.getItem(`${this.appService.productKey}.session_settings.building`);
  }

  public onLogout(){
    localStorage.removeItem(`${this.appService.productKey}.session_settings.building`);
  }
}
