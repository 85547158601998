import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BasicRequestService } from './basic-request.service';
import { Observable, Observer } from 'rxjs';
import { SweetalertService } from './sweetalert.service';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { LoginService } from './login.service';
import { SpinnerService } from './spinner.service';
import { TranslocoService } from '@ngneat/transloco';
import { CompanyViewService } from './company.view.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DriverService extends BasicRequestService {
  constructor(public transloc: TranslocoService, public snack: MatSnackBar, public companyView: CompanyViewService, public spinner: SpinnerService, private alert: SweetalertService, public http: HttpClient, public appService: AppService, public loginService: LoginService) {
    super(transloc, snack, companyView, http, appService, loginService, spinner);
  }
  //   [post] devices/{deviceId}/add
  // No body tem que vir um field points. array de ids dos points pra adicionar

  public addPoints(deviceId: string, points: string[]): Observable<any> {
    return this.doPostSecure(`devices/${deviceId}/cmd/add`, { entity: { points: points } });
  }

  //[POST] devices/{deviceId}/cmd/remove
  public removePoints(deviceId: string, points: string[]): Observable<any> {
    return this.doPostSecure(`devices/${deviceId}/cmd/remove`, { entity: { points: points } });
  }

  public removeFDPoints(deviceId: string, points: string[]): Observable<any> {
    return this.doPostSecure(`devices/${deviceId}/cmd/deleteFD`, { entity: { points: points } });
  }

  // [POST] devices/{deviceId}/cmd/removeAll
  public removeAllPoints(deviceId): Observable<any> {
    return this.doPost(`devices/${deviceId}/cmd/removeAll`, {});
  }
  // [POST] devices/{deviceId}/cmd/addAll
  public addAllPoints(deviceId, filter: string = undefined) {
    return this.doPost(`devices/${deviceId}/cmd/addAll`, { regexp: filter });
  }

  //[GET] buildings/{buildingId}/devices ( lista de devices do predio )
  public getDevices(buildingId: string, page: number = 1, pageSize: number = 100, filter = "") {
    return this.doGetSecure(`buildings/${buildingId}/devices?page=${page}&pageSize=${pageSize}&fullText=${filter}`);
  }

  //[POST] buildings/{buildingId}/devices/{deviceId}/cmd/createFD
  public createPoint(buildingId: string, deviceId: string, points) {
    return this.doPostSecure(`devices/${deviceId}/cmd/createFD`, { entity: points })
  }

  //[GET] devices/{deviceId}/log
  public getLogs(deviceId: string) {
    return this.doGetSecure(`devices/${deviceId}/log`, false);
  }

  //[POST] devices/{deviceId}/cmd/logs
  public sendLogCommand(deviceId: string, start, end) {
    return this.doPostSecure(`devices/${deviceId}/cmd/logs`, { entity: { start: start, end: end } });
  }

  //[POST ]buildings/{buildingId}/devices/{deviceId}/cmd/discover
  public discoverDevices(buildingId: string, deviceId: string) {
    return this.sendCommand(buildingId, deviceId, "discover");
  }

  //[POST] devices/{deviceId}/cmd/listMetadata
  public listMetadata(deviceId: string) {
    return this.doPostSecure(`devices/${deviceId}/cmd/listMetadata`, {});
  }

  //[POST] devices/{deviceId}/cmd/getMetadata
  public getMetadata(deviceId: string, point: string) {
    return new Observable((observer: Observer<any>) => {
      this.doPostSecure(`devices/${deviceId}/cmd/getMetadata`, { entity: { point: point } }).subscribe(x => {
        console.log("x");
        observer.next(x);
      })
    });
  }

  //[POST] devices/{deviceId}/cmd/discoverFD
  public discoverFD(deviceId: string) {
    return this.doPostSecure(`devices/${deviceId}/cmd/discoverFD`, {});
  }

  // [GET] devices/{deviceId}/queuedCommands
  // public getQueuedCommands(deviceId:string){
  //   return this.doGet(`devices/${deviceId}/queuedCommands`);
  // }



  //[GET] buildings/{buildingId}/devices/{deviceId}/metadatas?fd_points=0 or 1 fd_points ( 0 = non FD, 1 = FD, any other value is all )
  public getMetada(buildingId: string, deviceId: string, queryParam: number, page: number = 1, pageSize: number = 10, filter: string = ""): Observable<any> {

    return new Observable((observer: Observer<any>) => {
      this.doGetSecure(`devices/${deviceId}/metadatas?fd_points=${queryParam}&page=${page}&pageSize=${pageSize}&fullText=${filter}`).subscribe(x => {
        for (let d of x.data) {
          for (let d of x.data) {
            if (d.friendlyName)
              d.friendlyName = this.appService.convertFromASCII(d.friendlyName);
            if (d.point)
              d.point = this.appService.convertFromASCII(d.point);
            if (d.ord)
              d.ord = this.appService.convertFromASCII(d.ord);

            if (d.name)
              d.name = this.appService.convertFromASCII(d.name);
          }
        }
        observer.next(x);
      })
    });
  }

  public getMetadataFD(buildingId: string, deviceId: string, page: number = 1, pageSize: number = 10, filter: string = "") {
    return this.getMetada(buildingId, deviceId, 1, page, pageSize, filter);
  }

  public getMetadataNonFD(buildingId: string, deviceId: string, page: number = 1, pageSize: number = 10, filter: string = "") {
    return this.getMetada(buildingId, deviceId, 0, page, pageSize, filter);
  }

  public getMetadataAll(buildingId: string, deviceId: string, page: number = 1, pageSize: number = 10, filter: string = "") {
    return this.getMetada(buildingId, deviceId, 2, page, pageSize, filter);
  }

  //[POST] buildings/{buildingId}/devices/{deviceId}/cmd/{cmd}
  public sendCommand(buildingId: string, deviceId: string, cmd: string, content: any = {}) {
    return this.doPostSecure(`devices/${deviceId}/cmd/${cmd}`, content);
  }

  // [GET] buildings/{buildingId}/devices/{deviceId}
  public getDevice(buildingId: string, deviceId: string) {
    return this.doGetSecure(`devices/${deviceId}`);
  }

  //[GET] devices/{deviceId}/commands
  public getCommands(deviceId: string, lastFive: boolean = true) {
    if (lastFive)
      return this.doGetSecure(`devices/${deviceId}/commands?check=1`);
    else
      return this.doGetSecure(`devices/${deviceId}/commands`);
  }

  public getCommandByCid(deviceId: string, cid: string) {
    return this.doGetSecure(`devices/${deviceId}/commands?cid=${cid}`);
  }

  // [POST] buildings/{buildingId}/devices
  public createDevice(buildingId: string, content: { friendlyName: string, hostID: string, generateTags: boolean }) {
    const body = {};
    body["entity"] = content;

    return this.doPostSecure(`buildings/${buildingId}/devices`, body);
  }

  //[post] buildings/{buildingId}/devices/{deviceId}/resetToken
  public resetToken(buildingId: string, deviceId: string) {
    return this.doPostSecure(`devices/${deviceId}/resetToken`, {});
  }

  public update(buildingId: string, newEntity, id) {
    const body = {};
    body["entity"] = newEntity;

    return this.doPatch(`devices/${id}`, body);
  }

  //[DELETE] buildings/{buildingId}/devices/{deviceId}
  public deleteDevice(buildingId: string, deviceId: string) {
    return this.doDeleteSecure(`devices`, deviceId);
  }

  // [post] buildings/{buildingId}/devices/{deviceId}/block
  // no body se tiver o field block ele bloqueia. se vier unBlock ele desbloqueia
  public blockDevice(buildingId: string, deviceId: string) {
    const body = {};
    body["entity"] = { block: true };

    return this.doPostSecure(`devices/${deviceId}/block`, body);
  }

  public unblockDevice(buildingId: string, deviceId: string) {
    const body = {};
    body["entity"] = { unBlock: true };

    return this.doPostSecure(`devices/${deviceId}/block`, body);
  }


  public doGetSecure(url, treatError = true) {
    return new Observable<any>((observer: Observer<any>) => {
      this.doGet(url).subscribe(
        t => {
          observer.next(t);
          observer.complete();
        },
        err => {
          if (treatError) {
            this.threatError(err);
          }
          else {
            observer.error(err);
          }
        });
    });
  }

  public doPatchSecure(url, body, treatError = true) {
    return new Observable<any>((observer: Observer<any>) => {
      this.doPatch(url, body).subscribe(
        t => {
          observer.next(t);
          observer.complete();
        },
        err => {
          if (treatError) {
            this.threatError(err);
          }
          else {
            observer.error(err);
          }
        });
    });
  }

  public doDeleteSecure(url, id, treatError = true) {
    return new Observable<any>((observer: Observer<any>) => {
      this.doDelete(url, id).subscribe(
        t => {
          observer.next(t);
          observer.complete();
        },
        err => {
          if (treatError) {
            this.threatError(err);
          }
          else {
            observer.error(err);
          }
        });
    });
  }

  public doPostSecure(url, body, treatError = true) {
    return new Observable<any>((observer: Observer<any>) => {
      this.doPost(url, body).subscribe(
        t => {
          observer.next(t);
          observer.complete();
        },
        err => {
          if (treatError) {
            this.threatError(err);
          }
          else {
            observer.error(err);
          }
        });
    });
  }

  private threatError(err: any) {
    console.log(err);
    let message = "";

    if (err.status == 404) {
      message = "Resource not found"
    }
    else if (err.status == 403) {
      message = "Unauthorized access."
    }
    else if (err.error.errors) {
      let errors = "";
      for (let error of err.error.errors) {
        errors += <string>error.message + "\n ";
      }

      message = "Server returned the following errors: \n" + errors;
    }
    else {
      message = "Unkown error. Contact support."
    }
    this.spinner.fullStop();
    this.alert.info("Warning", message);
  }
}

