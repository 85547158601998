import { Injectable } from '@angular/core';

import * as moment from "moment-timezone";
import { Moment } from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateWrapperService {
  public timezoneChanged = new BehaviorSubject<string>("");

  private _tz: string = "";

  get tz() {
    return this._tz;
  }

  public getTimezoneOffset(timezone) {
    let t = moment().tz(timezone).utcOffset();
    console.log(t);
    return t;
  }

  constructor() {
  }

  public setTimezone(tz) {
    this._tz = tz;

    this.timezoneChanged.next(this._tz);
  }

  get timezones() {
    return moment.tz.names();
  }
}
