import { Component, OnInit, Input, Inject } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { AppService } from 'app/services/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.css']
})
export class MapPopupComponent implements OnInit {

  public geoLocation: DialogData = {
    lat:0,
    lng:0,
    textDescription: ""
  };

  constructor(private spinner: SpinnerService, private appService: AppService, public dialogRef: MatDialogRef<MapPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.geoLocation = this.data;
  }

}

export interface DialogData {
  lat: number;
  lng: number;
  textDescription: string;
}
