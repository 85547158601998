import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguagesDataService {

  private _languages: {
    code: string,
    name: string,
    flag: string
  }[] = [
      {
        code: "en",
        name: "English",
        flag: "United-Kingdom"
      },
      {
        code: "pt-br",
        name: "Português do Brasil",
        flag: "Brazil"
      },
      {
        code: "nl",
        name: "Nederlands",
        flag: "Netherlands"
      },
      {
        code:"es",
        name: "Español",
        flag:"Spain"
      }
    ]

  get languages(): { code: string, name: string, flag: string }[] {
    return this._languages;
  }

  public getLanguage(code: string){
    return this._languages.find(x => x.code == code);
  }

  constructor() { }
}
