import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-note-timeline',
  templateUrl: './note-timeline.component.html',
  styleUrls: ['./note-timeline.component.css']
})
export class NoteTimelineComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<NoteTimelineComponent>) { }

  ngOnInit(): void {
  }

}
