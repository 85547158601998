import { Observable, throwError as observableThrowError } from "rxjs";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { environment } from "environments/environment";
import { UserType } from "app/interfaces/baseinterface";
import { AppI18nService } from "app/services/app.i18n.service";
import { LoginService } from "app/services/login.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { AppService } from "./app.service";
import { HttpClient } from "@angular/common/http";
import { BasicRequestService } from './basic-request.service';
import { CompanyViewService } from "./company.view.service";
import { TranslocoService } from "@ngneat/transloco";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class BaseService<T> extends BasicRequestService {
  public rootUrl = "";

  constructor(public transloco: TranslocoService, public snackbar: MatSnackBar, public companyView: CompanyViewService, public http: HttpClient, public loginService: LoginService, public i18n: AppI18nService, public spinner: SpinnerService,
    public alert: SweetalertService, public appService: AppService) {
    super(transloco, snackbar, companyView, http, appService, loginService, spinner);
    const x: T = undefined;
    this.rootUrl = typeof (x);
  }

  public create(entity: T, restOfUrl?: string): Observable<any> {
    const body = {};
    body["entity"] = entity;

    return this.doPost(restOfUrl !== undefined ? restOfUrl : this.rootUrl, body).map(response => {
      return response;
    });
  }

  public delete(uid: string, restOfUrl?: string): Observable<any> {
    return this.doDelete(restOfUrl !== undefined ? this.rootUrl + restOfUrl : this.rootUrl, uid).map(response => response);
  }

  private returnDiffJson(originalJson, newJson) {
    // let diff = {};
    // for (const key of Object.keys(newJson)) {
    //   if (toString.call(newJson[key]) === '[object Object]' && Object.keys(newJson[key]).length > 0) {
    //     diff = Object.assign(diff, this.returnDiffJson(originalJson[key], newJson[key]));
    //   } else if (newJson[key] !== originalJson[key]) {
    //     diff[key] = newJson[key];
    //   }
    // }
    // return diff;

    let ret = {};
    for (let i in newJson) {
      if (!originalJson.hasOwnProperty(i) || newJson[i] !== originalJson[i]) {
        ret[i] = newJson[i];
      }
    }
    return ret;
  }

  public cleanArray(array: string[]) {
    return array.filter(x => x !== "");
  }

  public update(originalEntity: T, newEntity: T, restOfUrl?: string): Observable<any> {
    // const body = {};

    // body[ this.rootUrl ] = this.returnDiffJson( originalEntity, newEntity );

    const body = {};
    body["entity"] = newEntity;

    return this.doPatch((restOfUrl !== undefined ? this.rootUrl + restOfUrl : this.rootUrl) + "/" + originalEntity["_id"], body).
      map(response => response);
  }

  public getOne(uid: string, restOfUrl?: string): Observable<any> {
    return this.doGet(this.rootUrl, uid, restOfUrl).map(response => response as any);
  }


  public getAll(restofurl?: string, pagination?: IPaginationQuery, filter?: string, refQuery?: string[]): Observable<any> {

    let completeUrl = this.rootUrl;
    let stringParams: string[] = [];


    if (restofurl) {
      stringParams.push(restofurl);
    }

    if (refQuery) {
      for (let r of refQuery) {
        stringParams.push(`refQuery=${encodeURIComponent(JSON.stringify([{ "path": r }]))}`);
      }
    }

    if(pagination){
      stringParams.push(`page=${pagination.page}`)
      stringParams.push(`pageSize=${pagination.pageSize}`)
    }

    if (filter) {
      filter = encodeURI(filter);
      stringParams.push(`fullText=${filter}`);
    }

    if (stringParams.length > 0) {
      completeUrl += "?" + stringParams.join("&");
    }

    return this.doGet(completeUrl).map(response => {
      return response as IGetResponse<T>;
    });
  }

  public simpleErrorHandler = (error: any) => {
    return observableThrowError({ body: error });
  };

  public handleError = (errorResponse: Response | any) => {
    console.log("Error while requesting data from server:");
    console.log(errorResponse);

    try {
      if (errorResponse.status === 404) {
        this.alert.info(this.i18n.translate("general.errors.error"), this.i18n.translate("general.errors.notFound"));
      } else if (errorResponse.status == 401) {
        this.alert.info(this.i18n.translate("general.messages.securityWarning"),
          this.i18n.translate("general.messages.tokenExpired"));
        this.spinner.desactivate();
        this.loginService.logout(false, true, true);
      } else if (errorResponse.error.errors) {
        if (errorResponse.error.errors.message) {
          this.alert.info(this.i18n.translate("general.warnings.warning"), errorResponse.error.errors.message);
        }
        else {
          let errors = "";
          for (let err of errorResponse.error.errors) {
            errors += <string>err.message + ", ";
          }
          this.alert.info(this.i18n.translate("general.warnings.warning"), errors.substring(0, errors.length - 2));
        }
      }
    } catch {
      this.spinner.fullStop();
      this.alert.info(this.i18n.translate("general.warnings.warning"), this.i18n.translate("general.errors.callSupport"));
    }

    this.spinner.desactivate();
    return { status: errorResponse.status, errors: errorResponse.error.errors };
  };




  public generateHtmlListOfErrors(returnedError: IReturnedError): string {
    let ret = "<ul style='text-align: left;'>";

    for (let error of returnedError.errors) {
      ret += "<li>" + error.message + "</li>";
    }

    ret += "</ul>";

    return ret;
  }
}

export interface IServiceError {
  field?: string;
  message: string;
}

export interface IReturnedError {
  status?: number;
  errors: IServiceError[];
}

export interface IPaginationQuery {
  page: number;
  pageSize: number;
}

export enum DescAsc {
  asc = "ascending",
  desc = "descending"
}

export interface IGetResponse<T> {
  data: T[];

  [x: string]: any;

  page: number;
  pageSize: number;
  totalPages: number;
  totalRecordsFound: number;
}

export interface IPostResponse<T> {
  data: T;
}

export interface ISearchParams {
  field: string;
  value: string;
}
