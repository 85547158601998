import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { AdminPointService } from 'app/services/data.service';
import { AppHighstockComponent } from 'app/components/common/highcharts/highstock/highstock.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-admin-prediction',
  templateUrl: './admin-prediction.component.html',
  styleUrls: ['./admin-prediction.component.css']
})
export class AdminPredictionComponent implements OnInit {

  constructor(private spinner: SpinnerService, private dataService: AdminPointService) { }

  @ViewChild("chart1", { static: true }) chart1: AppHighstockComponent;
  @ViewChild("chart2") chart2: AppHighstockComponent;

  public chart1Model = {
    week: 5,
    day: 1
  }

  public chart2Model = {
    week: 5,
    day: 1
  }

  public chartOptions = {
    chart: { zoomType: "x" },
    title: { text: "" },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      }
    },
    xAxis: {
      type: "datetime"
    },
    tooltip: { split: false, shared: true },
    yAxis: [
      {
        opposite: false, // right
        title: { text: "ppm", x: 19, y: 0 },
        labels: { x: 24, y: -2, align: "right" }
      }
    ],
    series: []
  };

  public refreshChart1() {
    this.spinner.activate();
    this.dataService.getPrediction(this.chart1Model.week, this.chart1Model.day).subscribe(x => {
      console.log(x.data);

      this.dataChart1 = x.data;

      this.spinner.desactivate();

      this.changeDataChart1();

      this.getTable(x.analytics[this.chart1Model.day + ""])
    })
  }

  public changeDataChart1() {
    let d = this.dataChart1[this.chart1Model.day + ""];

    let c = 0;

    for (let day of d) {
      day.id = "" + c;

      this.chart1.updateSeries(day, day.id);
      c++;
    }

    if (c < 8) {
      for (let i = c; i < 8; i++) {
        this.chart1.removeById(i + "");
      }
    }
  }

  public datasource = new MatTableDataSource<any>();
  public tableData = [];
  public tableHeaders = [];

  public getTable(data) {
    this.tableData = [];
    this.tableHeaders = [" "];

    for (let key of Object.keys(data)) {
      let temp = [];
      temp[" "] = key;
      for (let col of Object.keys(data[key])) {
        if (this.tableHeaders.indexOf(col) == -1)
          this.tableHeaders.push(col);

        temp[col] = data[key][col];
      }
      this.tableData.push(temp)
    }

    for (let d of this.tableData) {
      for (let t of this.tableHeaders) {
        if (d[t] == undefined || d[t] == null)
          d[t] = "NA";
      }
    }

    console.log(this.tableData);
    console.log(this.tableHeaders);

    this.datasource.data = this.tableData;
  }

  public days = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

  public weekday = [
    {
      value: 1,
      name: "Monday"
    },
    {
      value: 2,
      name: "Tuesday"
    },
    {
      value: 3,
      name: "Wednesday"
    },
    {
      value: 4,
      name: "Thursday"
    },
    {
      value: 5,
      name: "Friday"
    },
    {
      value: 6,
      name: "Saturday"
    },
    {
      value: 7,
      name: "Sunday"
    }
  ]

  public dataChart1 = null;

  ngOnInit() {

  }



}
