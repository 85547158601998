import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root-dashboard',
  templateUrl: './root-dashboard.component.html',
  styleUrls: ['./root-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RootDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
