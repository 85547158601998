import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';


import * as moment from "moment-timezone";
import { SpinnerService } from 'app/services/spinner.service';
import { AdminPointService } from 'app/services/data.service';
import { BuildingService } from 'app/services/building.service';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AppI18nService } from 'app/services/app.i18n.service';
import { CompanyService } from 'app/services/company.service';
import { LoginService } from 'app/services/login.service';
import { getMinArrayObj, getMaxArrayObj } from 'app/app.helpers';
import { AdvancedChartComponent, ChartType } from 'app/components/common/advanced-chart/advanced-chart.component';
import { SessionService } from 'app/services/session.service';
import { SearchPopupComponent } from 'app/components/common/search-popup/search-popup.component';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { parseDataToFrequency } from "../../../helpers/typeHelper";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


@Component({
  selector: 'app-admin-advanced-view',
  templateUrl: './admin-advanced-view.component.html',
  styleUrls: ['./admin-advanced-view.component.css']
})
export class AdminAdvancedViewComponent implements OnInit {
  private today = moment.utc();

  @ViewChild("advacendChart", { static: true }) advacendChart: AdvancedChartComponent;
  @ViewChild("pointSelector", { static: true }) pointSelector: ElementRef;

  public maxDate = moment.utc();
  public building:any;

  private startDate = this.today.clone().add(-1, "d");
  private endDate = this.today.clone();
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public form = new FormGroup({
    start: new FormControl(this.today.clone().add(-1, "d"), [Validators.required]),
    end: new FormControl(this.today.clone(), [Validators.required]),
    tags: new FormControl([]),
    building: new FormControl(null, [Validators.required]),
    point: new FormControl(""),
    points: new FormControl([])
  });

  public charTypes: { type: string, name: string }[] = [
    { type: "spline", name: "Spline" },
    { type: "line", name: "Line" },
    { type: "area", name: "Area" },
    { type: "column", name: "Column" },
    { type: "areaspline", name: "Area Spline" },
  ]

  changeBuilding(building_formed){
    this.building = this.FilteredselectedBuilding._id;
    console.log(this.building);
    this.onBuildingChange();


  }
  public pointsStatus: { unit: string, points: number, status: string, type: string, csv: { filename: string, data: any }, sampleFrequency?: any, }[] = [];

  // public listOfPoints: any[] = [];
  public filteredPoints: any[] = [];
  private initialPoint: any = "";

  public filteredBuildings: any[];
  private listOfBuildings: any[];
  public buildingFilter: any = "";

  public listOfTags = [];
  public filteredTags = [];
  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });

  public filterBuilding(){
    
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;
    let searchBase = this.listOfBuildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.listOfBuildings;
}
    
  }
  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.listOfBuildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  ngAfterViewInit() {
    this.form.get("point").valueChanges.subscribe(x => {
      // this.filterPoint();
    })

    this.route.queryParams.subscribe(async (query) => {
      this.spinner.activate();

      this.companyService.getCompanyBuildingsTags().subscribe(c => {
        this.listOfTags = c;

        this.buildingService.getAll().subscribe(buildings => {
          this.listOfBuildings = buildings.data;
          this.filteredBuildings = this.listOfBuildings.filter(building => {
            let add = building.name.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0 ||
              building.description.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0;
            if (building.address) {
              add = add || building.address.street.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0;
            }
            return add;
          });


          if (query["building"] != undefined) {
            for (let b of this.filteredBuildings) {
              if (b._id === query["building"]) {
                this.buildingFilter = b;
                this.form.get('building').setValue(b._id);
                this.filterTags();
                // this.onBuildingChange( { option: { value: b } }, undefined );
              }
            }
            console.log("this.dataService.GetListOfPoints");
            console.log(this.building);

            this.dataService.GetListOfPoints(this.building).subscribe(points => {
              let data = [];

              for (let point of points["data"]) {
                if (point.id == query["point"]) {
                  this.initialPoint = point;
                  this.form.get('points').setValue(point.id);

                  this.pointsStatus[point.id] = {
                    status: "Normal Data",
                    type: "spline",
                    csv: null,
                    points: 0,
                    unit: ""
                  }
                }
                data.push(point);
              }
              // this.listOfPoints = data;


              if (this.initialPoint) {
                this.form.get("points").setValue([this.initialPoint]);
                // this.filterPoint();
                this.GetDataForPoint(this.initialPoint);
              }


              this.spinner.desactivate();
            })

          }
          else {
            this.spinner.desactivate();
          }


        })
      });
    });
  }


  tagsChanged() {
    // this.filterPoint();
  }

  filterTags() {
    if (this.building) {
      this.filteredTags = this.listOfTags[this.building];
    }
    else {
      this.filteredTags = [];
    }
  }

  // filterPoint() {

  //   let tempPoint = this.point instanceof Object ? this.point.name : this.point;
  //   if (this.tags.length == 0) {
  //     this.filteredPoints = this.listOfPoints.filter(x => x.name.toLowerCase().indexOf(tempPoint.toLowerCase()) >= 0);
  //   }
  //   else {
  //     this.filteredPoints = this.listOfPoints.filter(point => (point.tags.filter(x => this.tags.indexOf(x) >= 0).length > 0) && point.name.toLowerCase().indexOf(tempPoint.toLowerCase()) >= 0);
  //   }


  // }

  constructor(public dialog: MatDialog, private sessionService: SessionService, public router: Router, private dataService: AdminPointService, private spinner: SpinnerService,
    private route: ActivatedRoute, public i18n: AppI18nService, private buildingService: BuildingService,
    private companyService: CompanyService, private loginService: LoginService) {
  }

  public openDialog() {
    const dialogRef = this.dialog.open(SearchPopupComponent, {
      width: '960px',
      data: {
        single: false,
        assetName: "Points",
        displayField: "name",
        valueField: "id",
        retunObject: true,
        disableSelect: false,
        searchCallback: this.searchPoints.bind(this),
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.length > 0) {
        this.addPoint(x);
      }
    })
  }

  public searchPoints(filter: string, pageOptions: { page: number, pageSize: number, hasNext: boolean }): Observable<any> {
    return new Observable(observer => {
      this.spinner.activate();
      this.dataService.GetListOfPoints(this.building, false, filter, pageOptions.page, pageOptions.pageSize, 1).subscribe(x => {
        this.spinner.desactivate();

        observer.next(x.data);
        observer.complete();
      })

    })
  }

  ngOnInit(): void {
  }

  public generateCSV(csv) {
    var str = 'timestamp, value, unit, ord\r\n';

    for (var i = 0; i < csv.data.length; i++) {
      var line = '';


      for (var index in csv.data[i]) {
        if (line != '') line += ','

        line += csv.data[i][index];
      }

      str += line + '\r\n';
    }

    var file = new Blob([str], {
      type: "text/csv;charset=UTF-8"
    });
    if ((<any>window.navigator).msSaveOrOpenBlob) // IE10+
      (<any>window.navigator).msSaveOrOpenBlob(file, csv.filename + ".csv");
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = csv.filename + ".csv";
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  public dateChanged(dateStr, dateObj) {
    if (dateObj === 1) {
      this.startDate = moment.utc(dateStr);
    } else {
      this.endDate = moment.utc(dateStr);
    }
  }

  public data: any[] = [];

  private GetDataForPoint(point) {

    let value = this.form.value;

    let start = value.start.clone().utc().startOf("day");
    let end = value.end.clone().utc().endOf("day");

    this.spinner.activate();
    this.dataService.GetDataForPoint({
      building: this.building,
      point: point.id,
      from: start.format("YYYY-MM-DD"),
      to: end.format("YYYY-MM-DD")
    }).subscribe(resp => {
      this.spinner.desactivate();


      let current = moment.utc();
      let start = value.start.clone().utc().startOf("day");
      let end = value.end.clone().utc().endOf("day");

      if (resp["data"].length == 0) {
        this.pointsStatus[point.id].status = `No readings.`
      }
      else if (resp["data"][0].dataType == 8) {
        let lastValue = "";

        let at = "";
        for (let week of resp.data) {

          let date = moment.utc(week["timestamp"]);
          let dt = date.valueOf();

          for (let i = 1; i < 8; i++) {
            for (let j = 0; j < 1440; j++) {

              if (date >= end || date <= start) {
                break;
              }

              if (week.weekPointData[i][j] != null && week.weekPointData[i][j] != undefined) {
                lastValue = week.weekPointData[i][j];
                at = moment(dt).format("DD/MM/YYYY HH:mm Z")
              }
              dt += 60000;
            }
          }
        }

        this.pointsStatus[point.id].status = `Text value detected. Last message was '${lastValue}' at ${at}.`
      }
      else {

        let pointData = [];

        for (let d of resp["data"]) {

          let date = moment.utc(d["timestamp"]).startOf("day");

          for (let wd of Object.keys(d.weekPointData)) {
            let dataArray = d.weekPointData[wd];

            if (date < start) {
              date = date.add(1, "day");
              continue;
            }
            else if (date > end) {
              break;
            }

            let dt = date.valueOf();


            for (let i = 0; i < 1440; i++) {


              if (dt > current.valueOf()) {
                break;
              }

              if (dataArray[i] == undefined || dataArray[i] == null) {
                pointData.push([dt, null]);
              }
              else {
                pointData.push([dt, Number(dataArray[i])]);
              }


              dt += 60000;
            }

            date = date.add(1, "day");
          }
        }

        let isValid = false;
        if (resp["data"][0].dataType == 1) {
          isValid = true;
        }
        else {
          for (let d of pointData) {
            if (d.length > 0) {
              isValid = true;
              if (d[1] != null)
                d[1] = Math.floor(d[1] * 100) / 100
            }
          }
        }

        if (isValid) {
          let min = getMinArrayObj(pointData, 1);
          let max = getMaxArrayObj(pointData, 1);

          if (point.unit) {
            this.pointsStatus[point.id].unit = point.unit;
          }
          else {
            this.pointsStatus[point.id].unit = "No Metadata";
          }

          if (min == max) {
            this.pointsStatus[point.id].status = `Constant value ${min} for the selected time window.`
          }
          else {

            pointData.sort((a, b) => {
              return a[0] < b[0] ? -1 : 1;
            }); 
            this.pointsStatus[point.id].status = "Normal Data";
            this.pointsStatus[point.id].sampleFrequency = (point.sampleFrequency || 1);
            this.advacendChart.addPoint({
              data: parseDataToFrequency(pointData, point.sampleFrequency),
              dataGrouping: {
                enabled: true
              },
              id: point.id,
              name: this.listOfBuildings.find(x => x._id == this.building).name + " - " + point.name,
              type: ChartType.Spline,
              color: this.getRandomColor(),
              connectNulls: false,
              yAxis: "",
              zIndex: 0,
              gapSize: (point.sampleFrequency || 1)*60000,
              gapUnit: 'value',
              tooltip: {
                valueDecimals: 2,
                valuePrefix: '',
                valueSuffix: ' ' + point.unit
              }
            })
          }
        } else {
          this.pointsStatus[point.id].status = `No data`
        }

        // console.log(pointData);
      }
    })
  }

  public addPoint(event) {
    for (let p of event) {

      if (this.points.find(x => x.id == p.id) == null) {
        this.pointsStatus[p.id] = {
          status: "Normal Data",
          type: "spline",
          csv: null,
          points: 0,
          unit: ""
        }

        this.points.push(p);
        console.log(this.points);
        this.form.get("points").setValue(this.points);

        this.form.get("point").setValue("");

        this.GetDataForPoint(p);
      }
    }
  }

  public getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  public refresh() {
    for (let point of this.points) {
      this.GetDataForPoint(point);
    }
  }

  onBuildingChange() {
    // this.spinner.activate();

    // // this.form.get('points').setValue([]);


    // this.dataService.GetListOfPoints(this.building).subscribe(
    //   resp => {
    //     let data = [];
    //     this.listOfPoints = data.concat(resp["data"]);

    //     this.listOfPoints.forEach(x => {

    //       x.name += " ((" + this.resolveDataType(x.dataType) + "))";

    //       x.buildingName = this.listOfBuildings.find(x => x._id == this.building).name;
    //     })

    //     this.filterTags();
    //     this.form.get("tags").setValue([]);

    //     this.filterPoint();
    //     this.spinner.desactivate();
    //   },
    //   error => {
    //     console.log(error);
    //     this.spinner.desactivate();
    //   });
  }


  public resolveDataType(dataType) {
    switch (dataType) {
      case 1: return "Boolean";
      case 2: return "Boolean Writable";
      case 3: return "Numeric";
      case 4: return "Numeric Writable";
      case 5: return "Enum";
      case 6: return "Enum Writable";
      case 7: return "String";
      case 8: return "String Writable";
    }
  }

  public removePoint(point) {
    this.points.splice(this.points.indexOf(point), 1);
    this.advacendChart.removePoint(point.id);
  }

  displayByName(obj) {
    return obj ? obj.name : obj;
  }

  get point(): any {
    return this.form.get("point").value;
  }

  get points(): any {
    return this.form.get("points").value;
  }
  // get building(): string {
  //   return this.form.get("building").value;
  // }

  get tags(): string[] {
    return this.form.get("tags").value;
  }

  public copy(ord) {
    const el = document.createElement('textarea');
    el.value = ord;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}
