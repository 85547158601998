import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dummy-modal',
  templateUrl: './dummy-modal.component.html',
  styleUrls: ['./dummy-modal.component.css']
})
export class DummyModalComponent implements OnInit {

  public html:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    console.log(this.data);
    try {
      this.html = "<mat-dialog-content><pre>" + JSON.stringify(JSON.parse(this.data),null,"    ") + "</pre></mat-dialog-content>";
    } catch (e) {
      this.html = this.data;
    }
  }

}
