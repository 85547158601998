import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HighchartsComponent } from 'app/components/common/highcharts/highcharts/highcharts.component';
import { PointTagsComponent } from 'app/components/common/point-tags/point-tags.component';

@Component({
  selector: 'app-comparsion-chart',
  templateUrl: './comparsion-chart.component.html',
  styleUrls: ['./comparsion-chart.component.css']
})
export class ComparsionChartComponent implements AfterViewInit {

  @ViewChild("chart", { static: false }) chart: HighchartsComponent;

  public title: string = "";

  constructor(public dialogRef: MatDialogRef<PointTagsComponent>, @Inject(MAT_DIALOG_DATA) public data: { xAxis: any,  name: string, series: { id: string, name: string, data: any[] }[] }) { }

  ngAfterViewInit(): void {
    this.title = this.data.name;

    while (this.chart.Chart.series.length) {
      this.chart.Chart.series[0].remove(false);
    }

    this.chart.updateXaxis(this.data.xAxis);

    for(let s of this.data.series){
      this.chart.updateSeries(s, s.id, false, false);
    }

    this.chart.redraw();

  }


}
