import { Component, OnInit, Input, Host, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnInit {

  @Input() forLabel: string;
  @Input() message: string;
  @Input() icon: string = "fa-question-circle"
  @Input() size: number = 0;
  @Input() position: Position = Position.Above;


  constructor(private element: ElementRef) { }

  ngOnInit() {
  }
}

export enum Position {
  After = 'after',
  Before = 'before',
  Above = 'above',
  Below = 'below',
  Left = 'left',
  Right = 'right'
}
