import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { AdminPointService, IMetadata } from "../../../../services/data.service";
import { SelectItem } from "../../../../components/common/appSelect/select.item.interface";
import { SpinnerService } from "../../../../services/spinner.service";
import { AppI18nService } from "../../../../services/app.i18n.service";
import { AppService } from "../../../../services/app.service";
import { CompanyService } from "app/services/company.service";
import { LoginService } from "app/services/login.service";
import { SweetalertService } from "../../../../services/sweetalert.service";
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATEPICKER_FORMAT } from 'app/components/adapters/DatepickerFormats';
import { BuildingService } from 'app/services/building.service';
import { SessionService } from "app/services/session.service";

@Component({
  selector: "metadata-create",
  templateUrl: "metadata.create.template.html",
  styleUrls: ["./metadata.create.component.css"],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }
  ]
})

export class AdminMetadataCreateComponent implements OnInit, AfterViewInit {

  public form = new FormGroup({
    accuracy: new FormControl(),
    dateInstalled: new FormControl(),
    friendlyName: new FormControl(),
    manufacturer: new FormControl(),
    maxValue: new FormControl(),
    minValue: new FormControl(),
    nonZeroDataNotification: new FormControl(),
    subscribedPoint: new FormControl(),
    model: new FormControl(),
    pointName: new FormControl("", [Validators.required]),
    resolution: new FormControl(),
    tags: new FormArray([]),
    unit: new FormControl(),
    unitType: new FormControl(),
    building: new FormControl("", [Validators.required]),
    gaps: new FormArray([]),
    meterDataPoint: new FormControl(),
    meterType: new FormControl(),
    meterUseDelta: new FormControl(),
    approximation: new FormControl(),
    tempDataPoint: new FormControl(),
    serialNumber: new FormControl(),
  });

  typeChanged(item) {
    if (item.value != null && item.value != undefined) {
      this.units = [];

      // this.point.unit = "";
      // this.point.unitType = item.id;

      this.form.get('unit').setValue("");
      this.form.get("unitType").setValue(item.value);


      if (this.form.get('unitType').value) {
        for (let u of this.unitTypes[<any>this.form.get('unitType').value]) {
          this.units.push({ id: u, text: u });
        }
      }
    } else if (item.id != this.form.get("unitType").value) {
      this.units = [];
      this.form.get('unit').setValue("");
    }
  }

  // public unitChanged(item) {
  //   this.point.unit = item.id;
  // }

  // public meterTypeChanged(event) {
  //   this.point.meterType = event.id;
  // }

  // public meterApproximationChanged(event) {
  //   this.point.approximation = event.id;
  // }

  public newTag: string = "";

  public pointName = "";
  public point: IMetadata = { tags: [] };

  public units: SelectItem[] = [];
  public types: SelectItem[] = [];
  public meterTypes: SelectItem[] = [];
  public approximationOptions: SelectItem[] = [
    { id: "average", text: "average" },
    { id: "high", text: "highest" },
    { id: "low", text: "lowest" },
    { id: "sum", text: "sum" }
  ];

  public selectedType;
  public selectedUnit;
  public selectedMeterType;
  public selectedApproximation;
  private unitTypes = {};

  public buildings = [];

  public GetDateString(date) {
    return this.appService.GetDateString(date);
  }

  constructor(private sessionService: SessionService, private activatedRoute: ActivatedRoute, private buildingService: BuildingService, public alert: SweetalertService, private appService: AppService, private dataService: AdminPointService,
    private route: ActivatedRoute, private location: Location, private spinner: SpinnerService, private router: Router,
    public i18n: AppI18nService, private companyService: CompanyService, private loginService: LoginService) {


  }

  ngAfterViewInit() {
  }

  get tagsForm() {
    return (<FormArray>this.form.get('tags')).controls;
  }

  ngOnInit() {

    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;

      this.spinner.desactivate();
    })

    this.spinner.activate();//getUnits
    this.dataService.GetUnits().subscribe(
      resp => {
        let i = 0;
        for (let type of resp["data"]["UnitList"]) {
          this.types.push({ id: type["type"], text: type["type"] });
          this.unitTypes[type["type"]] = type["units"];
        }
        this.spinner.desactivate();
      },
      error => {
        this.spinner.desactivate();
      }
    );


    this.form.get("building").setValue(this.sessionService.building);

    console.log(this.form.value);

    this.spinner.activate();//get company tags
    this.companyService.getOne(this.loginService.userInfo.company).subscribe(
      companyRes => {
        if (companyRes.data.tags && companyRes.data.tags.length > 0) {
          this.groupedTags = [
            {
              name: "Used Tags", listOfTags: companyRes.data.tags.sort((a, b) => {
                return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
              })
            }
          ].concat(this.groupedTags);
          this.filterTags();
        }
        this.spinner.desactivate();
      },
      err => {
        console.log(err);
        this.spinner.desactivate();
      }
    );


    for (let type in this.appService.meterImages) {
      this.meterTypes.push({ id: type, text: type });
    }
    this.filterTags();


    // this.spinner.activate();
    // this.route.params.subscribe(
    //   params => {
    //     this.form.get('building').setValue(params["id"]);

    //   },
    //   error => {
    //     this.spinner.desactivate();
    //   }
    // );
    if (this.form.get('unitType').value) {
      this.spinner.activate();
      for (let u of this.unitTypes[<any>this.form.get('unitType').value]) {
        this.units.push({ id: u, text: u });
      }
      this.spinner.desactivate();
    }
  }

  public goBack() {
    this.router.navigate([".."], { relativeTo: this.activatedRoute, queryParams: { building: this.form.get('building').value } });
  }

  public add(event) {
    // this.point.tags.push(event.option.value);
    (this.form.get('tags') as FormArray).push(new FormControl(event.option.value))
    this.newTag = "";
    this.filterTags();
  }

  public addCustom() {
    // this.point.tags.push(this.newTag);
    (this.form.get('tags') as FormArray).push(new FormControl(this.newTag))
    this.newTag = "";

  }

  public removeTag(i) {
    (this.form.get('tags') as FormArray).removeAt(i);
  }

  // public remove(tag) {
  //   this.point.tags = this.point.tags.filter(x => x != tag);
  // }

  public submit() {
    this.spinner.activate();

    this.sessionService.building = this.form.value.building;

    this.dataService.SetMetadata(undefined, this.form.value).subscribe(
      resp => {
        this.alert.success("Success!", "Point metadata created!");
        this.spinner.desactivate();
        this.goBack();
      },
      erro => {
        console.log(erro);
        this.alert.info(this.i18n.translate("general.notice.notice"), this.i18n.translate("general.notice.requiredFields"));
        this.spinner.desactivate();
      }
    );

  }

  public filterTags() {
    for (let group of this.groupedTags) {
      group.filteredTags = group.listOfTags.filter(tag => tag.toLowerCase().indexOf(this.newTag.toLowerCase()) >= 0);
    }
  }

  public groupedTags: any[] = [
    {
      name: "haystack", listOfTags: [
        "absorption",
        "ac",
        "active",
        "ahu",
        "ahuRef",
        "air",
        "airCooled",
        "angle",
        "apparent",
        "area",
        "avg",
        "barometric",
        "blowdown",
        "boiler",
        "bypass",
        "centrifugal",
        "chilled",
        "chilledBeamZone",
        "chilledWaterCool",
        "chilledWaterPlant",
        "chilledWaterPlantRef",
        "chiller",
        "circ",
        "circuit",
        "closedLoop",
        "cloudage",
        "cmd",
        "co",
        "co2",
        "coldDeck",
        "condensate",
        "condenser",
        "connection",
        "constantVolume",
        "cool",
        "coolOnly",
        "cooling",
        "coolingCapacity",
        "coolingTower",
        "cur",
        "curErr",
        "curStatus",
        "curVal",
        "current",
        "damper",
        "dc",
        "delta",
        "device",
        "device1Ref",
        "device2Ref",
        "dew",
        "directZone",
        "direction",
        "dis",
        "discharge",
        "diverting",
        "domestic",
        "dualDuct",
        "ductArea",
        "dxCool",
        "effective",
        "efficiency",
        "elec",
        "elecHeat",
        "elecMeterLoad",
        "elecMeterRef",
        "elecPanel",
        "elecPanelOf",
        "elecReheat",
        "enable",
        "energy",
        "entering",
        "enum",
        "equip",
        "equipRef",
        "evaporator",
        "exhaust",
        "export",
        "faceBypass",
        "fan",
        "fanPowered",
        "fcu",
        "filter",
        "flow",
        "flue",
        "freezeStat",
        "freq",
        "gas",
        "gasHeat",
        "gasMeterLoad",
        "geoAddr",
        "geoCity",
        "geoCoord",
        "geoCountry",
        "geoCounty",
        "geoPostalCode",
        "geoState",
        "geoStreet",
        "header",
        "heat",
        "heatExchanger",
        "heatPump",
        "heatWheel",
        "heating",
        "his",
        "hisErr",
        "hisInterpolate",
        "hisStatus",
        "hisTotalized",
        "hot",
        "hotDeck",
        "hotWaterHeat",
        "hotWaterPlant",
        "hotWaterPlantRef",
        "hotWaterReheat",
        "humidifier",
        "humidity",
        "hvac",
        "id",
        "imbalance",
        "import",
        "irradiance",
        "isolation",
        "kind",
        "leaving",
        "level",
        "lightLevel",
        "lighting",
        "lights",
        "lightsGroup",
        "load",
        "mag",
        "makeup",
        "mau",
        "max",
        "maxVal",
        "meter",
        "min",
        "minVal",
        "mixed",
        "mixing",
        "multiZone",
        "net",
        "network",
        "networkRef",
        "neutralDeck",
        "occ",
        "occupancyIndicator",
        "occupied",
        "oil",
        "openLoop",
        "outside",
        "parallel",
        "perimeterHeat",
        "pf",
        "phase",
        "point",
        "power",
        "precipitation",
        "pressure",
        "pressureDependent",
        "pressureIndependent",
        "primaryFunction",
        "primaryLoop",
        "protocol",
        "pump",
        "reactive",
        "reciprocal",
        "refrig",
        "reheat",
        "reheating",
        "return",
        "rooftop",
        "run",
        "screw",
        "secondaryLoop",
        "sensor",
        "series",
        "singleDuct",
        "site",
        "siteMeter",
        "sitePanel",
        "siteRef",
        "solar",
        "sp",
        "speed",
        "stage",
        "standby",
        "steam",
        "steamHeat",
        "steamMeterLoad",
        "steamPlant",
        "steamPlantRef",
        "subPanelOf",
        "submeterOf",
        "sunrise",
        "tank",
        "temp",
        "thd",
        "total",
        "tripleDuct",
        "tz",
        "unit",
        "unocc",
        "uv",
        "valve",
        "variableVolume",
        "vav",
        "vavMode",
        "vavZone",
        "vfd",
        "visibility",
        "volt",
        "volume",
        "water",
        "waterCooled",
        "waterMeterLoad",
        "weather",
        "weatherCond",
        "weatherPoint",
        "weatherRef",
        "wetBulb",
        "wind",
        "writable",
        "writeErr",
        "writeLevel",
        "writeStatus",
        "writeVal",
        "yearBuilt",
        "zone"
      ]
    }
  ];
}
