import { Component, OnInit, ViewChild } from "@angular/core";
import { AppI18nService } from "app/services/app.i18n.service";
import { AppService } from "app/services/app.service";
import { SpinnerService } from "app/services/spinner.service";
import { SweetalertService } from "app/services/sweetalert.service";
import { AdminPointService } from "../../../../services/data.service";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from "moment-timezone";
import { BuildingService } from 'app/services/building.service';
import { MatDialog } from "@angular/material/dialog";
import { QrPopupComponent } from "app/components/common/qr-popup/qr-popup.component";
import { SessionService } from "app/services/session.service";
import { QueryparamsService } from "app/services/queryparams.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: "app-admin-meter-list",
  templateUrl: "./meter.list.component.html",
  styleUrls: ["./meter.list.component.css"]
})
export class AdminMeterListComponent implements OnInit {


  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource = new MatTableDataSource();

  ngAfterViewInit() {
  }

  public displayedColumns: string[] = ["icon", "meter", "lastReading", "lastReceivedData", "dateInstalled", "tooltip"]

  pageSizeOptions: number[] = [12, 20, 60];

  public building: string = null;
  public building_name: string;
  public meters: any[];
  public allMeters: any[];
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });
  public filterBuilding(){
    
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;

    let searchBase = this.buildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.buildings;
}
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    this.building = this.FilteredselectedBuilding._id;
    console.log(this.building);
    this.updateData();


  }

  constructor(private queryService: QueryparamsService,private sessionService: SessionService, private buildingService: BuildingService, public dataService: AdminPointService, private appService: AppService,
    public i18n: AppI18nService, public dialog: MatDialog, private spinnerService: SpinnerService, private alert: SweetalertService, private route: ActivatedRoute) {

  }


  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.updateData();
  }

  public openQR(value, label) {
      console.log(value);
      console.log(label);
    const dialogRef = this.dialog.open(QrPopupComponent, {
      width: '460px',
      data: {
        qr: [{ inputString: value, label: "Sensor token for " + label }],
        qrSize: 400
      }
    });
  }

  public filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;

    this.updateData();
  }

  public updateData() {
    if (this.building == null) return;
    this.sessionService.building = this.building;

    this.queryService.ReplaceParams({
      filter: this.filter,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      building: this.building
    }).subscribe(x => {

    });

    this.spinnerService.activate();
    this.dataService.GetAllPointsWithMetadata(this.building, true, this.filter, this.pagination.page, this.pagination.pageSize).subscribe(
      results => {

        // console.log( results );
        let t = [];

        let tmpMeters = results.data;
        for (let d of tmpMeters) {
          // d.lastReceivedData = this.appService.Ago(d.lastReceivedData);
          // d.firstReceivedData = this.appService.GetDateString(d.firstReceivedData);
          // d.dateInstalled = this.appService.GetDateString(d.dateInstalled);
          d.image = this.appService.meterImages[d.meterType];
          d.pointName = d.pointName ? d.pointName : d.ord.split("/").slice(-5).join("/");

          d.tooltip = "";
          // d.tooltip = "Full name: " + d.pointName;

          if (d.manufacturer) {
            d.tooltip += "\nManufacturer: " + d.manufacturer;
          }
          if (d.model) {
            d.tooltip += "\nModel: " + d.model;
          }
          // if (d.dateInstalled) {
          //   d.tooltip += "\nDate Installed: " + d.dateInstalled;
          // }
          if (d.unitType) {
            d.tolltip += "\nUnit Type: " + d.unitType;
          }
          if (d.unit) {
            d.tolltip += "\nUnit: " + d.unit;
          }
          if (d.tags) {
            d.tooltip += "\nTags: " + d.tags.join(", ");
          }
          // if (d.lastReceivedData) {
          //   d.tooltip += "\nLast Received Data: " + d.lastReceivedData;
          // }
          // if (d.firstReceivedData) {
          //   d.tooltip += "\nFirst Received Data: " + d.firstReceivedData;
          // }
          if (d.numberOfDocs) {
            d.tooltip += "\nNumber of Days with Data: " + d.numberOfDocs;
          }
          if (d.numberOfDataPoints) {
            d.tooltip += "\nNumber of Data Received: " + d.numberOfDataPoints;
          }
          if (d.numberOfGaps) {
            d.tooltip += "\nNumber of Days without Data: " + d.numberOfGaps;
          }

          t.push({
            id: d._id,
            building: d.building,
            tooltip: d.tooltip,
            friendlyName: d.friendlyName,
            dateInstalled: moment(d.dateInstalled).format("DD/MM/YYYY HH:mm"),
            dateInstalledTooltip: moment(d.dateInstalled).format("DD/MM/YYYY HH:mm Z z"),
            lastReceivedData: moment(d.lastReceivedData).format("DD/MM/YYYY HH:mm"),
            lastReceivedDataTooltip: moment(d.lastReceivedData).format("DD/MM/YYYY HH:mm Z z"),
            icon: d.meterType,
            serialNumber: d.serialNumber,
            // meter: this.appService.convertFromASCII(d.pointName),
            meter: d.displayOrd,
            lastReading: (d.lastData == 8 ? d.lastReceivedDataValue : ((Math.round(d.lastReceivedDataValue * 100) / 100) + " " + d.unit))
          })
        }
        this.dataSource.data = t;
        this.meters = tmpMeters;
        this.allMeters = tmpMeters;

        this.metersFound = t.length > 0;

        // this.pageChange(null);

        this.spinnerService.desactivate();
      },
      err => {
        this.spinnerService.desactivate();
        console.log(err);
        // this.alert.error(this.i18n.translate('general.errors.error'), this.i18n.translate('general.errors.unkwonError', err));
      }
    );
  }

  public metersFound = true;
  public buildings = [];
  ngOnInit() {

    this.queryService.params().subscribe(params => {
      this.pagination.pageSize = params["pageSize"] ? parseInt(params["pageSize"]) : 100;
      this.pagination.page = params["page"] ? parseInt(params["page"]) : 1;
      this.filter = params["filter"];

      if (params["building"]) {
        this.building = params["building"];
      }
      else if (this.sessionService.building != "") {
        this.building = this.sessionService.building;
      }


      this.updateData();
    })

    // this.route.queryParams.subscribe(params => {
    //   if (params["building"]) {
    //     this.building = params["building"];
    //   }
    //   else if (this.sessionService.building != "") {
    //     this.building = this.sessionService.building;
    //   }

    //   if (this.building != null) {
    //     this.updateData();
    //   }
    // });

    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {
      this.spinnerService.desactivate();

      this.buildings = x.data;
    })
  }

}
