import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { DriverService } from 'app/services/driver.service';
import { BuildingService } from 'app/services/building.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AdminDevicesFormComponent } from '../admin-devices-form/admin-devices-form.component';
import { SweetalertService } from 'app/services/sweetalert.service';
import { forkJoin } from 'rxjs';
import { SessionService } from 'app/services/session.service';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-admin-devices',
  templateUrl: './admin-devices.component.html',
  styleUrls: ['./admin-devices.component.css']
})
export class AdminDevicesComponent implements OnInit {
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup;
  public filterBuilding(){
    
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;
      
    let searchBase = this.buildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.buildings;
}
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    this.selectedBuilding = this.FilteredselectedBuilding.id;
    console.log(building_formed);
    // console.log(this.building);
    // this.updateData();
    this.onBuildingChange(this.selectedBuilding);


  }
  constructor(private sessionService: SessionService, public alert: SweetalertService, public dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef, private buildingService: BuildingService, private spinnerService: SpinnerService, private driverService: DriverService) { }

  public displayedColumns: string[] = ["index", "selected", "device", "version", "status", "security"]

  public hasDevices = true;

  public buildings: { id: string, name: string }[] = [];

  public datasource = new MatTableDataSource<any>();
  public statusMessage = "";
  public devicesInfo: any[] = [];

  public pagination: { hasNext: boolean, page: number, pageSize: number } = { hasNext: false, page: 1, pageSize: 100 }
  public changePage(event) {

    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.onBuildingChange(this.selectedBuilding);
  }

  private filter: string = "";
  public applyFilter(value) {
    this.filter = value;
    this.pagination.page = 1;

    this.onBuildingChange(this.selectedBuilding);
  }


  public checkAll(event) {
    if (event.checked) {
      for (let i of this.datasource.data) {
        this.devicesInfo[i.id].selected = true;
      }

      this.hasAllSelected = true;
    }
    else {
      for (let i of this.datasource.data) {
        this.devicesInfo[i.id].selected = false;
      }

      this.hasAllSelected = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  public callForm(id: string = null) {
    console.log(this.devicesInfo[id]);
    const dialogRef = this.dialog.open(AdminDevicesFormComponent, {
      width: '600px',
      data: {
        buildings: this.buildings,
        selectedBuilding: this.selectedBuilding,
        device: id == null ? null : { friendlyName: this.devicesInfo[id].friendlyName, hostID: this.devicesInfo[id].hostId, id: this.devicesInfo[id]._id, generateTags: this.devicesInfo[id].generateTags }
      },
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x && x.friendlyName) {

        if (id) {
          this.spinnerService.activate();
          this.driverService.update(x.building, { friendlyName: x.friendlyName, hostId: x.hostID }, id).subscribe(res => {
            this.spinnerService.desactivate();

            this.alert.success("Success!", "The device has been updated");

            this.onBuildingChange(this.selectedBuilding);
          })
        }

        else {
          this.spinnerService.activate();
          this.driverService.createDevice(x.building, { friendlyName: x.friendlyName, hostID: x.hostID, generateTags: x.autoGenerateTags }).subscribe(res => {
            this.spinnerService.desactivate();

            this.alert.success("Success!", "New device created");

            this.onBuildingChange(this.selectedBuilding);
          })
        }
      }
    })
  }

  get hasSelected() {
    for (let i in this.devicesInfo) {
      if (this.devicesInfo[i].selected)
        return true;
    }
    return false;
  }

  public block(id: string) {
    this.spinnerService.activate();
    this.driverService.blockDevice(this.selectedBuilding, id).subscribe(res => {
      this.spinnerService.desactivate();

      this.alert.success("Success!", "The device has been blocked");
      this.onBuildingChange(this.selectedBuilding);
    })
  }

  public unblock(id: string) {
    this.spinnerService.activate();
    this.driverService.unblockDevice(this.selectedBuilding, id).subscribe(res => {
      this.spinnerService.desactivate();

      this.alert.success("Success!", "The device has been unblocked");
      this.onBuildingChange(this.selectedBuilding);
    })
  }

  public delete() {
    let ids = [];
    for (let i in this.devicesInfo) {
      if (this.devicesInfo[i].selected)
        ids.push(i);
    }

    this.alert.custom({
      title: "Warning", text: "Do you want to delete the selected devices?", buttons: {
        yes: {
          text: "Yes",
          value: "yes"
        },
        exit: {
          text: "No",
          value: "no"
        }
      },
      icon: "warning",

    }).then(x => {
      if (x == "yes") {

        this.spinnerService.activate();
        let arr = [];
        ids.forEach(id => {
          let t = this.driverService.deleteDevice(this.selectedBuilding, id);
          arr.push(t);
        })

        forkJoin(arr).subscribe(res => {
          this.spinnerService.desactivate();
          this.alert.success("Success!", "Devices deleted.")

          this.onBuildingChange(this.selectedBuilding);
        })
      }
      else if (x == "no") {

      }
    })
  }

  public changeSelected(event) {
    if (this.devicesInfo[event].selected == false) {
      this.hasAllSelected = false;
      this.changeDetectorRef.detectChanges();

      return;
    }
    else {
      for (let i of this.datasource.data) {
        if (this.devicesInfo[i.id].selected == false) {
          this.hasAllSelected = false;
          this.changeDetectorRef.detectChanges();

          return;
        }
      }

      this.hasAllSelected = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  public hasAllSelected: boolean = false;

  public resetToken(id: string) {
    this.spinnerService.activate();
    this.driverService.resetToken(this.selectedBuilding, id).subscribe(res => {
      this.spinnerService.desactivate();

      this.alert.success("Success!", "New token generated");
      this.onBuildingChange(this.selectedBuilding);
    })
  }


  ngOnInit() {
    this.buildingForm = new FormGroup({
      buildings_input: new FormControl('')
      
    });
    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(x => {

      this.spinnerService.desactivate();

      this.buildings = x.data.map(t => {
        return {
          id: t._id,
          name: t.name
        }
      })

      if(this.sessionService.building){
        this.selectedBuilding = this.sessionService.building;
        this.onBuildingChange(this.selectedBuilding);
      }
    })
  }

  public onBuildingChange(event) {
    this.spinnerService.activate();
    this.devicesInfo = [];
    this.datasource.data = [];

    this.sessionService.building = event;
    this.driverService.getDevices(event, this.pagination.page, this.pagination.pageSize, this.filter).subscribe(d => {
      this.spinnerService.desactivate();

      let totalDevices = 0;
      let blockedDevices = 0;
      let activeDevices = 0;
      let awaitingDevices = 0;

      this.datasource.data = d.data.map((device, index) => {
        device.selected = false;
        device.showToken = false;

        let statusMessage = device.blocked ? "Blocked" : (device.registered ? "Registered (Active)" : "Awaiting First Contact");

        this.devicesInfo[device._id] = device;

        totalDevices++;

        if (device.blocked) {
          blockedDevices++;
        }
        else if (device.registered) {
          activeDevices++;
        }
        else {
          awaitingDevices++;
        }

        return {
          index: index + 1,
          id: device._id,
          version: device.serviceVersion? device.serviceVersion : "1.0.0",
          name: device.friendlyName + " / " + device.hostId,
          token: device.token,
          status: statusMessage,
          blocked: device.blocked
        }
      });

      this.hasDevices = d.data.length > 0;

      this.pagination.hasNext = d.data.length > this.pagination.pageSize;

      this.statusMessage = totalDevices + " total, " + blockedDevices + " blocked, " + activeDevices + " active, " + awaitingDevices + " awaiting."
    })
  }


  public showToken(id: string, state: boolean) {
    this.devicesInfo[id].showToken = state;
  }
}
