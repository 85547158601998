import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedChartComponent } from './advanced-chart.component';
import { AppHighstockModule } from '../highcharts/highstock/highstock.module';
import { IboxModule } from '../ibox/ibox.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [AdvancedChartComponent],
  imports: [
    CommonModule,
    AppHighstockModule,
    IboxModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    ColorPickerModule,
    MatDatepickerModule,
    MatExpansionModule,
    TranslocoModule
  ],
  exports:[
    AdvancedChartComponent
  ]
})
export class AdvancedChartModule { }
