import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsListComponent } from './groups-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PaginatorModule } from '../paginator/paginator.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IboxModule } from '../ibox/ibox.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [GroupsListComponent, ConfirmationComponent],
  imports: [
    MatSelectModule,
    CommonModule,
    MatTooltipModule,
    RouterModule,
    PaginatorModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    IboxModule,
    FormsModule,
    TranslocoModule
  ],
  exports: [
    GroupsListComponent
  ]
})
export class GroupsListModule { }
