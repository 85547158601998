import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GantAlarmsComponent, FormatMonthYear, FormatYear, FormatMonth } from './gant-alarms.component';
import { GanttModule } from '../highcharts/gantt/gantt.module';
import { PaginatorModule } from '../paginator/paginator.module';

@NgModule({
  declarations: [GantAlarmsComponent, FormatMonthYear, FormatYear, FormatMonth],
  imports: [
    CommonModule,
    GanttModule,
    PaginatorModule
  ],
  exports: [GantAlarmsComponent, FormatMonthYear, FormatYear, FormatMonth]
})
export class GantAlarmsModule { }
