import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import swal from "sweetalert";

@Injectable()
export class SweetalertService {

  public success( title: string = "Success!", text: string, timer: number = 500000 ): Promise<any> {
    return swal( { title: title, text: text, icon: "success", timer: timer } );
  }

  public error( title: string, text: string, timer: number = 5000 ): Promise<any> {
    return swal( { title: title, text: text, icon: "error", timer: timer } );
  }

  public warning( title: string, text: string, timer: number = 5000 ): Promise<any> {
    return swal( { title: title, text: text, icon: "warning", timer: timer } );
  }

  public info( title: string, text: string, timer: number = 5000 ): Promise<any> {
    return swal( { title: title, text: text, icon: "info", timer: timer } );
  }

  public yesAndNow( title: string, text: string, icon: string ): Observable<boolean> {
    return new Observable( ( observer: Observer<any> ) => {
      this.custom( {
        title: title,
        text: text,
        buttons: {
          yes: {
            text: "Yes",
            value: "yes"
          },
          exit: {
            text: "No",
            value: "no"
          }
        },
        icon: icon
      } ).then( res => {
        if( res == "yes" ) {
          observer.next( true );
        } else if( res == "no" ) {
          observer.next( false );
        }
      } );
    } );
  }

  public custom( options ): Promise<any> {
    return swal( options );
  }
}
