import { Component, OnInit, ViewChild } from "@angular/core";
import { AppService } from "app/services/app.service";
import { NgForm } from "@angular/forms";
import { LoginService } from "app/services/login.service";
import { SpinnerService } from "app/services/spinner.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SweetalertService } from "../../../services/sweetalert.service";
import { UserService } from "../../../services/user.service";
import { UserType } from "../../../interfaces/baseinterface";
import { PlatformLocation } from "@angular/common";
import { ThemingService } from "app/services/theming.service";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-login",
  templateUrl: "login.template.html",
  styleUrls: ["./login.component.css"]
})

export class LoginComponent implements OnInit {

  @ViewChild("loginForm", { static: true }) form: NgForm;
  public needLogin = false;

  public defaultImages: string[] = ["shutterstock_1044043600_1080.jpg", "shutterstock_1247672974_1080.jpg"];

  constructor(private themingService: ThemingService, private route: ActivatedRoute, private userService: UserService,
    private alert: SweetalertService, public appService: AppService, private translocoService: TranslocoService,
    private loginService: LoginService, public spinner: SpinnerService, private router: Router) {

    this.spinner.activate();
    this.loginService.isAuthenticated().subscribe(
      (res) => {
        this.route.queryParams.subscribe(query => {
          if (query["returnUrl"]) {
            this.router.navigate([query["returnUrl"]]);
          } else {
            this.router.navigate(["/admin/"]);
            // if (this.loginService.userInfo.type == UserType.Admin) {
            //   this.router.navigate(["/admin/"]);
            // } else if (this.loginService.userInfo.type == UserType.Employee) {
            //   this.router.navigate(["/employee/"]);
            // } else if (this.loginService.userInfo.type == UserType.Root) {
            //   this.router.navigate(["/root/"]);
            // } else if (this.loginService.userInfo.type == UserType.Dashboard) {
            //   this.loginService.logout(false, false, false);
            //   this.router.navigate(["/"]);
            // }
          }
          this.spinner.desactivate();
        },
          (err) => {
            if(err.error == "User Session not found."){
              this.router.navigate(["/admin/"]);
            }
            console.log(err);
            this.spinner.desactivate();
          });
      },
      (error) => {
        console.log(error.error);
        console.log(error);
        if(error.error === "User Session not found."){
          this.router.navigate(["/admin/"]);
        }
        else {
          console.log(error);
          this.needLogin = true;
        }
        this.spinner.desactivate();
      }
    );
  }

  public backgroundImage: string = this.defaultImages[0];
  ngOnInit() {

    let context = this.defaultImages;
    if (this.themingService.hasProperty("loginBackgroundImages")) {
      context = this.themingService.dictionary.loginBackgroundImages;
    }

    this.backgroundImage = context[Math.floor(context.length * Math.random())]
  }

  public currentYear() {
    return new Date().getFullYear();
  }

  public canSubmit() {
    return this.form.valid;
  }

  public onSubmit() {
    if (this.canSubmit) {
      this.spinner.activate();
      this.loginService.authenticate(this.form.value.email, this.form.value.password).subscribe(
        (result: any) => {
          if (result.forcePasswordChange) {
            localStorage.setItem(this.appService.productKey + ".loggedUser", JSON.stringify(result));
            this.loginService.params = { username: this.form.value.email, password: this.form.value.password, loginInfo: result };
            this.alert.info(this.translocoService.translate('auth.login.alerts.securityWarning'),
              this.translocoService.translate('auth.login.alerts.updatePassword'));
            this.spinner.desactivate();
            this.router.navigate(["/auth/changePassword"], { relativeTo: this.route });
          } else {
            localStorage.setItem(this.appService.productKey + ".loggedUser", JSON.stringify(result));
            let userInfo = this.loginService.setUserInfo(result);
            let type = userInfo.type;
            console.log(userInfo);
            this.route.queryParams.subscribe(query => {
              if (query["returnUrl"]) {
                this.router.navigate([query["returnUrl"]]);
              } else {
                if (this.loginService.userInfo.type == UserType.Root) {
                  this.router.navigate(["/root/companies"]);
                }
                else {
                  this.router.navigate(["/admin/"]);
                }
                // if (this.loginService.userInfo.type == UserType.Admin) {
                //   this.router.navigate(["/admin/"]);
                // } else if (this.loginService.userInfo.type == UserType.Employee) {
                //   this.router.navigate(["/employee/"]);
                // } else if (this.loginService.userInfo.type == UserType.Root) {
                //   this.router.navigate(["/root/"]);
                // } else if (this.loginService.userInfo.type == UserType.Dashboard) {
                //   this.loginService.logout(true, false, false);
                //   this.router.navigate(["/"]);
                // }
              }

              this.spinner.desactivate();
            },
              (err) => {
                console.log(err);
                this.spinner.desactivate();
              });
          }
        },
        error => {
          console.log(error);
          this.spinner.desactivate();
          if (error.message === "User is not confirmed.") {
            this.loginService.params = { username: this.form.value.email, password: this.form.value.password };
            this.alert.info(this.translocoService.translate('auth.login.alerts.securityWarning'),
              this.translocoService.translate('auth.login.alerts.updatePassword'));
            this.router.navigate(["/auth/verify", this.form.value.email]);
          } else if (error.code === "UserNotFoundException") {
            this.alert.info(this.translocoService.translate('auth.login.alerts.securityWarning'),
              this.translocoService.translate('auth.login.alerts.invalidUsernameAndPassword'));
          } else {
            this.alert.info(this.translocoService.translate('auth.login.alerts.securityWarning'),
              this.translocoService.translate('auth.login.alerts.invalidUsernameAndPassword'));
          }
        }
      );
    }
  }
}
