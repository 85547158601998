import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FaultsService } from 'app/services/faults.service';
import { SpinnerService } from 'app/services/spinner.service';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { FutureDecisionsDate } from 'app/components/classes/futuredecisions.date';
import { BuildingService } from 'app/services/building.service';
import { TranslocoService } from '@ngneat/transloco';
import { SessionService } from 'app/services/session.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-faults-list',
  templateUrl: './faults-list.component.html',
  styleUrls: ['./faults-list.component.css']
})
export class FaultsListComponent implements OnInit {
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });
  public filterBuilding(){
    
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;

    let searchBase = this.buildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
      console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.buildings;
}
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    this.building = this.FilteredselectedBuilding._id;
    console.log(this.building);
    this.refresh();


  }
  constructor(private sessionService: SessionService, private transloco: TranslocoService, private buildingService: BuildingService, private spinner: SpinnerService) { }

  public datasource = new MatTableDataSource<any>();;
  public displayedColumns = ["severity", "date", "status", "issue", "apilink", "subject", "raisedby", "assignedto"];

  public pagination = { pageSize: 100, page: 1, hasNext: false }

  public hasResults = true;

  public building = "";
  public buildings = [];
  public severityfilter = [1, 5, 10];
  public issueType = [1, -1];

  public severityenym = [1, 5, 10];
  public issuetypeenum = [1, -1];
  // public date;
  public onlyOpen = true;

  // private filter = "";

  public applyFilter(filter) {
    this.filter = filter;

    this.refresh();
  }

  public date = new FutureDecisionsDate();
  public timewindow = "One day";


  public possibleWindows = ["One day", "Last three days", "Week", "Month", "Year"];
  public today = moment();

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  public sortQuery = null;

  public sortData(event) {
    let q = "";

    if (event.direction != "") {
      this.sortQuery = {};

      switch (event.active) {
        case "date":
          q = "timestamp";
          break;
        case "issue":
          q = "type";
          break;
        case "subject":
          q = "subject"
          break;
        case "assignedto":
          q = "assign"
          break;
        case "severity":
          q = "severity"
          break;
      }

      if (event.direction == "asc") {
        this.sortQuery[q] = 1;
      }
      else {
        this.sortQuery[q] = -1
      }

      // this.sortQuery = q;
    }
    else this.sortQuery = null;


    this.refresh();
  }

  ngOnInit(): void {

    this.spinner.activate();
    this.buildingService.getAll().subscribe(x => {
      this.buildings = x.data;
      this.spinner.desactivate();

      this.building = this.sessionService.building;
      if (this.building) {
        this.refresh();
      }
    })
  }

  public pageChanged(pagination) {
    this.pagination.page = pagination.page;
    this.pagination.pageSize = pagination.pageSize;

    this.refresh();
  }

  public filter = "";

  public refresh() {

    if (!this.building) return;

    let q = "";

    let start = this.date.dateObject.clone().startOf("day");
    let end;
    switch (this.timewindow) {
      case "One day":
        end = start.clone().add(1, "day").startOf("day");
        break;
      case "Last three days":
        end = start.clone().startOf("day");
        start = start.subtract(3, "day").startOf("day");
        break;
      case "Week":
        start = start.startOf("week");
        end = start.clone().endOf("week");
        break;
      case "Month":
        start = start.startOf("month");
        end = start.clone().endOf("month");
        break;
      case "Year":
        start = start.startOf("year");
        end = start.clone().endOf("year");
        break;
    }

    q = `start=${start.toISOString()}&end=${end.toISOString()}&type=${this.issueType.join("&type=")}&severity=${this.severityfilter.join("&severity=")}`

    if (this.sortQuery != null) {
      q += "&sort=" + encodeURIComponent(JSON.stringify(this.sortQuery));
    }

    this.datasource.data = [];

    this.spinner.activate();
    this.buildingService.getFaults(this.building, this.pagination.page, this.pagination.pageSize, this.filter, q).subscribe(x => {
      this.spinner.desactivate();
      console.log(x);

      this.pagination.hasNext = x.data.length > this.pagination.pageSize;

      this.hasResults = x.data.length > 0;

      this.datasource.data = x.data.map(t => {
        return {
          date: moment(t.created_at).format("DD/MM/YYYY HH:mm"),
          dateTooltip: moment(t.created_at).format("DD/MM/YYYY HH:mm Z z"),
          status: t.resolved ? this.transloco.translate("admin.faults.list.resolved") : (t.notFault ? this.transloco.translate("admin.faults.list.notfault") : this.transloco.translate("admin.faults.list.open")),
          issue: this.transloco.translate("admin.faults.issueenum." + t.type),
          subject: t.subject,
          description: t.description,
          raisedBy: t.createdBy ? `${t.createdBy.person.name} (${t.createdBy.email})` : this.transloco.translate('admin.faults.list.app'),
          assignedto: t.assign ? `${t.assign.person.name} (${t.assign.email})` : this.transloco.translate('admin.faults.list.unassigned'),
          severity: this.transloco.translate('admin.faults.severityenum.' + t.severity),
          severityCode: t.severity,
          _id: t._id,
          apiLink: t.apiKey,
          building: t.building
        }
      });
    });
  }

}
