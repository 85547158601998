import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { AppHighstockComponent } from '../highcharts/highstock/highstock.component';

@Component({
  selector: 'app-prediction-chart',
  templateUrl: './prediction-chart.component.html',
  styleUrls: ['./prediction-chart.component.css']
})
export class PredictionChartComponent implements OnInit, OnChanges {
  @Input() dataToSelect: { name: string, id: any }[] = [{
    name: "option 1",
    id: 0
  },
  {
    name: "option 2",
    id: 1
  }];

  public modelTypes: { label: string, id: string }[] = [
    {
      id: "yesterday",
      label: "Yesterday, this time"
    },
    {
      id: "lastWeekd",
      label: "This day, week before"
    },
    {
      id: "fourweeks",
      label: "This day, four weeks before combined"
    },
    {
      id: "weekdays",
      label: "Combined model of previous weekdays (5 days)"
    },
    {
      id: "weekend",
      label: "Combined model of previous weekend (2 days)"
    },
  ]

  public modelType: string = "yesterday";

  @Input() livepredictionData: { rawData: any[], filtered: any[], prediction: any[], sd: any[] };
  @Input() yesdterdayData: { rawData: any[], filtered: any[], prediction: any[] };

  @Output() onDataSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("livePrediction", { static: true }) livePrediction: AppHighstockComponent;
  @ViewChild("yesterdayResults", { static: true }) yesterdayResults: AppHighstockComponent;

  public changedSelection() {
    if (this.modelType != "" && (this.selectedData != null || this.selectedData != undefined))
      this.onDataSelected.emit(this.selectedData);
  }

  public tiles: { title: string, content: string, summary: string, footer: string }[] = [{
    title: "test title 1",
    content: "content 1",
    summary: "summary 1",
    footer: "footer 1"
  },
  {
    title: "test title 2",
    content: "content 2",
    summary: "summary 2",
    footer: "footer 2"
  }]
  public selectedTile = null;
  public selectTile(tile){
    this.selectedTile = tile;
  }

  public selectedData = null;

  public livepredictionOptions = {
    chart: {

    },

    rangeSelector: {
      enabled: false
    },

    title: {
      text: ''
    },

    tooltip: {
    },

    series: [
      {
        name: 'Filtered Data',
        id: "filtered",
        type: 'spline',
        showInNavigator: true,
        // color: "#1ab394"
      },
      {
        name: 'Raw Data',
        id: "raw",
        type: 'spline',
        showInNavigator: true,
        // color: "#ED5565"
      },
      {
        name: 'Prediction',
        id: "prediction",
        type: 'spline',
        zIndex: 10,
        showInNavigator: true,
        // color: "black"
      },
      {
        name: 'Standard Deviation',
        id: "sd",
        type: 'areasplinerange',
        showInNavigator: true,
        // color: "#23c6c8"
      }]
  };

  public yesterdayOptions = {
    chart: {

    },

    rangeSelector: {
      enabled: false
    },

    title: {
      text: ''
    },

    tooltip: {
    },

    series: [
      {
        name: 'Filtered Data',
        id: "filtered",
        type: 'spline',
        showInNavigator: true,
      },
      {
        name: 'Raw Data',
        id: "raw",
        type: 'spline',
        showInNavigator: true,
      },
      {
        name: 'Prediction',
        id: "prediction",
        type: 'spline',
        zIndex: -1,
        showInNavigator: true,
      }
    ]
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.livepredictionData.currentValue.rawData.length > 0) {
      this.updatelivePredictionChart();
      this.updateyesterdayChart();
    }
  }

  public updatelivePredictionChart() {
    this.livePrediction.updateSeries({ data: this.livepredictionData.rawData, id: "raw" }, "raw");
    this.livePrediction.updateSeries({ data: this.livepredictionData.filtered, id: "filtered" }, "filtered");
    this.livePrediction.updateSeries({ data: this.livepredictionData.prediction, id: "prediction" }, "prediction");
    this.livePrediction.updateSeries({ data: this.livepredictionData.sd, id: "sd" }, "sd");
  }

  public updateyesterdayChart() {
    this.yesterdayResults.updateSeries({ data: this.yesdterdayData.prediction, id: "prediction" }, "prediction");
    this.yesterdayResults.updateSeries({ data: this.yesdterdayData.rawData, id: "raw" }, "raw");
    this.yesterdayResults.updateSeries({ data: this.yesdterdayData.filtered, id: "filtered" }, "filtered");
  }

  constructor() { }

  ngOnInit() {
  }

}
