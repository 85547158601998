import { AfterViewInit, Component, ViewChild, ViewEncapsulation, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerService } from "../../../services/spinner.service";
import { AdminPointService } from "../../../services/data.service";
import { AppHighstockComponent } from "../../../components/common/highcharts/highstock/highstock.component";
import * as moment from "moment-timezone";
import { IBuilding } from "../../../interfaces/building";
import { LoginService } from "../../../services/login.service";
import { CompanyService } from "../../../services/company.service";
import { AppI18nService } from "../../../services/app.i18n.service";
import { BuildingService } from "../../../services/building.service";
import { getMaxArrayObj, getMinArrayObj } from "../../../app.helpers";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { DATEPICKER_FORMAT } from "app/components/adapters/DatepickerFormats";
import { SessionService } from "app/services/session.service";
import { FutureDecisionsDate } from "app/components/classes/futuredecisions.date";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { SearchPopupComponent } from "app/components/common/search-popup/search-popup.component";
import { parseDataToFrequency } from "../../../helpers/typeHelper";

@Component({
  selector: "app-admin-point-data",
  templateUrl: "./admin-sensor-data.component.html",
  styleUrls: ["./admin-sensor-data.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }
  ]
})

export class AdminPointDataComponent implements AfterViewInit {

  private today = new FutureDecisionsDate();

  public maxDate = moment.utc().endOf("day");

  @ViewChild("pointSelector", { static: true }) pointSelector: ElementRef;

  public charTypes: { type: string, name: string }[] = [
    { type: "spline", name: "Spline" },
    { type: "line", name: "Line" },
    { type: "area", name: "Area" },
    { type: "column", name: "Column" },
    { type: "areaspline", name: "Area Spline" },
  ]

  public form = new FormGroup({
    start: new FormControl(this.today.clone().add(-1, "d"), [Validators.required]),
    end: new FormControl(this.today.clone(), [Validators.required]),
    tags: new FormControl([]),
    building: new FormControl(null, [Validators.required]),
    point: new FormControl(""),
    points: new FormControl([]),
    timeSelection: new FormControl("Day")
  });

  public max = new FutureDecisionsDate().endOf("day");
  public possibleWindows = ["Day", "Month", "Week"];

  @ViewChild("chartStock", { static: true }) chart: AppHighstockComponent;
  public ChangeToType: any[] = [["Change to Bar chart", "line"], ["Change to Line chart", "column"]];
  public chartType = 0;

  private series: any = {};

  public pointsStatus: { unit: string, points: number, status: string, type: string, csv: { filename: string, data: any }, sampleFrequency?: any }[] = [];

  private startDate = this.today.clone().add(-1, "d");
  private endDate = this.today.clone();

  // public listOfPoints: any[] = [];
  public filteredPoints: any[] = [];
  private initialPoint: any = undefined;

  public filteredBuildings: IBuilding[];
  private listOfBuildings: IBuilding[];
  public buildingFilter: any = "";

  public listOfTags = [];
  public filteredTags = [];
  

  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup = new FormGroup({
    buildings_input: new FormControl('')
    
  });

  public filterBuilding(){
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;
      

    let searchBase = this.listOfBuildings;
    this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
    //   console.log(this.BuildingSearchResult);
    // return this.buildings.filter(building => 
    //   this.buildings.includes(filterValue));
}else {
    this.BuildingSearchResult = this.listOfBuildings;
}
  }
  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.listOfBuildings;
    // console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    // this.building = this.FilteredselectedBuilding._id;


    this.buildingFilter = this.FilteredselectedBuilding;
    this.form.get('building').setValue(this.FilteredselectedBuilding._id);
    this.filterTags();
    this.sessionService.building = this.FilteredselectedBuilding._id;
    // console.log(this.building);
    // this.onBuildingChange();


  }



  chartOptions: any = {
    chart: {
      zoomType: "x",
      height: 600,
      // alignTicks: false
    },
    plotOptions: {
      series: {
        connectNulls: false,
        showEmpty: false,
        dataGrouping: {
          enabled: false,
          // enabled: true,
          // units: [
          //   ["minute", [15, 30]],
          //   ["hour", [1, 2, 6, 12]],
          //   ["day", [1]],
          //   ["week", [1]],
          //   ["month", [1, 2, 3, 6]],
          //   ["year", [1]]
          // ]
        }
      }
    },
    xAxis: {
      type: "datetime",
      ordinal: false
    },
    navigator: {
      // enabled: false,
      series: {
        includeInCSVExport: true
      }
    },
    legend: {
      floating: false,
      enabled: true,
      align: "center",
      shadow: true,
      y: 50,
      verticalAlign: "top"
    },
    tooltip: {
      enabled: true,
      valueDecimals: 2,
      format: "{point.y:." + 2 + "f}",
      shared: true,
      split: false
    },
    rangeSelector: {
      inputEnabled: false,
      buttons: [
        /*{
         type: "hour",
         count: 1,
         text: "1h"
         }, {
         type: "day",
         count: 1,
         text: "1d"
         }, {
         type: "week",
         count: 1,
         text: "1w"
         }, {
         type: "month",
         count: 1,
         text: "1m"
         }, {
         type: "year",
         count: 1,
         text: "1y"
         },*/ {
          type: "all",
          text: "All"
        }
      ],
      selected: 1, // all
      buttonTheme: {
        width: null
      }
    }
  };

  ngOnInit() {
    // console.log('get ngOnInit');
    if(this.route.snapshot.queryParamMap.get("point")){
    //   console.log(this.route.snapshot.queryParamMap);

      // this.building = this.route.snapshot.queryParamMap.get("building");
    }
  }

  ngAfterViewInit() {
    this.route.params.subscribe(params=>{
    //   console.log(params);
  })
    // console.log(this.route.snapshot.paramMap);
    this.form.get("point").valueChanges.subscribe(x => {
    //   console.log(x);
      // this.filterPoint();
    })

    this.route.queryParams.subscribe(async (query) => {
    //   console.log("QUERY POINT");
    //   console.log(query["point"]);
      // this.form.get('points').setValue(query["point"]);
      this.spinner.activate();

      this.companyService.getCompanyBuildingsTags().subscribe(c => {
        this.listOfTags = c;

        this.buildingService.getAll().subscribe(buildings => {
          this.listOfBuildings = buildings.data;
          this.filteredBuildings = this.listOfBuildings.filter(building => {
            let add = building.name.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0 ||
              building.description.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0;
            if (building.address) {
              add = add || building.address.street.toLowerCase().indexOf(this.buildingFilter.toLowerCase()) >= 0;
            }
            return add;
          });

          if (this.sessionService.building && query["building"] == undefined) {
            this.buildingFilter = this.listOfBuildings.find(x => x._id == this.sessionService.building);
            this.form.get('building').setValue(this.sessionService.building);
            this.onBuildingChange();
          }

          if (query["building"] != undefined) {
            for (let b of this.filteredBuildings) {
            //   console.log(b.name + "||" + query["building"]);
              if (b._id === query["building"]) {
                this.buildingFilter = b;
                this.FilteredselectedBuilding = b;
                this.form.get('building').setValue(b._id);
                this.filterTags();
                this.sessionService.building = b._id;
                // this.onBuildingChange( { option: { value: b } }, undefined );
              }
            }
            if (this.buildingFilter) {
              // this.dataService.GetListOfPoints(this.building).subscribe(points => {
                this.dataService.GetPointMetadata(this.building, query["point"]).subscribe(points => {
                // console.log(points);
                let data = [];
                let point = points.data;
                point.name = point.friendlyName;
                point.id = point._id;
                point.displayOrd = point.ord;
                this.initialPoint = point;
                // console.log(this.initialPoint);
                    this.form.get('points').setValue(point.id);

                    this.pointsStatus[point.id] = {
                      status: "Normal Data",
                      type: "spline",
                      csv: null,
                      points: 0,
                      unit: ""
                    }
                    data.push(point);
                    this.GetDataForPoint(point)
                    
                  

                // for (let point of points["data"]) {
                //   console.log("for of points");
                //   console.log(point.id + " / " + query["point"]);
                //   if (point.id == query["point"]) {
                //     this.initialPoint = point;
                //     this.form.get('points').setValue(point.id);

                //     this.pointsStatus[point.id] = {
                //       status: "Normal Data",
                //       type: "spline",
                //       csv: null,
                //       points: 0,
                //       unit: ""
                //     }
                //   }
                //   data.push(point);
                // }
                // this.listOfPoints = data;


                if (this.initialPoint) {
                  this.form.get("points").setValue([this.initialPoint]);
                  // this.filterPoint();
                  // this.GetDataForPoint(this.initialPoint);
                }


                this.spinner.desactivate();
              })
            }
            else {
            }

          }
          else {
            this.spinner.desactivate();
          }


        })
      });
    });
  }

  constructor(public dialog: MatDialog, private sessionService: SessionService, public router: Router, private dataService: AdminPointService, private spinner: SpinnerService,
    private route: ActivatedRoute, public i18n: AppI18nService, private buildingService: BuildingService,
    private companyService: CompanyService, private loginService: LoginService) {
  }

  public leftCount = 0;
  public rightCount = 0;

  public generateCSV(csv) {
    var str = 'timestamp, value, unit, ord\r\n';

    for (var i = 0; i < csv.data.length; i++) {
      var line = '';


      for (var index in csv.data[i]) {
        if (line != '') line += ','

        line += csv.data[i][index];
      }

      str += line + '\r\n';
    }

    var file = new Blob([str], {
      type: "text/csv;charset=UTF-8"
    });
    if ((<any>window.navigator).msSaveOrOpenBlob) // IE10+
      (<any>window.navigator).msSaveOrOpenBlob(file, csv.filename + ".csv");
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = csv.filename + ".csv";
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  private GetDataForPoint(point) {

    let value = this.form.value;

    this.spinner.activate();
    let start = value.start.dateObject.clone();
    let end = start.clone();

    switch (value.timeSelection) {
      case "Month":
        start = start.startOf("month");
        end = end.endOf("month");
        break;
      case "Day":
        start = start.startOf("day");
        end = end.endOf("day");
        break;
      case "Week":
        start = start.startOf("week");
        end = end.endOf("week");
        break;
    }


    this.dataService.GetDataForPoint({
      building: this.form.get("building").value,
      point: point.id,
      from: start.format("YYYY-MM-DD"),
      to: end.format("YYYY-MM-DD")
    }
    ).subscribe(
      resp => {
        let pointData = [];
        let filteredData = [];
        let current = moment.utc();

        if (resp["data"].length == 0) {
          this.pointsStatus[point.id].status = `No readings.`
        }

        else if (resp["data"][0].dataType == 8) {
          let lastValue = "";

          let at = "";
          for (let week of resp.data) {

            let date = moment.utc(week["timestamp"]);
            let dt = date.valueOf();

            for (let i = 1; i < 8; i++) {
              for (let j = 0; j < 1440; j++) {

                if (date >= end || date <= start) {
                  break;
                }

                if (week.weekPointData[i][j] != null && week.weekPointData[i][j] != undefined) {
                  lastValue = week.weekPointData[i][j];
                  at = moment(dt).format("DD/MM/YYYY HH:mm Z")
                }
                dt += 60000;
              }
            }
          }

          this.pointsStatus[point.id].status = `Text value detected. Last message was '${lastValue}' at ${at}.`
        }

        else {

          for (let d of resp["data"]) {

            let date = moment.utc(d["timestamp"]).startOf("day");

            for (let wd of Object.keys(d.weekPointData)) {
              let dataArray = d.weekPointData[wd];

              if (date < start) {
                date = date.add(1, "day");
                continue;
              }
              else if (date > end) {
                break;
              }

              let dt = date.valueOf();

              // console.log(wd);
              // console.log(dataArray);

              for (let i = 0; i < 1440; i++) {


                if (dt > current.valueOf()) {
                  break;
                }

                if (dataArray[i] == undefined || dataArray[i] == null) {
                  pointData.push([dt, null]);
                }
                else {
                  pointData.push([dt, Number(dataArray[i])]);
                }


                dt += 60000;
              }

              date = date.add(1, "day");
            }

            console.log(pointData);
          }

          pointData.sort((a, b) => {
            return a[0] < b[0] ? -1 : 1;
          });

          filteredData.sort((a, b) => {
            return a[0] < b[0] ? -1 : 1;
          });

          if (pointData.length > 0) {
            this.chart.removeById(point.id);
            this.chart.removeById(point.id + "_filtered");

            let isValid = false;
            if (resp["data"][0].dataType == 1) {
              isValid = true;
            }
            else {
              for (let d of pointData) {
                if (d.length > 0) {
                  isValid = true;
                  if (d[1] != null)
                    d[1] = Math.floor(d[1] * 100) / 100
                }
              }
            }


            if (isValid) {
              let min = getMinArrayObj(pointData, 1);
              let max = getMaxArrayObj(pointData, 1);

              if (point.unit) {
                this.pointsStatus[point.id].unit = point.unit;
              }
              else {
                this.pointsStatus[point.id].unit = "No Metadata";
              }


              this.pointsStatus[point.id].csv = {
                filename: this.listOfBuildings.find(x => x._id == value.building).name + " - " + point.name + "- from " + start.format("YYYY-MM-DD") + " to " + end.format("YYYY-MM-DD"),
                data: pointData.map(x => {

                  return {
                    date: moment(x[0]).toISOString(),
                    value: x[1],
                    unit: this.pointsStatus[point.id].unit,
                    point: point.ord
                  }
                })

              }

              if (min == max || min == null) {
                this.pointsStatus[point.id].status = `Constant value ${max} for the selected time window.`
              }
              else {
                this.pointsStatus[point.id].sampleFrequency = (point.sampleFrequency || 1);
                this.pointsStatus[point.id].status = "Normal Data";


                if (resp.data[0].dataType == 1) {
                  let id = "";
                  if (this.binaryHelper != "" && this.maxMinHelper.find(x => x.id == this.binaryHelper).ids.indexOf(point.id) >= 0) {
                    id = this.maxMinHelper.find(x => x.id == this.binaryHelper).id;
                  }
                  else {
                    let isRight = false;
                    if (this.leftCount == 2) {
                      this.rightCount++;
                      isRight = true;
                    }
                    else {
                      this.leftCount++;
                      isRight = false;
                    }

                    let n = {
                      id: "id" + this.yAxisCounter,
                      average: 0,
                      min: 0,
                      max: 1,
                      pos: this.yAxisCounter,
                      ids: [point.id],
                      isRight: isRight
                    };

                    this.yAxisCounter++;

                    this.binaryHelper = n.id;
                    this.maxMinHelper.push(n)



                    if (n.pos == 0) {
                      this.chart.updateYaxisByPosition({
                        min: 0,
                        id: n.id,
                        max: 1,
                        showLastLabel: true,
                        showEmpty: false,
                        title: {
                          enabled: false,
                        },
                        opposite: isRight
                      }, 0);
                    }
                    else {
                      this.chart.updateYaxis({
                        min: 0,
                        id: n.id,
                        tickInterval: 1,
                        showLastLabel: true,
                        max: 1,
                        showEmpty: false,
                        title: {
                          enabled: false,
                        },
                        opposite: isRight
                      }, n.id)
                    }

                    id = n.id;
                  }
                  this.pointsStatus[point.id].sampleFrequency = (point.sampleFrequency || 1)*60000;
                  this.series[point.id] = {
                    id: point.id/*point.id*/,
                    name: point.name,
                    data: parseDataToFrequency(pointData, point.sampleFrequency),
                    gapSize: (point.sampleFrequency || 1)*60000,
                    gapUnit: 'value',
                    type: "spline",
                    showInNavigator: true,
                    yAxis: id,
                  };

                  this.chart.updateSeries(this.series[point.id], point.id);
                }
                else {
                  let axis = this.checkYAxis(min, max, point.id);
                  // console.log(axis);
                  // console.log(axis);
                  // this.chart.updateYaxis({ min: min - (max - min) * 0.1, max: max + (max - min) * 0.1 });


                  this.series[point.id] = {
                    id: point.id/*point.id*/,
                    name: point.name,
                    data: parseDataToFrequency(pointData, point.sampleFrequency),
                    type: "spline",
                    gapSize: (point.sampleFrequency || 1)*60000,
                    gapUnit: 'value',
                    showInNavigator: true,
                    yAxis: axis.id,
                    dataGrouping: {
                      approximation: "max"
                      // approximation: point.approximation || "max"
                    }
                  };
                  this.chart.updateSeries(this.series[point.id], point.id);
                  // console.log("filtered data")
                  // console.log(filteredData);
                  if (filteredData.length > 0) {
                    this.series[point.id + "_filtered"] = {
                      id: point.id + "_filtered"/*point.id+"_filtered"*/,
                      name: "[Filtered] " + point.name,
                      data: parseDataToFrequency(filteredData, point.sampleFrequency),
                      type: "spline",
                      gapSize: (point.sampleFrequency || 1)*60000,
                      gapUnit: 'value',
                      showInNavigator: true,
                      yAxis: axis.id,
                      dataGrouping: {
                        approximation: "max"
                        // approximation: point.approximation || "max"
                      }
                    };
                    this.chart.updateSeries(this.series[point.id + "_filtered"], point.id + "_filtered", true, true);
                  }
                  this.chart.zoomOut();
                }
                this.pointsStatus[point.id].points = pointData.filter(x => x[1] != null).length;

              }
            }
            else {
              this.pointsStatus[point.id].status = `No data`
            }
          } else {
            this.pointsStatus[point.id].status = `No data`
          }

        }
        this.spinner.desactivate();
        this.form.get("point").setValue("");
      },
      error => {
        this.pointsStatus[point.id].status = `Backend error (Timeout). Call support.`
        this.spinner.desactivate();
      }
    );
  }

  public maxMinHelper: {
    id: any,
    max: number,
    min: number,
    average: number,
    pos: number,
    ids: string[],
    isRight: boolean
  }[] = [];


  public binaryHelper = "";

  private checkYAxis(min, max, id) {

    for (let helper of this.maxMinHelper) {
      if (helper.ids.indexOf(id) >= 0) {
        if (max > helper.max) {
          helper.max = max;
        }
        if (min < helper.min) {
          helper.min = min;
        }

        if (helper.id == "id0") {
          this.chart.updateYaxisByPosition({
            min: helper.min,
            id: helper.id,
            showLastLabel: true,
            max: helper.max,
            showEmpty: false,
          }, 0);
        }

        else {
          this.chart.updateYaxis({
            min: helper.min,
            max: helper.max,
            id: helper.id,
            showLastLabel: true,
            showEmpty: false,
            title: {
              enabled: false,
            }
          }, helper.id, true, true);
        }

        return helper;
      }
    }

    if (this.maxMinHelper.length >= 4) {
      // return this.maxMinHelper[4];
    }
    else {

      // let i = 0;
      // for (; i < this.maxMinHelper.length; i++) {
      //   let helper = this.maxMinHelper[i];

      //   let oldRange = (helper.max - helper.min);
      //   let newRange = (max - min);
      //   let oldMedian = helper.min + (oldRange / 2);
      //   let newMedian = min + (newRange / 2);


      //   if (!(newMedian * 5 < oldMedian) && !(newRange * 5 < oldRange) && !(oldMedian * 5 < newMedian) && !(oldRange * 5 < newRange)) {

      //     if (max > helper.max) {
      //       helper.max = max;
      //     }
      //     if (min < helper.min) {
      //       helper.min = min;
      //     }

      //     helper.ids.push(id);


      //     if (helper.id == "id0") {
      //       this.chart.updateYaxisByPosition({
      //         min: helper.min,
      //         id: helper.id,
      //         showLastLabel: true,
      //         max: helper.max,
      //         showEmpty: false,
      //       }, 0);
      //     }
      //     else {
      //       this.chart.updateYaxis({
      //         min: helper.min,
      //         showLastLabel: true,
      //         max: helper.max,
      //         id: helper.id,
      //         showEmpty: false,
      //         title: {
      //           enabled: false,
      //         }
      //       }, helper.id, true, true);
      //     }
      //     return helper;
      //   }

      // }
    }


    let isRight = false;
    if (this.leftCount == 2) {
      this.rightCount++;
      isRight = true;
    }
    else {
      this.leftCount++;
      isRight = false;
    }

    let n = {
      id: "id" + this.yAxisCounter,
      average: 0,
      min: min,
      max: max,
      pos: this.yAxisCounter,
      ids: [id],
      isRight: isRight
    };

    this.yAxisCounter++;

    this.maxMinHelper.push(n)


    if (n.pos == 0) {
      this.chart.updateYaxisByPosition({
        min: n.min,
        showLastLabel: true,
        id: n.id,
        max: n.max,
        showEmpty: false,
        title: {
          enabled: false,
        },
        opposite: isRight
      }, 0);
    }
    else {
      this.chart.updateYaxis({
        min: n.min,
        id: n.id,
        showLastLabel: true,
        max: n.max,
        showEmpty: false,
        title: {
          enabled: false,
        },
        opposite: isRight
      }, n.id)
    }
    // console.log(this.chart.Chart.yAxis);

    return this.maxMinHelper[this.maxMinHelper.length - 1];
  }

  // filterPoint() {

  //   let tempPoint = this.point instanceof Object ? this.point.name : this.point;
  //   if (this.tags.length == 0) {
  //     this.filteredPoints = this.listOfPoints.filter(x => x.name.toLowerCase().indexOf(tempPoint.toLowerCase()) >= 0);
  //   }
  //   else {
  //     this.filteredPoints = this.listOfPoints.filter(point => (point.tags.filter(x => this.tags.indexOf(x) >= 0).length > 0) && point.name.toLowerCase().indexOf(tempPoint.toLowerCase()) >= 0);
  //   }


  // }


  changeChartType() {
    this.chartType++;
    if (this.chartType == this.ChangeToType.length) {
      this.chartType = 0;
    }
    for (let serie in this.series) {
      this.chart.updateSeries({ type: this.ChangeToType[this.chartType][1] }, serie);
    }
  }

  public refresh() {
    for (let point of this.points) {
      this.GetDataForPoint(point);
    }
  }

  public resolveDataType(dataType) {
    switch (dataType) {
      case 1: return "Boolean";
      case 2: return "Boolean Writable";
      case 3: return "Numeric";
      case 4: return "Numeric Writable";
      case 5: return "Enum";
      case 6: return "Enum Writable";
      case 7: return "String";
      case 8: return "String Writable";
    }
  }

  public openDialog() {
    const dialogRef = this.dialog.open(SearchPopupComponent, {
      width: '960px',
      data: {
        single: false,
        assetName: "Points",
        displayField: "name",
        valueField: "id",
        retunObject: true,
        disableSelect: false,
        searchCallback: this.searchPoints.bind(this),
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.length > 0) {
        this.addPoint(x);
      }
    })
  }

  public searchPoints(filter: string, pageOptions: { page: number, pageSize: number, hasNext: boolean }): Observable<any> {
    return new Observable(observer => {
      this.spinner.activate();
      this.dataService.GetListOfPoints(this.form.get("building").value, false, filter, pageOptions.page, pageOptions.pageSize, 1).subscribe(x => {
        this.spinner.desactivate();

        observer.next(x.data);
        observer.complete();
      })

    })
  }

  onBuildingChange() {
    // this.spinner.activate();
    this.sessionService.building = this.building;
    // this.form.get('points').setValue([]);

    // this.dataService.GetListOfPoints(this.building).subscribe(
    //   resp => {
    //     let data = [];
    //     this.listOfPoints = data.concat(resp["data"]);



    //     this.listOfPoints.forEach(x => {
    //       // while (x.name.indexOf("$2d") >= 0) {
    //       //   x.name = x.name.replace("$2d", "-")
    //       // }

    //       // while (x.name.indexOf("$20") >= 0) {
    //       //   x.name = x.name.replace("$20", " ")
    //       // }

    //       // while (x.name.indexOf("$2e") >= 0) {
    //       //   x.name = x.name.replace("$2e", ".")
    //       // }

    //       x.name += " ((" + this.resolveDataType(x.dataType) + "))";
    //     })

    //     this.filterTags();
    //     this.form.get("tags").setValue([]);

    //     this.filterPoint();
    //     this.spinner.desactivate();
    //   },
    //   error => {
    //     console.log(error);
    //     this.spinner.desactivate();
    //   });
  }

  displayByName(obj) {
    return obj ? obj.name : obj;
  }

  tagsChanged() {
    // this.filterPoint();
  }

  filterTags() {
    if (this.building) {
      this.filteredTags = this.listOfTags[this.building];
    }
    else {
      this.filteredTags = [];
    }
  }


  public yAxisCounter = 0;

  public removePoint(point) {
    let t = this.points;
    t.splice(t.indexOf(point), 1);

    this.chart.removeById(point.id);
    this.chart.removeById(point.id + "_filtered");

    let helper = this.maxMinHelper.find(x => x.ids.indexOf(point.id) >= 0);

    if (helper) {
      helper.ids.splice(helper.ids.indexOf(point.id), 1);

      if (helper.isRight) {
        this.rightCount--;
      }
      else {
        this.leftCount--;
      }

      if (helper.ids.length == 0) {
        this.chart.removeYAxis(helper.id);
        this.maxMinHelper.splice(this.maxMinHelper.indexOf(helper), 1);
      }
    }
  }

  get building(): string {
    return this.form.get("building").value;
  }

  get tags(): string[] {
    return this.form.get("tags").value;
  }

  public copy(ord) {
    const el = document.createElement('textarea');
    el.value = ord;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  public addPoint(event) {

    for (let p of event) {

      if (this.points.find(x => x.id == p.id) == null) {
        this.pointsStatus[p.id] = {
          status: "Normal Data",
          type: "spline",
          csv: null,
          points: 0,
          unit: ""
        }

        this.points.push(p);
        // console.log(this.points);
        this.form.get("points").setValue(this.points);

        this.GetDataForPoint(p);
      }
    }
  }

  get point(): any {
    return this.form.get("point").value;
  }

  get points(): any {
    return this.form.get("points").value;
  }
}
