import { Component, OnInit, ViewChild } from '@angular/core';
import { DriverService } from 'app/services/driver.service';
import { SpinnerService } from 'app/services/spinner.service';
import { BuildingService } from 'app/services/building.service';
import { MatDialog } from '@angular/material/dialog';
import { PointFormComponent } from 'app/components/common/point-form/point-form.component';
import { SweetalertService } from 'app/services/sweetalert.service';
import { HealthStatus, PointsViewComponent, badPoint, badSub, getSubStatus, getPointStatus } from 'app/components/common/points-view/points-view.component';
import { Subscription, Observable } from 'rxjs';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-admin-server-points',
  templateUrl: './admin-server-points.component.html',
  styleUrls: ['./admin-server-points.component.css']
})
export class AdminServerPointsComponent implements OnInit {

  public buildings: { id: string, name: string }[] = [];
  public selectedBuilding: string = "";
  public selectedDevice: string = "";
  public devices: { id: string, name: string }[] = [];

  public progress: number = 0;
  public status: string = "No command sent";

  public selection: any[] = [];

  @ViewChild("pointsView", { static: true }) pointsView: PointsViewComponent;

  public hasNext: boolean = false;
  public page: number = 1;
  public pageSize: number = 100;

  public points: { selected: boolean, etc: string, index: number, healthTooltip: string, value: any, id: string, ord: string, health: HealthStatus, meta: string[], tags: { key: string, value: string }[], type: any }[] = []

  constructor(private alert: SweetalertService, public dialog: MatDialog, private driverService: DriverService, private spinnerService: SpinnerService, private buildingService: BuildingService) { }

  public getDevices(event) {
    this.spinnerService.activate()
    this.driverService.getDevices(event).subscribe(d => {
      this.selectedBuilding = event;
      this.devices = d.data.map(x => { return { id: x._id, name: x.friendlyName + " " + x.hostId } });

      this.spinnerService.desactivate();
    });
  }

  public newPoint() {
    let data = {
      buildings: this.buildings,
      devices: this.devices,
      selectedDevice: this.selectedDevice,
      selectedBuilding: this.selectedBuilding
    }

    const dialogRef = this.dialog.open(PointFormComponent, {
      width: '600px',
      data: data
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res != null) {
        this.spinnerService.activate();
        this.driverService.createPoint(res.building, res.device, { points: res.points }).subscribe(X => {
          this.spinnerService.desactivate();


          this.pointsView.commandSent();

          this.alert.success("Success!", "  New point command has been sent. To view up to date points, please run another discover after successful completion.");

          this.selectedBuilding = res.building;
          this.selectedDevice = res.device;

          this.deviceChanged(res.device);
        })
      }
    })
  }


  public lastUpdateAt: string = "";
  public timer: Subscription = new Subscription();

  public commands: any[] = [];

  // private checkCommands() {

  //   this.timer.unsubscribe();
  //   this.timer = Observable.timer(0, 60000).subscribe(t => {

  //     this.status = "Checking command queue...";

  //     this.lastUpdateAt = "Last update at " + moment().format("DD/MM/YYYY HH:mm");


  //     this.progress = -1;

  //     if (this.progress == 0) {
  //       this.status = "Checking commands queue...";
  //       this.progress = 0;
  //     }



  //     this.driverService.getCommands(this.selectedDevice).subscribe(x => {
  //       //Discover
  //       let commands = x.data.filter(t => t.command == 1 && t.ack == false && t.sendFailure == false && t.receiveFailure == false);

  //       this.commands = x.data;

  //       if (commands.length == 0) {
  //         this.progress = 100;
  //         this.status = "Queue is empty."

  //         let command = x.data.filter(t => t.command == 1);
  //         command = command[0];
  //         if (command != undefined) {
  //           if (command.sendFailure || command.receiveFailure) {
  //             this.status += " Last discover command sent at " + moment(command.sentTS).format("DD/MM/YYYY HH:mm") + " has failed at " + moment(command.receivedTS).format("DD/MM/YYYY HH:mm") + "."
  //           }
  //         }
  //       }
  //       else if (commands.length == 1) {
  //         let c = commands[0];
  //         this.status = "Discover sent. ";

  //         if (c.totalResponseMessages == 0) {
  //           this.progress = -1;
  //           this.status += "Waiting on successful acknowledgement.";
  //         }
  //         else {
  //           this.progress = (c.receivedResponse / c.totalResponseMessages) * 100;

  //           if (this.progress == 100) {
  //             this.status = "Last discover command was sent at " + moment(c.sentTS).format("DD/MM/YYYY HH:mm") + ". Messages received at " + moment(c.receivedTS).format("DD/MM/YYYY HH:mm") + ".";
  //           }
  //           else {
  //             this.status += "Command has not finished yet. "
  //           }
  //         }
  //       }
  //       else if (commands.length > 1) {
  //         this.progress = -2;
  //         this.status = "Error. Too many commands."
  //       }
  //     });
  //   })
  // }

  public disableDelete: boolean = true;

  public ids: string[] = [];

  public changeSelection(event) {
    this.disableDelete = true;
    let keys = Object.keys(event);

    this.ids = [];
    for (let key of keys) {
      if (event[key] == true) {
        this.disableDelete = false;
        this.ids.push(key);
      }
    }
  }

  public filter: string = "";

  public delete() {

    this.pointsView.commandSent();

    this.spinnerService.activate();
    this.driverService.removeFDPoints(this.selectedDevice, this.ids).subscribe(x => {
      this.spinnerService.desactivate();

      this.alert.success("Successs!", "Server generated points deleted.");
    })
  }

  public pageChanged(event) {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.filter = event.filter;

    console.log(this.page);

    this.deviceChanged(this.selectedDevice);
  }

  public blockActions(event) {
    this.blockDiscover = event.discover;
  }

  public blockDiscover: boolean = true;

  public discover() {
    this.alert.custom({
      title: "Attention", text: "This operation will execute a command that can take between one and five minutes to complete. Please confirm", buttons: {
        yes: {
          text: "Yes",
          value: "yes"
        },
        no: {
          text: "No",
          value: "no"
        }
      },
      icon: "info",

    }).then(x => {
      if (x == "yes") {

        this.pointsView.commandSent();
        this.needRefresh = true;
        this.spinnerService.activate();
        this.driverService.discoverFD(this.selectedDevice).subscribe(x => {
          this.pointsView.updateProgress();
          this.spinnerService.desactivate();
        }, err => {
          this.spinnerService.desactivate();
          if (err.error.errors) {
            this.alert.info("Attention", err.error.errors[0].message);
          }
          else {
            this.alert.error("Error", "Unkown error. Contatct the admin.");
          }
          this.pointsView.updateProgress();
        })
      }
    })
  }

  public updateMetadata(event) {
    this.spinnerService.activate();
    console.log(event);
    this.driverService.getMetadata(this.selectedDevice, event.id).subscribe(x => {
      this.spinnerService.desactivate();

      this.pointsView.updateProgress();
    })
  }

  public edit(id: string) {
    let p = this.points.find(x => x.id == id);

    let data = {
      buildings: this.buildings,
      devices: this.devices,
      selectedDevice: this.selectedDevice,
      selectedBuilding: this.selectedBuilding,
      point: {
        name: p.etc,
        type: this.getTypeNum(p.type),
        value: p.value
      }
    }

    const dialogRef = this.dialog.open(PointFormComponent, {
      width: '600px',
      data: data
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res != null) {
        this.spinnerService.activate();
        this.driverService.createPoint(this.selectedBuilding, this.selectedDevice, { points: res.points }).subscribe(x => {
          this.spinnerService.desactivate();


          this.pointsView.commandSent();

          this.alert.success("Success!", "Update command has been sent. To view up to date values, please run another discover after successful completion.");

          this.deviceChanged(this.selectedDevice);
        })
      }
    });
  }

  public needRefresh: boolean = false;

  public release(event) {
    if (this.needRefresh && event == 9 || event == 10 || event == 11) {
      this.needRefresh = false;
      this.deviceChanged(this.selectedDevice);
    }
  }



  public statusMessage = "";
  public originalStatus = [];

  public deviceChanged(event) {
    this.progress = 0;
    this.status = "Queue empty";
    this.selectedDevice = event;

    this.originalStatus = [];
    this.statusMessage = "";

    this.spinnerService.activate();
    this.driverService.getMetadataFD(this.selectedBuilding, event, this.page, this.pageSize, this.filter).subscribe(
      suc => {
        this.spinnerService.desactivate();

        this.hasNext = suc.data.length > this.pageSize ? true : false;

        this.points = suc.data.map((t, index) => {

          let status = HealthStatus.Good;
          let statusTooltip = "Ok";


          if (t.lastReceivedSubStatus != undefined && typeof (t.lastReceivedSubStatus) === 'number') {

            if (badPoint.indexOf(t.lastReceivedPointStatus) >= 0 || badSub.indexOf(t.lastReceivedSubStatus) >= 0) {
              status = HealthStatus.Bad;
            }
            else {
              status = HealthStatus.Good;
            }


            statusTooltip = "Subscription status is '" + getSubStatus(t.lastReceivedSubStatus) + "'. Point status is '" + getPointStatus(t.lastReceivedPointStatus) + "'.";
          }

          return {
            etc: t.friendlyName,
            index: (this.page - 1) * this.pageSize + index + 1,
            tags: [],
            health: status,
            meta: t.facets ? t.facets.split(",") : [],
            healthTooltip: statusTooltip,
            type: this.getType(t.dataType),
            id: t._id,
            ord: t.ord,
            selected: t.uid ? true : false,
            value: t.dataType == 2 ? (t.lastReceivedDataValue ? "true" : "false") : t.lastReceivedDataValue
          }
        })

        console.log(this.points);

        if (this.points.length > this.pageSize)
          this.points = this.points.splice(0, this.points.length - 1);
      },
      err => {

        this.spinnerService.desactivate();
        this.progress = 100;

        if (err.status >= 500 && err.status < 517) {
          this.status = "Error: Something went wrong, try again later.";
        }
        else {
          this.status = "Error: " + err.error.errors[0].message;
        }
      }
    )
  }


  public getType(number: number) {
    switch (number) {
      case 1:
      case 2:
        return "Boolean";
      case 3:
      case 4:
        return "Double";
      case 5:
      case 6:
        return "Enum";
      case 7:
      case 8:
        return "String"
    }
  }
  public getTypeString(num: number) {
    switch (num) {
      case 2:
        return "Boolean";
      case 4:
        return "Double";
      case 8:
        return "String";
    }
  }

  public getTypeNum(str: string) {
    switch (str) {
      case "Boolean":
        return 2;
      case "Double":
        return 4;
      case "String":
        return 8;
    }
  }

  ngOnInit() {
    this.spinnerService.activate();
    this.buildingService.getAll().subscribe(d => {
      this.buildings = d.data.map(x => { return { id: x._id, name: x.name } });

      this.spinnerService.desactivate();
    });
  }

}
