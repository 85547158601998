import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PointTagsComponent } from '../point-tags/point-tags.component';
import { PointFormComponent } from '../point-form/point-form.component';

import * as moment from "moment-timezone";
import { ProgressDisplayComponent } from '../progress-display/progress-display.component';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-points-view',
  templateUrl: './points-view.component.html',
  styleUrls: ['./points-view.component.css']
})
export class PointsViewComponent implements OnInit, OnChanges, OnDestroy {
  public FilteredselectedBuilding:any = "";
  public selectedBuilding:any = "";
  public BuildingSearchResult:any;
  public buildingForm: FormGroup;
  public filterBuilding(){
    let newf;
    if(this?.FilteredselectedBuilding){
  if(this?.FilteredselectedBuilding?.name){
      newf = this?.FilteredselectedBuilding.name.toLowerCase();
  }
  else {
      newf = this?.FilteredselectedBuilding.toLowerCase();
  }
  const filterValue = newf;
      
      console.log(filterValue);
      if(this.buildings){
          let searchBase = this.buildings;
          this.BuildingSearchResult = searchBase.filter(s => s.name.toLowerCase().includes(filterValue));
            console.log(this.BuildingSearchResult);
          // return this.buildings.filter(building => 
          //   this.buildings.includes(filterValue));
      
      }
  }else {
      this.BuildingSearchResult = this.buildings;
  }
  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  public formatBuildings(){
    let buildings = this.buildings;
    console.log(buildings);
    let i =0;
    let buildingssimple = [];
    for(i=0; i<buildings.length; i++){
      buildingssimple[i] = buildings[i]['name'];
    }

    return buildingssimple;
  }

  changeBuilding(building_formed){
    this.selectedBuilding = this.FilteredselectedBuilding.id;
    console.log(building_formed);
    // console.log(this.building);
    // this.updateData();
    this.buildingChange(this.selectedBuilding);


  }
  public showCommands: MatTableDataSource<any> = new MatTableDataSource<any>();;

  @Output() onBlockAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRelease: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdateMetadata: EventEmitter<any> = new EventEmitter<any>();


  @Input() lockAll: boolean = false;

  @ViewChild("progressDisplay", { static: true }) progressDisplay: ProgressDisplayComponent;

  public updateProgress() {
    this.progressDisplay.updateCommands();
  }

  private locked: boolean = false;
  get isLocked(){
    return this.isLocked;
  }

  public updateMetadata(point) {
    this.onUpdateMetadata.emit(point);
  }

  public release(event) {
    this.onRelease.emit(event);
  }

  public originalStatus = [];

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.points) {
      console.log(this.points);
      this.datasource.data = this.points;

      this.originalStatus = [];
      for (let p of this.points) {
        this.originalStatus[p.id] = p.selected;
      }

      let temp = this.points.find(x => x.value != null || x.value != undefined);
      if (temp) {
        this.displayedColumns = ["index", "selected", "point", "type", "value", "health", "tags", "meta"];
      }
      else {
        this.displayedColumns = ["index", "selected", "point", "type", "health", "tags", "meta"];
      }
    }
    if (changes.progress) {
      if (this.progress == -1) {
        this.mode = "query";
      }
      else if (this.progress == -2) {
        this.mode = "indeterminate";
      }
      else if (this.progress >= 0 && this.progress <= 100) {
        this.mode = "determinate";
      }
    }

    if (changes.commands) {

      let d = [];

      for (let command of this.commands) {
        let status = "";

        if (command.ack) {
          if (command.receivedResponse == command.totalResponseMessages) {
            status = "Completed Successful";
          }
        }
        else if (command.sendFailureReason) {
          status = command.sendFailureReason.type;
        }
        else if (command.receiveFailureReason) {
          status = command.receiveFailureReason.type;
        }
        else if (command.sentMessages != command.totalSendMessages || command.sent) {
          status = "Waiting for response";
        }
        else {
          status = "Unkown error";
        }

        d.push({
          command: this.getCommandName(command.command),
          timestamp: moment(command.timestamp).format("DD/MM/YYYY HH:mm:ss Z"),
          status: status
        })
      }

      // d.sort();

      this.showCommands.data = d;
    }
  }


  public blockActions(event) {
    this.onBlockAction.emit(event);
  }

  public getCommandName(cid: number) {
    switch (cid) {
      case 1:
        return "Discover";
      case 2:
        return "Add";
      case 3:
        return "Remove";
      case 4:
        return "List Histories";
      case 5:
        return "Get History";
      case 6:
        return "Get All Metadata"
      case 7:
        return "Get Metadata for single point";
      case 8:
        return "Get Log History";
      case 9:
        return "Discover FD Points";
      case 10:
        return "Create FD Point";
      case 11:
        return "Delete FD Point";
      case 12:
        return "Resync";
      default:
        return "Unkown command";
    }
  }

  @Input() commands: any[] = [];
  @Input() lastUpdateAt: string = "";

  public mode: string = "determinate";

  public applyFilter(filterValue: string) {
    this.pagination.page = 1;
    this.pagination.filter = filterValue;

    this.emitPageAndFilter();
  }

  public changeSelected(event) {
    let p = this.points.find(x => x.id == event);

    let selected = [];
    for (let po of this.points) {
      selected[po.id] = po.selected
    }

    if (p.selected == false) {
      this.hasAllSelected = false;
      this.changeDetectorRef.detectChanges();

      this.onSelectionChange.emit(selected);

      return;
    }
    else {
      for (let i of this.datasource.data) {
        if (i.selected == false) {
          this.hasAllSelected = false;
          this.changeDetectorRef.detectChanges();

          this.onSelectionChange.emit(selected);
          return;
        }
      }

      this.hasAllSelected = true;
      this.changeDetectorRef.detectChanges();

      this.onSelectionChange.emit(selected);
    }
  }

  public checkAll(event) {
    let selected = [];
    for (let po of this.points) {
      selected[po.id] = event.checked;
    }


    if (event.checked) {
      for (let i of this.datasource.data) {
        i.selected = true;
      }

      this.hasAllSelected = true;
    }
    else {
      for (let i of this.datasource.data) {
        i.selected = false;
      }

      this.hasAllSelected = false;
    }

    this.changeDetectorRef.detectChanges();

    this.onSelectionChange.emit(selected);
  }

  public hasAllSelected: boolean = false;

  public buildingChange(event) {
    this.onBuildingChange.emit(event);
  }

  public deviceChanged(event) {
    this.onDeviceChange.emit(event);
  }

  public edit(id: string) {
    this.onClickPoint.emit(id);
  }

  public commandSent() {
    this.progressDisplay.resetState();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog) { }

  ngOnDestroy(): void {
  }

  @Input() selectedDevice: string = "";

  @Input() devices: { id: string, name: string }[] = [];
  @Input() buildings: { id: string, name: string }[] = [];

  @Input() points: { uid: number, uidAck: boolean, value: any, toRemove: boolean, selected: boolean, index: number, healthTooltip: string, id: string, ord: string, health: string, meta: string, tags: { key: string, value: string }[], type: any }[] = []

  @Input() statusMessage: string = "No comand sent";
  @Input() progress: number = 0;

  @Input() enableEdit: boolean = false;

  @Input() title: string = "Points";

  @Input() hasNextPage: boolean = false;

  @Output() onChangePageConfig: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBuildingChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeviceChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickPoint: EventEmitter<any> = new EventEmitter<any>();

  public datasource = new MatTableDataSource<any>();
  public displayedColumns: string[] = ["index", "selected", "point", "type", "health", "tags", "meta"];

  public openTags(id: string) {
    let p = this.points.find(x => x.id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.tags.map(x => x.key + ": " + x.value),
        title: "Tags for " + p.ord
      }
    });
  }


  public pagination: { page: number, pageSize: number, filter: string } = { page: 1, pageSize: 100, filter: "" }

  private emitPageAndFilter() {
    this.onChangePageConfig.emit(this.pagination);
  }


  public changePageOptions(event) {
    this.pagination.page = event.page;
    this.pagination.pageSize = event.pageSize;

    this.emitPageAndFilter();
  }

  public openFacets(id: string) {
    let p = this.points.find(x => x.id == id);
    const dialogRef = this.dialog.open(PointTagsComponent, {
      width: '600px',
      data: {
        list: p.meta,
        title: "Meta for " + p.ord
      }
    });
  }

  ngOnInit() {
    this.buildingForm = new FormGroup({
      buildings_input: new FormControl('')
      
    });
  }


}


export enum HealthStatus {
  Good = "btn-primary",
  Bad = "btn-danger",
  Unkown = "btn-default",
  Warning = "btn-warning"
}




export function getPointStatus(point) {
  switch (point) {
    case 0:
      return "Unkonw";
    case 1:
      return "Alarm";
    case 2:
      return "Down";
    case 3:
      return "Disabled";
    case 4:
      return "Fault";
    case 5:
      return "Null";
    case 6:
      return "Ok";
    case 7:
      return "Overridden";
    case 8:
      return "Stale";
  }
}

export function getSubStatus(sub) {
  switch (sub) {
    case 0:
      return "Unsuscribed";
    case 1:
      return "Pending Subscribe";
    case 2:
      return "Sending Subscribe";
    case 3:
      return "Abort Subscribe";
    case 4:
      return "Subscribed";
    case 5:
      return "Pending Unsubscribe";
    case 6:
      return "Sending Unsubscribe";
    case 7:
      return "Resubscribe";
    case 8:
      return "Proxy Error";
    case 9:
      return "Unknown";
  }
}

export var badPoint = [2, 3, 4, 8];
export var badSub = [];
