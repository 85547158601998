import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BuildingCompleteFormComponent } from "./building-complete-form.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTabsModule } from "@angular/material/tabs";
import { BuildingFormModule } from "app/components/common/building-form/building-form.module";
import { SpinnerModule } from "app/components/common/spinner/spinner.module";

@NgModule( {
  imports: [
    SpinnerModule,
    CommonModule,
    MatTabsModule,
    BuildingFormModule,
    MatExpansionModule,
    MatDialogModule
  ],
  declarations: [ BuildingCompleteFormComponent ],
  exports: [ BuildingCompleteFormComponent ]
} )
export class BuildingCompleteFormModule {
}
