
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService, IServiceError } from 'app/services/base.service';
import { UserType } from '../interfaces/baseinterface';
import { IApiKey } from "../interfaces/apiKey";

@Injectable()
export class ApiKeyService extends BaseService<IApiKey> {

  rootUrl = 'apiKeys';


  private checkApiKey(object: IApiKey) {
    let errors: IServiceError[] = [];

    if (object.description === '') {
      errors.push({ field: 'description', message: 'Description is required' });
    }

    /*if ( object.expiration_date === '' ) {
      errors.push( { field: 'expiration_date', message: 'Expiration date is required' } );
    }*/

    /*if ( object.linkedIPAddress === '' ) {
      errors.push( { field: 'linkedIPAddress', message: 'linked IP address is required' } );
    }*/

    if (errors.length > 0) {
      return observableThrowError({ status: 400, errors: errors });
    }

    return undefined;
  }

  public create(object: IApiKey) {
    return super.create(object);
  }

  public update(oldObject: IApiKey, object: IApiKey) {
    return super.update(oldObject, object);
  }

  public delete(uuid: string) {
    return super.delete(uuid);
  }

  public newToken(uid: string): Observable<any> {
    return this.doGet(this.rootUrl, uid + "/newKey").map(response => response as any);
  }
}
