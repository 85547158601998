import { Component, Input, OnInit } from "@angular/core";
import { SpinnerService } from "app/services/spinner.service";
import * as Highcharts from "highcharts/highstock";
import Exporting from 'highcharts/modules/exporting';
import PatterFill from "highcharts/modules/pattern-fill";
import XRange from "highcharts/modules/xrange";
import HighchartsBoost from "highcharts/modules/boost";

import More from "highcharts/highcharts-more";

import * as moment from "moment-timezone";
import { DateWrapperService } from "app/services/date-wrapper.service";
import { FutureDecisionsDate } from "app/components/classes/futuredecisions.date";

Exporting(Highcharts);
PatterFill(Highcharts);
HighchartsBoost(Highcharts);
XRange(Highcharts);
More(Highcharts);

@Component({
  selector: "app-highstock",
  templateUrl: "./highstock.component.html",
  styleUrls: ["./highstock.component.css"]
})

export class AppHighstockComponent {

  public filteredTimezones: string[] = [];

  private r = Math.random().toString();

  get randomId() {
    return this.r.substr(2, this.r.length);
  }

  get fullId() {
    return "chartContainer_" + this.randomId;
  }

  public highcharts = Highcharts;
  private chart: any;
  get Chart() {
    return this.chart;
  }

  @Input() fillGaps: boolean = false;
  @Input() globalTimezone: boolean = false;
  @Input() timezoneSelectorPosition: "topleft" | "topright" | "bottomleft" | "bottomright" | "none" = "none";
  @Input() availableTimezones: string[] = this.dateService.timezones;
  @Input() currentTimezone: string = this.dateService.tz;

  @Input() allOptions = null;
  @Input() chartOption = { zoomType: "x", height: 600 };
  @Input() horizontal = false;
  @Input() title = "Set the chart Title with the Input field title";
  @Input() subTitle = "";
  @Input() xAxis = { type: "datetime" };
  @Input() yAxis = [{ opposite: false }];
  @Input() tooltip = { shared: true, split: false };
  @Input() scrollbar;
  @Input() legend = {
    enabled: true
  };
  @Input() series = [];
  @Input() navigator = {
    series: {
      includeInCSVExport: false
    }
  };
  @Input() rangeSelector;
  @Input() plotOptions;
  @Input() chartExportOptions = {
    buttons: {
      contextButton: {
        menuItems: [
          // {
          //   textKey: "downloadCSV",
          //   onclick: function () {
          //     this.downloadCSV();
          //   }
          // },
          // {
          //   textKey: "downloadXLS",
          //   onclick: function () {
          //     this.downloadXLS();
          //   }
          // },
          // {
          //   separator: true
          // },
          {
            textKey: "printChart",
            onclick: function () {
              this.print();
            }
          }, {
            textKey: "downloadPNG",
            onclick: function () {
              this.exportChart();
            }
          }, {
            textKey: "downloadJPEG",
            onclick: function () {
              this.exportChart({
                type: "image/jpeg"
              });
            }
          }, {
            textKey: "downloadPDF",
            onclick: function () {
              this.exportChart({
                type: "application/pdf"
              });
            }
          }, {
            textKey: "downloadSVG",
            onclick: function () {
              this.exportChart({
                type: "image/svg+xml"
              });
            }
          }
        ]
      }
    }
  };

  constructor(private dateService: DateWrapperService) {
    this.dateService.timezoneChanged.subscribe(x => {
      this.currentTimezone = x;
      this.timezoneChanged(x);
    })
    // component = this;
    // Highcharts.dateFormats = {
    //   W: function (timestamp) {
    //     return moment.utc(timestamp).isoWeek();
    //   }
    // };

    // ( function( H ) {
    //   H.wrap( H.Tooltip.prototype, "hide", function( defaultCallback ) {
    //     let tooltipElem = document.querySelector( ".highcharts-tooltip" );
    //     let tooltipDiv = document.querySelector( "div > .highcharts-tooltip" );
    //     if( tooltipElem ) {
    //       tooltipElem.setAttribute( "opacity", "0" );
    //     }
    //     if( tooltipDiv ) {
    //       tooltipDiv.setAttribute( "style", "position: absolute; left: 0px; top: 0px; opacity: 0;" );
    //     }
    //   } );
    //   H.wrap( H.Tooltip.prototype, "refresh", function( proceed, points ) {
    //     let tooltipElem = document.querySelector( ".highcharts-tooltip" );
    //     let tooltipDiv = document.querySelector( "div > .highcharts-tooltip" );
    //     if( tooltipElem ) {
    //       tooltipElem.setAttribute( "opacity", "1" );
    //     }
    //     if( tooltipDiv ) {
    //       tooltipDiv.setAttribute( "style", "position: absolute; left: 0px; top: 0px; opacity: 1;" );
    //     }
    //     // Run the original proceed method
    //     proceed.apply( this, Array.prototype.slice.call( arguments, 1 ) );

    //   } );
    // }( Highcharts ) );
  }

  ngAfterViewInit() {
    let options: any = {
      chart: this.chartOption,
      title: {
        text: this.title
      },
      subtitle: {
        text: this.subTitle
      },
      yAxis: this.yAxis,
      credits: {
        enabled: false
      },
      tooltip: this.tooltip,
      series: this.series,
      exporting: { enabled: false },
      navigator: this.navigator
    };

    if (this.allOptions != null) {
      options = this.allOptions;
    }

    else {
      if (this.plotOptions) {
        options["plotOptions"] = this.plotOptions;
      }
      if (this.scrollbar) {
        options["scrollbar"] = this.scrollbar;
      }
      if (this.xAxis) {
        options["xAxis"] = this.xAxis;
      }
      if (this.legend) {
        options["legend"] = this.legend;
      }
      if (this.rangeSelector) {
        options["rangeSelector"] = this.rangeSelector;
      }
      if (this.chartExportOptions) {
        options["exporting"] = this.chartExportOptions;
      }
      options["componentClass"] = this;

      options["plotOptions"].connectNulls = this.fillGaps;
    }
    // console.log(options);
    this.chart = Highcharts.stockChart(this.fullId, options);
  }

  zoomOut() {
    this.chart.zoomOut();
  }

  public filterTimezones(filter) {
    if (filter)
      this.filteredTimezones = this.availableTimezones.filter((p: any) => {
        return p.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
  }

  addYaxis(axis: any, redraw: boolean = true, animation: any = true): boolean {
    if (this.chart && axis.id) {
      this.chart.addAxis(axis, this.horizontal, redraw, animation);
      return true;
    }
    return false;
  }

  updateYaxis(updatedValues: any, id: any = undefined, redraw: boolean = true, animation: any = true): boolean {
    if (this.chart) {
      let axis;
      if (!id) {
        axis = this.chart.yAxis[0];
      } else {
        axis = this.chart.get(id);
      }
      if (axis) {
        axis.update(updatedValues);
      } else {
        this.addYaxis(updatedValues, redraw, animation);
      }
      return true;
    }
    return false;
  }

  updateYaxisByPosition(updatedValues: any, position: number) {
    if (this.chart) {
      if (this.chart.yAxis[position]) {
        this.chart.yAxis[position].update(updatedValues);
      }
      else
        this.addYaxis(updatedValues);
      return true;
    }
    return false;
  }

  updateChart(updatedValues: any): boolean {
    if (this.chart) {
      this.chart.update(updatedValues);
      return true;
    }
    return false;
  }

  updateXaxis(updatedValues: any): boolean {
    if (this.chart) {
      this.chart.xAxis[0].update(updatedValues);
      return true;
    }
    return false;
  }

  addSeries(serie: any, redraw: boolean = true, animation: any = true): boolean {
    if (this.chart && serie.id) {
      this.chart.addSeries(serie, redraw, animation);
      return true;
    }
    return false;
  }

  updateSeries(updatedSeries: any, id: any, redraw: boolean = true, animation: any = true): boolean {
    if (this.chart) {
      let x = this.chart.get(id);
      if (x) {
        x.update(updatedSeries, redraw);
      } else {
        this.addSeries(updatedSeries, redraw, animation);
      }
      return true;
    }
    return false;
  }

  addPointToSerie(point: any[2], seriesPosition: number, redraw: boolean = true, shift: boolean = true, animation: any = true): boolean {
    if (this.chart) {
      this.chart.series[seriesPosition].addPoint(point, redraw, shift, animation);
      return true;
    }
    return false;
  }

  updateById(updatedOptions: any, id: any = undefined, redraw: boolean = true): boolean {
    if (this.chart) {
      if (id) {
        this.chart.get(id).update(updatedOptions, redraw);
      } else {
        this.chart.update(updatedOptions, redraw, true);
      }
      return true;
    }
    return false;
  }

  removeYAxis(id: string) {
    this.chart.get(id).remove();
  }

  removeById(id) {
    if (this.chart) {
      let x = this.chart.get(id);
      if (x) {
        x.remove();
      } else {
        return false;
      }
      return true;
    }
    return false;
  }

  redraw() {
    this.chart.redraw();
  }

  public getSeries(id) {
    return this.chart.get(id);
  }

  public timezoneChanged(tz) {
    if (tz) {
      let t = this.dateService.getTimezoneOffset(tz.option.value)

      this.updateChart({
        time: {
          timezoneOffset: t == 0 ? undefined : t
        }
      })
    }
  }

  GetDataXaxisMinMax() {
    return [this.chart.xAxis[0].dataMin, this.chart.xAxis[0].dataMax];
  }
}
