import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

import * as Highcharts from "highcharts/highcharts-gantt";

require("highcharts/modules/gantt")(Highcharts);


@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css']
})
export class GanttComponent implements OnInit, AfterViewInit {

  private r = Math.random().toString();

  get randomId() {
    return this.r.substr(2, this.r.length);
  }

  get fullId() {
    return "chartContainer_" + this.randomId;
  }

  public highcharts = Highcharts;
  private chart: any;
  get Chart() {
    return this.chart;
  }

  removeById(id) {
    if (this.chart) {
      let x = this.chart.get(id);
      if (x) {
        x.remove();
      } else {
        return false;
      }
      return true;
    }
    return false;
  }

  public updateSeries(series) {
    if (this.chart) {
      let n = this.chart.get("ocurrences");
      if (n) {
        this.initChart(this.chartOptions);
        this.updateSeries(series);
      }
      else {
        this.chart.addSeries(series, true, true);
      }
    }
  }

  updateXaxis(updatedValues: any): boolean {
    if (this.chart) {
      this.chart.xAxis[0].update(updatedValues);
      return true;
    }
    return false;
  }

  @Input() chartOptions = {};

  constructor() { }

  ngAfterViewInit(): void {
    this.initChart(this.chartOptions);
  }

  private initChart(chartOptions) {
    this.chart = Highcharts.ganttChart(this.fullId, chartOptions);
  }

  ngOnInit() {
  }

}
