import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFormComponent } from './user-form.component';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IboxModule } from 'app/components/common/ibox/ibox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from "../tooltip/tooltip.module";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    IboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    TooltipModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatIconModule,
    TranslocoModule
  ],
  declarations: [UserFormComponent],
  exports: [UserFormComponent]
})
export class UserFormModule { }
