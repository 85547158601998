import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DriverService } from 'app/services/driver.service';
import { Subscription, Observable } from 'rxjs';


import * as moment from "moment-timezone";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-progress-display',
  templateUrl: './progress-display.component.html',
  styleUrls: ['./progress-display.component.css']
})
export class ProgressDisplayComponent implements OnInit, OnChanges, OnDestroy {
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.device && this.device != "") {
      this.updateCommands();
    }
    if (changes.commands) {
      this.commandsToExecute = this.commands;
    }
  }

  private hasFinished: boolean = false;

  @Input() commands: string[] = [];
  private commandsToExecute: string[] = [];

  @Input() device: string = "";
  @Output() blockActions: EventEmitter<{ discover: boolean, getAllMetadata: boolean, other: boolean }> = new EventEmitter<{ discover: boolean, getAllMetadata: boolean, other: boolean }>();
  @Output() onRelease: EventEmitter<any> = new EventEmitter<any>();

  constructor(private driverService: DriverService, private transloco: TranslocoService) { }
  ngOnDestroy(): void {
    this.refresh.unsubscribe();
  }

  public commandsHistory: { command: string, status: string }[] = [];

  ngOnInit() {
  }

  public mode: string = "determinate";
  public value: number = 0;

  public status: { first: { css: string, message: string }, second: { css: string, message: string }, third: { css: string, message: string } } = {
    first: {
      css: "current",
      message: this.transloco.translate("components.progressdisplay.ready")
    },
    second: {
      css: "",
      message: ""
    },
    third: {
      css: "",
      message: ""
    }
  }

  public historyMessages: { message: string, timestamp: string }[] = [{
    message: this.transloco.translate("components.progressdisplay.waitingdevice"),
    timestamp: moment().format("DD/MM/YYYY HH:mm:ss")
  }];

  public lastCommandId = "";

  private refresh: Subscription = new Subscription();
  private timer: Subscription = new Subscription();
  public remainingTime: string = "";

  private startTimer() {
    // this.timer.unsubscribe();
    // this.timer = Observable.timer(0, 1000).subscribe(x => {
    //   this.remainingTime = (30 - x) + "";

    //   if (30 - x == 0) {
    //     this.timer.unsubscribe();
    //     this.remainingTime = "";
    //   }
    // })
  }

  public step: number = 0;
  public stepText: string = "";
  private lastRefresh: string = "";

  public resetState() {
    this.status.first.css = "current";
    this.status.second.css = "";
    this.status.third.css = ""

    this.status.first.message = this.transloco.translate("components.progressdisplay.preparingcommand");
    this.status.second.message = "";
    this.status.third.message = "";


    this.pushMessage(this.transloco.translate("components.progressdisplay.preparingcommand"));

    this.blockActions.emit({
      discover: true,
      getAllMetadata: true,
      other: true
    })
    this.hasFinished = false;
    this.startTimer();
  }

  public updateCommands() {

    // this.driverService.getCommandByCid(this.device, "60").subscribe(x => console.log(x));



    this.refresh.unsubscribe();
    this.refresh = Observable.timer(0, 30000).subscribe(t => {


      this.blockActions.emit({
        discover: true,
        getAllMetadata: true,
        other: true,
      })

      if (this.commandsToExecute.length > 0) {
        this.pushMessage(this.transloco.translate("components.progressdisplay.commandswaintingcount", { commands: this.commandsToExecute.length }));
      }
      this.pushMessage(this.transloco.translate("components.progressdisplay.checkingqueue"));

      this.inprogress = true;
      this.driverService.getCommands(this.device).subscribe(x => {


        this.inprogress = false;

        let last = x.data[0];

        // if (this.commandsToExecute.indexOf(last.cid) > -1) {
        //   this.commandsToExecute = this.commandsToExecute.splice(this.commandsToExecute.indexOf(last.cid), 1);

        //   this.step++;

        //   this.stepText = "[" + this.step + " of " + this.commands.length + "]"

        //   if(this.commandsToExecute == []){
        //     this.step = 0;
        //   }
        // }

        if (!last) {
          this.status.first.css = "current";
          this.status.second.css = "";
          this.status.third.css = ""

          this.status.first.message = this.transloco.translate("components.progressdisplay.ready");
          this.status.second.message = "";
          this.status.third.message = "";

          this.pushMessage(this.transloco.translate("components.progressdisplay.queueready"));

          this.blockActions.emit({
            discover: false,
            getAllMetadata: false,
            other: false
          })

          this.startTimer();
        }
        else if (!last.sent && !last.received && !last.ack) {

          this.status.first.css = "current";
          this.status.second.css = "";
          this.status.third.css = ""

          this.status.first.message = this.transloco.translate("components.progressdisplay.commandinflight");
          this.status.second.message = "";
          this.status.third.message = "";

          if (last.command == 1 || last.command == 9) {
            if (last.sendFailure) {
              this.status.first.css = "rejected";
              this.status.second.css = "";
              this.status.third.css = ""

              this.status.first.message = this.transloco.translate("components.progressdisplay.commandtimeout");
              this.status.second.message = "";
              this.status.third.message = "";

              this.pushMessage(this.transloco.translate("components.progressdisplay.commandtimeout", { command: this.transloco.translate("components.progressdisplay.commandsenum.discover") }));

              this.blockActions.emit({
                discover: false,
                getAllMetadata: false,
                other: false
              })
              this.startTimer();
              // this.onRelease.emit(last.command);
              this.hasFinished = true;
            }
            else {

              this.pushMessage(this.transloco.translate("components.progressdisplay.commandinflightrelease", { command: this.transloco.translate("components.progressdisplay.commandsenum.discover") }));

              this.blockActions.emit({
                discover: true,
                getAllMetadata: true,
                other: true
              })
              this.hasFinished = false;
              this.startTimer();
            }
          }
          else if (last.command == 6 || last.command == 7) {
            if (last.sendFailure) {
              this.status.first.css = "rejected";
              this.status.second.css = "";
              this.status.third.css = ""

              this.status.first.message = this.transloco.translate("components.progressdisplay.commandtimeout");
              this.status.second.message = "";
              this.status.third.message = "";

              this.pushMessage(this.transloco.translate("components.progressdisplay.commandtimeoutrelease", { command: this.transloco.translate("components.progressdisplay.commandsenum.getallmetadata") }));

              this.blockActions.emit({
                discover: false,
                getAllMetadata: false,
                other: false
              })
              this.startTimer();
              // this.onRelease.emit(last.command);
              this.hasFinished = true;
            }
            else {
              this.pushMessage(this.transloco.translate("components.progressdisplay.commandinflightrelease", { command: this.transloco.translate("components.progressdisplay.commandsenum.getallmetadata") }));

              this.blockActions.emit({
                discover: true,
                getAllMetadata: true,
                other: true
              })
              this.startTimer();
              this.hasFinished = false;
            }
          }
          else {
            this.pushMessage(this.transloco.translate("components.progressdisplay.commandinflightrelease", { command: this.getCommandName(last.command) }));

            this.blockActions.emit({
              discover: false,
              getAllMetadata: false,
              other: false
            })
            this.startTimer();
            this.hasFinished = false;
          }
        }
        //enviou para device
        else if (last.sent && !last.received && !last.ack) {

          this.lastCommandId = last._id;

          this.status.first.css = "completed";
          this.status.second.css = "current";
          this.status.third.css = ""

          this.status.first.message = this.transloco.translate("components.progressdisplay.commandinflight");
          this.status.second.message = this.transloco.translate("components.progressdisplay.waitingforack");
          this.status.third.message = "";

          if (last.receiveFailure) {
            this.status.first.css = "current";
            this.status.second.css = "rejected ";
            this.status.third.css = ""

            this.status.first.message = this.transloco.translate("components.progressdisplay.ready");
            this.status.second.message = this.transloco.translate("components.progressdisplay.commandtimeout");
            this.status.third.message = "";


            this.pushMessage(this.transloco.translate("components.progressdisplay.commandtimeoutreleasegeneric"));

            this.blockActions.emit({
              discover: false,
              getAllMetadata: false,
              other: false
            })
            this.startTimer();
            this.hasFinished = true;
          }
          else if (last.command == 1 || last.command == 9) {
            this.pushMessage(this.transloco.translate("components.progressdisplay.commandwaitingrelease", { command: this.getCommandName(last.command) }));

            this.blockActions.emit({
              discover: true,
              getAllMetadata: true,
              other: true
            })
            this.startTimer();
            this.hasFinished = false;
          }
          else if (last.command == 6 || last.command == 7) {
            this.pushMessage(this.transloco.translate("components.progressdisplay.commandwaitingrelease", { command: this.getCommandName(last.command) }));

            this.blockActions.emit({
              discover: true,
              getAllMetadata: true,
              other: true
            })
            this.startTimer();
            this.hasFinished = false;
          }

          else {
            this.pushMessage(this.transloco.translate("components.progressdisplay.commandwaitingrelease", { command: this.getCommandName(last.command) }));

            this.blockActions.emit({
              discover: true,
              getAllMetadata: true,
              other: true
            })
            this.startTimer();
            this.hasFinished = false;
          }
        }
        else if (!last.sent && !last.received) {
          this.lastCommandId = last._id;

          this.status.first.css = "current";
          this.status.second.css = "";
          this.status.third.css = ""

          this.status.first.message = this.transloco.translate("components.progressdisplay.commandinflight");
          this.status.second.message = "";
          this.status.third.message = "";

          if (last.sendFailure) {
            this.status.first.css = "current rejected";

            this.status.first.message = this.transloco.translate("components.progressdisplay.rejected");

            if (last.sendFailureReason) {
              this.pushMessage(this.transloco.translate("components.progressdisplay.lastcommanderror", { command: this.getCommandName(last.command), type: last.sendFailureReason.type }))
            }
            else {
              this.pushMessage(this.transloco.translate("components.progressdisplay.lastcommanderrorunkown", { command: this.getCommandName(last.command) }));
            }

            this.pushMessage(this.transloco.translate("components.progressdisplay.unblocking"));
          }
        }
        //recebeu primeira resposta do device
        else if (last.sent && !last.received && last.ack) {
          this.lastCommandId = last._id;

          this.status.first.css = "completed";
          this.status.second.css = "completed";
          this.status.third.css = "current"

          this.status.first.message = this.transloco.translate("components.progressdisplay.commandinflight");
          this.status.second.message = this.transloco.translate("components.progressdisplay.acknowledge");
          this.status.third.message = this.transloco.translate("components.progressdisplay.waitingtocomplete");

          if (last.receiveFailure) {

            this.status.third.css = "rejected current"

            this.status.third.message = this.transloco.translate("components.progressdisplay.rejected");

            if (last.receiveFailureReason) {
              this.pushMessage(this.transloco.translate("components.progressdisplay.lastcommanderror", { command: this.getCommandName(last.command), type: last.receiveFailureReason.type }))
            }
            else {
              this.pushMessage(this.transloco.translate("components.progressdisplay.lastcommanderrorunkown", { command: this.getCommandName(last.command) }));
            }
            this.pushMessage(this.transloco.translate("components.progressdisplay.released"));

            this.blockActions.emit({
              discover: false,
              getAllMetadata: false,
              other: false
            })
            this.startTimer();
            // this.onRelease.emit(last.command);
            this.hasFinished = true;
          }
          else if (last.command == 1 || last.command == 9) {
            this.pushMessage(this.transloco.translate("components.progressdisplay.commandinprogressrelease", { command: this.getCommandName(last.command) }));

            this.blockActions.emit({
              discover: true,
              getAllMetadata: true,
              other: true
            })
            this.startTimer();
            this.hasFinished = false;
          }
          else if (last.command == 6 || last.command == 7) {
            this.pushMessage(this.transloco.translate("components.progressdisplay.commandinprogressrelease", { command: this.getCommandName(last.command) }));

            this.blockActions.emit({
              discover: true,
              getAllMetadata: true,
              other: true
            })
            this.startTimer();
            this.hasFinished = false;
          }

          else {
            this.pushMessage(this.transloco.translate("components.progressdisplay.commandinprogressrelease", { command: this.getCommandName(last.command) }));

            this.blockActions.emit({
              discover: true,
              getAllMetadata: true,
              other: true
            })
            this.startTimer();
            this.hasFinished = true;
          }
        }
        //recebeu tudo do device
        else if (last.sent && last.received && last.ack) {
          this.lastCommandId = last._id;

          if (last.receiveFailure) {

            this.status.first.css = "current";
            this.status.second.css = "completed";
            this.status.third.css = "rejected "

            this.status.first.message = this.transloco.translate("components.progressdisplay.ready");
            this.status.second.message = this.transloco.translate("components.progressdisplay.acknowledge");
            this.status.third.message = this.transloco.translate("components.progressdisplay.rejected");

            if (last.receiveFailureReason) {
              this.pushMessage(this.transloco.translate("components.progressdisplay.lastcommanderror", { command: this.getCommandName(last.command), type: last.receiveFailureReason.type }))
            }
            else {
              this.pushMessage(this.transloco.translate("components.progressdisplay.lastcommanderrorunkown", { command: this.getCommandName(last.command) }));
            }
            this.pushMessage(this.transloco.translate("components.progressdisplay.released"));

            this.blockActions.emit({
              discover: false,
              getAllMetadata: false,
              other: false
            })
            this.startTimer();
            // this.onRelease.emit(last.command);
            this.hasFinished = true;
          }
          else if (last.processedFailure) {
            this.status.first.css = "current";
            this.status.second.css = "completed";
            this.status.third.css = "rejected "

            this.status.first.message = this.transloco.translate("components.progressdisplay.ready");
            this.status.second.message = this.transloco.translate("components.progressdisplay.acknowledge");
            this.status.third.message = this.transloco.translate("components.progressdisplay.rejected");

            this.pushMessage(this.transloco.translate("components.progressdisplay.commandinprogressrelease", { command: this.getCommandName(last.command), reason: last.processedFailureReason ? last.processedFailureReason.type : "Unknown" }));

            this.blockActions.emit({
              discover: false,
              getAllMetadata: false,
              other: false
            })
            this.startTimer();
          }
          else if (!last.processed) {
            this.status.first.css = "completed";
            this.status.second.css = "completed";
            this.status.third.css = "current";

            this.status.first.message = this.transloco.translate("components.progressdisplay.commandinflight");
            this.status.second.message = this.transloco.translate("components.progressdisplay.acknowledge");
            this.status.third.message = this.transloco.translate("components.progressdisplay.waitingtocomplete");

            this.pushMessage(this.transloco.translate("components.progressdisplay.commandinprogressrelease", { command: this.getCommandName(last.command) }));

            this.blockActions.emit({
              discover: true,
              getAllMetadata: true,
              other: true
            })
            this.startTimer();
            this.hasFinished = false;
          }
          else {
            this.status.first.css = "current";
            this.status.second.css = "completed";
            this.status.third.css = "completed "

            this.status.first.message = this.transloco.translate("components.progressdisplay.ready");
            this.status.second.message = this.transloco.translate("components.progressdisplay.acknowledge");
            this.status.third.message = this.transloco.translate("components.progressdisplay.successful");

            this.pushMessage(this.transloco.translate("components.progressdisplay.lastcommandsuccess", { command: this.getCommandName(last.command) }));
            this.pushMessage(this.transloco.translate("components.progressdisplay.released"));

            this.blockActions.emit({
              discover: false,
              getAllMetadata: false,
              other: false
            })
            this.startTimer();

            if (last._id != this.lastRefresh) {
              this.onRelease.emit(last.command);
              this.lastRefresh = last._id;
            }
            this.hasFinished = true;
          }
        }

      })

    });
  }

  public inprogress: boolean = false;

  public pushMessage(message: string) {
    this.historyMessages.unshift({
      message: message,
      timestamp: moment().format("DD/MM/YYYY HH:mm:ss")
    })

    // if (this.historyMessages.length > 5) {
    //   this.historyMessages = this.historyMessages.splice(0, 5);
    // }
  }


  public getCommandName(cid: number) {
    if (cid > 12 || cid <= 0) {
      return this.transloco.translate("components.progressdisplay.commandsenum.unkown")
    }
    else {
      return this.transloco.translate("components.progressdisplay.commandsenum." + cid.toString());
    }
    // switch (cid) {
    //   case 1:
    //     return "Discover";
    //   case 2:
    //     return "Add";
    //   case 3:
    //     return "Remove";
    //   case 4:
    //     return "List Histories";
    //   case 5:
    //     return "Get History";
    //   case 6:
    //     return "Get All Metadata"
    //   case 7:
    //     return "Get Metadata for single point";
    //   case 8:
    //     return "Get Log History";
    //   case 9:
    //     return "Discover FD Points";
    //   case 10:
    //     return "Create FD Point";
    //   case 11:
    //     return "Delete FD Point";
    //   case 12:
    //     return "Resync";
    //   default:
    //     return "Unkown command";
    // }
  }

}
